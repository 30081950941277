import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import {Container, TableFooter} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ButtonWithIcon from "../../buttons/ButtonWithIcon";
import TableRow from "@material-ui/core/TableRow";
import {getComparator, stableSort} from "../../../services/SortService";
import EmployerFileTableRow from "./EmployerFileTableRow";
import AddFileDialog from "./AddFileDialog";
import EmployerFileTableHeading from "./EmployerFileTableHeading";
import TitleWithButton from "../../TitleWithButton";
import Pagination from "../Pagination";

const EmployerFileTable = ({files, setFiles}) => {

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('creationDate');
    const [addFile, setAddFile] = useState(false);
    const role = localStorage.getItem("role")

    return (
        <Container>
            <TitleWithButton title={"Baza materiałów pracodawcy"} button={role === "EMPLOYER" ? (
                <ButtonWithIcon
                    icon={<AddIcon/>}
                    onClick={() => setAddFile(true)}
                    label={"Dodaj plik"}
                />
            ) : null}/>
            <TableContainer>
                <Table>
                    <EmployerFileTableHeading
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                    />
                    <TableBody>
                        {stableSort(files, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((file, index) =>
                                <EmployerFileTableRow key={file.id} file={file} setFiles={setFiles}/>
                            )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <Pagination
                                rows={files}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <AddFileDialog open={addFile} setOpen={setAddFile} setFiles={setFiles}/>
        </Container>
    );
};

export default EmployerFileTable;
