import React from "react";
import styled from "styled-components";
import logo from "../../assets/logo.png"
import OutlineButtonLink from "../../components/buttons/OutlineButtonLink";
import {makeStyles} from "@material-ui/core/styles";
import Footer from "../../components/Footer";


const PublicHomePage = () => {

    const classes = useStyles();

    return (
        <Container>
            <div className={classes.content}>
                <div className={classes.button}>
                    <OutlineButtonLink label={"Utwórz konto"} href={process.env.REACT_APP_BASE_PATH + "/rejestracja"}/>
                </div>
                <img src={logo} alt="Logo" className={classes.logo}/>
                <div className={classes.button}>
                    <OutlineButtonLink label={"Zaloguj się"} href={process.env.REACT_APP_BASE_PATH + "/logowanie"}/>
                </div>
            </div>
            <Footer/>
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "30px 0",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            alignItems: "space-between",
        },
    },
    logo: {
        display: "flex",
        width: "40vw",
        [theme.breakpoints.down("md")]: {
            width: "70vw"
        },
        [theme.breakpoints.down("sm")]: {
            width: "80vw"
        },
    },
    button: {
        width: "20vw",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
            width: "30vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "50vw"
        },
        [theme.breakpoints.down(350)]: {
            width: "60vw"
        },
    }
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
`;

export default PublicHomePage;