import React, {useContext, useEffect, useState} from 'react'
import {WorkerContext} from "../../contexts/WorkerContext";
import EvaluatorsInfo from "../evaluationProgress/EvaluatorsInfo";
import styled from "styled-components";
import {searchMyDataUsingPOST} from "../../swagger/vue-api-client";
import {convertWorker} from "../../services/WorkerService";
import SummaryChart from "../evaluationSummary/SummaryChart";
import {getWorkerEvaluationResult, getWorkerSelfEvaluationResult, isPublished} from "../../services/EvaluationService";
import Title from "../Title";
import SummaryTable from "../evaluationSummary/SummaryTable";

const MyEvaluation = ({setIsShown}) => {

    const workerContext = useContext(WorkerContext)
    let self = workerContext.me.selfEvaluation
    let coworker = workerContext.me.coworkerEvaluation
    let supervisor = workerContext.me.supervisorEvaluation
    const [result, setResult] = useState([])
    const [show, setShow] = useState(false)
    const [onlySelf, setOnlySelf] = useState(false)

    useEffect(() => {
        searchMyDataUsingPOST({})
            .then((res) => {
                workerContext.setMe(convertWorker(res.data))
                setShow(convertWorker(res.data).status === "ZAKOŃCZONA")
                setIsShown(convertWorker(res.data).status === "ZAKOŃCZONA")
                if (res.data.selfEvaluation != null && !isPublished(res.data.selfEvaluation, res.data.coworkerEvaluation, res.data.supervisorEvaluation)) {
                    setOnlySelf(true)
                    return getWorkerSelfEvaluationResult(res.data.id)
                } else if (isPublished(res.data.selfEvaluation, res.data.coworkerEvaluation, res.data.supervisorEvaluation)) {
                    setOnlySelf(false)
                    return getWorkerEvaluationResult(res.data.id)
                }
                setShow(false)
                setIsShown(false)
            })
            .then((res) => {
                setResult(res)
            })
            .catch((error) => {
                console.log(error.response)
            })
        // workerContext cannot be in a dependency array, because as an effect of above function, it changes
        // eslint-disable-next-line
    }, [])

    if (show) {
        return (
            <Container>
                <Title title={"Wynik oceny"}/>
                <Content>
                    <SummaryTable result={result}/>
                    <ChartContainer>
                        <SummaryChart result={result}/>
                    </ChartContainer>
                    {onlySelf ? null : (
                        <EvaluatorsInfoContainer>
                            <EvaluatorsInfo self={self} coworker={coworker} supervisor={supervisor}/>
                        </EvaluatorsInfoContainer>
                    )}
                </Content>
            </Container>
        )
    } else {
        return null
    }
}

const Container = styled.div`
  width: 70%;
  margin: 5% 15%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
`;

const ChartContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const EvaluatorsInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 30px 50px;
`;

export default MyEvaluation;