import React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {TableCell, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core/styles";
import FinalResultTableRow from "./FinalResultTableRow";

const FinalResultTable = ({data}) => {

    const height = window.innerHeight
    const classes = useStyles({height})

    return (
        <TableContainer className={classes.container}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Zakres kompetencji</TableCell>
                        <TableCell>Start</TableCell>
                        <TableCell>Obecnie</TableCell>
                        <TableCell>Meta</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => <FinalResultTableRow key={index} row={row}/>)}
                </TableBody>
            </Table>
        </TableContainer>
    )
}


const useStyles = makeStyles(theme => ({
    container: props => ({
        maxHeight: props.height !== null ? props.height * 0.75 : 500,
        marginTop: 80,
    }),
}));

export default FinalResultTable;