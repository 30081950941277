import React, {useEffect, useState} from 'react';
import Header from "../../components/Header";
import Subheader from "../../components/Subheader";
import {Colors} from "../../constants/colors";
import {verifyEmailUsingPOST} from "../../swagger/vue-api-client";
import TrademarksHeader from "../../components/TrademarksHeader";
import {makeStyles} from "@material-ui/core/styles";
import LeftTurquoiseContainer from "../../components/LeftTurquoiseContainer";
import RightWhiteContainer from "../../components/RightWhiteContainer";
import Footer from "../../components/Footer";

const EmployerConfirmEmailPage = ({history}) => {

    const classes = useStyles()
    const token = (new URLSearchParams(window.location.search)).get("verificationToken")
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        verifyEmailUsingPOST({emailVerificationRequest: {emailVerificationToken: token}})
            .then((result) => {})
            .catch((error) => {
                setErrorMessage(error.response.data)
            });
    })

    return (
        <div className={classes.container}>
            <TrademarksHeader history={history}/>
            <LeftTurquoiseContainer/>
            <RightWhiteContainer>
                {errorMessage.length > 0 ? (
                    <Header title={errorMessage} color={Colors.RED}/>
                ) : (
                    <div>
                        <Header title={"Twój adres email został zweryfikowany!"} color={Colors.TURQUOISE}/>
                        <Subheader title={"Musisz teraz poczekać aż administrator zweryfikuje Twoje dane i aktywuje konto."} color={Colors.TURQUOISE}/>
                        <Subheader title={"Zostaniesz o tym poinformowany poprzez wiadomość email."} color={Colors.TURQUOISE}/>
                    </div>
                )}
            </RightWhiteContainer>
            <Footer onlySmallScreen={true}/>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100vm",
        height: "100vh",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            fontSize: 14,
            textAlign: "center",
        },
    },
}));

export default EmployerConfirmEmailPage;