import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip} from "@material-ui/core";
import React, {useContext} from "react";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import EvaluatorFormRow from "./EvaluatorFormRow";
import {WorkerContext} from "../../contexts/WorkerContext";
import {makeStyles} from "@material-ui/core/styles";

const EvaluatorsForm = ({worker, selfEvaluation, setSelfEvaluation,
                            coworkerEvaluation, setCoworkerEvaluation,
                            supervisorEvaluation, setSupervisorEvaluation,
                            enabledCompetences}) => {

    const classes = useStyles()
    const workerContext = useContext(WorkerContext)

    return (
        <Container>
            <Header>
                Wskaż zakresy oceny i osoby oceniające
            </Header>
            <TableContainer>
                <Table>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell align="center">typ oceny</TableCell>
                            {workerContext.competences.map((item, index) => {
                                if (item.shortName === "WO")
                                    return null
                                else
                                    return (
                                    <Tooltip title={item.name} placement="top" arrow  key={index}>
                                        <TableCell align="center">{item.shortName}</TableCell>
                                    </Tooltip>
                            )})}
                            <TableCell align="center">oceniający</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <EvaluatorFormRow
                            label={"Samoocena"}
                            evaluation={selfEvaluation}
                            setEvaluation={setSelfEvaluation}
                            enabledCompetences={enabledCompetences}
                            workerName={worker.name}
                            workerId={worker.id}
                        />
                        <EvaluatorFormRow
                            label={"Ocena współpracownika"}
                            evaluatorLabel={"Współpracownik"}
                            evaluation={coworkerEvaluation}
                            enabledCompetences={enabledCompetences}
                            setEvaluation={setCoworkerEvaluation}
                            workerId={worker.id}
                        />
                        <EvaluatorFormRow
                            label={"Ocena przełożonego"}
                            evaluatorLabel={"Przełożony"}
                            evaluation={supervisorEvaluation}
                            enabledCompetences={enabledCompetences}
                            setEvaluation={setSupervisorEvaluation}
                            workerId={worker.id}
                        />
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    tableHead: {
        [theme.breakpoints.down(700)]: {
            display: "none",
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 5% 5%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Header = styled.div`
  margin-bottom: 15px;
  color: ${Colors.GREEN};
  font-weight: bold;
  text-transform: uppercase;
`;

export default EvaluatorsForm;