import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized} from "../../services/LinksService";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router";
import FilesCarousel from "../../components/recommendation/FilesCarousel";
import {WorkerContext} from "../../contexts/WorkerContext";
import {isPublished} from "../../services/EvaluationService";
import {assignedByIdUsingGET, getAllStepsUsingGET, searchMyDataUsingPOST} from "../../swagger/vue-api-client";
import {convertWorker} from "../../services/WorkerService";
import Title from "../../components/Title";
import {Colors} from "../../constants/colors";

const RecommendationPage = ({history}) => {

    const classes = useStyles()
    let {recommendationId} = useParams()
    const [recommendation, setRecommendation] = useState(null)
    const [files, setFiles] = useState([])
    const [show, setShow] = useState(false);
    const workerContext = useContext(WorkerContext)
    let role = localStorage.getItem("role")

    useEffect(() => {
        checkIsUserAuthorized(role, history)
            .then((response) => {
                return searchMyDataUsingPOST({})
            })
            .then((res) => {
                workerContext.setMe(convertWorker(res.data))
                setShow(isPublished(res.data.selfEvaluation, res.data.coworkerEvaluation, res.data.supervisorEvaluation))
                return getAllStepsUsingGET({eLearningId: recommendationId})
            })
            .then((res) => {
                setFiles(res.data);
                return assignedByIdUsingGET({eLearningId: recommendationId})
            })
            .then((res) => {
                setRecommendation(res.data);
            })
            .catch((error) => {
                console.log(error);
            })
        // eslint-disable-next-line
    }, [history, recommendationId, role])

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                {show ?
                    <React.Fragment>
                        {recommendation === null ? null : <Title title={history?.location?.state?.title + ": " + recommendation.name}/>}
                        {files.length > 0 ?
                            <FilesCarousel files={files} recommendation={recommendation} history={history}/> :
                            <div className={classes.messageContainer}>Pracodawca lub lider zmiany powiadomi Cię o szczegółach dotyczących rekomendowanego działania</div>
                        }
                    </React.Fragment> : null}
            </div>
            <Footer/>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
    messageContainer: {
        display: "flex",
        flex: 1,
        minHeight: 200,
        justifyContent: "center",
        marginTop: "20%",
        fontSize: "160%",
        color: Colors.GREEN,
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default RecommendationPage;