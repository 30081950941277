import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import InputField from "../../InputField";
import {Colors} from "../../../constants/colors";
import {RiUserLine} from "react-icons/ri";
import {IoMailOutline} from "react-icons/io5";
import {editWorkerUsingPUT} from "../../../swagger/vue-api-client";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import DialogTitle from "../../DialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import DialogButtonsContainer from "../../DialogButtonsContainer";

const EditWorkerDialog = ({open, setOpen, worker}) => {

    const classes = useStyles()


    const [id] = useState(worker.id);
    const [name, setName] = useState(worker.firstName);
    const [surname, setSurname] = useState(worker.lastName);
    const [email, setEmail] = useState(worker.email);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleEditUser = (userName, userSurname) => {

        setIsLoading(true)
        let newWorkerData = {
            id: id,
            name: userName,
            surname: userSurname,
        }
        editWorkerUsingPUT({
            workerEditDto: newWorkerData
        })
            .then((response) => {
                setOpen(false);
                setErrorMessage('');
                setIsLoading(false);
            })
            .catch((error) => {
                setErrorMessage(error.response.data);
                setIsLoading(false);
            });
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle title={"Edytuj pracownika"}/>
            <div className={classes.container}>
                <InputField
                    value={name}
                    setValue={setName}
                    placeholder={"Imię"}
                    icon={<RiUserLine size={20} color={Colors.LIGHT_GREY}/>}
                />
                <InputField
                    value={surname}
                    setValue={setSurname}
                    placeholder={"Nazwisko"}
                    icon={<RiUserLine size={20} color={Colors.LIGHT_GREY}/>}
                />
                <InputField
                    disabled={true}
                    value={email}
                    setValue={setEmail}
                    placeholder={"Email"}
                    icon={<IoMailOutline size={20} color={Colors.LIGHT_GREY}/>}
                />
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"Anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"Zapisz zmiany"}
                        onClick={() => handleEditUser(name, surname, email)}
                        backgroundColor={Colors.GREEN}
                    />
                </DialogButtonsContainer>
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "70%",
        padding: "20px 15%",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            padding: "20px 2%",
        }
    },
}));

export default EditWorkerDialog;