import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {makeStyles} from "@material-ui/core/styles";
import ErrorAndLoadingMessage from "../../components/ErrorAndLoadingMessage";
import ButtonWithoutIcon from "../../components/buttons/ButtonWithoutIcon";
import {Colors} from "../../constants/colors";
import BasicCompanyData from "../../components/finances/BasicCompanyData";
import WorkersInProgram from "../../components/finances/WorkersInProgram";
import AssumptionsData from "../../components/finances/AssumptionsData";
import ExpectedChanges from "../../components/finances/ExpectedChanges";
import ImplementersInProgram from "../../components/finances/ImplementersInProgram";
import {getEfficiencyCalculationsUsingGET, setEfficiencyCalculationsUsingPOST} from "../../swagger/vue-api-client";
import {CircularProgress} from "@material-ui/core";

const FinancesDataForm = () => {

    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState('')

    const [voivodeship, setVoivodeship] = useState('')
    const [duration, setDuration] = useState('')
    const [workersNumber, setWorkersNumber] = useState(0)
    const [placesNumber, setPlacesNumber] = useState(0)
    const [pupilsNumber, setPupilsNumber] = useState(0)
    const [price, setPrice] = useState(0)
    const [cost, setCost] = useState(0)
    const [dataSource, setDataSource] = useState('')

    const [increasedDemandForExistingServicesOption, setIncreasedDemandForExistingServicesOption] = useState('')
    const [increasedDemandForExistingServicesPercentage, setIncreasedDemandForExistingServicesPercentage] = useState(0)
    const [increasedPricesForExistingServicesOption, setIncreasedPricesForExistingServicesOption] = useState('')
    const [increasedPricesForExistingServicesPercentage, setIncreasedPricesForExistingServicesPercentage] = useState(0)
    const [lowerCostsOfExistingServicesOption, setLowerCostsOfExistingServicesOption] = useState('')
    const [lowerCostsOfExistingServicesPercentage, setLowerCostsOfExistingServicesPercentage] = useState(0)

    const [workers, setWorkers] = useState([])
    const [implementers, setImplementers] = useState([])

    const [coverageEnabled, setCoverageEnabled] = useState(false)
    const [coverage, setCoverage] = useState('')
    const [equipmentEnabled, setEquipmentEnabled] = useState(false)
    const [changesInStructureEnabled, setChangesInStructureEnabled] = useState(false)
    const [changesInStructure, setChangesInStructure] = useState(0)
    const [changesInMotivatingEnabled, setChangesInMotivatingEnabled] = useState(false)
    const [changesInMotivating, setChangesInMotivating] = useState(0)
    const [changesInCommunicationEnabled, setChangesInCommunicationEnabled] = useState(false)
    const [changesInCommunication, setChangesInCommunication] = useState(0)
    const [changesInKnowledgeEnabled, setChangesInKnowledgeEnabled] = useState(false)
    const [changesInKnowledge, setChangesInKnowledge] = useState(0)


    useEffect(() => {
        setIsLoading(true)
        getEfficiencyCalculationsUsingGET()
            .then((res) => {
                let basicDataDTO = res.data.basicDataDTO
                setVoivodeship(basicDataDTO.voivodeship)
                setDuration(basicDataDTO.analysisPeriodInYears)
                setWorkersNumber(basicDataDTO.workersNumber)
                setPlacesNumber(basicDataDTO.placesInResidence)
                setPupilsNumber(basicDataDTO.averageNumberOfWorkersPerMonth)
                setPrice(basicDataDTO.priceOfCareService)
                setCost(basicDataDTO.costOfCareService)
                setDataSource(basicDataDTO.dataSource)

                let expectedChangesInMarketPositionDTO = res.data.expectedChangesInMarketPositionDTO
                setIncreasedDemandForExistingServicesOption(expectedChangesInMarketPositionDTO.increasedDemandForExistingServicesOption)
                setIncreasedDemandForExistingServicesPercentage(expectedChangesInMarketPositionDTO.increasedDemandForExistingServicesPercentage)
                setIncreasedPricesForExistingServicesOption(expectedChangesInMarketPositionDTO.increasedPricesForExistingServicesOption)
                setIncreasedPricesForExistingServicesPercentage(expectedChangesInMarketPositionDTO.increasedPricesForExistingServicesPercentage)
                setLowerCostsOfExistingServicesOption(expectedChangesInMarketPositionDTO.lowerCostsOfExistingServicesOption)
                setLowerCostsOfExistingServicesPercentage(expectedChangesInMarketPositionDTO.lowerCostsOfExistingServicesPercentage)

                let workersTableDataDTO = res.data.workersTableDataDTO
                setWorkers(workersTableDataDTO.workersTableRows)
                setImplementers(workersTableDataDTO.workersTableResponsibleRows)

                let plannedActivitiesDataDTO = res.data.plannedActivitiesDataDTO
                setCoverageEnabled(plannedActivitiesDataDTO.studyVisitGeographicalScope)
                setCoverage(plannedActivitiesDataDTO.studyVisitGeographicalScopeOption)
                setEquipmentEnabled(plannedActivitiesDataDTO.equipmentPurchase)
                setChangesInStructureEnabled(plannedActivitiesDataDTO.organizationalStructureChanges)
                setChangesInStructure(plannedActivitiesDataDTO.organizationalStructureChangesCost)
                setChangesInMotivatingEnabled(plannedActivitiesDataDTO.motivationSystemChanges)
                setChangesInMotivating(plannedActivitiesDataDTO.motivationSystemChangesCost)
                setChangesInCommunicationEnabled(plannedActivitiesDataDTO.communicationSystemChanges)
                setChangesInCommunication(plannedActivitiesDataDTO.communicationSystemChangesCost)
                setChangesInKnowledgeEnabled(plannedActivitiesDataDTO.industryKnowledgeChanges)
                setChangesInKnowledge(plannedActivitiesDataDTO.industryKnowledgeChangesCost)

                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error)
                if (error && error.response && typeof error.response.data === 'string') {
                    setMessage(error.response.data)
                }
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        if (dataSource === "tak") {
            setIncreasedDemandForExistingServicesOption('dane statystyczne')
            setIncreasedPricesForExistingServicesOption('dane statystyczne')
            setLowerCostsOfExistingServicesOption('dane statystyczne')
            workers.forEach((worker) => {
                worker.salaryOption = 'wartość z bazy danych statystycznych'
                worker.workshopTrainingCostOption = 'wartość z bazy danych statystycznych'
                worker.advisoryTrainingCostOption = 'wartość z bazy danych statystycznych'
                worker.practicalTrainingCostOption = 'wwartość z bazy danych statystycznych'
            })
            implementers.forEach((implementer) => {
                implementer.salaryOption = 'wartość z bazy danych statystycznych'
            })
        } else if (dataSource === "nie - wyłącznie dane własne") {
            setIncreasedDemandForExistingServicesOption('dane własne')
            setIncreasedPricesForExistingServicesOption('dane własne')
            setLowerCostsOfExistingServicesOption('dane własne')
            workers.forEach((worker) => {
                worker.salaryOption = 'wartość podana samodzielnie'
                worker.workshopTrainingCostOption = 'wartość podana samodzielnie'
                worker.advisoryTrainingCostOption = 'wartość podana samodzielnie'
                worker.practicalTrainingCostOption = 'wartość podana samodzielnie'
            })
            implementers.forEach((implementer) => {
                implementer.salaryOption = 'wartość podana samodzielnie'
            })
        }
    }, [dataSource])

    const getWorkers = () => {
        let ans = []
        workers.forEach((item, index) => {
            ans.push({
                fullName: item.fullName,
                position: item.position,
                engagementDimension: parseFloat(item.engagementDimension),
                salaryOption: item.salaryOption,
                salary: parseFloat(item.salary),
                workshopTrainingCostOption: item.workshopTrainingCostOption,
                workshopTrainingCost: parseFloat(item.workshopTrainingCost),
                advisoryTrainingCostOption: item.advisoryTrainingCostOption,
                advisoryTrainingCost: parseFloat(item.advisoryTrainingCost),
                practicalTrainingCostOption: item.practicalTrainingCostOption,
                practicalTrainingCost: parseFloat(item.practicalTrainingCost),
            })
        })
        return ans
    }

    const getImplementers = () => {
        let ans = []
        implementers.forEach((item, index) => {
            ans.push({
                workerResponsibleForImplementation: item.workerResponsibleForImplementation,
                position: item.position,
                engagementDimension: item.engagementDimension,
                salaryOption: item.salaryOption,
                salary: item.salary,
            })
        })
        return ans
    }

    const handleCalculate = () => {
        setIsLoading(true)

        let body = {
            basicDataDTO: {
                voivodeship: voivodeship,
                analysisPeriodInYears: parseInt(duration),
                workersNumber: parseInt(workersNumber),
                placesInResidence: parseInt(placesNumber),
                averageNumberOfWorkersPerMonth: parseFloat(pupilsNumber),
                priceOfCareService: parseFloat(price),
                costOfCareService: parseFloat(cost),
                dataSource: dataSource,
            },
            expectedChangesInMarketPositionDTO: {
                increasedDemandForExistingServicesOption: increasedDemandForExistingServicesOption,
                increasedDemandForExistingServicesPercentage: parseFloat(increasedDemandForExistingServicesPercentage),
                increasedPricesForExistingServicesOption: increasedPricesForExistingServicesOption,
                increasedPricesForExistingServicesPercentage: parseFloat(increasedPricesForExistingServicesPercentage),
                lowerCostsOfExistingServicesOption: lowerCostsOfExistingServicesOption,
                lowerCostsOfExistingServicesPercentage: parseFloat(lowerCostsOfExistingServicesPercentage),
            },
            workersTableDataDTO: {
                workersTableRows: getWorkers(),
                workersTableResponsibleRows: getImplementers(),
            },
            plannedActivitiesDataDTO: {
                studyVisitGeographicalScope: coverageEnabled,
                studyVisitGeographicalScopeOption: coverage,
                equipmentPurchase: equipmentEnabled,
                organizationalStructureChanges: changesInStructureEnabled,
                organizationalStructureChangesCost: parseFloat(changesInStructure),
                motivationSystemChanges: changesInMotivatingEnabled,
                motivationSystemChangesCost: parseFloat(changesInMotivating),
                communicationSystemChanges: changesInCommunicationEnabled,
                communicationSystemChangesCost: parseFloat(changesInCommunication),
                industryKnowledgeChanges: changesInKnowledgeEnabled,
                industryKnowledgeChangesCost: parseFloat(changesInKnowledge),
            },
        }

        setEfficiencyCalculationsUsingPOST({efficiencyMain: body})
            .then((res) => {
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })

    }

    if (isLoading)
        return <div className={classes.loadingContainer}><CircularProgress/></div>

    if (message.length > 0)
        return <div className={classes.loadingContainer}>{message}</div>

    return (
        <Container>
            <div className={classes.description}>
                Proszę podać podstawowe informacje o działalności podmiotu oraz założeniach wdrożeniowych,
                by możliwa była wycena kosztów i korzyści implemenctacji narzędzia Reskilling.
            </div>

            <BasicCompanyData
                voivodeship={voivodeship} setVoivodeship={setVoivodeship}
                duration={duration} setDuration={setDuration}
                workersNumber={workersNumber} setWorkersNumber={setWorkersNumber}
                placesNumber={placesNumber} setPlacesNumber={setPlacesNumber}
                pupilsNumber={pupilsNumber} setPupilsNumber={setPupilsNumber}
                price={price} setPrice={setPrice}
                cost={cost} setCost={setCost}
                dataSource={dataSource} setDataSource={setDataSource}
            />
            {(dataSource === "nie - wyłącznie dane własne" || dataSource === "nie - dane mieszane") &&
                <ExpectedChanges
                    increasedDemandForExistingServicesOption={increasedDemandForExistingServicesOption}
                    setIncreasedDemandForExistingServicesOption={setIncreasedDemandForExistingServicesOption}
                    increasedDemandForExistingServicesPercentage={increasedDemandForExistingServicesPercentage}
                    setIncreasedDemandForExistingServicesPercentage={setIncreasedDemandForExistingServicesPercentage}
                    increasedPricesForExistingServicesOption={increasedPricesForExistingServicesOption}
                    setIncreasedPricesForExistingServicesOption={setIncreasedPricesForExistingServicesOption}
                    increasedPricesForExistingServicesPercentage={increasedPricesForExistingServicesPercentage}
                    setIncreasedPricesForExistingServicesPercentage={setIncreasedPricesForExistingServicesPercentage}
                    lowerCostsOfExistingServicesOption={lowerCostsOfExistingServicesOption}
                    setLowerCostsOfExistingServicesOption={setLowerCostsOfExistingServicesOption}
                    lowerCostsOfExistingServicesPercentage={lowerCostsOfExistingServicesPercentage}
                    setLowerCostsOfExistingServicesPercentage={setLowerCostsOfExistingServicesPercentage}
                    dataSource={dataSource}
                />
            }
            <WorkersInProgram
                workers={workers} setWorkers={setWorkers} dataSource={dataSource}
            />
            <ImplementersInProgram
                workers={implementers} setWorkers={setImplementers} dataSource={dataSource}
            />
            <AssumptionsData
                coverageEnabled={coverageEnabled} setCoverageEnabled={setCoverageEnabled}
                coverage={coverage} setCoverage={setCoverage}
                equipmentEnabled={equipmentEnabled} setEquipmentEnabled={setEquipmentEnabled}
                changesInStructureEnabled={changesInStructureEnabled}
                setChangesInStructureEnabled={setChangesInStructureEnabled}
                changesInStructure={changesInStructure} setChangesInStructure={setChangesInStructure}
                changesInMotivatingEnabled={changesInMotivatingEnabled}
                setChangesInMotivatingEnabled={setChangesInMotivatingEnabled}
                changesInMotivating={changesInMotivating} setChangesInMotivating={setChangesInMotivating}
                changesInCommunicationEnabled={changesInCommunicationEnabled}
                setChangesInCommunicationEnabled={setChangesInCommunicationEnabled}
                changesInCommunication={changesInCommunication} setChangesInCommunication={setChangesInCommunication}
                changesInKnowledgeEnabled={changesInKnowledgeEnabled}
                setChangesInKnowledgeEnabled={setChangesInKnowledgeEnabled}
                changesInKnowledge={changesInKnowledge} setChangesInKnowledge={setChangesInKnowledge}
                dataSource={dataSource}
            />

            <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={""}/>
            <ButtonsContainer>
                <ButtonWithoutIcon
                    label={"Oblicz koszty i korzyści"}
                    onClick={handleCalculate}
                />
            </ButtonsContainer>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
    description: {
        marginBottom: "4%",
        marginTop: "4%",
        fontSize: 17,
        color: Colors.GREY,
        [theme.breakpoints.down(600)]: {
            textAlign: "center",
            marginTop: 10,
        }
    },
    loadingContainer: {
        display: "flex",
        flex: 1,
        minHeight: 200,
        justifyContent: "center",
        marginTop: "10%",
        fontSize: 18,
        color: Colors.GREY,
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

const ButtonsContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FinancesDataForm;