import React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {TableCell, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Result4TableRow from "./Result4TableRow";
import {makeStyles} from "@material-ui/core/styles";

const Result4Table = ({result}) => {

    const classes = useStyles()

    return (
        <div>
            <TableContainer className={classes.container}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Zakres</TableCell>
                            <TableCell>Wartość [PLN]</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.map((row, index) => <Result4TableRow key={index} row={row}/>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer className={classes.printContainer}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.printHeadCell}>Zakres</TableCell>
                            <TableCell className={classes.printHeadCell}>Wartość [PLN]</TableCell>
                        </TableRow>
                        {result.map((row, index) => <Result4TableRow key={index} row={row}/>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "inline",
        [`@media print`]: {
            display: "none",
        }
    },
    printContainer: {
        display: "none",
        [`@media print`]: {
            display: "inline",
            overflow: "visible",
        }
    },
    printHeadCell: {
        [`@media print`]: {
            fontWeight: "bold",
        }
    }
}));


export default Result4Table;