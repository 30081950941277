import React, {useContext, useEffect} from 'react';
import styled from "styled-components";
import {searchCompanyUsingPOST} from "../../swagger/vue-api-client";
import {checkIsUserAuthorized} from "../../services/LinksService";
import {getClearSearchCriteria} from "../../services/SearchService";
import CompanyTable from "../../components/table/company/CompanyTable";
import {AdminContext} from "../../contexts/AdminContext";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";

const CompaniesPage = ({history}) => {

    const classes = useStyles()
    const adminContext = useContext(AdminContext)

    useEffect(() => {
        checkIsUserAuthorized("ADMIN", history)
            .then((response) => {
                return searchCompanyUsingPOST({searchCriteria: getClearSearchCriteria()});
            })
            .then((res) => {
                adminContext.setCompanies(res.data.filter(company =>
                    company.employers.length > 0 && company.employers[0].active === true).map((item, index) => {
                        return {
                            id: item.id,
                            name: item.name,
                            employerName: item.employers[0].name,
                            email: item.email,
                            description: item.description,
                            phoneNumber: item.phoneNumber,
                            employerEmail: item.employers[0].email,
                            employerId: item.employers[0].id,
                            workers: item.workers,
                            agreementForDataSharing: item.agreementForDataSharing,
                            publishedForPublicAccess : item.publishedForPublicAccess,
                            logoFile: item.logoFile,
                            logoUrl: item.logoUrl
                        }
                }));
                adminContext.setCompaniesToAccept(res.data.filter(company =>
                    company.employers.length > 0 && company.employers[0].active === false).map((item, index) => {
                        return {
                        id: item.id,
                        name: item.name,
                        employerName: item.employers[0].name,
                        email: item.email,
                        description: item.description,
                        phoneNumber: item.phoneNumber,
                        employerEmail: item.employers[0].email,
                        employerId: item.employers[0].id,
                        workers: item.workers,
                        agreementForDataSharing: item.agreementForDataSharing,
                        publishedForPublicAccess : item.publishedForPublicAccess,
                        logoFile: item.logoFile,
                        logoUrl: item.logoUrl
                    }
                }));
            })
            .catch((error) => {
                console.log(error)
            })

        // adminContext cannot be in a dependency array, because as an effect of above function, it changes
        // eslint-disable-next-line
    }, [history])

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                {adminContext.companiesToAccept.length > 0 ? (
                    <React.Fragment>
                        <CompanyTable
                            title={"Oczekujące na akceptację"}
                            selectable={true}
                            rows={adminContext.companiesToAccept}
                        />
                        <div style={{height: 50}}/>
                    </React.Fragment>
                ) : null}
                {adminContext.companies.length > 0 ? (
                    <CompanyTable
                        title={"Zarejestrowane firmy"}
                        selectable={false}
                        rows={adminContext.companies}
                    />
                ) : null}
            </div>
            <Footer/>
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default CompaniesPage;