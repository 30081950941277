import {Tooltip} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";

const PointWithLabel = ({label, percent, isLast=false, placement="top"}) => {

    const classes = useStyles()

    return (
            <div>
                <Tooltip title={label} placement={placement} arrow>
                    <div className={classes.bigScreen}>
                        <PointContainer isLast={isLast}>
                            <Point>{Math.round(percent)}%</Point>
                        </PointContainer>
                    </div>
                </Tooltip>
                <div className={classes.smallScreen}>
                    <PointContainerSmallScreen isLast={isLast}>
                        <Point>{Math.round(percent)}%</Point>
                    </PointContainerSmallScreen>
                    <Label isLast={isLast}>{label}</Label>
                </div>
            </div>
    )
};


const useStyles = makeStyles(theme => ({
    smallScreen: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.up(800)]: {
            display: "none",
        }
    },
    bigScreen: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down(800)]: {
            display: "none",
        }
    },
}));


const PointContainer = styled.div`
  width: ${params => params.isLast ? '64px' : '30px'};
  height: ${params => params.isLast ? '64px' : '30px'};
  margin: ${params => params.isLast ? '-17px' : '0px'};
  padding: 17px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${params => params.isLast ? Colors.WHITE : 'transparent'};
  transition: all 0.4s;
  z-index: 1;
  font-size: 17px;

  &:hover {
    width: 64px;
    height: 64px;
    margin: -17px;
    color: ${Colors.WHITE};
  }
`;

const PointContainerSmallScreen = styled.div`
  width: ${params => params.isLast ? '64px' : '30px'};
  min-width: ${params => params.isLast ? '64px' : '30px'};
  height: ${params => params.isLast ? '64px' : '30px'};
  margin: ${params => params.isLast ? '-17px' : '0px'};
  padding: 17px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${params => params.isLast ? Colors.WHITE : 'transparent'};
  transition: all 0.4s;
  z-index: 1;
  font-size: 17px;

  &:hover {
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin: -17px;
    color: ${Colors.WHITE};
  }
`;

const Label = styled.div`
  margin-left: ${params => params.isLast ? "34px" : "17px"};
`;

const Point = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background-color: ${Colors.TURQUOISE};
`;

export default PointWithLabel;