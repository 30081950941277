import React from "react";

export const WorkerContext = React.createContext({
    evaluations: undefined,
    setEvaluations: () => {},

    me: undefined,
    setMe: () => {},

    competences: undefined,
    setCompetences: () => {},
});