import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import styled from "styled-components";
import DialogTitle from "../../DialogTitle";
import TextInput from "../../TextInput";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import {Colors} from "../../../constants/colors";
import DialogButtonsContainer from "../../DialogButtonsContainer";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import {createQuizFromTemplateUsingPOST} from "../../../swagger/vue-api-client";
import {convertPost} from "../../../services/WorkerService";

const AddQuizDialog = ({open, setOpen, setPosts}) => {

    const [keyWord, setKeyWord] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const handleAddQuiz = (keyword) => {
        setIsLoading(true)
        createQuizFromTemplateUsingPOST({keyword: keyword})
            .then((res) => {
                setErrorMessage('')
                setOpen(false)
                setPosts(current => [...current, convertPost(res.data)])
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                setErrorMessage("Nie udało się stworzyć ankiety.")
                console.log(error)
            })
    }

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle title={"Wyślij ankietę"}/>
            <Container>
                <TextInput label={"Słowo klucz"} value={keyWord} setValue={setKeyWord}/>
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"Anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"Wyślij"}
                        onClick={() => handleAddQuiz(keyWord)}
                        backgroundColor={Colors.GREEN}
                    />
                </DialogButtonsContainer>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 5%;
  width: 90%;
`;

export default AddQuizDialog;