import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";
import {formatNumberWithSpaces} from "../../services/NumberService";

const Result1TableRow = ({row, showDetails, isVariant2}) => {

    let bolded = row.type === "K" || row.type === "S"
    let colored = row.type === "S"
    const classes = useStyles({bolded, colored})

    const getWorkers = () => {
        return isVariant2 ? row.workers2 : row.workers1
    }

    const format = (number) => {
        return formatNumberWithSpaces(number)
    }

    return (
        <React.Fragment>
            <TableRow className={classes.row}>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.leftAlignCell}>{row.description}</TableCell>
                <TableCell className={classes.cell}>{isVariant2 ? format(row.sumSingle2) : format(row.sumSingle1)}</TableCell>
                {showDetails ? getWorkers().map((item, index) => (
                    <TableCell className={classes.cell} key={index + "row"}>{format(item)}</TableCell>
                )) : null}
                <TableCell className={classes.cell}>{isVariant2 ? format(row.sumAll2) : format(row.sumAll1)}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    cell: props => ({
        fontWeight: props.bolded ? "bold" : "normal",
        backgroundColor: props.bolded ? (props.colored ? Colors.PASTEL_TURQUOISE : Colors.VERY_LIGHT_GREY) : "white",
        textAlign: "center",
    }),
    leftAlignCell: props => ({
        fontWeight: props.bolded ? "bold" : "normal",
        backgroundColor: props.bolded ? (props.colored ? Colors.PASTEL_TURQUOISE : Colors.VERY_LIGHT_GREY) : "white",
        textAlign: "left",
    }),
    row: {
        [`@media print`]: {
            pageBreakInside: "avoid",
        }
    }
}));

export default Result1TableRow;