import {TableCell, TableRow} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import TurquoiseRadio from "../TurquoiseRadio";

const EvaluationQuestion = ({question, answer, invertedScale, handleChangeAnswer, even, triedToFinish, sent}) => {

    const [selectedValue, setSelectedValue] = useState(answer);
    const answerScale = invertedScale ? [5,4,3,2,1] : [1,2,3,4,5]

    useEffect(() => {setSelectedValue(answer)}, [answer]);

    let backgroundColor = (triedToFinish && selectedValue === undefined) ? (Colors.GREEN2) : (even ? Colors.PASTEL_TURQUOISE : Colors.WHITE)
    const classes = useStyles({backgroundColor})

    const handleChange = (event) => {
        setSelectedValue(parseInt(event.target.value));
        handleChangeAnswer(parseInt(event.target.value));
    };

    return (
        <TableRow classes={{root: classes.row}}>
            <TableCell classes={{root: classes.cellRoot}}>{question}</TableCell>
            {answerScale.map((item, index) => (
                <TableCell align={"center"} classes={{root: classes.cellRoot}} key={index}>
                    <TurquoiseRadio
                        checked={selectedValue === item}
                        onChange={handleChange}
                        value={item}
                        disabledProp={sent}
                    />
                </TableCell>
            ))}
        </TableRow>
    )
}

const useStyles = makeStyles(theme => ({
    row: props => ({
        backgroundColor: props.backgroundColor,
    }),
    cellRoot: {
        border: "none",
        fontSize: 15,
    },
}));

export default EvaluationQuestion;