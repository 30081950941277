import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import logoWhite from "../assets/logo-white.png";
import trademarks from "../assets/trademarks.png";
import {makeStyles} from "@material-ui/core/styles";
import {IoArrowBackOutline} from "react-icons/all";
import {Colors} from "../constants/colors";
import OnlyIconButton from "./buttons/OnlyIconButton";

const TrademarksHeader = ({history}) => {

    const classes = useStyles()

    return (
        <div>
            <div className={classes.backButton}>
                <OnlyIconButton
                onClick={() => history.goBack()}
                icon={<IoArrowBackOutline color={Colors.WHITE} size={40}/>}
                backgroundColor={Colors.TURQUOISE}
            />
            </div>
            <div className={classes.backHome}><Link to={"/"}><img className={classes.homeLogo} src={logoWhite} alt={"logo"}/></Link></div>
            <div className={classes.trademarks}><Logos src={trademarks} alt={"trademarks"}/></div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    trademarks: {
        position: "absolute",
        right: 30,
        top: 30,
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    },
    backHome: {
        position: "absolute",
        left: 30,
        top: 30,
        [theme.breakpoints.down("md")]: {
            right: 20,
            top: 20,
            left: "unset",
        },
    },
    backButton: {
        position: "absolute",
        display: "none",
        [theme.breakpoints.down("md")]: {
            display: "flex",
            left: 20,
            top: 20,
        },
    },
    homeLogo: {
        display: "block",
        height: 110,
        [theme.breakpoints.down("sm")]: {
            height: 70,
        },
    }
}));

const Logos = styled.img`
  display: block;
  width: 30vw;
`;

export default TrademarksHeader;