import React from "react";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import ClearIcon from "@material-ui/icons/Clear";
import {makeStyles} from "@material-ui/core/styles";

const EvaluatorInfoColumn = ({evaluation, label}) => {

    const classes = useStyles()
    const notSelected = () => (
        <Content>
            <ClearIcon style={{color: Colors.GREY}}/>
        </Content>
    )

    const getCompetencesList = (competences, evaluator) => (
        <Content>
            <EvaluatorName>
                {evaluator.name}
            </EvaluatorName>
            <List>
                {competences.map((competence, index) => (
                    <ListItem key={index}>{competence.name}</ListItem>
                ))}
            </List>
        </Content>
    );

    return (
        <div className={classes.container}>
            <Header>{label}</Header>
            {evaluation === null ? notSelected() : getCompetencesList(evaluation.competences, evaluation.evaluator)}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        [theme.breakpoints.down(800)]: {
            paddingBottom: "5%",
            marginBottom: "5%",
            borderBottomColor: Colors.LIGHTER_GREY,
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
        }
    },
}));

const Header = styled.div`
  margin-bottom: 20px;
  color: ${Colors.GREEN};
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const EvaluatorName = styled.div`
  margin-bottom: 20px;
  color: ${Colors.GREY};
  width: 100%;
  text-align: center;
`;

const List = styled.div`
  list-style-type: none;
`;

const ListItem = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

export default EvaluatorInfoColumn;