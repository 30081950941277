import React from 'react';
import styled from "styled-components";
import {Colors} from "../../constants/colors";

const ButtonWithoutIcon = ({label, onClick, color=Colors.WHITE, backgroundColor=Colors.TURQUOISE, disabled}) => {
    return (
        disabled ? (
            <DisabledButton onClick={onClick} color={color} backgroundColor={backgroundColor} disabled>
                {label}
            </DisabledButton>
        ) : (
            <ClickableButton onClick={onClick} color={color} backgroundColor={backgroundColor}>
                {label}
            </ClickableButton>
        )
    );
};

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-width: 0;
  border-radius: 50px;
  font-size: 1em;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px 15px;
  text-align: center;
  transition: opacity 0.3s;
`;

const ClickableButton = styled(Button)`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  cursor: pointer;

  &:focus, &:hover, &:active {
    outline: none;
  }

  &:hover, &:active {
    opacity: 0.7;
  }
`;

const DisabledButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
`;

export default ButtonWithoutIcon;