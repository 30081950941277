export const getClearSearchCriteria = () => {
    return {
        page: {
            limit: 100,
            offset: 0,
            sortField: null,
            sortOrder: null,
        },
    };
};

export const getCompanyWorkerSearchCriteria = () => {
    const companyId = localStorage.getItem("companyId");

    return {
        companyId: companyId,
        page: {
            limit: 100,
            offset: 0,
            sortField: null,
            sortOrder: null,
        },
    };
};

export const getEvaluationWithIdSearchCriteria = (evaluationId) => {
    return {
        id: evaluationId,
        page: {
            limit: 100,
            offset: 0,
            sortField: null,
            sortOrder: null,
        },
    };
};

export const getEvaluationWorkerSearchCriteria = (workerId) => {
    return {
        workerId: workerId,
        page: {
            limit: 100,
            offset: 0,
            sortField: null,
            sortOrder: null,
        },
    };
};

export const getEvaluationEvaluatorSearchCriteria = (evaluatorId) => {
    return {
        evaluatorId: evaluatorId,
        page: {
            limit: 100,
            offset: 0,
            sortField: null,
            sortOrder: null,
        },
    };
};

export const getSinglePostSearchCriteria = (id) => {
    return {
        postId: id,
        page: {
            limit: 100,
            offset: 0,
            sortField: null,
            sortOrder: null,
        },
    };
};