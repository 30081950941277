import React from 'react';
import styled from "styled-components";
import {Colors} from "../../constants/colors";

const ButtonWithIcon = ({label, onClick, color=Colors.WHITE, backgroundColor=Colors.TURQUOISE,
                            icon, disabled, iconOnLeft=false}) => {

    const buttonInside = (
        <React.Fragment>
            {iconOnLeft ? <LeftIcon>{icon}</LeftIcon> : null}
            <Label>{label}</Label>
            {!iconOnLeft ? <RightIcon>{icon}</RightIcon> : null}
        </React.Fragment>
    )

    return (
        disabled ? (
            <DisabledButton onClick={onClick} color={color} backgroundColor={backgroundColor} disabled>
                {buttonInside}
            </DisabledButton>
        ) : (
            <ClickableButton onClick={onClick} color={color} backgroundColor={backgroundColor}>
                {buttonInside}
            </ClickableButton>
        )
    );
};

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-width: 0;
  border-radius: 50px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  padding: 5px 15px;
  text-align: center;
  transition: opacity 0.3s;
`;

const ClickableButton = styled(Button)`
  background-color: ${props => props.backgroundColor};
  cursor: pointer;
  color: ${props => props.color};

  &:focus, &:hover, &:active {
    outline: none;
  }

  &:hover, &:active {
    opacity: 0.5;
  }
`;

const DisabledButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
`;

const Label = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftIcon = styled.div`
    margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RightIcon = styled.div`
    margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ButtonWithIcon;