import React from 'react';
import styled from "styled-components";
import {Colors} from "../constants/colors";

const DataField = ({value, title}) => {

    return (
        <Container>
            <TitleText>
                {title}
            </TitleText>
            <Text>
                {value}
            </Text>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0.5em;
`;

const TitleText = styled.div`
  flex: 1;
  padding: 1em;
  color: ${Colors.GREY};
  font-weight: bold;
`;

const Text = styled.div`
  flex: 2;
  padding: 1em;
  color: ${Colors.GREY};
`;

export default DataField;
