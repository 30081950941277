import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized} from "../../services/LinksService";
import {searchPostUsingPOST} from "../../swagger/vue-api-client";
import {getSinglePostSearchCriteria} from "../../services/SearchService";
import {useParams} from "react-router";
import PostTable from "../../components/table/post/PostTable";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";

const ForumPostPage = ({history}) => {

    const classes = useStyles()
    let { postId } = useParams();

    const [post, setPost] = useState(null)
    const [comments, setComments] = useState([])

    useEffect(() => {
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((res) => {
                return searchPostUsingPOST({postSearchCriteria: getSinglePostSearchCriteria(postId)})
            })
            .then((res) => {
                if (res.data.length > 0) {
                    let post = res.data[0]
                    let comments = res.data[0].comments
                    let postInCommentFormat = {
                        id: 0,
                        author: post.author,
                        creationDate: post.creationDate,
                        content: post.content,
                        version: post.version,
                    };
                    setPost(post)
                    setComments([postInCommentFormat].concat(comments))
                }
            })
            .catch((error) => {
                console.log(error.response)
            })
    }, [history, postId])

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <PostTable comments={comments} setComments={setComments}
                           post={post} setPost={setPost} history={history}/>
            </div>
            <Footer/>
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default ForumPostPage;