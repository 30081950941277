import React from "react";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import CompetencesGroup from "./CompetencesGroup";
import {makeStyles} from "@material-ui/core/styles";

const CompetencesForm = ({competences, setCompetences, setEnabledCompetences}) => {

    const classes = useStyles()

    const handleChange = (index, newGroup) => {
        competences.splice(index, 1, newGroup);
        setCompetences(competences);

        let newEnabledCompetences = [];
        for(let i = 0; i < competences.length; ++i) {
            if(competences[i].enabled) {
                newEnabledCompetences.push(competences[i].id);
            }
        }

        setEnabledCompetences(newEnabledCompetences);
    };

    const getCompetencesGroup = (index, group) => (
        <CompetencesGroup
            group={group}
            onChange={(value) => handleChange(index, value)}
        />
    );
    return (
        <Container>
            <Header>Wskaż kompetencje do oceny</Header>
            <div className={classes.form}>
                {competences.length >= 1 ? getCompetencesGroup(0, competences[0]) : null}
                {competences.length >= 2 ? getCompetencesGroup(1, competences[1]) : null}
                {competences.length >= 3 ? getCompetencesGroup(2, competences[2]) : null}
                {competences.length >= 4 ? getCompetencesGroup(3, competences[3]) : null}
            </div>
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    form: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0 5%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Header = styled.div`
  margin-left: 20px;
  margin-bottom: 30px;
  color: ${Colors.GREEN};
  font-weight: bold;
  text-transform: uppercase;
`;

export default CompetencesForm;