import DoneIcon from "@material-ui/icons/Done";
import React from "react";
import styled from "styled-components";
import ButtonWithIcon from "../../buttons/ButtonWithIcon";
import {CircularProgress} from "@material-ui/core";
import TitleWithButton from "../../TitleWithButton";

const CompanyTableHeader = ({numSelected, title, selectable, onAccept, isLoading}) => {

    return (
        <TitleWithButton title={title} button={
            <React.Fragment>
                {isLoading ? (
                    <LoadingIconContainer>
                        <CircularProgress/>
                    </LoadingIconContainer>
                ) : null}

                {selectable ? (
                    numSelected > 0 ? (
                        <ButtonWithIcon icon={<DoneIcon/>} onClick={onAccept} label={"Zaakceptuj"}/>
                    ) : (
                        <ButtonWithIcon icon={<DoneIcon/>} label={"Zaakceptuj"} disabled={true}/>
                    )
                ) : null}
            </React.Fragment>
        }/>
    );
};

const LoadingIconContainer = styled.div`
  margin: 0 15px;
`;

export default CompanyTableHeader;