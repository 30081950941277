import {Dialog} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import DialogTitle from "../DialogTitle";
import Subheader from "../Subheader";
import RecommendationWorkersTable from "./RecommendationWorkersTable";
import ButtonWithoutIcon from "../buttons/ButtonWithoutIcon";
import DialogButtonsContainer from "../DialogButtonsContainer";
import {makeStyles} from "@material-ui/core/styles";
import ErrorAndLoadingMessage from "../ErrorAndLoadingMessage";
import {Colors} from "../../constants/colors";

const WorkersListDialog = ({open, setOpen, workersRecommendations, setWorkerRecommendations, title, color}) => {

    const classes = useStyles()
    const [localWorkersRecommendations, setLocalWorkersRecommendations] = useState([])

    const [recommendedAll, setRecommendedAll] = useState(false)
    const [doneAll, setDoneAll] = useState(false)
    const [supervisionAll, setSupervisionAll] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setLocalWorkersRecommendations(workersRecommendations.map((item, index) => {
            return {
                worker: item.worker,
                recommendation: item.recommendation,
                recommended: item.recommended,
                done: item.done,
                supervision: item.supervision,
                isRecommendedDisabled: item.isRecommendedDisabled,
                isDoneDisabled: item.isDoneDisabled,
            }
        }))
    }, [workersRecommendations])

    const handleSaveChanges = () => {
        setIsLoading(true)

        let body = localWorkersRecommendations.map((item, index) => {
            return {
                worker: item.worker,
                recommendation: item.recommendation,
                recommended: item.recommended || recommendedAll,
                done: item.done || doneAll,
                supervision: supervisionAll !== '' ? supervisionAll : Number(item.supervision),
            }
        })

        setWorkerRecommendations(body)
        setIsLoading(false)
        setOpen(false)
    }

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'md'} fullWidth={true}>
            <DialogTitle title={title}/>
            <div className={classes.container}>
                <Subheader title={"Lista pracowników, którym zaleca się realizację tego zadania"} color={color}/>
                <RecommendationWorkersTable
                    workersRecommendations={localWorkersRecommendations}
                    setWorkersRecommendations={setLocalWorkersRecommendations}
                    recommendedAll={recommendedAll} setRecommendedAll={setRecommendedAll}
                    doneAll={doneAll} setDoneAll={setDoneAll}
                    supervisionAll={supervisionAll} setSupervisionAll={setSupervisionAll}
                />
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={''}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"Anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"Zatwierdź"}
                        onClick={handleSaveChanges}
                    />
                </DialogButtonsContainer>
                <div className={classes.info}>
                    Po zatwierdzeniu wszystkich wybranych zaleceń, należy użyć przycisku "ZAPISZ ZMIANY" w panelu głównym.
                </div>
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "7%",
    },
    info: {
        marginTop: "2%",
        fontSize: "20px",
        textAlign: "center",
        color: Colors.GREY,
    },
}));

export default WorkersListDialog;