import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import {deleteCompanyUsingDELETE} from "../../../swagger/vue-api-client";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import DialogTitle from "../../DialogTitle";
import DialogButtonsContainer from "../../DialogButtonsContainer";

const DeleteCompanyDialog = ({open, setOpen, company, setCompanies}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onDeleteCompany = () => {

        setIsLoading(true);

        deleteCompanyUsingDELETE({id: company.id})
            .then((res) => {
                setCompanies(current => current.filter(c => c.id !== company.id))
                setErrorMessage('')
                setIsLoading(false)
                setOpen(false)
            })
            .catch((error) => {
                setErrorMessage(error.response.data)
                setIsLoading(false)
            })
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle title={"Czy na pewno chcesz usunąć firmę " + company.name + "?"}/>
            <Container>
                <ErrorAndLoadingMessage errorMessage={errorMessage} isLoading={isLoading}/>
                <DialogButtonsContainer onlySmallScreen={false}>
                    <ButtonWithoutIcon
                        label={"anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"usuń"}
                        onClick={() => onDeleteCompany(company.id)}
                        backgroundColor={Colors.RED}
                    />
                </DialogButtonsContainer>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 20px 40px 20px;
  text-align: center;
`;

export default DeleteCompanyDialog;