import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";
import {FiDownload} from "react-icons/fi";
import ButtonWithIconLink from "../buttons/ButtonWithIconLink";

const FileDownload = ({file, width}) => {

    const classes = useStyles({width})

    return (
        <div className={classes.downloadContainer}>
            <div className={classes.name}>Nie udało się wyświetlić podglądu pliku.</div>
            <div className={classes.downloadButtonWithLabel}>
                <ButtonWithIconLink
                    icon={<FiDownload size={20} color={Colors.WHITE}/>}
                    label="Pobierz plik"
                    href={file.contentText}
                />
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    downloadContainer: props => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: props.width,
        height: 300,

    }),
    downloadButtonWithLabel: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    name: {
        marginBottom: 40,
        color: Colors.TURQUOISE,
        fontSize: 20,
    },
    description: {
        marginBottom: 60,
        textAlign: "center",
    },
    label: {
        color: Colors.MEDIUM_GREY,
    },
}));

export default FileDownload;