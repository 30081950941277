import React, {useState} from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {TableCell, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import WorkersInProgramTableRow from "./WorkersInProgramTableRow";
import {makeStyles} from "@material-ui/core/styles";

const allHeadCells = [
    {id: 'name', label: 'Imię i nazwisko', align: 'center'},
    {id: 'position', label: 'Stanowisko pracownika w strukturze organizacyjnej', align: 'center'},
    {id: 'workingTime', label: 'Wymiar zaangażowania w przeliczeniu na etaty', align: 'center'},
    {id: 'earningsValue', label: 'Wynagrodzenie pracownika [brutto PLN]', align: 'center'},
    {id: 'cost1Value', label: 'Koszt części szkoleniowo-warsztatowej [brutto PLN]', align: 'center'},
    {id: 'cost2Value', label: 'Koszt części doradczo-instruktażowej [brutto PLN]', align: 'center'},
    {id: 'cost3Value', label: 'Koszt części praktycznej [brutto PLN]', align: 'center'},
];

const statisticHeadCells = [
    {id: 'name', label: 'Imię i nazwisko', align: 'center'},
    {id: 'position', label: 'Stanowisko pracownika w strukturze organizacyjnej', align: 'center'},
    {id: 'workingTime', label: 'Wymiar zaangażowania w przeliczeniu na etaty', align: 'center'},
];

const WorkersInProgramTable = ({workers, setWorkers, dataSource}) => {

    const classes = useStyles()
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false)

    const handleWorkerChange = (index, newWorker) => {
        setIsLoading(true)
        let newWorkers = [...workers]
        newWorkers.splice(index, 1, newWorker)
        setWorkers(newWorkers)
        setIsLoading(false)
        // splice changes both newWorkers and workers, so setWorkers do not refresh page and I'm doing it in above line
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    {(dataSource === "nie - wyłącznie dane własne" || dataSource === "nie - dane mieszane") ?
                        (<TableRow>
                            {allHeadCells.map((item, index) => (
                                <TableCell key={index} className={classes.headCell}>{item.label}</TableCell>
                            ))}
                        </TableRow>) :
                        (<TableRow>
                            {statisticHeadCells.map((item, index) => (
                                <TableCell key={index} className={classes.headCell}>{item.label}</TableCell>
                            ))}
                        </TableRow>)}
                </TableHead>
                <TableBody>
                    {workers.map((row, index) =>
                        <WorkersInProgramTableRow
                            key={index} worker={row}
                            setWorker={(newWorker) => handleWorkerChange(index, newWorker)}
                            dataSource={dataSource}
                        />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const useStyles = makeStyles(theme => ({
    headCell: {
        fontWeight: "500",
        textAlign: "center",
    }
}));

export default WorkersInProgramTable;