import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import React from "react";
import styled from "styled-components";

const HeadingCells = ({order, orderBy, setOrder, setOrderBy, headCells}) => {

    const createSortHandler = (property) => (event) => {
        handleRequestSort(event, property);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <React.Fragment>
            {headCells.map((headCell, index) => {
                return headCell.label.length > 0 ?
                    (headCell.sortable ? (
                        <TableCell
                        key={headCell.id}
                        align={headCell.align}
                        padding={'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <SortIcon>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </SortIcon>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    ) : (
                        <TableCell key={index} align={headCell.align}>{headCell.label}</TableCell>
                    ))
                    : (<TableCell key={index}/>)
            })}
        </React.Fragment>
    );
}

const SortIcon = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
`;

export default HeadingCells;