import {Switch, withStyles} from "@material-ui/core";
import {Colors} from "../constants/colors";

const TurquoiseSwitch = ({value, setValue}) => {

    const handleChange = () => {
        value === false ? setValue(true) : setValue(false)
    }

    return (
        <CustomColorSwitch
            checked={value}
            onChange={handleChange}
        />
    );
};

const CustomColorSwitch = withStyles({
    switchBase: {
        color: Colors.LIGHT_GREY,
        '&$checked': {
            color: Colors.TURQUOISE,
        },
        '&$checked + $track': {
            backgroundColor: Colors.TURQUOISE,
        },
    },
    checked: {},
    track: {
        backgroundColor: Colors.LIGHT_GREY,
    },
})(Switch);

export default TurquoiseSwitch;