import React, {useState} from 'react';
import {TableCell, TableRow} from "@material-ui/core";
import 'react-toastify/dist/ReactToastify.css';
import {makeStyles} from "@material-ui/core/styles";
import TurquoiseCheckbox from "../TurquoiseCheckbox";
import {Colors} from "../../constants/colors";
import SelectNumber from "../finances/SelectNumber";

const MotivatorsEvaluationRow = ({motivator, even, triedToFinish, motivatorsCheckedCount, setMotivatorsCheckedCount}) => {

    if (motivator.firstColumn == null) {
        motivator.firstColumn = motivator.name
        if (motivator.exist) motivator.secondColumn = "tak"
        motivator.thirdColumn = motivator.opinion
    }

    const [checked, setChecked] = useState(motivator.secondColumn)
    const [value, setValue] = useState(motivator.thirdColumn)

    const backgroundColor = (triedToFinish && checked === "tak" && motivator.thirdColumn === 0) ? (Colors.GREEN2) : (even ? Colors.PASTEL_TURQUOISE : Colors.WHITE)
    const classes = useStyles({backgroundColor})

    return (

        <TableRow classes={{root: classes.row}}>
            <TableCell classes={{root: classes.cellRoot}}>{motivator.firstColumn}</TableCell>
            <TableCell align={"center"} classes={{root: classes.cellRoot}}>
                <TurquoiseCheckbox
                    checked={checked === "tak"}
                    onChange={() => {
                        if (checked === "tak") {
                            motivator.secondColumn = "nie"
                            motivator.exist = false
                            setMotivatorsCheckedCount(motivatorsCheckedCount - 1)
                            setChecked("nie")
                        } else {
                            motivator.secondColumn = "tak"
                            motivator.exist = true
                            setMotivatorsCheckedCount(motivatorsCheckedCount + 1)
                            setChecked("tak")
                        }
                    }}
                    disabled={motivatorsCheckedCount >= 5 && (checked === "nie" || !motivator.exist)}
                />
            </TableCell>
            <TableCell align={"center"} classes={{root: classes.cellRoot}}>
                <SelectNumber
                    number={value}
                    setNumber={(newValue) => {
                        motivator.thirdColumn = newValue
                        motivator.opinion = newValue
                        setValue(newValue)
                    }}
                    label={""} noValueLabel={""}
                    width={"100%"}
                    from={1}
                    to={5}
                    disabled={motivator.secondColumn === "nie" || !motivator.exist}
                />
            </TableCell>
        </TableRow>
    )
};

const useStyles = makeStyles(theme => ({
    row: props => ({
        backgroundColor: props.backgroundColor,
    }),
    cellRoot: {
        border: "none",
        fontSize: 15,
    },
}));

export default MotivatorsEvaluationRow;