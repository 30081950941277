import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";
import {formatNumberWithSpaces} from "../../services/NumberService";

const Result3TableRow = ({row, showDetails}) => {

    let bolded = row.type === "K" || row.type === "S"
    let colored = row.type === "S"
    const classes = useStyles({bolded, colored})

    const format = (number) => {
        return formatNumberWithSpaces(number)
    }

    return (
        <React.Fragment>
            <TableRow className={classes.row}>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.leftAlignCell}>{row.description}</TableCell>
                <TableCell className={classes.cell}>{format(row.sum1)}</TableCell>
                {showDetails ? row.workers1.map((item, index) => (
                    <TableCell className={classes.cell} key={index + "row1"}>{format(item)}</TableCell>
                )) : null}
                <TableCell className={classes.cell}>{format(row.sum2)}</TableCell>
                {showDetails ? row.workers2.map((item, index) => (
                    <TableCell className={classes.cell} key={index + "row2"}>{format(item)}</TableCell>
                )) : null}
            </TableRow>
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    cell: props => ({
        fontWeight: props.bolded ? "bold" : "normal",
        backgroundColor: props.bolded ? (props.colored ? Colors.PASTEL_TURQUOISE : Colors.VERY_LIGHT_GREY) : "white",
        textAlign: "center",
    }),
    leftAlignCell: props => ({
        fontWeight: props.bolded ? "bold" : "normal",
        backgroundColor: props.bolded ? (props.colored ? Colors.PASTEL_TURQUOISE : Colors.VERY_LIGHT_GREY) : "white",
        textAlign: "left",
    }),
    row: {
        [`@media print`]: {
            pageBreakInside: "avoid",
        }
    }
}));

export default Result3TableRow;