import React from 'react';
import Subheader from "../Subheader";
import {makeStyles} from "@material-ui/core/styles";
import ExpectedChangesRow from "./ExpectedChangesRow";

const ExpectedChanges = ({increasedDemandForExistingServicesOption, setIncreasedDemandForExistingServicesOption,
                             increasedDemandForExistingServicesPercentage, setIncreasedDemandForExistingServicesPercentage,
                             increasedPricesForExistingServicesOption, setIncreasedPricesForExistingServicesOption,
                             increasedPricesForExistingServicesPercentage, setIncreasedPricesForExistingServicesPercentage,
                             lowerCostsOfExistingServicesOption, setLowerCostsOfExistingServicesOption,
                             lowerCostsOfExistingServicesPercentage, setLowerCostsOfExistingServicesPercentage,
                             dataSource}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Subheader title={"Spodziewane zmiany w pozycji rynkowej"}/>
            <div>
                <ExpectedChangesRow
                    label={"wzrost zapotrzebowania na dotychczasowe usługi [wzrost obłożenia miejsc w placówce opiekuńczej / DDP - w %]"}
                    option={increasedDemandForExistingServicesOption}
                    setOption={setIncreasedDemandForExistingServicesOption}
                    percentage={increasedDemandForExistingServicesPercentage}
                    setPercentage={setIncreasedDemandForExistingServicesPercentage}
                    dataSource={dataSource}
                />
                <ExpectedChangesRow
                    label={"wzrost cen na dotychczasowe usługi [wzrost cen miejsc w placówce opiekuńczej / DDP - w %, PLN]"}
                    option={increasedPricesForExistingServicesOption}
                    setOption={setIncreasedPricesForExistingServicesOption}
                    percentage={increasedPricesForExistingServicesPercentage}
                    setPercentage={setIncreasedPricesForExistingServicesPercentage}
                    dataSource={dataSource}
                />
                <ExpectedChangesRow
                    label={"spadek kosztów dotychczasowych usług [spadek kosztu miejsca w placówce opiekuńczej / DDP - w %, PLN]"}
                    option={lowerCostsOfExistingServicesOption}
                    setOption={setLowerCostsOfExistingServicesOption}
                    percentage={lowerCostsOfExistingServicesPercentage}
                    setPercentage={setLowerCostsOfExistingServicesPercentage}
                    dataSource={dataSource}
                />
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 100,
    },
    item: {
        display: "flex",
        justifyContent: "space-between"
    },
}));

export default ExpectedChanges;