import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import NumberInput from "../finances/NumberInput";
import TurquoiseCheckbox from "../TurquoiseCheckbox";

const RecommendationWorkersTableRow = ({workerName, setWorker, recommended, done, supervision, isRecommendedDisabled, isDoneDisabled}) => {

    const rangeProps = { inputProps: { min: 0, max: 5 }}

    return (
        <React.Fragment>
            <TableRow>
                <TableCell align={"center"}>{workerName}</TableCell>
                <TableCell align={"center"}>
                    <TurquoiseCheckbox
                        checked={recommended}
                        onChange={() => setWorker(!recommended, "recommended")}
                        disabled={isRecommendedDisabled}
                    />
                </TableCell>
                <TableCell align={"center"}>
                    <TurquoiseCheckbox
                        checked={done}
                        onChange={() => setWorker(!done, "done")}
                        disabled={!recommended || isDoneDisabled}
                    />
                </TableCell>
                <TableCell align={"center"}>
                    <NumberInput
                        value={supervision}
                        setValue={(value) => setWorker(value, "supervision")}
                        label={""}
                        disabled={!recommended}
                        inputProps={rangeProps}
                    />
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default RecommendationWorkersTableRow;