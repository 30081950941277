import React from "react";
import Header from "../../components/Header";
import Subheader from "../../components/Subheader";
import {Colors} from "../../constants/colors";
import OutlineButtonLink from "../../components/buttons/OutlineButtonLink";
import SignInForm from "../../components/login/SignInForm";
import TrademarksHeader from "../../components/TrademarksHeader";
import {makeStyles} from "@material-ui/core/styles";
import Footer from "../../components/Footer";
import LeftTurquoiseContainer from "../../components/LeftTurquoiseContainer";

const SignInPage = ({history}) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <TrademarksHeader history={history}/>
            <LeftTurquoiseContainer>
                <div className={classes.headerContainer}><Header title={"Cześć!"} color={Colors.WHITE}/></div>
                <Subheader title={"Nie masz jeszcze konta? Dołącz do nas!"} color={Colors.WHITE}/>
                <div className={classes.buttonContainer}>
                    <OutlineButtonLink
                        href={process.env.REACT_APP_BASE_PATH + "/rejestracja"}
                        label={"Utwórz konto"}
                        color={Colors.WHITE}
                        backgroundColor={Colors.TURQUOISE}
                    />
                </div>
            </LeftTurquoiseContainer>
            <SignInForm history={history}/>
            <Footer onlySmallScreen={true}/>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100vm",
        height: "100vh",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            fontSize: 14,
            textAlign: "center",
        },
    },
    headerContainer: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: 30,
        },
    },
    buttonContainer: {
        marginBottom: 30,
    },
}));

export default SignInPage;