import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {
    getAllCompetencesUsingGET,
    getOrganisationEvaluationInputUsingGET,
    searchEvaluationUsingPOST
} from "../../swagger/vue-api-client";
import {checkIsUserAuthorized} from "../../services/LinksService";
import WaitingEvaluations from "../../components/evaluation/WaitingEvaluations";
import {getEvaluationEvaluatorSearchCriteria} from "../../services/SearchService";
import {WorkerContext} from "../../contexts/WorkerContext";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import MyEvaluation from "../../components/evaluation/MyEvaluation";
import Footer from "../../components/Footer";
import FinishedEvaluations from "../../components/evaluation/FinishedEvaluations";
import NoEvaluations from "../../components/evaluation/NoEvaluations";

const AllEvaluationsPage = ({history}) => {

    const workerContext = useContext(WorkerContext)
    let role = localStorage.getItem("role")
    const [isWaiting, setIsWaiting] = useState(true);
    const [isFinished, setIsFinished] = useState(true);
    const [isShown, setIsShown] = useState(role === "WORKER");
    const [sent, setSent] = useState(null)

    useEffect(() => {
        checkIsUserAuthorized(role, history)
            .then((response) => {
                return searchEvaluationUsingPOST({evaluationSearchCriteria:
                        getEvaluationEvaluatorSearchCriteria(localStorage.getItem("id"))});
            })
            .then((res) => {
                workerContext.setEvaluations(res.data)
                return getAllCompetencesUsingGET();
            })
            .then((response) => {
                workerContext.setCompetences(response.data);
            })
            .catch((error) => {
                console.log(error.response)
            })

        checkIsUserAuthorized("EMPLOYER", history)
            .then((response) => {
                return getOrganisationEvaluationInputUsingGET();
            })
            .then((res) => {
                setSent(res.data.sent)
                if (res.data.sent === true) {
                    setIsFinished(true)
                }
                else if (res.data.sent === false) {
                    setIsWaiting(true)
                }
            })
            .catch((error) => {
                setSent(null)
                console.log(error.response)
            })


        // workerContext cannot be in a dependency array, because as an effect of above function, it changes
        // eslint-disable-next-line
    }, [history])

    console.log(sent)

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <Content>
                <WaitingEvaluations history={history} setIsWaiting={setIsWaiting} sent={sent} role={role}/>
                <FinishedEvaluations history={history} setIsFinished={setIsFinished} sent={sent} role={role}/>
                {role === "WORKER" ? <MyEvaluation setIsShown = {setIsShown} /> : null}
                {isShown + isWaiting + isFinished === 0 ? <NoEvaluations/> : null}
            </Content>
            <Footer/>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export default AllEvaluationsPage;