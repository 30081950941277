import React, {useEffect, useState} from "react";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import {checkIsUserAuthorized} from "../../services/LinksService";
import {getCompanyUsingGET} from "../../swagger/vue-api-client";
import DataField from "../../components/DataField";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import ButtonWithoutIcon from "../../components/buttons/ButtonWithoutIcon";
import EditCompanyByEmployerDialog from "../../components/EditCompanyByEmployerDialog";
import TitleWithButton from "../../components/TitleWithButton";
import styled from "styled-components";

const CompanyPage = ({history}) => {

    const [company, setCompany] = useState({})
    const [editCompany, setEditCompany] = useState(false)

    const classes = useStyles()

    useEffect(() => {
        checkIsUserAuthorized("EMPLOYER", history)
            .then((response) => {
                return getCompanyUsingGET({companyId: parseInt(localStorage.companyId)})
            })
            .then((res) => {
                setCompany(res.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }, [history])

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <TitleWithButton title={"Dane firmy"} button={
                    <ButtonWithoutIcon
                        label={"Zmień dane"}
                        onClick={() => setEditCompany(true)}
                    />
                }/>
                <DataField
                    value={company.name}
                    title={"Nazwa firmy:"}
                />
                <DataField
                    value={company.email}
                    title={"Email firmy:"}
                />
                <DataField
                    value={company.phoneNumber}
                    title={"Numer telefonu firmy:"}
                />
                <DataField
                    value={company.description}
                    title={"Opis firmy:"}
                />
                <DataField
                    value={company.agreementForDataSharing ? "Tak" : "Nie"}
                    title={"Udostępnia dane:"}
                />
            </div>
            <EditCompanyByEmployerDialog
                open={editCompany}
                setOpen={setEditCompany}
                company={company}
                setCompany={setCompany}
            />
            <Footer/>
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "7% 2%",
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default CompanyPage;