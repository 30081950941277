import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized} from "../../services/LinksService";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import {assignedELearningsUsingGET, searchEmployerFilesUsingPOST} from "../../swagger/vue-api-client";
import {getClearSearchCriteria} from "../../services/SearchService";
import EmployerFileTable from "../../components/table/employerFile/EmployerFileTable";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import ElearningTiles from "../../components/elearning/ElearningTiles";

const MaterialsPage = ({history}) => {

    const classes = useStyles()
    const [files, setFiles] = useState([])
    const [elearnings, setElearnings] = useState([])

    useEffect(() => {
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((response) => {
                return searchEmployerFilesUsingPOST({searchCriteria: getClearSearchCriteria()})
            })
            .then((res) => {
                setFiles(res.data)
            })
            .catch((error) => {
                console.log(error);
            })
        checkIsUserAuthorized(role, history)
            .then((response) => {
                return assignedELearningsUsingGET()
            })
            .then((res) => {
                setElearnings(res.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }, [history])

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <EmployerFileTable files={files} setFiles={setFiles}/>
                <br/>
                <br/>
                <br/>
                <ElearningTiles elearnings={elearnings} history={history}/>
            </div>
            <Footer/>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default MaterialsPage;