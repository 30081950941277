import React from 'react';
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import {Link} from "react-router-dom";

const HorizontalMenuItem = ({to, label, current}) => {

    return (
        <Item current={current}><StyledLink to={to}>{label}</StyledLink></Item>
    );
};

const Item = styled.div`
  display: flex;
  height: 60%;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: 3px solid ${props => props.current ? Colors.TURQUOISE : Colors.WHITE};
`;

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: ${Colors.BLACK};
  width: 100%;
  height: 100%;
  transition: color 0.1s;
  font-size: 1.1em;

  &:hover, &:active {
    text-decoration: none;
  }

  &:hover, &:active {
    color: ${Colors.TURQUOISE};
  }
`;

export default HorizontalMenuItem;