import {Dialog} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import styled from "styled-components";
import CompetencesForm from "./CompetencesForm";
import EvaluatorsForm from "./EvaluatorsForm";
import ELearningSourcesForm from "./ELearningSourcesForm"
import 'react-toastify/dist/ReactToastify.css';
import DialogTitle from "../DialogTitle";
import {EmployerContext} from "../../contexts/EmployerContext";
import {WorkerContext} from "../../contexts/WorkerContext";
import {
    createEvaluationData,
    mapCompetenceToEvaluationCompetence,
    sendNewEvaluation
} from "../../services/EvaluationService";
import AskForEvaluationSummary from "./AskForEvaluationSummary";
import AgreementForm from "./AgreementForm";
import ButtonWithoutIcon from "../buttons/ButtonWithoutIcon";
import Pdf from "../../assets/pdf/Zogniskowane wywiady grupowe_Instrukcja.pdf";
import {convertELearningSource} from "../../pages/shared/ForumPage";
import {getELearningSourcesUsingGET} from "../../swagger/vue-api-client";

const _ = require('lodash');

const AskForEvaluationDialog = ({open, setOpen, worker}) => {
    const employerContext = useContext(EmployerContext);
    const workerContext = useContext(WorkerContext);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [publishApproval, setPublishApproval] = useState(true);

    const [selfEvaluation, setSelfEvaluation] = useState(createEvaluationData(false, worker.id, worker.id, [], publishApproval));
    const [coworkerEvaluation, setCoworkerEvaluation] = useState(createEvaluationData(false, worker.id, '', [], publishApproval));
    const [supervisorEvaluation, setSupervisorEvaluation] = useState(createEvaluationData(false, worker.id, '', [], publishApproval));

    const [competences, setCompetences] = useState([]);
    const [enabledCompetences, setEnabledCompetences] = useState([]);
    const [eLearningSources, setELearningSources] = useState([]);
    const [enabledSources, setEnabledSources] = useState([]);
    let waitingRequests = 0

    useEffect(() => {
        setCompetences(workerContext.competences.map((item) => mapCompetenceToEvaluationCompetence(item)))
        getELearningSourcesUsingGET({companyId: parseInt(localStorage.companyId)})
            .then((res) => {
                setELearningSources(res.data.map((item, index) => convertELearningSource(item)))
            })
    }, [workerContext.competences])

    const insertDetailedCompetences = (evaluation, setEvaluation) => {
        let newEvaluation = Object.assign(evaluation);
        let newCompetences = [];

        competences.forEach((competenceType, index) => {
            if (competenceType.enabled && evaluation.competences.indexOf(competenceType.id) !== -1) {
                competenceType.competences.forEach((competence, index2) => {
                    if (competence.enabled) {
                        newCompetences.push(competence.id);
                    }
                })
            }
        })

        newEvaluation.competences = newCompetences;
        setEvaluation(newEvaluation);
    };

    const setPublishedEvaluation = (evaluation, setEvaluation) => {
        let newEvaluation = Object.assign(evaluation);
        newEvaluation.publishApproval = publishApproval;
        setEvaluation(newEvaluation)
    }

    const handleSubmitEvaluation = () => {
        let oldSelfEvaluation = _.cloneDeep( selfEvaluation );
        let oldCoworkerEvaluation = _.cloneDeep( coworkerEvaluation );
        let oldSupervisorEvaluation = _.cloneDeep( supervisorEvaluation );

        setPublishedEvaluation(selfEvaluation, setSelfEvaluation)
        setPublishedEvaluation(coworkerEvaluation, setCoworkerEvaluation)
        setPublishedEvaluation(supervisorEvaluation, setSupervisorEvaluation)

        setIsLoading(true);
        setErrorMessage("");

        insertDetailedCompetences(selfEvaluation, setSelfEvaluation)
        insertDetailedCompetences(coworkerEvaluation, setCoworkerEvaluation)
        insertDetailedCompetences(supervisorEvaluation, setSupervisorEvaluation)

        let evaluationsToSubmit = 0;
        if (selfEvaluation.enabled && selfEvaluation.competences.length > 0) {
            evaluationsToSubmit++
            sendNewEvaluation(selfEvaluation, "selfEvaluation", "SELF",
                waitingRequests, employerContext, setOpen, setIsLoading, worker, setErrorMessage, null)
        }
        if (coworkerEvaluation.enabled && coworkerEvaluation.competences.length > 0) {
            evaluationsToSubmit++
            sendNewEvaluation(coworkerEvaluation, "coworkerEvaluation", "COWORKER",
                waitingRequests, employerContext, setOpen, setIsLoading, worker, setErrorMessage, null)
        }
        if (supervisorEvaluation.enabled && supervisorEvaluation.competences.length > 0) {
            evaluationsToSubmit++
            sendNewEvaluation(supervisorEvaluation, "supervisorEvaluation", "SUPERVISOR",
                waitingRequests, employerContext, setOpen, setIsLoading, worker, setErrorMessage, eLearningSources)
        }

        if (evaluationsToSubmit === 0) {
            // Rollback
            setSelfEvaluation(oldSelfEvaluation);
            setCoworkerEvaluation(oldCoworkerEvaluation);
            setSupervisorEvaluation(oldSupervisorEvaluation);
            setErrorMessage("Nie wybrano kompetencji do oceny i/lub osób mających oceniać.")
            setIsLoading(false)
        }
    }

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'md'} fullWidth={true}>
            <DialogTitle title={"Poproś o ocenę pracownika " + worker.name}/>
            <PdfContainer>
                <ButtonWithoutIcon
                    onClick={() => window.open(Pdf)}
                    label={"Jak wybrać kompetencje? - scenariusz wywiadu"}/>
            </PdfContainer>
            <Container>
                <FormContainer>
                    <CompetencesForm
                        competences={competences}
                        setCompetences={setCompetences}
                        setEnabledCompetences={setEnabledCompetences}
                    />
                    <ELearningSourcesForm
                        eLearningSources={eLearningSources}
                        setELearningSources={setELearningSources}
                        setEnabledSources={setEnabledSources}
                    />
                    <EvaluatorsForm
                        selfEvaluation={selfEvaluation}
                        setSelfEvaluation={setSelfEvaluation}
                        coworkerEvaluation={coworkerEvaluation}
                        setCoworkerEvaluation={setCoworkerEvaluation}
                        supervisorEvaluation={supervisorEvaluation}
                        setSupervisorEvaluation={setSupervisorEvaluation}
                        enabledCompetences={enabledCompetences}
                        worker={worker}
                    />
                    <AgreementForm publishApproval={publishApproval} setPublishApproval={setPublishApproval} />
                </FormContainer>
                <AskForEvaluationSummary
                    isLoading={isLoading}
                    errorMessage={errorMessage}
                    setOpen={setOpen}
                    onSubmit={handleSubmitEvaluation}
                />
            </Container>
        </Dialog>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PdfContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export default AskForEvaluationDialog;