import React from 'react';
import styled from "styled-components";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import Title from "../../components/Title";
import CustomTabs from "../../components/financesResult/CustomTabs";
import FinancesDataForm from "../../components/finances/FinancesDataForm";
import FinancesResult from "../../components/financesResult/FinancesResult";
import FinalFinancesResult from "../../components/financesResult/FinalFinancesResult";

const FinancesPage = ({history}) => {

    const classes = useStyles()

    /*useEffect(() => {
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((response) => {})
            .catch((error) => {
                console.log(error);
            })
    }, [history])

     */


    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <Title title={"Wycena kosztów i korzyści"}/>
                <CustomTabs
                    tabs={[
                        {label: "Dane", children: <FinancesDataForm/>},
                        {label: "Wyniki", children: <FinancesResult history={history}/>},
                        {label: "Finalne wyniki", children : <FinalFinancesResult history={history}/>}
                    ]}
                />
            </div>
            <Footer/>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default FinancesPage;