import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import ButtonWithoutIcon from "../../components/buttons/ButtonWithoutIcon";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Title from "../../components/Title";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import ErrorAndLoadingMessage from "../../components/ErrorAndLoadingMessage";
import BigScreenOrganisationEvaluation from "../../components/evaluation/BigScreenOrganisationEvaluation";
import {checkIsUserAuthorized, getRoleDependentLink} from "../../services/LinksService";
import {
    getOrganisationEvaluationInputUsingGET, setOrganisationEvaluationInputUsingPOST,
} from "../../swagger/vue-api-client";

const OrganisationEvaluationPage = ({history}) => {

    const classes = useStyles()
    const [sentData, setSentData] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [triedToFinish, setTriedToFinish] = useState(false);
    const [data, setData] = useState([])
    const [receivedData, setReceivedData] = useState([])
    const [motivators, setMotivators] = useState([])
    const [motivatorTitle, setMotivatorTitle] = useState("")
    const ref = useRef(null)

    useEffect(() => {
        const newEquipmentSection = (title, question1, rows1, question2, rows2, question3, rows3, addMotivators) => {
            return {
                name: title,
                questions: [
                    {
                        name: question1,
                        subQuestions: rows1,
                        motivators: addMotivators,
                    },
                    {
                        name: question2,
                        subQuestions: rows2,
                        motivators: addMotivators,
                    },
                    {
                        name: question3,
                        subQuestions: rows3,
                        motivators: addMotivators,
                    }
                ]
            }
        }

        const newSection = (title, question, rows, addMotivators) => {
            return {
                name: title,
                questions: [
                    {
                        name: question,
                        subQuestions: rows,
                        motivators: addMotivators,
                    },
                ]
            }
        }
        let answers = []
        let tempMotivators = []
        let tempRows = []
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((response) => {
                    return getOrganisationEvaluationInputUsingGET()
            })
            .then((res) => {
                let tempData = []
                tempData.push(newEquipmentSection(res.data.equipmentTitle,
                    res.data.equipmentFirstSectionQuestion, res.data.equipmentFirstSectionRows,
                    res.data.equipmentSecondSectionQuestion, res.data.equipmentSecondSectionRows,
                    res.data.equipmentThirdSectionQuestion, res.data.equipmentThirdSectionRows,
                    false)
                )
                tempData.push(newSection(res.data.organisationStructureTitle,
                    res.data.organisationStructureQuestion, res.data.organisationStructureRows, false)
                )

                let i = 0, size = res.data.motivationSystemRows.length
                while (i <size && res.data.motivationSystemRows[i].secondColumn !== null) {
                    tempRows.push(res.data.motivationSystemRows[i])
                    i++
                }

                setMotivatorTitle(res.data.motivationSystemRows[i].firstColumn)
                i++

                while (i <size) {
                    if (res.data.motivationSystemRows[i].secondColumn !== "tak") {
                        res.data.motivationSystemRows[i].secondColumn = "nie"
                    }
                    tempMotivators.push(res.data.motivationSystemRows[i])
                    i++
                }

                tempData.push(newSection(res.data.motivationSystemTitle,
                    res.data.motivationSystemQuestion, tempRows, true)
                )
                tempData.push(newSection(res.data.communicationSystemTitle,
                    res.data.communicationSystemQuestion, res.data.communicationSystemRows, false)
                )
                tempData.push(newSection(res.data.industryKnowledgeTitle,
                    res.data.industryKnowledgeQuestion, res.data.industryKnowledgeRows, false)
                )

                let index = 0
                for (let i = 0; i < tempData.length; ++i) {
                    for (let j = 0; j < tempData[i].questions.length; ++j) {
                        for (let k = 0; k < tempData[i].questions[j].subQuestions.length; ++k) {
                            if(tempData[i].questions[j].subQuestions[k].secondColumn !== null) {
                                tempData[i].questions[j].subQuestions[k].id = index++
                                answers.push(tempData[i].questions[j].subQuestions[k].secondColumn)
                            }
                        }
                    }
                }
                ref.current = answers
                setMotivators(tempMotivators)
                setData(tempData)
                setSentData(res.data.sent)
                setReceivedData(res.data)
            })
            .catch((error) => {
                console.log(error.response)
            })

    }, [history])

    const handleSaveSurvey = (sent) => {
        const fillData = (rows) => {
            let found = false
            let id = 0
            for (let i = 0; i < rows.length; ++i) {
                if (rows[i].secondColumn !== null && found === false) {
                    rows[i].secondColumn = ref.current[rows[i].id]
                } else if (found === false) {
                    found = true
                } else {
                    rows[i].secondColumn = motivators[id].secondColumn
                    id++
                }
            }
        }
        let tempData = receivedData

        fillData(tempData.equipmentFirstSectionRows)
        fillData(tempData.equipmentSecondSectionRows)
        fillData(tempData.equipmentThirdSectionRows)
        fillData(tempData.organisationStructureRows)
        fillData(tempData.motivationSystemRows)
        fillData(tempData.communicationSystemRows)
        fillData(tempData.industryKnowledgeRows)
        if (sent === true) {
            tempData.sent = true;
        }

        setOrganisationEvaluationInputUsingPOST({organisationEvaluationInputDto: tempData})
            .then((res) => {
                if (sent) {
                    history.push(getRoleDependentLink("/ocenianie"))
                    toast.success("Sukces! Zapisano dane ankiety.")
                } else {
                    toast.success("Sukces! Zapisano dane ankiety.")
                }
            })
            .catch((error) => {
                console.log(error.response)
            })
    };

    const handleFinishSurvey = () => {
        setIsLoading(true)
        let isCompleted = true

        console.log(data)
        let answers = ref.current

        for (let i = 0; i < answers.length; ++i) {
            if(answers[i] === "") {
                isCompleted = false
                break
            }
        }

        if (isCompleted) {
            handleSaveSurvey(true)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            setTriedToFinish(true)
            toast.error("Nie wszystkie pola są wypełnione.")
        }
    }

    const getTitle = () => {
        if (sentData)
            return "Podgląd gotowości organizacyjnej"
        else
            return "Gotowość organizacyjna"

    }

    const buttons = (
        <ButtonsContainer>
            <ButtonWithoutIcon
                label={"Zapisz zmiany"}
                onClick={() => handleSaveSurvey(false)}
            />
            <div style={{width: 30}}/>
            <ButtonWithoutIcon
                label={"Zakończ i prześlij"}
                onClick={handleFinishSurvey}
            />
        </ButtonsContainer>
    )

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <Title title={getTitle()}/>
                <BigScreenOrganisationEvaluation
                    data={data}
                    triedToFinish={triedToFinish}
                    answers={ref.current}
                    motivators={motivators}
                    motivatorTitle={motivatorTitle}
                />
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={""}/>
                {data.length === 0 || sentData ? null : buttons}
                <ToastContainer/>
            </div>
            <Footer/>
        </Container>
    )
};

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "70%",
        margin: "5% 15%",
        [theme.breakpoints.down("md")]: {
            width: "90%",
            margin: "5%",
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

const ButtonsContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default OrganisationEvaluationPage;