import React from 'react';
import {TableContainer} from "@material-ui/core";
import 'react-toastify/dist/ReactToastify.css';
import Section from "./Section";
import {makeStyles} from "@material-ui/core/styles";

const BigScreenOrganisationEvaluation = ({data, answers, triedToFinish, motivatorTitle, motivators}) => {

    const height = window.innerHeight
    const classes = useStyles({height})

    return (
        <TableContainer className={classes.container}>
            {data.map((item, index) => (
                <Section
                    key={index}
                    name={item.name}
                    questions={item.questions}
                    triedToFinish={triedToFinish}
                    answers={answers}
                    motivators={motivators}
                    motivatorTitle={motivatorTitle}
                />
            ))}
        </TableContainer>
    )
};

const useStyles = makeStyles(theme => ({
    container: props => ({
        maxHeight: props.height !== null ? props.height * 0.75 : 500,
        marginTop: 80,
        [theme.breakpoints.down(700)]: {
            marginTop: 0,
        },
    }),
}));

export default BigScreenOrganisationEvaluation;