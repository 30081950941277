import React from 'react';
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";

const OutlineButton = ({label, onClick, color=Colors.TURQUOISE, backgroundColor=Colors.WHITE, disabled}) => {

    const classes = useStyles({color, backgroundColor})

    return (
        <button className={classes.button} onClick={onClick} disabled={disabled}>
            {label}
        </button>
    );
};

const useStyles = makeStyles(theme => ({
    button: props => ({
        display: "block",
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: props.color,
        borderRadius: 50,
        backgroundColor: props.backgroundColor,
        color: props.color,
        textTransform: "uppercase",
        textDecoration: "none",
        padding: "15px 25px",
        fontSize: 25,
        textAlign: "center",
        transition: "background-color 0.3s, color 0.3s",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            padding: "10px 15px",
            fontSize: 20,
        },
        "&:focus, &:hover, &:link, &:active": {
            backgroundColor: props.color,
            color: Colors.WHITE,
            outline: "none",
        }
    }),
}));

export default OutlineButton;