import React, {useState} from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {TableCell, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core/styles";
import RecommendationWorkersTableRow from "./RecommendationWorkerTableRow";
import {Colors} from "../../constants/colors";
import TurquoiseCheckbox from "../TurquoiseCheckbox";
import NumberInput from "../finances/NumberInput";

const RecommendationWorkersTable = ({workersRecommendations, setWorkersRecommendations, recommendedAll, setRecommendedAll,
                                        doneAll, setDoneAll, supervisionAll, setSupervisionAll}) => {

    const classes = useStyles()
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false)
    const rangeProps = { inputProps: { min: 0, max: 5 }}

    const handleWorkerChange = (index, value, at) => {

        setIsLoading(true)
        let newWorkers = [...workersRecommendations]
        let newWorker = Object.assign(workersRecommendations[index])
        newWorker[at] = value
        newWorkers.splice(index, 1, newWorker)
        setWorkersRecommendations(newWorkers)
        setIsLoading(false)
        // splice changes both newWorkers and workers, so setWorkers do not refresh page and I'm doing it in above line
    }

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.headCell}>Imię i nazwisko</TableCell>
                        <TableCell className={classes.headCell}>Zaleć realizację</TableCell>
                        <TableCell className={classes.headCell}>Zrealizowano</TableCell>
                        <TableCell className={classes.headCell}>Wynik superwizji</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align={"center"} className={classes.coloredCell}>WSZYSCY</TableCell>
                        <TableCell align={"center"} className={classes.coloredCell}>
                            <TurquoiseCheckbox checked={recommendedAll} onChange={() => setRecommendedAll(c => !c)}/>
                        </TableCell>
                        <TableCell align={"center"} className={classes.coloredCell}>
                            <TurquoiseCheckbox checked={doneAll} onChange={() => setDoneAll(c => !c)} disabled={!recommendedAll}/>
                        </TableCell>
                        <TableCell align={"center"} className={classes.coloredCell}>
                            <NumberInput value={supervisionAll} setValue={setSupervisionAll} label={""}
                                disabled={!recommendedAll} inputProps={rangeProps}/>
                        </TableCell>
                    </TableRow>
                    {workersRecommendations.map((item, index) =>
                        <RecommendationWorkersTableRow
                            key={index}
                            workerName={item.worker.name}
                            setWorker={(value, at) => handleWorkerChange(index, value, at)}
                            recommended={recommendedAll || item.recommended}
                            done={doneAll || item.done}
                            supervision={supervisionAll !== '' ? supervisionAll : item.supervision}
                            isRecommendedDisabled={item.isRecommendedDisabled}
                            isDoneDisabled={item.isDoneDisabled}
                        />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const useStyles = makeStyles(theme => ({
    headCell: {
        fontWeight: "500",
        textAlign: "center",
    },
    coloredCell: {
        backgroundColor: Colors.PASTEL_TURQUOISE,
    },
}));

export default RecommendationWorkersTable;