import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import {RiLockPasswordLine} from "react-icons/ri";
import InputField from "./InputField";
import {Colors} from "../constants/colors";
import ErrorAndLoadingMessage from "./ErrorAndLoadingMessage";
import ButtonWithoutIcon from "./buttons/ButtonWithoutIcon";
import {changePasswordUsingPOST} from "../swagger/vue-api-client";
import DialogTitle from "./DialogTitle";
import DialogButtonsContainer from "./DialogButtonsContainer";
import {makeStyles} from "@material-ui/core/styles";

const ChangePasswordDialog = ({open, setOpen}) => {

    const classes = useStyles()
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleChangePassword = () => {
        setIsLoading(true)

        if (password !== passwordRepeat) {
            setErrorMessage("Podane hasła są różne");
            setIsLoading(false)
        } else if (password.length < 8) {
            setErrorMessage("Hasło musi mieć co najmniej 8 znaków");
            setIsLoading(false)
        } else {
            changePasswordUsingPOST({
                passwordChangeRequest: {
                    newPassword: password
                }
            })
                .then((response) => {
                    setErrorMessage('');
                    setIsLoading(false);
                    setOpen(false);
                })
                .catch((error) => {
                    if (error && error.response && typeof error.response.data === 'string') {
                        setErrorMessage(error.response.data)
                    } else {
                        setErrorMessage("Wystąpił błąd podczas zmieniania hasła")
                    }
                    setIsLoading(false);
                });
        }
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle title={"Zmień hasło"}/>
            <div className={classes.container}>
                <InputField
                    type={"password"}
                    value={password}
                    setValue={setPassword}
                    placeholder={"Nowe hasło"}
                    icon={<RiLockPasswordLine size={20} color={Colors.LIGHT_GREY}/>}
                />
                <InputField
                    type={"password"}
                    value={passwordRepeat}
                    setValue={setPasswordRepeat}
                    placeholder={"Powtórz nowe hasło"}
                    icon={<RiLockPasswordLine size={20} color={Colors.LIGHT_GREY}/>}
                />
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"Zmień hasło"}
                        onClick={handleChangePassword}
                    />
                </DialogButtonsContainer>
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "7%",
    },
}));

export default ChangePasswordDialog;