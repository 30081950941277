import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import HeadingCells from "../HeadingCells";

const headCells = [
    {id: 'number', label: '', align: 'left'},
    {id: 'name', label: 'Imię i nazwisko', align: 'left'},
    {id: 'email', label: 'Email', align: 'left'},
    {id: 'status', label: 'Stan oceny', align: 'center'},
    {id: 'delete', label: 'Usuń', align: 'left'},
    {id: 'edit', label: 'Edytuj', align: 'left'},
];

const WorkerTableHeading = ({order, orderBy, setOrder, setOrderBy}) => {
    return (
        <TableHead>
            <TableRow>
                <HeadingCells setOrderBy={setOrderBy} orderBy={orderBy} setOrder={setOrder} order={order} headCells={headCells}/>
            </TableRow>
        </TableHead>
    );
}

export default WorkerTableHeading;