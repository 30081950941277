import React from 'react';
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";

const OutlineButtonLink = ({label, href, color=Colors.TURQUOISE, backgroundColor=Colors.WHITE}) => {

    const classes = useStyles({backgroundColor, color})

    return (
        <a className={classes.button} href={href}>
            {label}
        </a>
    );
};

const useStyles = makeStyles(theme => ({
    button: props => ({
        display: "block",
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: props.color,
        borderRadius: 50,
        backgroundColor: props.backgroundColor,
        color: props.color,
        textTransform: "uppercase",
        textDecoration: "none",
        padding: "15px 25px",
        fontSize: 25,
        textAlign: "center",
        transition: "background-color 0.3s, color 0.3s",
        cursor: "pointer",
        [theme.breakpoints.down("sm")]: {
            padding: "10px 15px",
            fontSize: 20,
        },
        "&:focus, &:hover, &:active": {
            backgroundColor: props.color,
            color: props.backgroundColor,
            outline: "none",
        }
    }),
}));

export default OutlineButtonLink;