import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";
import ButtonWithIcon from "../buttons/ButtonWithIcon";
import {BsCardChecklist} from "react-icons/all";
import WorkersListDialog from "./WorkersListDialog";

const StageSummary = ({workersRecommendations, setWorkerRecommendations, title, color}) => {

    const classes = useStyles()
    const [seeWorkers, setSeeWorkers] = useState(false)

    const getLabelForm = (number) => {
        if (number === 1)
            return "1 pracownika"
        else
            return number + " pracowników"
    }

    if (workersRecommendations.length === 0)
        return null

    return (
        <div className={classes.container}>
            <ButtonWithIcon
                color={Colors.BLACK}
                backgroundColor={Colors.WHITE}
                label={"Zobacz " + getLabelForm(workersRecommendations.length)}
                icon={<BsCardChecklist color={color} size={30}/>}
                onClick={() => setSeeWorkers(true)}
                iconOnLeft={true}
            />
            <WorkersListDialog
                open={seeWorkers}
                setOpen={setSeeWorkers}
                workersRecommendations={workersRecommendations}
                setWorkerRecommendations={setWorkerRecommendations}
                title={title}
                color={color}
            />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        alignItems: "center",
        margin: "10px 5%",
    },
}));

export default StageSummary;