import {makeStyles, Table, TableBody, TableContainer} from "@material-ui/core";
import React from "react";
import SummaryRow from "./SummaryRow";

const SummaryTable = ({result}) => {

    const classes = useStyles()

    return (
        <div className={classes.formContainer}>
            <div className={classes.header}>Poziom luki kompetencyjnej</div>
            <div className={classes.header}>w skali 0-4</div>
            <TableContainer>
                <Table>
                    <TableBody>
                        {result.map((competenceType, typeIndex) => (
                            <SummaryRow competenceType={competenceType} key={typeIndex + "t"}/>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            fontWeight: "bold",
        },
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "30px 50px",
        [theme.breakpoints.down(700)]: {
            padding: 10,
        }
    },
    header: {
        fontWeight: "bold",
        marginBottom: 10,
        fontSize: 16,
        textAlign: "center",
    },
}));

export default SummaryTable;