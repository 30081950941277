import React, {useState} from 'react';
import styled from "styled-components";
import Header from "../../components/Header";
import Subheader from "../../components/Subheader";
import {Colors} from "../../constants/colors";
import InputField from "../../components/InputField";
import OutlineButton from "../../components/buttons/OutlineButton";
import {remindPasswordUsingPOST} from "../../swagger/vue-api-client";
import ErrorAndLoadingMessage from "../../components/ErrorAndLoadingMessage";
import {IoMailOutline} from "react-icons/io5";
import TrademarksHeader from "../../components/TrademarksHeader";
import {makeStyles} from "@material-ui/core/styles";
import Footer from "../../components/Footer";
import LeftTurquoiseContainer from "../../components/LeftTurquoiseContainer";

const ForgotPasswordPage = ({history}) => {

    const classes = useStyles()

    const [email, setEmail] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [complete, setComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const regex = /\b[a-z0-9._+-]+@[a-z0-9.-]+\.[a-z]{2,}\b/

    const handleSend = () => {
        if (email === "" || email.match(regex) === null || email.match(regex)["0"] !== email) {
            setErrorMessage("Podany adres email jest niepoprawny")
        } else {
            setIsLoading(true)
            remindPasswordUsingPOST({
                passwordRemindRequest: {
                    email: email
                }
            })
                .then((result) => {
                    setComplete(true);
                    setIsLoading(false)
                })
                .catch((error) => {
                    setErrorMessage(error.response.data)
                    setIsLoading(false)
                });
        }
    }

    return (
        <div className={classes.container}>
            <TrademarksHeader history={history}/>
            <LeftTurquoiseContainer>
                <Header title={"Cześć!"}/>
                <Subheader title={"Na podany adres email wyślemy link weryfikacyjny."} color={Colors.WHITE}/>
                <Subheader title={"Wejdź w niego, by utworzyć nowe hasło."} color={Colors.WHITE}/>
            </LeftTurquoiseContainer>
            <RightWhiteContainer>
                {complete ? (
                    <Subheader title={"Sprawdź swoją skrzynkę mailową."} color={Colors.BLACK}/>
                ) : (
                    <React.Fragment>
                        <Header title={"Zapomniane hasło"} color={Colors.TURQUOISE}/>
                        <div className={classes.form}>
                            <InputField
                                value={email}
                                setValue={setEmail}
                                placeholder={"Email"}
                                icon={<IoMailOutline size={20} color={Colors.LIGHT_GREY}/>}
                            />
                        </div>
                        <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                        <OutlineButton
                            label={"Wyślij"}
                            onClick={() => handleSend()}
                        />
                    </React.Fragment>
                )}
            </RightWhiteContainer>
            <Footer onlySmallScreen={true}/>
        </div>
    );
};


const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100vm",
        height: "100vh",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            fontSize: 14,
            textAlign: "center",
        },
    },
    form: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        [theme.breakpoints.down("sm")]: {
            width: "80%",
        }
    },
}));

const RightWhiteContainer = styled.div`
  display: flex;
  background-color: ${Colors.WHITE};
  flex: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default ForgotPasswordPage;