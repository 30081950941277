export const professions = [
    {value: "kierownik", label: "kierownik"},
    {value: "pracownik administracyjny", label: "pracownik administracyjny"},
    {value: "księgowy/a", label: "księgowy/a"},
    {value: "fizjoterapeuta/tka", label: "fizjoterapeuta/tka"},
    {value: "rehabilitant/tka", label: "rehabilitant/tka"},
    {value: "opiekun/ka medyczny/a", label: "opiekun/ka medyczny/a"},
    {value: "pielęgniarz/pielęgniarka", label: "pielęgniarz/pielęgniarka"},
    {value: "terapeuta/tka zajęciowy", label: "terapeuta/tka zajęciowy"},
    {value: "arteterapeuta/tka", label: "arteterapeuta/tka"},
    {value: "opiekun/a / asystent/a osoby niepełnosprawnej / starszej", label: "opiekun/ka / asystent/ka osoby niepełnosprawnej / starszej"},
    {value: "psycholog", label: "psycholog"},
    {value: "neurologopeda", label: "neurologopeda"},
    {value: "inny pracownik", label: "inny pracownik"},
]
