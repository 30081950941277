import React, {useState} from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {TableCell, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core/styles";
import RecommendationTableRow from "./RecommendationTableRow";

const headCells = [
    {id: 'competenceName', label: 'Kompetencja', align: 'left'},
    {id: 'competenceLevelGap', label: 'Luka kompetencyjna', align: 'left'},
    {id: 'stage', label: 'Etap', align: 'left'},
    {id: 'task', label: 'Zalecenie', align: 'left'},
    {id: 'workers', label: 'Lista pracowników', align: 'left'},
];

const RecommendationTable = ({recommendations, setRecommendations}) => {

    const height = window.innerHeight
    const classes = useStyles({height})
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false)

    const handleChangeRecommendation = (index, newRecommendation) => {
        setIsLoading(true)
        let newRecommendations = [...recommendations]
        newRecommendations.splice(index, 1, newRecommendation)
        setRecommendations(newRecommendations)
        setIsLoading(false)
    }

    return (
        <TableContainer className={classes.container}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {headCells.map((item, index) => (
                            <TableCell key={index} className={classes.headCell}>{item.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {recommendations.map((row, index) =>
                        <RecommendationTableRow
                            key={index}
                            recommendation={row}
                            setRecommendation={(newRecommendation) => handleChangeRecommendation(index, newRecommendation)}
                        />
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const useStyles = makeStyles(theme => ({
    container: props => ({
        //Wyświetlanie tabeli bez scrolla
        // maxHeight: props.height !== null ? props.height * 0.75 : 500,
        marginTop: 30,
    }),
    headCell: {
        fontWeight: "500",
    }
}));

export default RecommendationTable;