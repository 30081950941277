import React from 'react';
import {Colors} from "../../constants/colors";
import {Link} from "react-router-dom";
import logo from "../../assets/logo.png";
import HorizontalMenuItem from "./HorizontalMenuItem";
import {getRoleDependantHomePageLink, getRoleDependentLink, getRoleIndependentLink} from "../../services/LinksService";
import {makeStyles} from "@material-ui/core/styles";
import RoleDependantUserMenu from "./RoleDependantUserMenu";

const HorizontalMenu = ({menuItems, history}) => {

    const classes = useStyles()
    const current = getRoleIndependentLink(window.location.pathname)
    const name = localStorage.getItem("name")
    const role = localStorage.getItem("role")

    const getRoleLabel = () => {
        if (role === "ADMIN")
            return "Administrator"
        else if (role === "EMPLOYER")
            return "Pracodawca"
        else if (role === "WORKER")
            return "Pracownik"
    }

    return (
        <div className={classes.container}>
            <div><Link to={getRoleDependantHomePageLink(role)}><img className={classes.homeLogo} src={logo} alt="Logo"/></Link></div>
            <div className={classes.items}>
                {menuItems.map((item, index) => (
                    <HorizontalMenuItem
                        to={getRoleDependentLink(item.to)}
                        label={item.label}
                        current={item.to === current}
                        key={index}
                    />
                ))}
            </div>
            <div className={classes.name}>
                <div>{name}</div>
                <div className={classes.role}>{getRoleLabel()}</div>
            </div>
            <RoleDependantUserMenu role = {role} history={history}/>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: 110,
        padding: "0 1vw",
        backgroundColor: Colors.WHITE,
        borderBottomColor: Colors.LIGHT_GREY,
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        [theme.breakpoints.down(1200)]: {
            display: "none",
        },
        [`@media print`]: {
            display: "none"
        }
    },
    items: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        margin: "0 30px",
        height: "100%",
    },
    name: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        fontSize: "1.1em",
        textAlign: "right",
    },
    role: {
        color: Colors.MEDIUM_GREY,
        textAlign: "right",
    },
    homeLogo: {
        display: "block",
        height: 90,
    }
}));

export default HorizontalMenu;