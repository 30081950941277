import React, {useEffect, useState} from "react";
import {Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis, Legend} from "recharts";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import {formatNumber} from "../../services/NumberService";

const FinalResultColumnChart = ({chartData}) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        setData([])
        for (let i = 0; i < chartData.length; ++i) {
            setData(prevState => [...prevState, {
                id: i + 1,
                name: chartData[i].competenceName,
                Start: formatNumber(chartData[i].competenceLevelGapStart),
                Obecnie: formatNumber(chartData[i].competenceLevelGapNow),
                Meta: formatNumber(chartData[i].competenceLevelGapFinish)
            }])
        }
    },[chartData])

    const getChartWidth = () => {
        return Math.min(window.innerWidth - 50, 1320)
    }

    const getChartHeight = () => {
        return Math.min(2 * window.innerHeight / 3, 800)
    }

    return (
        <Container>
            <BarChart
                layout={"horizontal"}
                width={getChartWidth()}
                height={getChartHeight()}
                data={data}
                margin={{
                    top: 5,
                    right: 8,
                    left: 16,
                    bottom: 5,
                }}
            >
                <YAxis domain={[0, 5]}/>
                <XAxis dataKey="name" type="category" stroke="#ccc" tick={null}/>
                <Tooltip wrapperStyle={{backgroundColor: '#ccc'}}/>
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                <Bar dataKey={"Start"} barSize={25} fill={Colors.TURQUOISE}/>
                <Bar dataKey={"Obecnie"} barSize={25} fill={Colors.GREEN}/>
                <Bar dataKey={"Meta"} barSize={25} fill={Colors.BLUE}/>
                <Legend/>
            </BarChart>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  margin: 40px 10px 5px 0;
  justify-content: center;
  align-items: center;
`;

export default FinalResultColumnChart;