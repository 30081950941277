import {Dialog, Tooltip} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import {
    deleteELearningSourceUsingDELETE, getELearningSourcesUsingGET,
    saveELearningSourcesUsingPOST
} from "../../../swagger/vue-api-client";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import DialogTitle from "../../DialogTitle";
import DialogButtonsContainer from "../../DialogButtonsContainer";
import InputField from "../../InputField";
import {makeStyles} from "@material-ui/core/styles";
import {BsList} from "react-icons/bs";
import {IoIosSchool, IoIosPricetag, IoIosTime} from "react-icons/io";
import ButtonWithIcon from "../../buttons/ButtonWithIcon";
import AddIcon from "@material-ui/icons/Add";
import OnlyIconButton from "../../buttons/OnlyIconButton";
import {AiOutlineDelete} from "react-icons/ai";
import {toast} from "react-toastify";
import {convertELearningSource} from "../../../pages/shared/ForumPage";

const AddELearningSourceDialog = ({open, setOpen, eLearningSources, setELearningSources}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const classes = useStyles();
    const [limitReached, setLimitReached] = useState(false);
    const [deleteELearningSource, setDeleteELearningSource] = useState(false);

    useEffect(() => {
        if (eLearningSources.length === 15) {
            setLimitReached(true)
        } else {
            setLimitReached(false)
        }
    }, [eLearningSources.length])

    const handleSaveChanges = () => {

        setIsLoading(true)

        saveELearningSourcesUsingPOST({sources: eLearningSources})
            .then((res) => {
                toast.success("Sukces! Zapisano źródła")
                setOpen(false)
                return getELearningSourcesUsingGET({companyId: parseInt(localStorage.companyId)})
            })
            .then((res) => {
                setELearningSources(res.data.map((item, index) => convertELearningSource(item)))
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error)
                toast.error("Nie udało się zapisać źródeł")
                setIsLoading(false)
            })
    }

    const handleChange = (index, item, value, at) => {
        setIsLoading(true)
        let newItem = Object.assign(item)
        newItem[at] = value

        let newSources = [...eLearningSources]
        newSources.splice(index, 1, newItem)
        setELearningSources(newSources)
        setIsLoading(false)
    }

    const addNewSource = () => {
        if (eLearningSources.length < 15) {
            let newSources = [...eLearningSources]
            newSources.push({})
            setELearningSources(newSources)
        }
    }

    const onELearningSourceDelete = (item, index) => {

        setIsLoading(true)

        let newSources = [...eLearningSources]
        newSources.splice(index, 1)
        setELearningSources(newSources)

        if (item.id !== undefined) {
            deleteELearningSourceUsingDELETE({id: item.id})
                .then((res) => {
                    setErrorMessage('')
                })
                .catch((error) => {
                    console.log(error)
                    toast.error("Nie udało się usunąć źródła")
                })
        }
        setIsLoading(false)
        setDeleteELearningSource(false)
    }

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'lg'} fullWidth={true}>
            <DialogTitle title={"Źródła tematów i cen szkoleń"}/>
            <Container>
                {eLearningSources.map((item, index) => {
                    if (item.professionalCompetence == null) {
                        item.professionalCompetence = ''
                    }
                    if (item.elearningName == null) {
                        item.elearningName = ''
                    }
                    return (
                        <div key={index} className={classes.item}>
                            {index+1}.
                            <InputField
                                value={item.professionalCompetence}
                                setValue={(value) => handleChange(index, item, value, "professionalCompetence")}
                                placeholder={"Kompetencja zawodowa"}
                                icon={<BsList size={20} color={Colors.LIGHT_GREY}/>}
                            />
                            <InputField
                                value={item.elearningName}
                                setValue={(value) => handleChange(index, item, value, "elearningName")}
                                placeholder={"Szkolenie"}
                                icon={<IoIosSchool size={20} color={Colors.LIGHT_GREY}/>}
                            />
                            <InputField
                                value={item.elearningCost}
                                setValue={(value) => handleChange(index, item, value, "elearningCost")}
                                placeholder={"Koszt szkolenia"}
                                icon={<IoIosPricetag size={20} color={Colors.LIGHT_GREY}/>}
                            />
                            <InputField
                                value={item.elearningTime}
                                setValue={(value) => handleChange(index, item, value, "elearningTime")}
                                placeholder={"Czas szkolenia"}
                                icon={<IoIosTime size={20} color={Colors.LIGHT_GREY}/>}
                            />
                            <Tooltip title={"Usuń źródło"} placement={"top"}>
                                <div>
                                    <OnlyIconButton
                                        icon={<AiOutlineDelete size={25} color={Colors.RED}/>}
                                        onClick={() => setDeleteELearningSource(true)}
                                    />
                                </div>
                            </Tooltip>
                            <Dialog onClose={() => setDeleteELearningSource(false)} open={deleteELearningSource} maxWidth={'sm'} fullWidth={true}>
                                <DialogTitle title={"Czy na pewno chcesz usunąć źródło?"}/>
                                <Container>
                                    <ErrorAndLoadingMessage errorMessage={errorMessage} isLoading={isLoading}/>
                                    <DialogButtonsContainer onlySmallScreen={false}>
                                        <ButtonWithoutIcon
                                            label={"anuluj"}
                                            onClick={() => setDeleteELearningSource(false)}
                                        />
                                        <ButtonWithoutIcon
                                            label={"usuń"}
                                            onClick={() => onELearningSourceDelete(item, index)}
                                            backgroundColor={Colors.RED}
                                        />
                                    </DialogButtonsContainer>
                                </Container>
                            </Dialog>
                        </div>
                    )
                })}
                <ButtonWithIcon
                    icon={<AddIcon/>}
                    onClick={() => addNewSource()}
                    label={"Dodaj kolejne źródło"}
                    disabled={limitReached}
                />
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"Anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"Zapisz"}
                        onClick={() => handleSaveChanges()}
                        backgroundColor={Colors.GREEN}
                    />
                </DialogButtonsContainer>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 5%;
  width: 90%;
`;

const useStyles = makeStyles(theme => ({
    item: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
}));

export default AddELearningSourceDialog;