import React from 'react';
import styled from "styled-components";
import {Colors} from "../../constants/colors";

const OnlyTextButton = ({onClick, backgroundColor=Colors.WHITE, text, disabled = false}) => {

    return (
        disabled ? (
            <DisabledButton onClick={onClick} backgroundColor={backgroundColor} disabled>
                {text}
            </DisabledButton>
        ) : (
            <ClickableButton onClick={onClick} backgroundColor={backgroundColor}>
                {text}
            </ClickableButton>
        )
    );
};

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-width: 0;
  border-radius: 50px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  padding: 5px 15px;
  margin: 10px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s;
`;

const ClickableButton = styled(Button)`
  background-color: ${props => props.backgroundColor};
  cursor: pointer;

  &:focus, &:hover, &:active {
    outline: none;
  }

  &:hover, &:active {
    color: ${Colors.TURQUOISE};
  }
`;

const DisabledButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
`;

export default OnlyTextButton;