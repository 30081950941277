import React, {useState} from "react";
import {BrowserRouter as Router, Route, Switch, useHistory} from "react-router-dom";
import SignUpPage from "./pages/public/SignUpPage"
import SignInPage from "./pages/public/SignInPage";
import PublicHomePage from "./pages/public/PublicHomePage";
import WorkersPage from "./pages/employer/WorkersPage";
import ForumPage from "./pages/shared/ForumPage";
import {EmployerContext} from "./contexts/EmployerContext";
import WorkerConfirmEmailPage from "./pages/public/WorkerConfirmEmailPage";
import WorkerConfirmEmailCompletePage from "./pages/public/WorkerConfirmEmailCompletePage";
import EmployerConfirmEmailPage from "./pages/public/EmployerConfirmEmailPage";
import SignUpCompletePage from "./pages/public/SignUpCompletePage";
import AllEvaluationsPage from "./pages/shared/AllEvaluationsPage";
import CompaniesPage from "./pages/admin/CompaniesPage";
import {WorkerContext} from "./contexts/WorkerContext";
import {AdminContext} from "./contexts/AdminContext";
import ForumPostPage from "./pages/shared/ForumPostPage";
import ForgotPasswordPage from "./pages/public/ForgotPasswordPage";
import CreateNewPasswordPage from "./pages/public/CreateNewPasswordPage";
import CreateNewPasswordCompletePage from "./pages/public/CreateNewPasswordCompletePage";
import MaterialsPage from "./pages/shared/MaterialsPage";
import EvaluationPage from "./pages/shared/EvaluationPage";
import AllRecommendationsPage from "./pages/shared/AllRecommendationsPage";
import RecommendationPage from "./pages/worker/RecommendationPage";
import QuizPage from "./pages/worker/QuizPage";
import ProfilePage from "./pages/public/ProfilePage";
import CompanyPage from "./pages/employer/CompanyPage";
import FinancesPage from "./pages/employer/FinancesPage";
import AllCompanyRecommendationsPage from "./pages/employer/AllCompanyRecommendationPage";
import FinancialResult1 from "./pages/employer/FinancialResult1";
import FinancialResult2 from "./pages/employer/FinancialResult2";
import FinancialResult3 from "./pages/employer/FinancialResult3";
import FinancialResult4 from "./pages/employer/FinancialResult4";
import FinancialResult5 from "./pages/employer/FinancialResult5";
import FinancialResult6 from "./pages/employer/FinancialResult6";
import RecommendationPreviewPage from "./pages/employer/RecommendationPreviewPage";
import RecommendationSummaryPage from "./pages/worker/RecommendationSummaryPage";
import FillQuizPage from "./pages/public/FillQuizPage";
import {ToastContainer} from "react-toastify";
import OrganisationEvaluationPage from "./pages/employer/OrganisationEvaluationPage";
import TrendQuizCompletePage from "./pages/public/TrendQuizCompletePage";

export default function App() {

    // eslint-disable-next-line no-unused-vars
    let history = useHistory();

    const [companies, setCompanies] = useState([]);
    const [companiesToAccept, setCompaniesToAccept] = useState([]);
    const [companyExcelFiles, setCompanyExcelFiles] = useState([]);

    const [workers, setWorkers] = useState([]);

    const [evaluations, setEvaluations] = useState([]);
    const [me, setMe] = useState({});
    const [competences, setCompetences] = useState([]);

    return (
        <AdminContext.Provider value={{
            companies: companies,
            setCompanies: setCompanies,
            companiesToAccept: companiesToAccept,
            setCompaniesToAccept: setCompaniesToAccept,
            companyExcelFiles: companyExcelFiles,
            setCompanyExcelFiles: setCompanyExcelFiles,
        }}>
            <EmployerContext.Provider value={{
                workers: workers,
                setWorkers: setWorkers,
            }}>
                <WorkerContext.Provider value={{
                    evaluations: evaluations,
                    setEvaluations: setEvaluations,
                    me: me,
                    setMe: setMe,
                    competences: competences,
                    setCompetences: setCompetences,
                }}>
                    <Router basename={process.env.REACT_APP_BASE_PATH}>
                        <div>
                            <Switch>
                                <Route exact path="/" component={PublicHomePage}/>
                                <Route path="/logowanie" component={SignInPage}/>
                                <Route exact path="/rejestracja" component={SignUpPage}/>

                                <Route exact path="/rejestracja/ukonczona" component={SignUpCompletePage}/>
                                <Route exact path="/potwierdz-email/pracownik" component={WorkerConfirmEmailPage}/>
                                <Route exact path="/potwierdz-email/pracownik/ukonczone" component={WorkerConfirmEmailCompletePage}/>
                                <Route exact path="/potwierdz-email/pracodawca" component={EmployerConfirmEmailPage}/>
                                <Route exact path="/zapomniane-haslo/" component={ForgotPasswordPage}/>
                                <Route exact path="/nowe-haslo/" component={CreateNewPasswordPage}/>
                                <Route exact path="/nowe-haslo/ukonczone" component={CreateNewPasswordCompletePage}/>

                                <Route path="/admin/firmy" component={CompaniesPage}/>
                                <Route exact path="/admin/trendy" component={ForumPage}/>
                                <Route path="/admin/trendy/:postId" component={ForumPostPage}/>

                                <Route path="/pracodawca/pracownicy" component={WorkersPage}/>
                                <Route exact path="/pracodawca/ocenianie" component={AllEvaluationsPage}/>
                                <Route path="/pracodawca/ocenianie/organizacja" component={OrganisationEvaluationPage}/>
                                <Route path="/pracodawca/ocenianie/:evaluationId" component={EvaluationPage}/>
                                <Route exact path="/pracodawca/trendy" component={ForumPage}/>
                                <Route path="/pracodawca/trendy/:postId" component={ForumPostPage}/>
                                <Route path="/pracodawca/materialy" component={MaterialsPage}/>
                                <Route exact path="/pracodawca/zalecenia" component={AllCompanyRecommendationsPage}/>
                                <Route exact path="/pracodawca/zalecenia/:recommendationId" component={RecommendationPreviewPage}/>
                                <Route path="/pracodawca/finanse" component={FinancesPage}/>
                                <Route path="/pracodawca/wyniki1" component={FinancialResult1}/>
                                <Route path="/pracodawca/wyniki2" component={FinancialResult2}/>
                                <Route path="/pracodawca/wyniki3" component={FinancialResult3}/>
                                <Route path="/pracodawca/wyniki4" component={FinancialResult4}/>
                                <Route path="/pracodawca/wyniki5" component={FinancialResult5}/>
                                <Route path="/pracodawca/wyniki6" component={FinancialResult6}/>

                                <Route exact path="/pracownik/ocenianie" component={AllEvaluationsPage}/>
                                <Route path="/pracownik/ocenianie/:evaluationId" component={EvaluationPage}/>
                                <Route exact path="/pracownik/trendy" component={ForumPage}/>
                                <Route path="/pracownik/trendy/:postId" component={ForumPostPage}/>
                                <Route path="/pracownik/materialy" component={MaterialsPage}/>
                                <Route exact path="/pracownik/sciezka" component={AllRecommendationsPage}/>
                                <Route exact path="/pracownik/sciezka/:recommendationId" component={RecommendationPage}/>
                                <Route path="/pracownik/sciezka/:recommendationId/quiz" component={QuizPage}/>
                                <Route path="/pracownik/sciezka/:recommendationId/podsumowanie" component={RecommendationSummaryPage}/>

                                <Route path="/trend-quiz/:quizId/:guestToken" component={FillQuizPage}/>
                                <Route path="/trend-quiz/ukonczone" component={TrendQuizCompletePage}/>

                                <Route path="/profil" component={ProfilePage}/>
                                <Route path="/firma" component={CompanyPage}/>
                            </Switch>
                            <ToastContainer/>
                        </div>
                    </Router>
                </WorkerContext.Provider>
            </EmployerContext.Provider>
        </AdminContext.Provider>
    );
}