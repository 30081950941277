export const Colors = {
    WHITE: '#fff',
    BLACK: '#000',
    ALMOST_BLACK: '#313131',
    DARK_TURQUOISE: '#178DAA',
    TURQUOISE: '#18ABB5',
    PASTEL_TURQUOISE: '#daf6f6',
    LIGHT_TURQUOISE: '#c5f1f1',
    BLUE: '#59CAEA',
    BLUE_TURQUOISE: '#15B8D4',
    DARK_BLUE: '#0066ff',
    LIGHT_GREEN: '#c6f1c3',
    GREEN: '#40B649',
    GREEN2: '#78C36B',
    MEDIUM_GREEN: '#12803F',
    DARK_GREEN: '#11562C',
    DARKEST_GREEN: '#003300',
    VERY_LIGHT_GREY: '#F4F8F7',
    LIGHTER_GREY: '#dee2e1',
    LIGHT_GREY: '#BABEBD',
    MEDIUM_GREY: '#808080',
    GREY: '#616161',
    RED: '#f50057',
}
