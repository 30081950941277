import {Dialog} from "@material-ui/core";
import React, {useContext, useState} from "react";
import InputField from "../../InputField";
import {Colors} from "../../../constants/colors";
import {RiUserLine} from "react-icons/ri";
import {IoMailOutline} from "react-icons/io5";
import {registerWorkerUsingPOST} from "../../../swagger/vue-api-client";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import {EmployerContext} from "../../../contexts/EmployerContext";
import {convertWorker} from "../../../services/WorkerService";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import DialogTitle from "../../DialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import DialogButtonsContainer from "../../DialogButtonsContainer";

const AddWorkerDialog = ({open, setOpen}) => {

    const classes = useStyles()
    const employerContext = useContext(EmployerContext);

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCreateUser = (userName, userSurname, userEmail) => {

        setIsLoading(true)

        registerWorkerUsingPOST({
            workerRegistrationDto: {
                name: userName,
                surname: userSurname,
                email: userEmail
            }
        })
            .then((response) => {
                    setOpen(false);
                    setErrorMessage('');
                    setName('');
                    setSurname('');
                    setEmail('');
                    employerContext.setWorkers(current => [...current, convertWorker(response.data)]);
                    setIsLoading(false);
            })
            .catch((error) => {
                setErrorMessage(error.response.data);
                setIsLoading(false);
            });
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle title={"Dodaj pracownika"}/>
            <div className={classes.container}>
                <InputField
                    value={name}
                    setValue={setName}
                    placeholder={"Imię"}
                    icon={<RiUserLine size={20} color={Colors.LIGHT_GREY}/>}
                />
                <InputField
                    value={surname}
                    setValue={setSurname}
                    placeholder={"Nazwisko"}
                    icon={<RiUserLine size={20} color={Colors.LIGHT_GREY}/>}
                />
                <InputField
                    value={email}
                    setValue={setEmail}
                    placeholder={"Email"}
                    icon={<IoMailOutline size={20} color={Colors.LIGHT_GREY}/>}
                />
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"Anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"Utwórz konto"}
                        onClick={() => handleCreateUser(name, surname, email)}
                        backgroundColor={Colors.GREEN}
                    />
                </DialogButtonsContainer>
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "70%",
        padding: "20px 15%",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            padding: "20px 2%",
        }
    },
}));

export default AddWorkerDialog;