import React, {useState} from 'react';
import {Colors} from "../../constants/colors";
import {Link} from "react-router-dom";
import logo from "../../assets/logo.png";
import {
    getRoleDependantHomePageLink,
    getRoleDependentLink,
    getRoleIndependentLink,
    handleSignOut
} from "../../services/LinksService";
import {makeStyles} from "@material-ui/core/styles";
import {HiMenu} from "react-icons/hi";
import OnlyIconButton from "../buttons/OnlyIconButton";
import VerticalMenuItem from "./VerticalMenuItem";
import ButtonWithoutIcon from "../buttons/ButtonWithoutIcon";
import {IoArrowBackOutline, IoClose, IoPersonOutline} from "react-icons/all";

const VerticalMenu = ({menuItems, history}) => {

    const current = getRoleIndependentLink(window.location.pathname)
    const [open, setOpen] = useState(false)
    const classes = useStyles({open})
    const name = localStorage.getItem("name")
    const role = localStorage.getItem("role")

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <OnlyIconButton
                    onClick={() => history.goBack()}
                    icon={<IoArrowBackOutline color={Colors.ALMOST_BLACK} size={40}/>}
                />
                <Link to={getRoleDependantHomePageLink(role)}><img className={classes.homeLogo} src={logo} alt="Logo"/></Link>
                {open ? (
                    <OnlyIconButton
                        onClick={() => setOpen(c => !c)}
                        icon={<IoClose color={Colors.ALMOST_BLACK} size={40}/>}
                    />
                ) : (
                    <OnlyIconButton
                        onClick={() => setOpen(c => !c)}
                        icon={<HiMenu color={Colors.ALMOST_BLACK} size={40}/>}
                    />
                )}
            </div>
            {open ? (
                <div className={classes.items}>
                    {menuItems.map((item, index) => (
                        <VerticalMenuItem
                            to={getRoleDependentLink(item.to)}
                            label={item.label}
                            current={item.to === current}
                            key={index}
                        />
                    ))}
                    <div className={classes.signOutButton}>
                        <ButtonWithoutIcon
                            label={"wyloguj się"}
                            onClick={() => handleSignOut(history)}
                        />
                    </div>
                    <div className={classes.name}><IoPersonOutline size={25} color={Colors.GREY}/><div className={classes.nameText}>{name}</div></div>
                </div>
            ) : null}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        padding: 15,
        backgroundColor: Colors.WHITE,
        borderBottomColor: Colors.LIGHT_GREY,
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        [theme.breakpoints.up(1200)]: {
            display: "none",
        },
        [`@media print`]: {
            display: "none"
        }
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
    },
    items: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: 10,
    },
    signOutButton: {
        marginTop: 15,
    },
    name: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 15,
        color: Colors.GREY,
        fontSize: 17,
    },
    nameText: {
        marginLeft: 10,
    },
    homeLogo: {
        display: "block",
        height: 70,
    },
}));

export default VerticalMenu;