import React, {useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import StatusDependIcon from "./StatusDependIcon";
import OnlyIconButton from "../../buttons/OnlyIconButton";
import StatusDependDialog from "./StatusDependDialog";
import DeleteWorkerDialog from "./DeleteWorkerDialog";
import {Colors} from "../../../constants/colors";
import {AiOutlineDelete, AiOutlineEdit, AiOutlineMail} from "react-icons/ai";
import ButtonWithIcon from "../../buttons/ButtonWithIcon";
import SendWorkerVerificationEmailDialog from "./SendWorkerVerificationEmailDialog";
import EditWorkerDialog from "./EditWorkerDialog";

const WorkerTableRow = ({worker, number}) => {

    const classes = useStyles()

    const [open, setOpen] = useState(false);
    const [deleteWorker, setDeleteWorker] = useState(false);
    const [editWorker, setEditWorker] = useState(false);
    const [sendVerificationEmail, setSendVerificationEmail] = useState(false);

    const handleStatusDependButtonClick = () => {
        setOpen(true);
    };

    const getActionLabel = (status) => {
        if (status === "ZAKOŃCZONA") {
            return "Zobacz raport"
        } else if (status === "W TRAKCIE") {
            return "Zobacz postęp"
        } else if (status === "BRAK") {
            return "Zaplanuj ocenę"
        }
    }

    const canBeEdited = (status) => {
        switch (status) {
            case "BRAK":
            case "W TRAKCIE":
                return true;
            default:
                return false;
        }
    }

    return (
        <React.Fragment>
            <TableRow key={worker.company.id}>
                <TableCell className={classes.cell}>{number}</TableCell>
                <TableCell className={classes.cell}>{worker.name}</TableCell>
                <TableCell className={classes.cell}>
                    <div className={classes.textAndButton}>
                        {worker.email}
                        { !worker.emailVerified ?
                            <OnlyIconButton
                                icon={<AiOutlineMail color={Colors.GREEN} size={25}/>}
                                onClick={() => setSendVerificationEmail(true)}
                            /> : null
                        }
                    </div>
                </TableCell>
                <TableCell className={classes.cell}>
                    <ButtonWithIcon
                        color={Colors.BLACK}
                        backgroundColor={Colors.WHITE}
                        label={worker.status +", " + getActionLabel(worker.status)}
                        icon={<StatusDependIcon status={worker.status}/>}
                        onClick={handleStatusDependButtonClick}
                        iconOnLeft={true}
                    />
                </TableCell>
                <TableCell className={classes.cell} size={"small"}>
                    <OnlyIconButton
                        icon={<AiOutlineDelete color={Colors.RED} size={25}/>}
                        onClick={() => setDeleteWorker(true)}
                    />
                </TableCell >
                <TableCell className={classes.cell} size={"small"}>
                    <OnlyIconButton
                        disabled={!canBeEdited(worker.status)}
                        icon={<AiOutlineEdit color={Colors.TURQUOISE} size={25}/>}
                        onClick={() => setEditWorker(true)}
                    />
                </TableCell>
            </TableRow>
            <StatusDependDialog status={worker.status} open={open} setOpen={setOpen} worker={worker}/>
            <SendWorkerVerificationEmailDialog open={sendVerificationEmail} setOpen={setSendVerificationEmail} worker={worker}/>
            <DeleteWorkerDialog open={deleteWorker} setOpen={setDeleteWorker} worker={worker}/>
            <EditWorkerDialog open={editWorker} setOpen={setEditWorker} worker={worker} />
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    cell: {
        [theme.breakpoints.down("md")]: {
            padding: "16px 5px",
            fontSize: 13,
        },
    },
    textAndButton: {
        display: props => "flex",
        alignItems: "center",
    },
}));

export default WorkerTableRow;