import React from "react";
import {CardActions, CardContent, LinearProgress, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import {getTypeLabel} from "../../services/EvaluationService";
import {getRoleDependentLink} from "../../services/LinksService";
import ButtonWithoutIcon from "../buttons/ButtonWithoutIcon";

const EvaluationTile = ({evaluation, history, finished}) => {

    return (
        <Tile>
            <CardContent>
                <Name variant="h5">{evaluation.worker.name}</Name>
                <Type color="textSecondary">{getTypeLabel(evaluation.type)}</Type>
                <BorderLinearProgress variant="determinate" value={finished ? 100 : 30}/>
            </CardContent>
            {finished ? (
                <RightAlignedCardActions>
                    <ButtonWithoutIcon
                        label={"Podgląd"}
                        onClick={() => history.push(getRoleDependentLink("/ocenianie/" + evaluation.id))}
                        backgroundColor={Colors.TURQUOISE}
                    />
                </RightAlignedCardActions>
            ) : (
                <RightAlignedCardActions>
                    <ButtonWithoutIcon
                        label={"Oceń"}
                        onClick={() => history.push(getRoleDependentLink("/ocenianie/" + evaluation.id))}
                        backgroundColor={Colors.TURQUOISE}
                    />
                </RightAlignedCardActions>
            )}
        </Tile>
    )
}

const BorderLinearProgress = withStyles(theme => ({
    root: {
        height: 10,
        borderRadius: 5,
        marginTop: 40,
    },
    colorPrimary: {
        backgroundColor: Colors.VERY_LIGHT_GREY,
    },
    bar: {
        borderRadius: 5,
        backgroundColor: Colors.TURQUOISE,
    },
}))(LinearProgress);

const RightAlignedCardActions = withStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))(CardActions);

const Tile = styled.div`
  background-color: ${Colors.PASTEL_TURQUOISE};
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
`;

const Name = styled(Typography)`
  padding-bottom: 15px;
`;

const Type = styled(Typography)`
  text-transform: uppercase;
`;

export default EvaluationTile;