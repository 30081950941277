import React, {useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../constants/colors";
import styled from "styled-components";
import EditCommentDialog from "./EditCommentDialog";
import {getDateAndHourLabel} from "../../../services/DateService";
import ModerationInfo from "./ModerationInfo";
import ButtonWithIcon from "../../buttons/ButtonWithIcon";
import {GoSearch} from "react-icons/all";

const PostTableRow = ({comment, setComments, post, setPost, isPost=false, setShowQuiz, history}) => {

    const classes = useStyles();
    const [editionOpen, setEditionOpen] = useState(false)

    let role = localStorage.getItem("role");

    return (
        <TableRow className={classes.root}>
            <TableCell>
                <Cell>
                    <div className={classes.metadata}>
                        <div className={classes.moderationSmall}><ModerationInfo comment={comment} setEditionOpen={setEditionOpen}/></div>
                        <div className={classes.name}>{comment.author.name}</div>
                        <div className={classes.moderationBig}><ModerationInfo comment={comment} setEditionOpen={setEditionOpen}/></div>
                        <div className={classes.date}>{getDateAndHourLabel(new Date(comment.creationDate))}</div>
                    </div>
                    <Content>{comment.content}</Content>
                    {post !== null ? post.quiz !== null && isPost ?
                        role === "ADMIN" || role === "EMPLOYER" ?
                        <div className={classes.buttonsWrapper}>
                            <ButtonWithIcon
                                icon={<GoSearch size={25}/>}
                                onClick={() => setShowQuiz(true)}
                                label={"Zobacz wyniki ankiety"}
                            />
                        </div> : null : null : null
                    }
                </Cell>
            </TableCell>
            <EditCommentDialog
                open={editionOpen}
                setOpen={setEditionOpen}
                comment={comment}
                setComments={setComments}
                post={post}
                setPost={setPost}
            />
        </TableRow>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            border: 0,
            padding: 0,
            paddingBottom: 20,
        },
    },
    metadata: {
        display: "flex",
        width: "100%",
        marginBottom: 15,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down(700)]: {
            flexDirection: "column",
            alignItems: "flex-start",
        }
    },
    moderationBig: {
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
        marginRight: 40,
        marginTop: -2,
        [theme.breakpoints.down(700)]: {
            display: "none",
        }
    },
    moderationSmall: {
        display: "flex",
        width: "100%",
        [theme.breakpoints.up(700)]: {
            display: "none",
        }
    },
    name: {
        fontSize: 18,
        fontWeight: "bold",
        [theme.breakpoints.down(700)]: {
            fontSize: 16,
        }
    },
    date: {
        fontSize: 17,
        [theme.breakpoints.down(700)]: {
            fontSize: 15,
        }
    },
    buttonsWrapper: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "100%",
        marginTop: 25,
    },
}));

const Cell = styled.div`
  background-color: ${Colors.PASTEL_TURQUOISE};
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  align-items: flex-start;
`;

const Content = styled.div`
  color: gray;
  white-space: pre-wrap;
`;

export default PostTableRow;