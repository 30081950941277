import React from 'react';
import styled from "styled-components";
import {Colors} from "../constants/colors";

const DialogTitle = ({title}) => {
    return (
        <Container>
            <Text>{title}</Text>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 10px;
`;

const Text = styled.div`
  display: flex;
  text-transform: uppercase;
  color: ${Colors.BLACK};
  padding: 5px;
  border-radius: 10px;
  background-color: ${Colors.LIGHTER_GREY};
  font-size: 20px;
  text-align: center;
`;

export default DialogTitle;