import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {Colors} from "../constants/colors";


const TurquoiseSelect = ({value, setValue, possibilities=[], label, noValueLabel, noValueValue='', showNoValueLabel= true, disabled=false}) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <FormControl className={classes.formControl} style={{width: "100%"}} disabled={disabled}>
                {label === "" ? null : <InputLabel id="select-label" className={classes.label}>{label}</InputLabel>}
                <Select
                    labelId="select-label"
                    id="select"
                    value={value}
                    onChange={(event) => setValue(event.target.value)}
                    fullWidth={true}
                    className={classes.select}
                >
                    {showNoValueLabel ? <MenuItem value={noValueValue} key={0}>{noValueLabel}</MenuItem> : null}
                    {possibilities.map((item, index) => {
                        return (
                            <MenuItem value={item.value} key={index}>{item.label}</MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    container: {
        margin: 10,
        display: "flex",
        flex: 1,
        [`@media print`]: {
            display: "none"
        }
    },
    formControl: {
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderColor: Colors.TURQUOISE
        }
    },
    select: {
        '&:before': {
            borderColor: Colors.TURQUOISE,
        },
        '&:after': {
            borderColor: Colors.TURQUOISE,
        }
    },
    label: {
        color: Colors.MEDIUM_GREY,
        "&.Mui-focused": {
            color: Colors.TURQUOISE,
        },
    },
}));

export default TurquoiseSelect;
