import React from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import EvaluationQuestion from "../../components/evaluation/EvaluationQuestion";
import 'react-toastify/dist/ReactToastify.css';
import {makeStyles} from "@material-ui/core/styles";

const BigScreenEvaluation = ({questions, answers, triedToFinish, sent}) => {

    const height = window.innerHeight
    const classes = useStyles({height})

    return (
        <TableContainer className={classes.container}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell align={"center"}>Pytanie</TableCell>
                        <TableCell align={"center"}>Zupełnie się nie zgadzam</TableCell>
                        <TableCell align={"center"}>Raczej się nie zgadzam</TableCell>
                        <TableCell align={"center"}>Trudno powiedzieć</TableCell>
                        <TableCell align={"center"}>Raczej się zgadzam</TableCell>
                        <TableCell align={"center"}>W pełni się zgadzam</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {questions.map((item, index) => (
                        <EvaluationQuestion
                            key={index}
                            question={item.name}
                            answer={answers.get(index)}
                            invertedScale={item.invertedScale}
                            handleChangeAnswer={(value) => answers.set(index, value)}
                            even={index % 2 === 0}
                            triedToFinish={triedToFinish}
                            sent={sent}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
};

const useStyles = makeStyles(theme => ({
    container: props => ({
        maxHeight: props.height !== null ? props.height * 0.75 : 500,
        marginTop: 80,
        marginBottom: 20,
        [theme.breakpoints.down(700)]: {
            display: "none",
        },
    }),
}));

export default BigScreenEvaluation;