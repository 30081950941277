import AssessmentIcon from "@material-ui/icons/Assessment";
import {Colors} from "../../../constants/colors";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import StarIcon from '@material-ui/icons/Star';
import React from "react";

const StatusDependIcon = ({status}) => {

    if (status === "ZAKOŃCZONA") {
        return (
            <AssessmentIcon style={{ color: Colors.GREEN }}/>
        );
    } else if (status === "W TRAKCIE") {
        return (
            <TimelapseIcon style={{ color: Colors.TURQUOISE }}/>
        )
    } else if (status === "BRAK") {
        return (
            <StarIcon style={{ color: Colors.BLUE }}/>
        )
    } else {
        return null;
    }
};

export default StatusDependIcon;