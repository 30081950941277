import {FormControlLabel, FormGroup} from "@material-ui/core";
import React, {useState} from "react";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import TurquoiseCheckbox from "../TurquoiseCheckbox";

const CompetencesGroup = ({group, onChange}) => {

    const classes = useStyles();

    const [groupEnabled, setGroupEnabled] = useState(group.enabled)
    const [competences, setCompetences] = useState([])

    const handleChangeGroup = () => {
        const newGroup = Object.assign(group);
        newGroup.enabled = !group.enabled;
        onChange(newGroup);
        setGroupEnabled(newGroup.enabled);
    };

    const handleChangeItem = (competence, index) => {
        const currentIndex = competences.indexOf(competence);
        const newCompetences = [...competences];

        if (currentIndex === -1) {
            newCompetences.push(competence);
        } else {
            newCompetences.splice(currentIndex, 1);
        }
        setCompetences(newCompetences);

        const newGroup = Object.assign(group);
        const newItem = Object.assign(group.competences[index]);
        newItem.enabled = !newItem.enabled;
        newGroup.competences.splice(index, 1, newItem);
        onChange(newGroup);
    };

    return (
        <Container>
            <FormGroup>
                <FormControlLabel
                    key={group.id + "g"}
                    control={
                        <TurquoiseCheckbox
                            checked={groupEnabled}
                            onChange={handleChangeGroup}
                        />
                    }
                    label={group.name + " (" + group.shortName + ")"}
                    classes={{label: classes.label}}
                />
                <Competences>
                    {group.competences.map((item, index) => (
                        <FormControlLabel
                            key={index}
                            control={
                                <TurquoiseCheckbox
                                    checked={competences.indexOf(item.id) !== -1}
                                    onChange={() => handleChangeItem(item.id, index)}
                                />
                            }
                            label={item.name}
                            disabled={!groupEnabled}
                        />
                    ))}
                </Competences>
            </FormGroup>
        </Container>

    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Competences = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
`;

const useStyles = makeStyles(theme => ({
    label: {
        color: Colors.TURQUOISE,
        fontWeight: 'bold'
    },
}));

export default CompetencesGroup;