import React from "react";
import {Button, CardActions, CardContent, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";

const FinancesResultTile = ({label, subtitle, onClick}) => {

    const classes = useStyles()

    return (
        <Tile>
            <CardContent>
                <Name variant="h5">{label}</Name>
                <Type color="textSecondary">{subtitle}</Type>
            </CardContent>
            <div className={classes.separator}>
            <RightAlignedCardActions>
                <Button size="large" onClick={onClick}>Zobacz wyniki</Button>
            </RightAlignedCardActions>
            </div>
        </Tile>
    )
}

const useStyles = makeStyles(theme => ({
    separator: {
        borderTopWidth: 2,
        borderTopColor: Colors.TURQUOISE,
        borderTopStyle: "dashed",
        margin: "0 4%",
    },
}));

const RightAlignedCardActions = withStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))(CardActions);

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${Colors.PASTEL_TURQUOISE};
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
`;

const Name = styled(Typography)`
  padding-bottom: 15px;
`;

const Type = styled(Typography)`
  text-transform: uppercase;
`;

export default FinancesResultTile;