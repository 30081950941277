import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import HeadingCells from "../HeadingCells";

const headCells = [
    {id: 'name', label: 'Nazwa', align: 'left'},
    {id: 'description', label: 'Opis', align: 'left'},
    {id: 'creationDate', label: 'Data', align: 'left'},
    {id: 'buttons', label: '', align: 'left'},
];

const EmployerFileTableHeading = ({order, orderBy, setOrder, setOrderBy}) => {
    return (
        <TableHead>
            <TableRow>
                <HeadingCells setOrderBy={setOrderBy} orderBy={orderBy} setOrder={setOrder} order={order} headCells={headCells}/>
            </TableRow>
        </TableHead>
    );
}

export default EmployerFileTableHeading;