import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import {formatNumber} from "../../services/NumberService";

const FinalResultTableRow = ({row}) => {

    const classes = useStyles()

    return (
        <React.Fragment>
            <TableRow>
                <TableCell className={classes.cell}>{row.competenceName}</TableCell>
                <TableCell className={classes.cell}>{formatNumber(row.competenceLevelGapStart)}</TableCell>
                <TableCell className={classes.cell}>{formatNumber(row.competenceLevelGapNow)}</TableCell>
                <TableCell className={classes.cell}>{formatNumber(row.competenceLevelGapFinish)}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    cell: ({
        fontWeight: "normal",
        backgroundColor: "white",
    }),
}));

export default FinalResultTableRow;