import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized} from "../../services/LinksService";
import {searchPostUsingPOST} from "../../swagger/vue-api-client";
import {getClearSearchCriteria} from "../../services/SearchService";
import ForumTable from "../../components/table/forum/ForumTable";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import {convertPost} from "../../services/WorkerService";
import {getELearningSourcesUsingGET} from "../../swagger/vue-api-client";

const ForumPage = ({history}) => {

    const classes = useStyles()
    const [posts, setPosts] = useState([]);
    const [templates, setTemplates] = useState([])
    const [eLearningSources, setELearningSources] = useState([]);

    useEffect(() => {
        let role = localStorage.getItem("role");
        checkIsUserAuthorized(role, history)
            .then((res) => {
                return searchPostUsingPOST({postSearchCriteria: getClearSearchCriteria()})
            })
            .then((res) => {
                setPosts(res.data.map((item, index) => convertPost(item)))
            })
            .then((res) => {
                return getELearningSourcesUsingGET({companyId: parseInt(localStorage.companyId)})
            })
            .then((res) => {
                setELearningSources(res.data.map((item, index) => convertELearningSource(item)))
            })
            .catch((error) => {
                console.log(error.response)
            })
    }, [history])

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <ForumTable posts={posts} setPosts={setPosts} templates={templates}
                            setTemplates={setTemplates} history={history} eLearningSources={eLearningSources}
                            setELearningSources={setELearningSources}/>
            </div>
            <Footer/>
        </Container>
    );
};

export const convertELearningSource = (source) => {
    return {
        id: source.id,
        professionalCompetence: source.professionalCompetence,
        elearningName: source.elearningName,
        elearningCost: source.elearningCost,
        elearningTime: source.elearningTime,
        creationDate: source.creationDate,
        version: source.version
    }
}


const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default ForumPage;