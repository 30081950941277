import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import PointWithLabel from "./PointWithLabel";
import {makeStyles} from "@material-ui/core/styles";
import IncompletePointWithLine from "./IncompletePointWithLine";

const ProgressPointsLine = ({self, coworker, supervisor}) => {

    const classes = useStyles()
    const [points, setPoints] = useState([]);
    const [incompletePoints, setIncompletePoints] = useState([]);

    useEffect(() => {
        let localPoints = [];
        let incompleteLocalPoints = [];
        const selfEvaluationStatus = self !== null ? self.status : null;
        const coworkerEvaluationStatus = coworker !== null ? coworker.status : null;
        const supervisorEvaluationStatus = supervisor !== null ? supervisor.status : null;

        if (selfEvaluationStatus !== null) {
            if (selfEvaluationStatus === 'ASSIGNED') {
                incompleteLocalPoints.push("Rozpoczęcie samooceny");
                incompleteLocalPoints.push("Zakończenie samooceny");
            } else if (selfEvaluationStatus === 'STARTED') {
                localPoints.push("Rozpoczęcie samooceny");
                incompleteLocalPoints.push("Zakończenie samooceny");
            } else if (selfEvaluationStatus === 'SENT') {
                localPoints.push("Rozpoczęcie samooceny");
                localPoints.push("Zakończenie samooceny");
            }
        }
        if (coworkerEvaluationStatus !== null) {
            if (coworkerEvaluationStatus === 'ASSIGNED') {
                incompleteLocalPoints.push("Rozpoczęcie oceniania przez współpracownika");
                incompleteLocalPoints.push("Zakończenie oceniania przez współpracownika");
            } else if (coworkerEvaluationStatus === 'STARTED') {
                localPoints.push("Rozpoczęcie oceniania przez współpracownika");
                incompleteLocalPoints.push("Zakończenie oceniania przez współpracownika");
            } else if (coworkerEvaluationStatus === 'SENT') {
                localPoints.push("Rozpoczęcie oceniania przez współpracownika");
                localPoints.push("Zakończenie oceniania przez współpracownika");
            }
        }
        if (supervisorEvaluationStatus !== null) {
            if (supervisorEvaluationStatus === 'ASSIGNED') {
                incompleteLocalPoints.push("Rozpoczęcie oceniania przez przełożonego");
                incompleteLocalPoints.push("Zakończenie oceniania przez przełożonego");
            } else if (supervisorEvaluationStatus === 'STARTED') {
                localPoints.push("Rozpoczęcie oceniania przez przełożonego");
                incompleteLocalPoints.push("Zakończenie oceniania przez przełożonego");
            } else if (supervisorEvaluationStatus === 'SENT') {
                localPoints.push("Rozpoczęcie oceniania przez przełożonego");
                localPoints.push("Zakończenie oceniania przez przełożonego");
            }
        }

        setPoints(localPoints);
        setIncompletePoints(incompleteLocalPoints);

    }, [coworker, self, supervisor]);

    return (
        <div className={classes.container}>
            <PointWithLabel label={"Planowanie oceniania"} percent={0} isLast={points.length === 0}/>
            {points.map((item, index) => (
                <PointWithLine key={index.toString()}>
                    <div className={classes.line}/>
                    <PointWithLabel label={item} percent={(index + 1) * 100 / (points.length + incompletePoints.length)}
                                    isLast={index + 1 === points.length}/>
                </PointWithLine>
            ))}
            {incompletePoints.map((item, index) => (
                <IncompletePointWithLine title={item} key={index.toString() + "i"}/>
            ))}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 100,
        paddingLeft: 17,
        margin: "0 10px",
        [theme.breakpoints.down(800)]: {
            paddingTop: 30,
            flexDirection: "column",
            alignItems: "flex-start",
        }
    },
    line: {
        width: 60,
        height: 5,
        backgroundColor: Colors.TURQUOISE,
        [theme.breakpoints.down(800)]: {
            display: "none",
        }
    },
}));

const PointWithLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default ProgressPointsLine;