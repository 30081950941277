import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../constants/colors";
import styled from "styled-components";
import {getDateAndHourLabel} from "../../../services/DateService";
import {getRoleDependentLink} from "../../../services/LinksService";

const ForumTableRow = ({post, history}) => {

    const classes = useStyles();

    const getTextBeginning = (text) => {
        let maxLength = (window.innerWidth / 25);
        if (text.length >= maxLength) {
            return text.substring(0, maxLength) + "...";
        } else {
            return text;
        }
    }

    const getLongerTextBeginning = (text) => {
        let maxLength = (window.innerWidth / 10);
        if (text.length >= maxLength) {
            return text.substring(0, maxLength) + "...";
        } else {
            return text;
        }
    }

    const handleClick = (postId) => {
        history.push(getRoleDependentLink("/trendy/" + postId))
    };

    return (
        <React.Fragment>
                <TableRow
                    key={post.id + "s"}
                    className={classes.smallScreen}
                    onClick={() => handleClick(post.id)}
                >
                    <TableCell colSpan={4}>
                        <SingleCell>
                            <Title>{getLongerTextBeginning(post.title)}</Title>
                            <Content>{getLongerTextBeginning(post.content)}</Content>
                            <div className={classes.metadataContainer}>
                                <div className={classes.marginRight}>{post.author}</div>
                                <div className={classes.marginRight}>{getDateAndHourLabel(new Date(post.creationDate))}</div>
                                <div>{post.comments.length}</div>
                            </div>
                        </SingleCell>
                    </TableCell>
                </TableRow>
                <TableRow
                    key={post.id}
                    className={classes.bigScreen}
                    onClick={() => handleClick(post.id)}
                >
                    <TableCell width={"50%"}>
                        <LeftCell>
                            <Title>{getTextBeginning(post.title)}</Title>
                            <Content>{getTextBeginning(post.content)}</Content>
                        </LeftCell>
                    </TableCell>
                    <TableCell align={"center"}>
                        <Cell>{post.author}</Cell>
                    </TableCell>
                    <TableCell align={"center"}>
                        <Cell>{getDateAndHourLabel(new Date(post.creationDate))}</Cell>
                    </TableCell>
                    <TableCell align={"center"}>
                        <RightCell>{post.comments.length}</RightCell>
                    </TableCell>
                </TableRow>
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    smallScreen: {
        height: 100,
        '& > *': {
            border: 0,
            padding: 0,
            margin: 0,
        },
        [theme.breakpoints.up(700)]: {
            display: "none",
        }
    },
    bigScreen: {
        height: 100,
        '& > *': {
            border: 0,
            padding: 0,
            margin: 0,
        },
        [theme.breakpoints.down(700)]: {
            display: "none",
        }
    },
    metadataContainer: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        marginTop: 10,
    },
    marginRight: {
        marginRight: 10,
    }
}));

const Cell = styled.div`
  background-color: ${Colors.PASTEL_TURQUOISE};
  padding: 20px 0;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

const LeftCell = styled(Cell)`
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  padding-left: 20px;
  align-items: flex-start;
`;

const Title = styled.div`
  font-weight: bold;
`;

const Content = styled.div`
  color: gray;
`;

const RightCell = styled(Cell)`
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
`;

const SingleCell = styled(Cell)`
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0;
  align-items: flex-start;
`;

export default ForumTableRow;