import {Dialog, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React, {useState} from "react";
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import {searchPagesUsingGET} from "../../../swagger/vue-api-client";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import DialogTitle from "../../DialogTitle";
import DialogButtonsContainer from "../../DialogButtonsContainer";
import TextInput from "../../TextInput";
import Table from "@material-ui/core/Table";
import {makeStyles} from "@material-ui/core/styles";

const SearchPagesDialog = ({open, setOpen}) => {

    const [content, setContent] = useState('')

    const classes = useStyles()

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showResults, setShowResults] = useState(false)
    const [result, setResult] = useState([])
    const [titleWord, setTitleWord] = useState("")

    const searchResults = () => {
        setShowResults(false)
        setIsLoading(true)
        searchPagesUsingGET({word: content})
            .then((response) => {
                console.log(response.data)
                setTitleWord(content)
                setResult(response.data)
                setShowResults(true)
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error.response)
                setIsLoading(false)
                setErrorMessage("Nie udało się załadować wyników")
            })
    }

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle title={"Wyszukaj ilość wystąpień słowa"}/>
            <Container>
                <TextInput label={"Słowo"} value={content} setValue={setContent}/>
                {showResults ?
                    <React.Fragment>
                        <DialogTitle title={"Wyniki dla słowa " + titleWord}/>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow >
                                    <TableCell classes={{root: classes.cell}}>Strona</TableCell>
                                    <TableCell classes={{root: classes.cell}}>Ilość wystąpień</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result.map((item, index) => (
                                    <TableRow key={index}>
                                        <TableCell classes={{root: classes.cell}}>{item.page}</TableCell>
                                        <TableCell classes={{root: classes.resultCell}}>{item.appearances}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </React.Fragment>: null}
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"Anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"Szukaj"}
                        onClick={() => searchResults()}
                        backgroundColor={Colors.GREEN}
                    />
                </DialogButtonsContainer>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 5%;
  width: 90%;
`;

const useStyles = makeStyles(theme => ({
    cell: {
        fontSize: 15,
        width: "50%",
    },
    resultCell: {
        fontSize: 15,
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "50%",
    },

}));

export default SearchPagesDialog;