import React from "react";
import EvaluatorInfoColumn from "./EvaluatorInfoColumn";
import {makeStyles} from "@material-ui/core/styles";

const EvaluatorsInfo = ({self, coworker, supervisor}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <EvaluatorInfoColumn
                evaluation={self}
                label={"Samoocena"}
            />
            <EvaluatorInfoColumn
                evaluation={coworker}
                label={"Ocena współpracownika"}
            />
            <EvaluatorInfoColumn
                evaluation={supervisor}
                label={"Ocena przełożonego"}
            />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "grid",
        width: "90%",
        gridTemplateColumns: "1fr 1fr 1fr",
        [theme.breakpoints.down(800)]: {
            gridTemplateColumns: "1fr",
        }
    },
}));

export default EvaluatorsInfo;