import React from 'react';
import NumberInput from "./NumberInput";
import TurquoiseSelect from "../TurquoiseSelect";
import Subheader from "../Subheader";
import CheckboxForm from "../askForEvaluation/CheckboxForm";
import {makeStyles} from "@material-ui/core/styles";
import {coverages} from "../../constants/coverages";

const AssumptionsData = ({coverageEnabled, setCoverageEnabled, coverage, setCoverage, equipmentEnabled, setEquipmentEnabled, changesInStructureEnabled,
                             setChangesInStructureEnabled, changesInStructure, setChangesInStructure, changesInMotivatingEnabled,
                             setChangesInMotivatingEnabled, changesInMotivating, setChangesInMotivating, changesInCommunicationEnabled,
                             setChangesInCommunicationEnabled, changesInCommunication, setChangesInCommunication, changesInKnowledgeEnabled,
                             setChangesInKnowledgeEnabled, changesInKnowledge, setChangesInKnowledge, dataSource}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Subheader title={"Założenia co do planowanych działań w ramach Reskillingu"}/>
            <div className={classes.item}>
                <CheckboxForm
                    label={"Wizyta studyjna / praktyka"}
                    value={coverageEnabled}
                    setValue={() => setCoverageEnabled(c => !c)}
                    margin={"1%"}
                />
                <TurquoiseSelect
                    value={coverage}
                    setValue={setCoverage}
                    label={"Zasięg geograficzny"}
                    noValueLabel={"Wybierz zasięg"}
                    possibilities={coverages}
                    disabled={!coverageEnabled}
                />
            </div>
            <div className={classes.item}>
                <CheckboxForm
                    label={"zakup sprzętu"}
                    value={equipmentEnabled}
                    setValue={() => setEquipmentEnabled(c => !c)}
                    margin={"1%"}
                />
            </div>
            <div className={classes.item}>
                <CheckboxForm
                    label={"zmiany w zakresie struktury organizacyjnej"}
                    value={changesInStructureEnabled}
                    setValue={() => setChangesInStructureEnabled(c => !c)}
                    margin={"1%"}
                />
                {(dataSource !== "tak" && changesInStructureEnabled) &&
                    <NumberInput
                        value={changesInStructure === -1 ? "" : changesInStructure}
                        setValue={setChangesInStructure}
                        label={"Koszty wdrożenia [PLN]"}
                        disabled={!changesInStructureEnabled}
                    />
                }
            </div>
            <div className={classes.item}>
                <CheckboxForm
                    label={"zmiany w zakresie systemu motywacji"}
                    value={changesInMotivatingEnabled}
                    setValue={() => setChangesInMotivatingEnabled(c => !c)}
                    margin={"1%"}
                />
                {(dataSource !== "tak" && changesInMotivatingEnabled) &&
                    <NumberInput
                        value={changesInMotivating !== -1 ? changesInMotivating : ""}
                        setValue={setChangesInMotivating}
                        label={"Koszty wdrożenia [PLN]"}
                        disabled={!changesInMotivatingEnabled}
                    />
                }
            </div>
            <div className={classes.item}>
                <CheckboxForm
                    label={"zmiany w zakresie systemu komunikacji w podmiocie"}
                    value={changesInCommunicationEnabled}
                    setValue={() => setChangesInCommunicationEnabled(c => !c)}
                    margin={"1%"}
                />
                {(dataSource !== "tak" && changesInCommunicationEnabled) &&
                    <NumberInput
                        value={changesInCommunication !== -1 ? changesInCommunication : ""}
                        setValue={setChangesInCommunication}
                        label={"Koszty wdrożenia [PLN]"}
                        disabled={!changesInCommunicationEnabled}
                    />
                }
            </div>
            <div className={classes.item}>
                <CheckboxForm
                    label={"zmiany w zakresie znajomości branży"}
                    value={changesInKnowledgeEnabled}
                    setValue={() => setChangesInKnowledgeEnabled(c => !c)}
                    margin={"1%"}
                />
                {(dataSource !== "tak" && changesInKnowledgeEnabled) &&
                    <NumberInput
                        value={changesInKnowledge !== -1 ? changesInKnowledge : ""}
                        setValue={setChangesInKnowledge}
                        label={"Koszty wdrożenia [PLN]"}
                        disabled={!changesInKnowledgeEnabled}
                    />
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 100
    },
    item: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    doubleItem: {
        display: "flex",
        flex: 1,
    },
    coverageLabel: {
        display: "flex",
        flex: 1,
        margin: "1%",

    },
}));

export default AssumptionsData;