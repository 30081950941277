import React from 'react';
import TurquoiseSelect from "../TurquoiseSelect";

const SelectNumber = ({number, setNumber, label, noValueLabel, width, from, to, disabled=false}) => {

    let numbers = Array.from({length: to - from + 1}, (_, i) => i + from)
    // gives array [from, from+1, ..., to]
    let array = numbers.map((item, index) => {
        return {value: item, label: item}
    })

    return (
        <TurquoiseSelect
            value={number}
            setValue={setNumber}
            label={label}
            noValueLabel={noValueLabel}
            width={width}
            possibilities={array}
            disabled={disabled}
        />
    );
}

export default SelectNumber;
