import React, {useState} from "react";
import {Colors} from "../../constants/colors";
import ButtonWithoutIcon from "../buttons/ButtonWithoutIcon";
import ErrorAndLoadingMessage from "../ErrorAndLoadingMessage";
import 'react-toastify/dist/ReactToastify.css';
import DialogButtonsContainer from "../DialogButtonsContainer";
import AskForCertaintyDialog from "./AskForCertaintyDialog";
import AddIcon from "@material-ui/icons/Add";

const AskForEvaluationSummary = ({isLoading, errorMessage, setOpen, onSubmit}) => {

    const [isAsking, setIsAsking] = useState(false);

    return (
        <React.Fragment>
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer onlySmallScreen={false}>
                    <ButtonWithoutIcon
                        onClick={() => setOpen(false)}
                        label={"Anuluj"}
                    />
                    <ButtonWithoutIcon
                        icon={<AddIcon/>}
                        onClick={() => setIsAsking(true)}
                        label={"Poproś o ocenę"}
                        backgroundColor={Colors.GREEN}
                    />
                </DialogButtonsContainer>
                <AskForCertaintyDialog
                    open={isAsking}
                    setOpen={setIsAsking}
                    handleSubmitEvaluation={onSubmit}
                />
        </React.Fragment>
    );
};

export default AskForEvaluationSummary;