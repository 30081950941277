import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import {Container, TableFooter} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SchoolIcon from "@material-ui/icons/School";
import ButtonWithIcon from "../../buttons/ButtonWithIcon";
import {getComparator, stableSort} from "../../../services/SortService";
import TableRow from "@material-ui/core/TableRow";
import ForumTableHeading from "./ForumTableHeading";
import ForumTableRow from "./ForumTableRow";
import AddPostDialog from "./AddPostDialog";
import TitleWithButton from "../../TitleWithButton";
import Pagination from "../Pagination";
import {makeStyles} from "@material-ui/core/styles";
import AddQuizDialog from "./AddQuizDialog";
import SearchPagesDialog from "./SearchPagesDialog";
import AddELearningSourceDialog from "./AddELearningSourceDialog";

const ForumTable = ({posts, setPosts, history, eLearningSources, setELearningSources}) => {

    const classes = useStyles();

    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('creationDate');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [addPost, setAddPost] = useState(false);
    const [addQuiz, setAddQuiz] = useState(false);
    const [addELearningSource, setAddELearningSource] = useState(false);
    const [searchPages, setSearchPages] = useState(false);

    let role = localStorage.getItem("role");

    return (
        <Container>
            <TitleWithButton title={"Trendy"} button={
                <div className={classes.buttonContainer}>
                    { role === "EMPLOYER" ?
                        <div className={classes.buttonWrapper}>
                            <ButtonWithIcon
                                icon={<SchoolIcon/>}
                                onClick={() => setAddELearningSource(true)}
                                label={"Źródła tematów i cen szkoleń"}
                            />
                        </div> : null
                    }
                    { role === "ADMIN" || role === "EMPLOYER" ?
                        <div className={classes.buttonWrapper}>
                            <ButtonWithIcon
                                icon={<AddIcon/>}
                                onClick={() => setAddQuiz(true)}
                                label={"Dodaj nową ankietę"}
                            />
                        </div> : null
                    }
                    <div className={classes.buttonWrapper}>
                        <ButtonWithIcon
                            icon={<AddIcon/>}
                            onClick={() => setAddPost(true)}
                            label={"Dodaj nowy post"}
                        />
                    </div>
                </div>
            }/>
            <TableContainer>
                <Table>
                    <ForumTableHeading
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                    />
                    <TableBody>
                        {stableSort(posts, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) =>
                                <ForumTableRow key={row.id} post={row} history={history}/>
                            )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <Pagination
                                rows={posts}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <SearchPagesDialog open={searchPages} setOpen={setSearchPages}/>
            <AddPostDialog open={addPost} setOpen={setAddPost} setPosts={setPosts}/>
            <AddQuizDialog open={addQuiz} setOpen={setAddQuiz} setPosts={setPosts}/>
            <AddELearningSourceDialog open={addELearningSource} setOpen={setAddELearningSource} eLearningSources={eLearningSources}
            setELearningSources={setELearningSources}/>
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.down(600)]: {
            flexDirection: "column",
        }
    },
    buttonWrapper: {
        margin: "5px",
    }
}))

export default ForumTable;
