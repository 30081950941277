import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import {editCompanyUsingPUT} from "../../../swagger/vue-api-client";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import DialogTitle from "../../DialogTitle";
import DialogButtonsContainer from "../../DialogButtonsContainer";
import DataField from "../../DataField";
import {makeStyles} from "@material-ui/core/styles";
import CheckboxForm from "../../askForEvaluation/CheckboxForm";

const PublicationDialog = ({open, setOpen, company, setCompanies}) => {

    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [publicAccess, setPublicAccess] = useState(company.publishedForPublicAccess);

    const onEditCompany = () => {

        setIsLoading(true);
            editCompanyUsingPUT({
                editCompanyRequest: {
                    companyId: company.id,
                    publishedForPublicAccess: publicAccess
                }
            })
                .then((res) => {
                    console.log(res.data)
                    setCompanies(c => c.map((item, index) => {
                        if (item.id === company.id) {
                            res.data.employerName = res.data.employers[0].name;
                            res.data.employerEmail = res.data.employers[0].email;
                            return res.data
                        } else {
                            return item
                        }
                    }))
                    setErrorMessage('')
                    setIsLoading(false)
                    setOpen(false)
                })
                .catch((error) => {
                    console.log(error.response)
                    if (error.response && error.response.data && typeof error.response.data === 'string') {
                        setErrorMessage(error.response.data)
                    } else {
                        setErrorMessage("Nie udało się zmienić widoczności firmy " + company.name + ".")
                    }
                    setIsLoading(false)
                })
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'md'} fullWidth={true}>
            <div className={classes.container}>
                <DialogTitle title={"Publikacja firmy w bazie podmiotów"}/>
                <DataField title={"Nazwa firmy"} value={company.name}/>
                <DataField
                    title={"Zgoda firmy"}
                    value={"Firma " + (company.agreementForDataSharing ? "" : "nie ")
                    + "pozwoliła na udostępnienie swoich danych w bazie podmiotów."}
                />
                <AdminAccept>
                    <TitleText>Zgoda administratora</TitleText>
                    <AdminAcceptContent>
                        {company.agreementForDataSharing ? (
                            <CheckboxForm
                                value={publicAccess}
                                setValue={setPublicAccess}
                                label={"Opublikuj dane firmy w bazie podmiotów."}
                                margin={"0"}
                            />
                        ) : (
                            "Nie można opublikować danych firmy bez zgody jej właścicieli."
                        )}

                    </AdminAcceptContent>
                </AdminAccept>
                {company.agreementForDataSharing ? (
                    <React.Fragment>
                        <ErrorAndLoadingMessage errorMessage={errorMessage} isLoading={isLoading}/>
                        <DialogButtonsContainer onlySmallScreen={false}>
                            <ButtonWithoutIcon
                                label={"Anuluj"}
                                onClick={() => setOpen(false)}
                            />
                            <ButtonWithoutIcon
                                label={"Zaktualizuj"}
                                onClick={onEditCompany}
                                backgroundColor={Colors.GREEN}
                            />
                        </DialogButtonsContainer>
                    </React.Fragment>
                ) : null}
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "center",
        width: "90%",
        padding: "20px 5%",
        marginBottom: "5%",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            padding: "20px 2%",
        }
    }
}));

const AdminAccept = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0.5em;
`;

const TitleText = styled.div`
  flex: 1;
  padding: 1em;
  color: ${Colors.GREY};
  font-weight: bold;
`;

const AdminAcceptContent = styled.div`
  flex: 2;
  padding: 1em;
  color: ${Colors.GREY};
`;

export default PublicationDialog;