import React, {useContext, useEffect} from 'react';
import styled from "styled-components";
import {getAllCompetencesUsingGET, searchWorkerUsingPOST} from "../../swagger/vue-api-client";
import {checkIsUserAuthorized} from "../../services/LinksService";
import WorkerTable from "../../components/table/worker/WorkerTable";
import {getCompanyWorkerSearchCriteria} from "../../services/SearchService";
import {EmployerContext} from "../../contexts/EmployerContext";
import {convertWorker} from "../../services/WorkerService";
import {WorkerContext} from "../../contexts/WorkerContext";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";

const WorkersPage = ({history}) => {

    const classes = useStyles()
    const employerContext = useContext(EmployerContext)
    const workerContext = useContext(WorkerContext)

    useEffect(() => {
        checkIsUserAuthorized("EMPLOYER", history)
            .then((response) => {
                return searchWorkerUsingPOST({searchCriteria: getCompanyWorkerSearchCriteria()});
            })
            .then((response) => {
                let res = response.data.map((worker) =>  convertWorker(worker));
                employerContext.setWorkers(res);
                return getAllCompetencesUsingGET();
            })
            .then((response) => {
                workerContext.setCompetences(response.data);
            })
            .catch((error) => {
                console.log(error)
            })

        // employerContext cannot be in a dependency array, because as an effect of above function, it changes
        // eslint-disable-next-line
    }, [history]);

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <WorkerTable/>
            </div>
            <Footer/>
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default WorkersPage;