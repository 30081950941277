import React, {useContext, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import CompanyTableHeader from "./CompanyTableHeader";
import CompanyTableHeading from "./CompanyTableHeading";
import CompanyTableRow from "./CompanyTableRow";
import CompanyTablePagination from "./CompanyTablePagination";
import {activateEmployerAccountUsingPOST} from "../../../swagger/vue-api-client";
import {getComparator, stableSort} from "../../../services/SortService";
import {TableFooter} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import {AdminContext} from "../../../contexts/AdminContext";

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: Colors.PASTEL_TURQUOISE
        }
    },
    selected: {},
    checkbox: {
        "&checked, &checked:hover": {
            backgroundColor: Colors.BLUE
        }
    },
    checked: {},
}));

const CompanyTable = ({title, selectable, rows}) => {

    const adminContext = useContext(AdminContext)

    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isLoading, setIsLoading] = useState(0);

    const handleAcceptSelected = () => {
        selected.forEach((item, index) => {
            setIsLoading(old => old+1);
            let acceptedCompany = rows.filter(company => company.id === item)[0];
            activateEmployerAccountUsingPOST({userIdRequest: {id: acceptedCompany.employerId}})
                .then((response) => {
                    if (response.status === 200) {
                        setSelected(old => old.filter(companyId => companyId !== acceptedCompany.id));
                        adminContext.setCompanies(old => [...old, acceptedCompany]);
                        adminContext.setCompaniesToAccept(old => old.filter(company => company.id !== acceptedCompany.id));
                    }
                    setIsLoading(old => old-1);
                })
        })
    }

    return (
        <Container>
            <CompanyTableHeader
                numSelected={selected.length}
                title={title}
                selectable={selectable}
                onAccept={handleAcceptSelected}
                isLoading={isLoading}
            />
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <CompanyTableHeading
                        selectable={selectable}
                        numSelected={selected.length}
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        rows={rows}
                        setSelected={setSelected}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) =>
                                <CompanyTableRow
                                    key={index}
                                    selectable={selectable}
                                    row={row}
                                    index={index}
                                    selected={selected}
                                    setSelected={setSelected}
                                />)}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <CompanyTablePagination
                                rows={rows}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Container>
    );
}

const Container = styled.div`
  width: 100%;
`;

export default CompanyTable;
