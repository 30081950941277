import React from 'react';
import styled from "styled-components";
import {Colors} from "../../constants/colors";

const OnlyIconButton = ({onClick, backgroundColor=Colors.WHITE, icon, disabled = false}) => {

    return (
        disabled ? (
            <DisabledButton onClick={onClick} backgroundColor={backgroundColor} disabled>
                {icon}
            </DisabledButton>
        ) : (
            <ClickableButton onClick={onClick} backgroundColor={backgroundColor}>
                {icon}
            </ClickableButton>
        )
    );
};

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-width: 0;
  border-radius: 50px;
  padding: 5px 15px;
  transition: opacity 0.3s;
`;

const ClickableButton = styled(Button)`
  background-color: ${props => props.backgroundColor};
  cursor: pointer;

  &:focus, &:hover, &:active {
    outline: none;
  }

  &:hover, &:active {
    opacity: 0.5;
  }
`;

const DisabledButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
`;

export default OnlyIconButton;