import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import HeadingCells from "../HeadingCells";
import TurquoiseCheckbox from "../../TurquoiseCheckbox";

const headCells = [
    {id: 'name', label: 'Nazwa firmy', align: 'left', sortable: true},
    {id: 'phoneNumber', label: 'Numer telefonu', align: 'left', sortable: true},
    {id: 'companyEmail', label: 'Email', align: 'left', sortable: true},
    {id: 'public', label: 'Widoczność w bazie', align: 'center', sortable: false},
    {id: 'details', label: 'Szczegóły', align: 'center', sortable: false},
    {id: 'delete', label: 'Usuń', align: 'center', sortable: false},
];

const CompanyTableHeading = ({order, orderBy, numSelected, rows, setSelected, setOrder, setOrderBy, selectable}) => {

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const rowCount = rows.length;

    return (
        <TableHead>
            <TableRow>
                {selectable ? (
                    <TableCell padding="checkbox">
                        <TurquoiseCheckbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={handleSelectAllClick}
                        />
                    </TableCell>
                ) : null}
                <HeadingCells setOrderBy={setOrderBy} orderBy={orderBy} setOrder={setOrder} order={order} headCells={headCells}/>
            </TableRow>
        </TableHead>
    );
}

export default CompanyTableHeading;