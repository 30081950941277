import {Dialog} from "@material-ui/core";
import DialogButtonsContainer from "../DialogButtonsContainer";
import ButtonWithoutIcon from "../buttons/ButtonWithoutIcon";
import AddIcon from "@material-ui/icons/Add";
import {Colors} from "../../constants/colors";
import React from "react";
import styled from "styled-components";

const AskForCertaintyDialog = ({open, setOpen, handleSubmitEvaluation}) => {

    const handleClose = () => {
        setOpen(false);
    };

    const handleApproval = () => {
        handleSubmitEvaluation()
        setOpen(false)
    }

    return (
        <Dialog onClose={handleClose} open={open} maxWidth={'sm'} fullWidth={true}>
            <Message>
                Czy na pewno chcesz wysłać prośbę o ocenę?
                Pamiętaj, że po zatwierdzeniu nie będziesz mieć możliwości jej zmiany lub odwołania.
            </Message>
            <Container>
                <DialogButtonsContainer onlySmallScreen={false}>
                    <ButtonWithoutIcon
                        icon={<AddIcon/>}
                        onClick={() => handleClose()}
                        label={"Wróć"}
                        backgroundColor={Colors.TURQUOISE}
                        color={Colors.WHITE}
                    />
                    <ButtonWithoutIcon
                        icon={<AddIcon/>}
                        onClick={() => handleApproval()}
                        label={"Zatwierdź"}
                        backgroundColor={Colors.GREEN}
                        color={Colors.WHITE}
                    />
                </DialogButtonsContainer>
            </Container>
        </Dialog>
    );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px 0;
`;

const Message = styled.div`
  display: flex;
  font-size: 160%;
  
  justify-content: center;
  align-items: center;
  min-height: 40px;
  color: ${Colors.BLACK};
  margin: 5% 20px 10px 20px;
`;


export default AskForCertaintyDialog;