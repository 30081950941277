import React, {useContext} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {EmployerContext} from "../contexts/EmployerContext";
import {Colors} from "../constants/colors";

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderColor: Colors.TURQUOISE
        }
    },
    select: {
        '&:before': {
            borderColor: Colors.TURQUOISE,
        },
        '&:after': {
            borderColor: Colors.TURQUOISE,
        }
    },
    label: {
        color: Colors.MEDIUM_GREY,
        "&.Mui-focused": {
            color: Colors.TURQUOISE,
        },
    },
}));

const SelectWorker = ({worker, setWorker, label, disabled, excluded}) => {

    const employerContext = useContext(EmployerContext)
    const classes = useStyles();

    let id = localStorage.getItem("id")
    let name = localStorage.getItem("name")

    return (
        <div>
            <FormControl className={classes.formControl} style={{minWidth: 200}} disabled={disabled}>
                <InputLabel id="demo-simple-select-label" className={classes.label}>{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={worker}
                    onChange={(event) => setWorker(event.target.value)}
                    fullWidth={true}
                    className={classes.select}
                >
                    <MenuItem value={undefined} key={0}>Wybierz pracownika</MenuItem>
                    {employerContext.workers.map((item, index) => {
                        if (item.id === excluded) {
                            return null
                        } else {
                            return <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                        }
                    })}
                    <MenuItem value={id} >{name}</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}

export default SelectWorker;
