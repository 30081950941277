import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import TurquoiseRadio from "../TurquoiseRadio";
import {FormControlLabel} from "@material-ui/core";

const QuizAnswer = ({answer, number, handleChange, leftAlign=false}) => {

    const classes = useStyles()

    return (
        <div className={leftAlign ? classes.leftContainer : classes.container}>
            <FormControlLabel
                control={
                    <TurquoiseRadio
                        checked={answer.chosen === true}
                        onChange={(event) => {handleChange(event)}}
                        value={number}
                        name="radio-button-demo"
                        inputProps={{'aria-label': number}}
                    />
                }
                label={answer.answerText}
            />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 10,
    },
    leftContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        alignItems: "center",
        marginTop: 10,
    },
}));

export default QuizAnswer;