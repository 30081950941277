import {FormControlLabel} from "@material-ui/core";
import styled from "styled-components";
import TurquoiseCheckbox from "../TurquoiseCheckbox";

const AgreementForm = ({publishApproval, setPublishApproval}) => {

    const handleChange = () => {
        publishApproval === false ? setPublishApproval(true) : setPublishApproval(false)
    }

    return (
        <Container>
            <FormControlLabel
                control={
                    <TurquoiseCheckbox
                        checked={publishApproval}
                        onChange={handleChange}
                    />}
                label={"Udostępnij całościową ocenę pracownikowi (w przeciwnym wypadku otrzyma on wyłącznie wyniki samooceny)."}
            />
        </Container>
    );
};


const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 5% 15%;
  width: 70%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export default AgreementForm;