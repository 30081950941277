import React from 'react';
import Header from "../../components/Header";
import {Colors} from "../../constants/colors";
import OutlineButton from "../../components/buttons/OutlineButton";
import TrademarksHeader from "../../components/TrademarksHeader";
import LeftTurquoiseContainer from "../../components/LeftTurquoiseContainer";
import RightWhiteContainer from "../../components/RightWhiteContainer";
import {makeStyles} from "@material-ui/core/styles";
import Footer from "../../components/Footer";

const WorkerConfirmEmailCompletePage = ({history}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <TrademarksHeader history={history}/>
            <LeftTurquoiseContainer/>
            <RightWhiteContainer>
                <Header title={"Twoje konto jest już aktywne!"} color={Colors.TURQUOISE}/>
                <OutlineButton
                    label={"Zaloguj się"}
                    onClick={() => history.push("/logowanie")}
                />
            </RightWhiteContainer>
            <Footer onlySmallScreen={true}/>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100vm",
        height: "100vh",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            fontSize: 14,
            textAlign: "center",
        },
    },
}));

export default WorkerConfirmEmailCompletePage;