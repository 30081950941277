import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";
import Carousel from "react-simply-carousel";
import {IoArrowBackOutline, IoArrowForwardOutline} from "react-icons/all";
import FilePreview from "../../components/recommendation/FilePreview";
import RecommendationSummary from "./RecommendationSummary";
import {getElearningUsingGET} from "../../swagger/vue-api-client";
import {useParams} from "react-router";

const FilesCarousel = ({files, recommendation, history}) => {

    const classes = useStyles()
    const role = localStorage.getItem("role")
    let {recommendationId} = useParams()
    const [activeSlide, setActiveSlide] = useState(0)
    const [quizExist, setQuizExist] = useState(false)

    useEffect(() => {
        getElearningUsingGET({id: recommendationId})
            .then((res) => {
                setQuizExist(res.data.quizId !== null)
            })
            .catch((error) => {
                console.log(error);
            })
    }, [recommendationId])

    if (files.length > 1) {
        return (
            <div>
                <Carousel
                    updateOnItemClick
                    infinite={false}
                    containerProps={{
                        style: {
                            width: "100%",
                            justifyContent: "space-between",
                            marginTop: "5%",
                            marginBottom: "5%",
                        }
                    }}
                    activeSlideIndex={activeSlide}
                    onRequestChange={setActiveSlide}
                    forwardBtnProps={{
                        children: (role === "WORKER" && quizExist && activeSlide < files.length) ||
                        (role === "WORKER" && !quizExist && activeSlide < files.length - 1) ||
                        (role === "EMPLOYER" && activeSlide < files.length - 1) ?
                            <IoArrowForwardOutline color={Colors.TURQUOISE} size={30}/> : <div/>,
                        style: {
                            alignSelf: "center",
                            backgroundColor: Colors.WHITE,
                            border: "none",
                        },
                    }}
                    backwardBtnProps={{
                        children: activeSlide > 0 ? <IoArrowBackOutline color={Colors.TURQUOISE} size={30}/> : <div/>,
                        style: {
                            alignSelf: "center",
                            backgroundColor: Colors.WHITE,
                            border: "none",
                        },
                    }}
                    itemsToShow={1}
                    speed={400}
                >
                    {files.map((item, index) => (
                        <div className={classes.item} key={index}>
                            <div
                                className={classes.stepNumber}>Krok {activeSlide + 1} / {role === "WORKER" && quizExist ? files.length + 1 : files.length}</div>
                            <FilePreview file={item}/>
                        </div>
                    ))}
                    {role === "WORKER" && quizExist ? (
                        <div className={classes.item}>
                            <div className={classes.stepNumber}>Krok {activeSlide + 1} / {files.length + 1}</div>
                            <RecommendationSummary recommendation={recommendation} history={history}/>
                        </div>
                    ) : null}
                </Carousel>
            </div>
        )
    } else if (files.length === 1) {
        return (
            <FilePreview file={files[0]}/>
        )
    } else {
        return null
    }
}

const useStyles = makeStyles(theme => ({
    item: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    stepNumber: {
        marginBottom: 30,
        fontSize: 17,
        color: Colors.ALMOST_BLACK,
    },
}));

export default FilesCarousel;