import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import 'react-toastify/dist/ReactToastify.css';
import MotivatorsEvaluationRow from "./MotivatorsEvaluationRow";

const MotivatorsOrganisationEvaluation = ({triedToFinish, motivators, motivatorTitle}) => {

    let motivatorsExistCount = 0
    const [motivatorsCheckedCount, setMotivatorsCheckedCount] = useState(0)

    motivators.forEach((item) => {
        if (item.secondColumn === "tak") {
            motivatorsExistCount += 1
        }
    })

    useEffect(() => {
        setMotivatorsCheckedCount(motivatorsExistCount)
    },[])

    return (
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell width={"50%"}>{motivatorTitle}</TableCell>
                    <TableCell width={"25%"} align={"center"}>Czy jest stosowany?</TableCell>
                    <TableCell width={"25%"} align={"center"}>Czy jest skuteczny?</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {motivators.map((item, index) => {
                    return (
                        <MotivatorsEvaluationRow
                            key={index}
                            motivator={item}
                            even={index % 2 === 0}
                            triedToFinish={triedToFinish}
                            motivatorTitle={motivatorTitle}
                            motivatorsCheckedCount={motivatorsCheckedCount}
                            setMotivatorsCheckedCount={setMotivatorsCheckedCount}
                        />
                    )
                })}
            </TableBody>
        </Table>
    )
};

export default MotivatorsOrganisationEvaluation;