import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import {getComparator, stableSort} from "../../../services/SortService";
import {TableFooter} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import CompanyExcelFilesTableHeading from "./CompanyExcelFilesTableHeading";
import CompanyExcelFilesTableRow from "./CompanyExcelFilesTableRow";
import CompanyExcelFilesTablePagination from "./CompanyExcelFilesTablePagination";

const useStyles = makeStyles(theme => ({
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: Colors.PASTEL_TURQUOISE
        }
    },
    selected: {},
    checkbox: {
        "&checked, &checked:hover": {
            backgroundColor: Colors.BLUE
        }
    },
    checked: {},
}));

const CompanyExcelFilesTable = ({rows}) => {

    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    return (
        <Container>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size={'medium'}
                >
                    <CompanyExcelFilesTableHeading
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        rows={rows}
                    />
                    <TableBody>
                        {stableSort(rows, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row) =>
                                <CompanyExcelFilesTableRow
                                    row={row}
                                />)}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <CompanyExcelFilesTablePagination
                                rows={rows}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </Container>
    );
}

const Container = styled.div`
  width: 100%;
`;

export default CompanyExcelFilesTable;
