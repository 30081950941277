import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {setDomain} from "./swagger/vue-api-client";
import axios from 'axios';

setDomain(process.env.REACT_APP_API_URL);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

axios.interceptors.request.use((config) => {
    if (localStorage.getItem("token") !== null) {
        config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    }

    return config;
});
