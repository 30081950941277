import React, {useState} from 'react';
import styled from "styled-components";
import {IoEyeOffSharp, IoMailOutline} from "react-icons/io5";
import {RiLockPasswordLine} from "react-icons/ri";
import {loginUsingPOST} from "../../swagger/vue-api-client";
import {Colors} from "../../constants/colors";
import InputField from "../InputField";
import OutlineButton from "../buttons/OutlineButton";
import ErrorAndLoadingMessage from "../ErrorAndLoadingMessage";
import {makeStyles} from "@material-ui/core/styles";
import Header from "../Header";
import {getRoleDependantHomePageLink} from "../../services/LinksService";

const SignInForm = ({history}) => {

    const classes = useStyles();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [passwordType, setPasswordType] = useState("password")

    const handlePasswordShow = () => {
        setPasswordType("text")
    }

    const handlePasswordHide = () => {
        setPasswordType("password")
    }

    const keyDownEvent = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.code === "Enter" && email && password) {
            handleSignIn(email, password)
        }
    };

    const sendDataToMobileApp =  function (id, email, token, role, name, companyId) {
        if (!window.ReactNativeWebView) {
            return;
        }
        let message = {
            messageType: 'signin',
            id: id,
            email: email,
            token: token,
            role: role,
            name: name,
            companyId: companyId
        }

        window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }

    const handleSignIn = (email, password) => {
        setIsLoading(true)
        loginUsingPOST({
            authenticationRequest: {
                email: email,
                password: password
            }
        })
            .then((response) => {
                localStorage.setItem("id", response.data.id);
                localStorage.setItem("email", response.data.email);
                localStorage.setItem("token", response.data.token);
                localStorage.setItem("role", response.data.role);
                localStorage.setItem("name", response.data.name);
                if (response.data.companyId) {
                    localStorage.setItem("companyId", response.data.companyId);
                }

                sendDataToMobileApp(response.data.id, response.data.email, response.data.token,
                    response.data.role, response.data.name, response.data.companyId);

                setIsLoading(false)
                history.push(getRoleDependantHomePageLink(response.data.role))

            })
            .catch((error) => {
                setIsLoading(false)
                if (error && error.response && error.response.data && error.response.data.length < 100) {
                    setErrorMessage(error.response.data)
                } else {
                    setErrorMessage("Błąd połączenia z serwerem")
                }
            });
    };

    return (
        <Container>
            <Header title={"Zaloguj się do Reskilling"}/>
            <div className={classes.form} onKeyDown={keyDownEvent}>
                <InputField
                    type={"email"}
                    value={email}
                    setValue={setEmail}
                    placeholder={"Email"}
                    icon={<IoMailOutline size={20} color={Colors.LIGHT_GREY}/>}
                />
                <InputField
                    type={passwordType}
                    value={password}
                    setValue={setPassword}
                    placeholder={"Hasło"}
                    icon={<RiLockPasswordLine size={20} color={Colors.LIGHT_GREY}/>}
                    button={<IoEyeOffSharp size={20} color={Colors.LIGHT_GREY}
                    onMouseDown={handlePasswordShow}
                    onMouseUp={handlePasswordHide}/>}
                />
            </div>
            <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
            <OutlineButton
                label={"Zaloguj się"}
                onClick={() => handleSignIn(email, password)}
            />
            <ForgetContainer as={'a'} href={process.env.REACT_APP_BASE_PATH + "/zapomniane-haslo"}>
                Nie pamiętasz hasła?
            </ForgetContainer>
        </Container>
    );
};


const useStyles = makeStyles(theme => ({
    form: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        [theme.breakpoints.down("sm")]: {
            width: "80%",
        }
    },
}));


const Container = styled.div`
  display: flex;
  background-color: ${Colors.WHITE};
  flex: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ForgetContainer = styled.button`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  color: ${Colors.MEDIUM_GREY};
  transition: all 0.5s;

  &:focus, &:hover, &:active {
    outline: none;
  }

  &:hover, &:active {
    color: ${Colors.BLACK};
  }
`;

export default SignInForm;