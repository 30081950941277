import {FormControlLabel, FormGroup} from "@material-ui/core";
import React, {useState} from "react";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import TurquoiseCheckbox from "../TurquoiseCheckbox";
import SelectNumber from "../finances/SelectNumber";

const ELearningSourceGroup = ({group}) => {

    const classes = useStyles();

    const [groupEnabled, setGroupEnabled] = useState(group.enabled)
    const [eLearningSources, setELearningSources] = useState([])

    const handleChangeGroup = () => {
        const newGroup = Object.assign(group);
        newGroup.enabled = !group.enabled;
        setGroupEnabled(newGroup.enabled);
    };

    const handleChangeItem = (source, index) => {
        const currentIndex = eLearningSources.indexOf(source);
        const newSources = [...eLearningSources];

        if (currentIndex === -1) {
            newSources.push(source);
        } else {
            newSources.splice(currentIndex, 1);
        }
        setELearningSources(newSources);

        const newGroup = Object.assign(group);
        const newItem = Object.assign(group[index]);
        newItem.enabled = !newItem.enabled;
        newGroup.splice(index, 1, newItem);
    };

    return (
        <Container>
            <FormGroup>
                <FormControlLabel
                    key={group.id + "g"}
                    control={
                        <TurquoiseCheckbox
                            checked={groupEnabled}
                            onChange={handleChangeGroup}
                        />
                    }
                    label="Kompetencje zawodowe (KZ)"
                    classes={{label: classes.label}}
                />
                <div>
                    Zgodnie z planowanymi do wdrożenia trendami określ "twarde" kompetencje zawodowe, które są Twoim zdaniem niezbędne na stanowisku danego pracownika [wybór kompetencji będzie równoznaczny z zaplanowaniem dla danego pracownika szkolenia zawodowego z wybranego obszaru kompetencyjnego]:
                </div>
                <Competences>
                    {group.map((item, index) => (
                        <div className={classes.item}>
                            <div className={classes.itemLabel}>
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <TurquoiseCheckbox
                                            checked={eLearningSources.indexOf(item.id) !== -1}
                                            onChange={() => handleChangeItem(item.id, index)}
                                        />
                                    }
                                    label={item.professionalCompetence}
                                    disabled={!groupEnabled}
                                />
                            </div>
                            <div className={classes.itemInput}>
                                <SelectNumber
                                    number={item.competenceLevel}
                                    setNumber={(newValue) => {
                                        item.competenceLevel = newValue
                                    }}
                                    label={"Zdiagnozowany poziom kompetencji"} noValueLabel={""}
                                    from={1}
                                    to={5}
                                    disabled={!item.enabled}
                                />
                            </div>
                        </div>
                    ))}
                </Competences>
            </FormGroup>
        </Container>

    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Competences = styled.div`
  padding: 0 30px;
  display: flex;
  flex-direction: column;
`;

const useStyles = makeStyles(theme => ({
    label: {
        color: Colors.TURQUOISE,
        fontWeight: 'bold'
    },
    item: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    itemLabel: {
        display: 'flex',
        flex: 2,
        marginTop: '15px',
    },
    itemInput: {
        display: 'flex',
        flex: 2,
    }
}));

export default ELearningSourceGroup;