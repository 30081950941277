import React, {useContext, useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized} from "../../services/LinksService";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import DevelopmentPath from "../../components/recommendation/DevelopmentPath";
import Title from "../../components/Title";
import {getWorkerRecommendationSummaryUsingGET, searchMyDataUsingPOST} from "../../swagger/vue-api-client";
import DevelopmentPathHeader from "../../components/recommendation/DevelopmentPathHeader";
import {WorkerContext} from "../../contexts/WorkerContext";
import {convertWorker} from "../../services/WorkerService";

const AllRecommendationsPage = ({history}) => {

    const classes = useStyles()
    const [recommendations, setRecommendations] = useState([])
    const workerContext = useContext(WorkerContext)

    useEffect(() => {
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((response) => {
                let workerId = localStorage.getItem("id")
                return getWorkerRecommendationSummaryUsingGET({workerId: workerId})
            })
            .then((res) => {
                setRecommendations(res.data)
                return searchMyDataUsingPOST({})
            })
            .then((res) => {
                workerContext.setMe(convertWorker(res.data))
                //setShow(isPublished(res.data.selfEvaluation, res.data.coworkerEvaluation, res.data.supervisorEvaluation))
            })
            .catch((error) => {
                console.log(error);
            })
        // eslint-disable-next-line
    }, [history])

    return (
        <Container>
            <RoleDependantMenu history={history}/>

            <div className={classes.content}>
                <Title title={"Ścieżka Reskillingu"}/>
                {recommendations.map((item, index) => {
                    if (item.recommendationList === null) {
                        return (
                            <div className={classes.competenceHeader} key={index}>
                                <DevelopmentPathHeader recommendation={item} fontSize={20}/>
                            </div>
                        )
                    } else {
                        return (
                            <DevelopmentPath recommendation={item} key={index} history={history}/>
                        )
                    }
                })}
            </div>
            <Footer/>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "7% 2%",
        }
    },
    competenceHeader: {
        marginTop: 30,
        marginBottom: 30,
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default AllRecommendationsPage;