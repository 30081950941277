import React from "react";
import AskForEvaluationDialog from "../../askForEvaluation/AskForEvaluationDialog";
import EvaluationProgressDialog from "../../evaluationProgress/EvaluationProgressDialog";
import EvaluationSummaryDialog from "../../evaluationSummary/EvaluationSummaryDialog";

const StatusDependDialog = ({status, open, setOpen, worker}) => {

    if (status === "ZAKOŃCZONA") {
        return (
            <EvaluationSummaryDialog open={open} setOpen={setOpen} worker={worker}/>
        );
    } else if (status === "W TRAKCIE") {
        return (
            <EvaluationProgressDialog open={open} setOpen={setOpen} worker={worker}/>
        )
    } else if (status === "BRAK") {
        return (
            <AskForEvaluationDialog open={open} setOpen={setOpen} worker={worker}/>
        )
    } else {
        return null;
    }
};

export default StatusDependDialog;