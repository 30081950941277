import React from 'react';
import {voivodeships} from "../../constants/voivodeships";
import NumberInput from "./NumberInput";
import TurquoiseSelect from "../TurquoiseSelect";
import Subheader from "../Subheader";

const BasicCompanyData = ({voivodeship, setVoivodeship, duration, setDuration, workersNumber, setWorkersNumber,
                              placesNumber, setPlacesNumber, pupilsNumber, setPupilsNumber, price, setPrice,
                              cost, setCost, dataSource, setDataSource}) => {

    const durationPossibilities = [
        {value: 2, label: "2"},
        {value: 3, label: "3"},
        {value: 5, label: "5"}
    ]

    const dataSources = [
        {value: "tak", label: "tak"},
        {value: "nie - wyłącznie dane własne", label: "nie - wyłącznie dane własne"},
        {value: "nie - dane mieszane", label: "nie - dane mieszane"}
    ]

    return (
        <div>
            <Subheader title={"Podstawowe dane"}/>
            <TurquoiseSelect
                value={voivodeship}
                setValue={setVoivodeship}
                label={"Województwo, w którym działa podmiot"}
                noValueLabel={"Wybierz województwo"}
                possibilities={voivodeships}
            />
            <TurquoiseSelect
                value={duration}
                setValue={setDuration}
                label={"Okres analizy [liczb lat, dla których mają zostać oszacowane wyniki]"}
                possibilities={durationPossibilities}
                showNoValueLabel={false}
            />
            <NumberInput
                value={workersNumber}
                setValue={setWorkersNumber}
                label={"Liczba pracowników objętych działaniami na ścieżce Reskillingu"}
            />
            <NumberInput
                value={placesNumber}
                setValue={setPlacesNumber}
                label={"Liczba miejsc w dziennym domu pobytu / placówce opiekuńczej"}
            />
            <NumberInput
                value={pupilsNumber}
                setValue={setPupilsNumber}
                label={"Średnia liczba klientów w skali miesiąca"}
            />
            <NumberInput
                value={price}
                setValue={setPrice}
                label={"Suma przychodów z tytułu świadczenia usług w skali miesiąca"}
            />
            <NumberInput
                value={cost}
                setValue={setCost}
                label={"Suma kosztów świadczenia usług w skali miesiąca"}
            />
            <TurquoiseSelect
                value={dataSource}
                setValue={setDataSource}
                label={"Chcę skorzystać wyłącznie z danych statystycznych"}
                possibilities={dataSources}
                showNoValueLabel={false}
            />
        </div>
    )
}

export default BasicCompanyData;