import React from 'react';
import {Colors} from "../../constants/colors";
import Header from "../../components/Header";
import Subheader from "../../components/Subheader";
import OutlineButtonLink from "../../components/buttons/OutlineButtonLink";
import SignUpForm from "../../components/login/SignUpForm";
import TrademarksHeader from "../../components/TrademarksHeader";
import {makeStyles} from "@material-ui/core/styles";
import Footer from "../../components/Footer";
import LeftTurquoiseContainer from "../../components/LeftTurquoiseContainer";

const SignUpPage = ({history}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <TrademarksHeader history={history}/>
            <LeftTurquoiseContainer>
                <div className={classes.signUpContent}>
                    <div className={classes.headerContainer}><Header title={"Cześć!"} color={Colors.WHITE}/></div>
                    <Subheader title={"Masz już konto? Zaloguj się"} color={Colors.WHITE}/>
                    <div className={classes.buttonContainer}>
                        <OutlineButtonLink
                            href={process.env.REACT_APP_BASE_PATH + "/logowanie"}
                            label={"Zaloguj się"}
                            color={Colors.WHITE}
                            backgroundColor={Colors.TURQUOISE}
                        />
                    </div>
                </div>
            </LeftTurquoiseContainer>
            <SignUpForm history={history}/>
            <Footer onlySmallScreen={true}/>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100vm",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            fontSize: 14,
            textAlign: "center",
        },
    },
    signUpContent: {
        [theme.breakpoints.up("lg")]: {
            position: "absolute",
            top: "40vh",
        },
    },
    headerContainer: {
        [theme.breakpoints.down("sm")]: {
            paddingTop: 30,
        },
    },
    buttonContainer: {
        marginBottom: 30,
    }
}));

export default SignUpPage;