import React from 'react'
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import {GoSearch, IoClose} from "react-icons/all";

const NoEvaluations = () => {

    return (
        <Container>
            <Icons>
                <GoSearch color={Colors.LIGHT_GREY} size={180}/>
                <CloseIcon><IoClose color={Colors.LIGHT_GREY} size={90}/></CloseIcon>
            </Icons>
            <Message>Kiedy Twój pracodawca poprosi Cię o ocenienie siebie bądź innego pracownika, tutaj znajdziesz taką informację i możliwość.</Message>
        </Container>
    )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10%;
`;

const Icons = styled.div`
`;

const CloseIcon = styled.div`
  position: relative;
  bottom: 160px;
  left: 35px;
`;

const Message = styled.div`
  font-size: 160%;
  color: ${Colors.GREEN};
  text-align: center;
`;

export default NoEvaluations;