import {CircularProgress, Dialog} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import SummaryChart from "./SummaryChart";
import {getWorkerEvaluationResult} from "../../services/EvaluationService";
import DialogTitle from "../DialogTitle";
import OnlyIconButton from "../buttons/OnlyIconButton";
import {IoClose} from "react-icons/all";
import {Colors} from "../../constants/colors";
import DialogButtonsContainer from "../DialogButtonsContainer";
import SummaryTable from "./SummaryTable";
import ButtonWithoutIcon from "../buttons/ButtonWithoutIcon";
import {recalculateRecommendationsUsingPOST} from "../../swagger/vue-api-client";

const EvaluationSummaryDialog = ({open, setOpen, worker}) => {

    const [result, setResult] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(0)

    useEffect(() => {
        setIsLoading(true)
        getWorkerEvaluationResult(worker.id)
            .then((res) => {
                setResult(res)
                setRefresh(0)
                setIsLoading(false)
            })
            .catch((err) => {
                if (refresh < 10)
                    setTimeout(() => setRefresh(c => c+1), 1000)
                else
                    setIsLoading(false)
            })
    }, [worker.id, refresh])

    const recalculateWorker = () => {
        recalculateRecommendationsUsingPOST({workerId: {id: worker.id}})
            .then((res) => {
                console.log(res)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'md'} fullWidth={true}>
            <DialogTitle title={"Raport dotyczący oceny pracownika " + worker.name}/>
            {isLoading ? (
                <LoadingContainer><CircularProgress/></LoadingContainer>
            ) : (
                <Container>
                    <SummaryTable result={result}/>
                    <SummaryChart result={result}/>
                    <DialogButtonsContainer onlySmallScreen={true}>
                        <OnlyIconButton
                            icon={<IoClose color={Colors.WHITE} size={30}/>}
                            onClick={() => setOpen(false)}
                            backgroundColor={Colors.TURQUOISE}
                        />
                    </DialogButtonsContainer>
                    <div style={{display: "flex", justifyContent: "space-evenly", margin: 50}}>
                        <ButtonWithoutIcon
                            label={"Anuluj"}
                            onClick={() => setOpen(false)}
                        />
                        <ButtonWithoutIcon
                            label={"Przelicz wszystko ponownie"}
                            onClick={recalculateWorker}
                        />
                    </div>
                </Container>
            )}
        </Dialog>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10%;
`;

export default EvaluationSummaryDialog;