import React from 'react';
import {Colors} from "../constants/colors";
import {makeStyles} from "@material-ui/core/styles";

const Header = ({title, color}) => {

    const classes = useStyles({color});

    return (
        <div className={classes.header} color={color}>
            {title}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    header: props => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "2.5em",
        color: props.color ? props.color : Colors.TURQUOISE,
        fontWeight: "bold",
        margin: "30px 0",
        textAlign: "center",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1.8em",
        },
        [theme.breakpoints.down(350)]: {
            fontSize: "1.5em",
        },
    }),
}));

export default Header;