import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import {MdShortText, MdSubject} from "react-icons/md";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import LongInputField from "../../LongInputField";
import {editComment, editPost} from "../../../services/ForumService";
import DialogTitle from "../../DialogTitle";
import DialogButtonsContainer from "../../DialogButtonsContainer";

const EditCommentDialog = ({open, setOpen, comment, post, setPost, setComments}) => {

    const [title, setTitle] = useState(post.title)
    const [content, setContent] = useState(comment.content)

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const makeCleanupHavingRes = (res) => {
        if (res.length > 0) {
            setErrorMessage(res)
            setIsLoading(false)
        } else {
            setErrorMessage('')
            setIsLoading(false)
            setOpen(false)
        }
    }

    const onEdit = () => {
        setIsLoading(true);

        if (comment.id === 0) {
           editPost(post, content, title, setPost, setComments)
               .then((res) => makeCleanupHavingRes(res))
        } else {
            editComment(comment, content, setComments)
                .then((res) => makeCleanupHavingRes(res))
        }
    }

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'lg'} fullWidth={true}>
            <DialogTitle title={"Edytuj " + comment.id === "0" ? "post" : "komentarz"}/>
            <Container>
                {comment.id === 0 ? (
                    <LongInputField
                        value={title}
                        setValue={setTitle}
                        placeholder={"Tytuł"}
                        icon={<MdShortText size={20} color={Colors.LIGHT_GREY}/>}
                        rows={"1"}
                    />
                ) : null}
                <LongInputField
                    value={content}
                    setValue={setContent}
                    placeholder={"Treść posta"}
                    icon={<MdSubject size={20} color={Colors.LIGHT_GREY}/>}
                    rows={"20"}
                />
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"Anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"Moderuj"}
                        onClick={onEdit}
                        backgroundColor={Colors.GREEN}
                    />
                </DialogButtonsContainer>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 5%;
  width: 90%;
`;

export default EditCommentDialog;