import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized} from "../../services/LinksService";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import Title from "../../components/Title";
import {FormControlLabel} from "@material-ui/core";
import TurquoiseCheckbox from "../../components/TurquoiseCheckbox";
import Result3Table from "../../components/financesResult/Result3Table";
import {formatNumber} from "../../services/NumberService";
import {getFinancialAnalysisStepTwoOutputOneDataUsingGET} from "../../swagger/vue-api-client";
import ResultPieChart from "../../components/financesResult/ResultPieChart";
import ButtonWithoutIcon from "../../components/buttons/ButtonWithoutIcon";

const FinancialResult3 = ({history}) => {

    const classes = useStyles()
    const [result, setResult] = useState([])
    const [workers, setWorkers] = useState([])
    const [showDetails, setShowDetails] = useState(false)
    const [chartData, setChartData] = useState(null)

    useEffect(() => {
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((response) => {
                return getFinancialAnalysisStepTwoOutputOneDataUsingGET()
            })
            .then((res) => {
                console.log(res.data)
                let tempWorkers
                setChartData(res.data.chartData)
                setResult(res.data.rows.map((item, index) => {
                    let help = Object.assign(item)
                    let loyalty = item.loyaltyIncreaseTableRow
                    let motivation = item.motivationImproveTableRow

                    help.sum1 = formatNumber(loyalty.totalCost)
                    help.sum2 = formatNumber(motivation.totalCost)
                    help.workers1 = loyalty.workerList.map((w, iw) => formatNumber(w.cost))
                    help.workers2 = motivation.workerList.map((w, iw) => formatNumber(w.cost))
                    tempWorkers = loyalty.workerList.map((w, iw) => w.name)
                    return help
                }))
                setWorkers(tempWorkers)
            })
            .catch((error) => {
                console.log(error);
            })
    }, [history])

    const print = () => {
        window.print();
    }

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <Title title={"Korzyści stanowiskowe"}/>
                <div className={classes.tableContainer}>
                    <div className={classes.settingsRight}>
                        <FormControlLabel
                            control={<TurquoiseCheckbox checked={showDetails} onChange={() => {setShowDetails(c => !c)}}/>}
                            label={"Pokaż szczegóły"}
                        />
                    </div>
                    {chartData !== null ? <ResultPieChart chartData={chartData}/> : null}
                    <Result3Table result={result} workers={workers} showDetails={showDetails}/>
                </div>
                <div className={classes.buttonsContainer}>
                    <ButtonWithoutIcon
                        label={"Drukuj"}
                        onClick={() => print()}
                    />
                </div>
            </div>
            <Footer/>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "90%",
        margin: "5%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
    buttonsContainer : {
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [`@media print`]: {
            display: "none"
        }
    },
    settingsRight : {
        [`@media print`]: {
            display: "none"
        }
    }
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default FinancialResult3;