import {CircularProgress} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import {Colors} from "../constants/colors";

const ErrorAndLoadingMessage = ({isLoading, errorMessage}) => {

    const getErrorMessage = (message) => {
        if (message.length >= 100) {
            return message.substring(0, 100) + "...";
        } else {
            return message;
        }
    }

    return (
        <ErrorMessage>
            {isLoading ? <CircularProgress/> : <div>{getErrorMessage(errorMessage)}</div>}
        </ErrorMessage>
    );
};

const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 40px;
  color: ${Colors.RED};
  margin: 0 20px 10px 20px;
`;

export default ErrorAndLoadingMessage;