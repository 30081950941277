import React from 'react';
import styled from "styled-components";
import {Colors} from "../constants/colors";

const InputField = ({value, setValue, type, placeholder, icon, button, disabled}) => {

    return (
        <Container>
            {icon}
            <Input
                disabled={disabled}
                type={type}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                placeholder={placeholder}
            />
            {button}
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.VERY_LIGHT_GREY};
  margin: 0.8em;
  padding-left: 1em;
  padding-right: 1em;
  width: 90%;
`;

const Input = styled.input`
  flex: 1;
  width: 100%;
  padding: 1em;
  margin-left: 1em;
  color: ${props => props.disabled ? Colors.LIGHT_GREY : Colors.GREY};
  background-color: ${Colors.VERY_LIGHT_GREY};
  border: none;
  font-size: 1em;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${Colors.LIGHT_GREY};
  }
  :-ms-input-placeholder {
    color: ${Colors.LIGHT_GREY};
  }
  :focus {
    outline: none;
  }
`;

export default InputField;