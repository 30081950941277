import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized} from "../../services/LinksService";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import RecommendationTable from "../../components/table/recommendation/RecommendationTable";
import {
    getCompanyMergeSummaryUsingGET, getOrganisationEvaluationResultUsingGET,
    saveRecommendationsForWorkerUsingPOST
} from "../../swagger/vue-api-client";
import {CircularProgress} from "@material-ui/core";
import {Colors} from "../../constants/colors";
import TitleWithButton from "../../components/TitleWithButton";
import ButtonWithoutIcon from "../../components/buttons/ButtonWithoutIcon";
import OrganisationRecommendations from "../../components/organisationRecommendation/OrganisationRecommendations";

const AllCompanyRecommendationsPage = ({history}) => {

    const classes = useStyles()
    const [recommendations, setRecommendations] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [refresh, setRefresh] = useState(0)
    const [message, setMessage] = useState('')
    const [organisationResult, setOrganisationResult] = useState(null)

    useEffect(() => {
        setIsLoading(true)
        setMessage('')
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((response) => {
                let employerId = localStorage.getItem("id")
                return getCompanyMergeSummaryUsingGET({employerId: employerId})
            })
            .then((res) => {
                setRecommendations(res.data)
                return getOrganisationEvaluationResultUsingGET()
            })
            .then((res) => {
                setOrganisationResult(res.data)
                setRefresh(0)
                setIsLoading(false)
            })
            .catch((error) => {
                if (error && error.response && typeof error.response.data === 'string') {
                    setMessage(error.response.data)
                }
                setIsLoading(false)
            })
    }, [history, refresh])


    const handleSaveChanges = () => {

        setIsLoading(true)

        let body = []
        recommendations.forEach((competence, i1) => {
            if (competence.recommendationForCompanySummaryList !== null) {
                competence.recommendationForCompanySummaryList.forEach((task, i2) => {
                    if (task.workerListStageOne !== null) {
                        task.workerListStageOne.forEach((workerRecommendation, i3) => {
                            body.push(workerRecommendation)
                        })
                    }
                    if (task.workerListStageTwo !== null) {
                        task.workerListStageTwo.forEach((workerRecommendation, i3) => {
                            body.push(workerRecommendation)
                        })
                    }
                })
            }
        })

        saveRecommendationsForWorkerUsingPOST({recommendations: body})
            .then((res) => {
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <TitleWithButton
                    title={"Zalecenia dla całej firmy"}
                    button={<ButtonWithoutIcon label={"Zapisz zmiany"} onClick={handleSaveChanges}/>}
                />
                {isLoading ?
                    (<div className={classes.loadingContainer}><CircularProgress/></div>) :
                    (message.length > 0 ?
                            <div className={classes.loadingContainer}>{message}</div> :
                            <RecommendationTable
                                recommendations={recommendations}
                                setRecommendations={setRecommendations}
                            />
                    )
                }
                {message.length > 0 || isLoading ? null : (
                    <OrganisationRecommendations result={organisationResult}/>
                )}
            </div>
            <Footer/>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "7% 2%",
        }
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "10%",
        fontSize: 18,
        color: Colors.GREY,
    },
    organisation: {
        marginTop: 80,
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default AllCompanyRecommendationsPage;