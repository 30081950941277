import React, {useState} from 'react';
import Header from "../../components/Header";
import Subheader from "../../components/Subheader";
import {Colors} from "../../constants/colors";
import InputField from "../../components/InputField";
import {RiLockPasswordLine} from "react-icons/ri";
import OutlineButton from "../../components/buttons/OutlineButton";
import {verifyEmailUsingPOST} from "../../swagger/vue-api-client";
import ErrorAndLoadingMessage from "../../components/ErrorAndLoadingMessage";
import LeftTurquoiseContainer from "../../components/LeftTurquoiseContainer";
import RightWhiteContainer from "../../components/RightWhiteContainer";
import TrademarksHeader from "../../components/TrademarksHeader";
import {makeStyles} from "@material-ui/core/styles";
import Footer from "../../components/Footer";

const WorkerConfirmEmailPage = ({history}) => {

    const classes = useStyles()
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [complete, setComplete] = useState(false);
    const token = (new URLSearchParams(window.location.search)).get("token")

    const handleCreatePassword = (password, passwordRepeat) => {
        if (password !== passwordRepeat) {
            setErrorMessage("Podane hasła są różne");
        } else if (password.length < 8) {
            setErrorMessage("Hasło musi mieć co najmniej 8 znaków");
        } else {
            verifyEmailUsingPOST({
                emailVerificationRequest: {
                    emailVerificationToken: token,
                    password: password
                }})
                .then((result) => {
                    setComplete(true);
                })
                .catch((error) => {
                    setErrorMessage(error.response.data)
                });
        }
    };

    if (complete) {
        history.push("/potwierdz-email/pracownik/ukonczone");
    }

    return (
        <div className={classes.container}>
            <TrademarksHeader history={history}/>
            <LeftTurquoiseContainer>
                <Header title={"Cześć!"}/>
                <Subheader title={"Twój adres email został zweryfikowany!"} color={Colors.WHITE}/>
                <Subheader title={"Aby aktywować konto musisz jeszcze utworzyć do niego hasło."} color={Colors.WHITE}/>
            </LeftTurquoiseContainer>
            <RightWhiteContainer>
                <Header title={"Utwórz swoje hasło"} color={Colors.TURQUOISE}/>
                <div className={classes.form}>
                    <InputField
                        type={"password"}
                        value={password}
                        setValue={setPassword}
                        placeholder={"Hasło"}
                        icon={<RiLockPasswordLine size={20} color={Colors.LIGHT_GREY}/>}
                    />
                    <InputField
                        type={"password"}
                        value={passwordRepeat}
                        setValue={setPasswordRepeat}
                        placeholder={"Powtórz hasło"}
                        icon={<RiLockPasswordLine size={20} color={Colors.LIGHT_GREY}/>}
                    />
                </div>
                <ErrorAndLoadingMessage isLoading={false} errorMessage={errorMessage}/>
                <OutlineButton
                    label={"Utwórz"}
                    onClick={() => handleCreatePassword(password, passwordRepeat)}
                />
            </RightWhiteContainer>
            <Footer onlySmallScreen={true}/>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "row",
        width: "100vm",
        height: "100vh",
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            fontSize: 14,
            textAlign: "center",
        },
    },
    form: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        [theme.breakpoints.down("sm")]: {
            width: "80%",
        }
    },
}));

export default WorkerConfirmEmailPage;