import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import {sendVerificationEmailUsingPOST} from "../../../swagger/vue-api-client";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import DialogTitle from "../../DialogTitle";
import DialogButtonsContainer from "../../DialogButtonsContainer";

const SendWorkerVerificationEmailDialog = ({open, setOpen, worker}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onSendWorkerVerificationEmail = () => {

        setIsLoading(true);

        sendVerificationEmailUsingPOST({id: worker.id})
            .then((response) => {
                setErrorMessage('');
                setIsLoading(false);
                setOpen(false);
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    setErrorMessage("Nie udało się wysłać wiadomości email")
                }
                else {
                    setErrorMessage(error.response.data);
                }
                setIsLoading(false);
            });
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle title={"Czy chcesz wysłać ponownie email z linkiem aktywacyjnym do pracownika " + worker.name + "?"}/>
            <Container>
                <ErrorAndLoadingMessage errorMessage={errorMessage} isLoading={isLoading}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"wyślij"}
                        onClick={() => onSendWorkerVerificationEmail(worker.id)}
                        backgroundColor={Colors.GREEN}
                    />
                </DialogButtonsContainer>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 20px 40px 20px;
  text-align: center;
`;

export default SendWorkerVerificationEmailDialog;