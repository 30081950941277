import {TableCell, TableRow} from "@material-ui/core";
import React, {useState} from "react";
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import TurquoiseRadio from "../TurquoiseRadio";

const OrganisationAnswer = ({data, name, hasAnswer, answers, even = true, triedToFinish}) => {

    const [selectedValue, setSelectedValue] = useState(answers[data.id])
    const backgroundColor = (triedToFinish && selectedValue === "") ? (Colors.GREEN2) : (even ? Colors.PASTEL_TURQUOISE : Colors.WHITE)
    const classes = useStyles({backgroundColor})

    const changeAnswer = (event) => {
        setSelectedValue(event.target.value)
        answers[data.id] = event.target.value
    }

    return (
        <TableRow classes={{root: classes.row}}>
            <TableCell classes={{root: classes.cellRoot}}>{name}</TableCell>
            {hasAnswer ?
                <React.Fragment>
                    <TableCell align={"center"} classes={{root: classes.cellRoot}} >
                        <TurquoiseRadio
                            checked={selectedValue === "tak"}
                            onChange={changeAnswer}
                            value={"tak"}
                        />
                    </TableCell>
                    <TableCell align={"center"} classes={{root: classes.cellRoot}}>
                        <TurquoiseRadio
                            checked={selectedValue === "nie"}
                            onChange={changeAnswer}
                            value={"nie"}
                        />
                    </TableCell>
                </React.Fragment> :
                <React.Fragment>
                    <TableCell align={"center"} classes={{root: classes.cellRoot}} >
                    </TableCell>
                    <TableCell align={"center"} classes={{root: classes.cellRoot}}>
                    </TableCell>
                </React.Fragment>
            }
        </TableRow>
    )
}

const useStyles = makeStyles(theme => ({
    row: props => ({
        backgroundColor: props.backgroundColor,
    }),
    cellRoot: {
        fontSize: 15,
        border: "none"
    },

}));

export default OrganisationAnswer;