import React from 'react';
import {Colors} from "../../constants/colors";
import InputField from "../InputField";
import {IoMailOutline} from "react-icons/io5";
import {HiDocumentText, HiOutlineOfficeBuilding, HiOutlinePhone} from "react-icons/hi";
import Subheader from "../Subheader";
import LongInputField from "../LongInputField";
import CheckboxField from "../CheckboxField";
import BrowseFileField from "../BrowseFileField";

const SignUpCompanyForm = ({companyName, setCompanyName, companyEmail, setCompanyEmail, phoneNumber,
                               setPhoneNumber, description, setDescription, selectedFile, setSelectedFile,
                               agreementForDataSharing, setAgreementForDataSharing, getIconColor}) => {

    return (
        <div>
            <Subheader title={"Dane firmy"} color={Colors.TURQUOISE}/>
            <InputField
                type={"text"}
                value={companyName}
                setValue={setCompanyName}
                placeholder={"Nazwa firmy*"}
                icon={<HiOutlineOfficeBuilding size={20} color={getIconColor(companyName)}/>}
            />
            <InputField
                type={"email"}
                value={companyEmail}
                setValue={setCompanyEmail}
                placeholder={"Email firmy*"}
                icon={<IoMailOutline size={20} color={getIconColor(companyEmail)}/>}
            />
            <InputField
                type={"tel"}
                value={phoneNumber}
                setValue={setPhoneNumber}
                placeholder={"Numer telefonu*"}
                icon={<HiOutlinePhone size={20} color={getIconColor(phoneNumber)}/>}
            />
            <LongInputField
                value={description}
                setValue={setDescription}
                placeholder={"Opis"}
                icon={<HiDocumentText size={20} color={Colors.LIGHT_GREY}/>}
                rows={4}
            />
            <BrowseFileField selectedFile={selectedFile} setSelectedFile={setSelectedFile}
                             placeholder={"logo..."}/>
            <CheckboxField
                checked={agreementForDataSharing}
                onChange={() => setAgreementForDataSharing(c => !c)}
                label={"Chcę aby dane firmy wyświetlały się w bazie podmiotów"}
            />
        </div>
    );
};

export default SignUpCompanyForm;