import React from 'react';
import styled from "styled-components";
import {Colors} from "../constants/colors";

const LongInputField = ({value, setValue, placeholder, icon, rows}) => {

    return (
        <Container>
            <IconContainer>
                {icon}
            </IconContainer>
            <TextArea
                value={value}
                onChange={(event) => setValue(event.target.value)}
                placeholder={placeholder}
                rows={rows ? rows : 1}
                cols={"150"}
                maxLength={"250"}
            />
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${Colors.VERY_LIGHT_GREY};
  margin: 0.8em;
  padding-left: 1em;
  width: 90%;
`;

const IconContainer = styled.div`
  margin-top: 14px;
`;

const TextArea = styled.textarea`
  flex: 1;
  width: 100%;
  padding: 1em;
  color: ${Colors.GREY};
  background-color: ${Colors.VERY_LIGHT_GREY};
  border: none;
  font-family: inherit;
  font-size: inherit;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${Colors.LIGHT_GREY};
  }

  :-ms-input-placeholder {
    color: ${Colors.LIGHT_GREY};
  }

  :focus {
    outline: none;
  }
`;

export default LongInputField;