import React, {useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import UpdateFileDialog from "./UpdateFileDialog";
import {getDateLabel} from "../../../services/DateService";
import OnlyIconButton from "../../buttons/OnlyIconButton";
import {MdUpdate} from "react-icons/md";
import {AiOutlineDelete} from "react-icons/ai";
import {FiDownload} from "react-icons/fi";
import {Colors} from "../../../constants/colors";
import DeleteFileDialog from "./DeleteFileDialog";
import {makeStyles} from "@material-ui/core/styles";
import styled from "styled-components";
import {downloadEmployerFile} from "../../../services/FilesService";
import {Tooltip} from "@material-ui/core";

const useRowStyles = makeStyles({
    root: {
        '& > *': {},
    },
});

const EmployerFileTableRow = ({file, setFiles}) => {

    const classes = useRowStyles();
    const [deleteFile, setDeleteFile] = useState(false)
    const [updateFile, setUpdateFile] = useState(false)
    const role = localStorage.getItem("role")

    return (
        <TableRow className={classes.root}>
            <TableCell>{file.name}</TableCell>
            <TableCell>{file.description}</TableCell>
            <TableCell>{getDateLabel(new Date(file.creationDate))}</TableCell>
            <TableCell>
                <ButtonsContainer>
                    {role === "EMPLOYER" ? (
                        <React.Fragment>
                            <Tooltip title={"Usuń plik"} placement={"right"}>
                                <div>
                                    <OnlyIconButton
                                        icon={<AiOutlineDelete size={25} color={Colors.RED}/>}
                                        onClick={() => setDeleteFile(true)}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip title={"Dodaj nową wersję"} placement={"right"}>
                                <div>
                                    <OnlyIconButton
                                        icon={<MdUpdate size={25} color={Colors.GREEN}/>}
                                        onClick={() => setUpdateFile(true)}
                                    />
                                </div>
                            </Tooltip>
                        </React.Fragment>
                    ) : null}
                    <Tooltip title={"Pobierz plik"} placement={"right"}>
                        <div>
                            <OnlyIconButton
                                icon={<FiDownload size={25} color={Colors.TURQUOISE}/>}
                                onClick={() => downloadEmployerFile(file)}
                            />
                        </div>
                    </Tooltip>
                </ButtonsContainer>
            </TableCell>
            {role === "EMPLOYER" ? (
                <React.Fragment>
                    <UpdateFileDialog
                        open={updateFile}
                        setOpen={setUpdateFile}
                        file={file}
                        setFiles={setFiles}
                    />
                    <DeleteFileDialog
                        open={deleteFile}
                        setOpen={setDeleteFile}
                        file={file}
                        setFiles={setFiles}
                    />
                </React.Fragment>
            ) : null}
        </TableRow>
    );
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export default EmployerFileTableRow;