import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import HeadingCells from "../HeadingCells";

const headCells = [
    {id: 'name', label: 'Nazwa', align: 'left', sortable: true},
    {id: 'filename', label: 'Nazwa pliku', align: 'left', sortable: true},
    {id: 'description', label: 'Opis pliku', align: 'left', sortable: true},
    {id: 'actions', label: 'Akcje', align: 'center', sortable: false},
];

const CompanyExcelFilesTableHeading = ({order, orderBy, rows, setOrder, setOrderBy}) => {

    const rowCount = rows.length; // moze sie przydac

    return (
        <TableHead>
            <TableRow>
                <HeadingCells setOrderBy={setOrderBy} orderBy={orderBy} setOrder={setOrder} order={order} headCells={headCells}/>
            </TableRow>
        </TableHead>
    );
}

export default CompanyExcelFilesTableHeading;