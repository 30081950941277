import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";
import {HiClipboardCheck} from "react-icons/all";
import {getRoleDependentLink} from "../../services/LinksService";

const OrganisationELearnings = ({eLearnings}) => {

    const classes = useStyles()

    if (eLearnings === null)
        return null

    return (
        <div className={classes.container}>
            {eLearnings.map((item, index) => (
                <div className={classes.item} key={index + "e"}>
                    <HiClipboardCheck color={Colors.GREEN} size={30}/>
                    <div className={classes.label}>zalecenie:</div>
                    <a href={process.env.REACT_APP_BASE_PATH + getRoleDependentLink("/zalecenia/" + item.id)} className={classes.link}>
                        {item.name}
                    </a>
                </div>
            ))}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 10,
    },
    item: {
        display: "flex",
        alignItems: "center",
        marginTop: 15,
    },
    label: {
        margin: "0 10px",
        color: Colors.GREY,
    },
    link: {
        textDecoration: "none",
        color: Colors.BLACK,
        transition: "all 0.3s",
        "&:hover": {
            color: Colors.GREEN,
        }
    },
}));

export default OrganisationELearnings;