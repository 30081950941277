import {Dialog} from "@material-ui/core";
import React, {useContext, useEffect, useState} from "react";
import DialogTitle from "../../DialogTitle";
import {makeStyles} from "@material-ui/core/styles";
import {FiDownload} from "react-icons/all";
import ButtonWithIcon from "../../buttons/ButtonWithIcon";
import {
    downloadAllCompanyExcelFilesUsingGETURL,
    searchCompanyExcelFilesUsingPOST,
} from "../../../swagger/vue-api-client";
import {downloadFileAsBlob} from "../../../services/FilesService";
import CompanyExcelFilesTable from "./CompanyExcelFilesTable";
import {getClearSearchCriteria} from "../../../services/SearchService";

const CompanyExcelFilesDialog = ({open, setOpen, company}) => {

    const classes = useStyles()
    const [companyExcelFiles, setCompanyExcelFiles] = useState(false);

    async function downloadAllExcelFiles() {
        await downloadFileAsBlob(downloadAllCompanyExcelFilesUsingGETURL({companyId: company.id}),
            company.id + ".zip");
    }

    useEffect(() => {
        searchCompanyExcelFilesUsingPOST({companyId: company.id, searchCriteria: getClearSearchCriteria()})
            .then((response) => {
                setCompanyExcelFiles(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'md'} fullWidth={true}>
            <DialogTitle title={"Pliki excel firmy"} />
            <div className={classes.container}>
                <ButtonWithIcon icon={<FiDownload/>} onClick={downloadAllExcelFiles}
                    label={"Pobierz wszystkie pliki"} className={classes.button}/>
                <React.Fragment>
                    <CompanyExcelFilesTable
                        rows={companyExcelFiles}
                    />
                </React.Fragment>
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "center",
        width: "90%",
        padding: "20px 5%",
        marginBottom: "5%",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            padding: "20px 2%",
        }
    },
    button: {
        height: 50
    }
}));

export default CompanyExcelFilesDialog;