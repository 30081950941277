import React from "react";
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import QuizAnswer from "./QuizAnswer";

const QuizQuestionTile = ({question, handleChangeAnswer, even, index, leftAlign=false}) => {

    const classes = useStyles();

    const handleChange = (event) => {
        handleChangeAnswer(parseInt(event.target.value));
    };

    return (
        <div className={even ? classes.evenRowRoot : classes.oddRowRoot}>
            <div className={classes.question}>{index+1}. {question.questionText}</div>
            {question.answers.map((item, index) => (
                <QuizAnswer key={item.id} answer={item} number={index}
                            handleChange = {handleChange} leftAlign={leftAlign}/>
            ))}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    evenRowRoot: {
        display: "flex",
        flexDirection: "column",
        padding: 10,
        marginTop: 20,
        backgroundColor: Colors.PASTEL_TURQUOISE,
    },
    oddRowRoot: {
        display: "flex",
        flexDirection: "column",
        padding: 10,
        marginTop: 20,
        backgroundColor: Colors.WHITE,
    },
    question: {
        margin: 8,
    },
    answers: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "flex-start",
        paddingLeft: "5%",
    },
}));

export default QuizQuestionTile;