import React from "react";
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import styled from "styled-components";
import {HiCheck} from "react-icons/all";
import {getRoleDependentLink} from "../../services/LinksService";
import {Tooltip} from "@material-ui/core";

const DevelopmentPoint = ({title, description="", isLast = false, isFirst = false, color1, color2, complete, id, history, recommendation}) => {

    const gradient = "linear-gradient(to right, " + color1 + ", " + color2 + ")"
    const classes = useStyles({gradient, complete})

    const point = (
        <Point color={color2} complete={complete}>
            {complete ? <HiCheck size={25} color={Colors.WHITE}/> : null}
        </Point>
    )

    return (
        <div className={classes.container}>
            {isFirst ? null : <div className={classes.line}/>}
            <Tooltip title={description} placement={"top"}>
                <button className = {classes.link} type="button"
                        onClick={() => {
                            if (id == null) {
                                return;
                            }
                            history.push({
                                    pathname: getRoleDependentLink("/sciezka/" + id),
                                    state: {title: recommendation.competenceName}
                                }
                            )
                        }}>
                    <div className={classes.bigScreen}>
                        <PointContainer isLast={isLast}>
                            {point}
                        </PointContainer>
                    </div>
                    <div className={classes.label}>{title}</div>
                    <div className={classes.smallScreen}>
                        <PointContainerSmallScreen>{point}</PointContainerSmallScreen>
                        <Label>{title}</Label>
                    </div>
                </button>
            </Tooltip>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    line: props => ({
        width: 200,
        height: 5,
        backgroundImage: props.gradient,
        opacity: props.complete ? 1 : 0.3,
        [theme.breakpoints.down(800)]: {
            display: "none",
        }
    }),
    smallScreen: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.up(800)]: {
            display: "none",
        }
    },
    bigScreen: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down(800)]: {
            display: "none",
        }
    },
    link: {
        textDecoration: "none",
        border: "none",
        outline: "none",
        backgroundColor: Colors.WHITE,
        padding: 0,
        color: Colors.ALMOST_BLACK,
        transition: "all 0.3s",
        "&:hover": {
            color: Colors.MEDIUM_GREY,
        }
    },
    label: {
        position: "absolute",
        maxWidth: 180,
        zIndex: 1,
        fontSize: 15,
        [theme.breakpoints.down(800)]: {
            display: "none",
        }
    },
}));


const PointContainer = styled.div`
  width: ${params => params.isLast ? '64px' : '30px'};
  height: ${params => params.isLast ? '64px' : '30px'};
  margin: ${params => params.isLast ? '-17px' : '0px'};
  padding: 17px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.WHITE};
  transition: all 0.4s;
  z-index: 1;
  font-size: 17px;
  background-color: ${Colors.WHITE};

  &:hover {
    width: 64px;
    height: 64px;
    margin: -17px;
  }
`;

const PointContainerSmallScreen = styled.div`
  width: 30px;
  min-width: 30px;
  height: 30px;
  margin: 0;
  padding: 17px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
  z-index: 1;
  font-size: 17px;
`;

const Label = styled.div`
  margin-left: 17px;
`;

const Point = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  background-color: ${params => params.color};
  opacity: ${params => params.complete ? 1 : 0.3};
`;

export default DevelopmentPoint;