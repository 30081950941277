import {withStyles} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {Colors} from "../constants/colors";

const TurquoiseCheckbox = (props) => {

    return (
        <CustomColorCheckbox {...props}/>
    );
};

const CustomColorCheckbox = withStyles({
    root: {
        "&$checked": {
            color: Colors.TURQUOISE
        },
        "&$disabled": {
            color: Colors.LIGHT_GREY
        }
    },
    checked: {},
    disabled: {}
})((props) => <Checkbox color="default" {...props}/>);


export default TurquoiseCheckbox;