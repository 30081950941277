import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Subheader from "../Subheader";
import {Colors} from "../../constants/colors";
import OrganisationELearnings from "./OrganisationELearnings";

const Equipment = ({result}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Subheader title={"Sprzęt"} justify={"left"}/>
            <div className={classes.columns}>
                <div className={classes.column}>
                    <div className={classes.header}>Lista sprzętów do uzupełnienia</div>
                    {result.equipmentToBuy.map((item, index) => (
                        <div key={index}>{item}</div>
                    ))}
                </div>
                <div className={classes.column}>
                    <div className={classes.header}>Lista sprzętów do unowocześnienia</div>
                    {result.equipmentToUpgrade.map((item, index) => (
                        <div key={index + "i"} className={classes.item}>{item}</div>
                    ))}
                </div>
            </div>
            <OrganisationELearnings eLearnings={result.equipmentELearnings}/>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 30,
        marginBottom: 30,
    },
    columns: {
        display: "flex",
        justifyContent: "space-between",
    },
    column: {
        lineHeight: 1.5,
        color: Colors.ALMOST_BLACK,
    },
    header: {
        color: Colors.BLACK,
        textTransform: "uppercase",
        marginBottom: 20,
        fontSize: 17,
    },
    item: {
        marginBottom: 10,
    },
}));

export default Equipment;