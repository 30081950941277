import React, {useState} from 'react';
import {Colors} from "../../constants/colors";
import InputField from "../InputField";
import {IoEyeOffSharp, IoMailOutline} from "react-icons/io5";
import {RiLockPasswordLine, RiSettings2Line, RiUserLine} from "react-icons/ri";
import Subheader from "../Subheader";
import {makeStyles} from "@material-ui/core/styles";

const SignUpEmployerForm = ({userName, setUserName, userSurname, setUserSurname, userEmail, setUserEmail,
                            password, setPassword, passwordRepeat, setPasswordRepeat, getIconColor}) => {

    const classes = useStyles()
    const [passwordType, setPasswordType] = useState("password")
    const [passwordRepeatType, setPasswordRepeatType] = useState("password")

    const handlePasswordShow = () => {
        setPasswordType("text")
    }

    const handlePasswordHide = () => {
        setPasswordType("password")
    }

    const handlePasswordRepeatShow = () => {
        setPasswordRepeatType("text")
    }

    const handlePasswordRepeatHide = () => {
        setPasswordRepeatType("password")
    }

    return (
                <div className={classes.employerData}>
                    <Subheader title={"Dane użytkownika"} color={Colors.TURQUOISE}/>
                    <InputField
                        type={"text"}
                        value={userName}
                        setValue={setUserName}
                        placeholder={"Imię*"}
                        icon={<RiUserLine size={20} color={getIconColor(userName)}/>}
                    />
                    <InputField
                        type={"text"}
                        value={userSurname}
                        setValue={setUserSurname}
                        placeholder={"Nazwisko*"}
                        icon={<RiUserLine size={20} color={getIconColor(userSurname)}/>}
                    />
                    <InputField
                        type={"email"}
                        value={userEmail}
                        setValue={setUserEmail}
                        placeholder={"Email*"}
                        icon={<IoMailOutline size={20} color={getIconColor(userEmail)}/>}
                    />
                    <InputField
                        type={passwordType}
                        value={password}
                        setValue={setPassword}
                        placeholder={"Hasło*"}
                        icon={<RiLockPasswordLine size={20} color={getIconColor(password)}/>}
                        button={<IoEyeOffSharp size={20} color={Colors.LIGHT_GREY}
                        onMouseDown={handlePasswordShow}
                        onMouseUp={handlePasswordHide}/>}
                    />
                    <InputField
                        type={passwordRepeatType}
                        value={passwordRepeat}
                        setValue={setPasswordRepeat}
                        placeholder={"Powtórz hasło*"}
                        icon={<RiSettings2Line size={20} color={getIconColor(passwordRepeat)}/>}
                        button={<IoEyeOffSharp size={20} color={Colors.LIGHT_GREY}
                        onMouseDown={handlePasswordRepeatShow}
                        onMouseUp={handlePasswordRepeatHide}/>}
                    />
                </div>
    );
};

const useStyles = makeStyles(theme => ({
    employerData: {
        marginTop: 50,
    },
}));

export default SignUpEmployerForm;