import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const DialogButtonsContainer = ({children, onlySmallScreen=false}) => {

    const classes = useStyles()

    if (onlySmallScreen) {
        return (
            <div className={classes.buttonsContainerSmall}>
                {children}
            </div>
        )
    } else {
        return (
            <div className={classes.buttonsContainer}>
                {children}
            </div>
        )
    }
}

const useStyles = makeStyles(theme => ({
    buttonsContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        width: "70%",
        margin: "0 15%",
        [theme.breakpoints.down(700)]: {
            width: "100%",
            margin: 0,
        }
    },
    buttonsContainerSmall: {
        display: "none",
        [theme.breakpoints.down(800)]: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
            margin: "20px 0",
        },
    },
}));

export default DialogButtonsContainer;