import React from "react";
import {Button, CardActions, CardContent, withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import {getRoleDependentLink} from "../../services/LinksService";

const ElearningTile = ({history, elearning}) => {

    let role = localStorage.getItem("role")

    return (
        <Tile>
            <CardContent>
                <Name variant="h5">{elearning.name}</Name>
            </CardContent>
            <RightAlignedCardActions>
                <Button size="large"
                        onClick={() => {
                            if (role === "EMPLOYER") {
                                history.push(getRoleDependentLink("/zalecenia/" + elearning.id))
                            } else {
                                history.push(getRoleDependentLink("/sciezka/" + elearning.id))
                            }
                        }}
                > Podgląd</Button>
            </RightAlignedCardActions>

        </Tile>
    )
}

const RightAlignedCardActions = withStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
}))(CardActions);

const Tile = styled.div`
  background-color: ${Colors.PASTEL_TURQUOISE};
  margin: 20px;
  padding: 10px;
  border-radius: 10px;
`;

const Name = styled(Typography)`
  padding-bottom: 15px;
`;

const Type = styled(Typography)`
  text-transform: uppercase;
`;

export default ElearningTile;