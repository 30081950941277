import React, {useEffect, useState} from "react";
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import TurquoiseRadio from "../TurquoiseRadio";

const EvaluationQuestionTile = ({question, answer, invertedScale, handleChangeAnswer, even, triedToFinish}) => {

    const backgroundColor = (triedToFinish && answer === null) ? (Colors.GREEN2) : (even ? Colors.PASTEL_TURQUOISE : Colors.WHITE)
    const classes = useStyles({backgroundColor});
    const [selectedValue, setSelectedValue] = useState(answer);
    const answerScale = invertedScale ? [5,4,3,2,1] : [1,2,3,4,5]

    useEffect(() => {
        setSelectedValue(answer)
    }, [answer]);


    const handleChange = (event) => {
        setSelectedValue(parseInt(event.target.value));
        handleChangeAnswer(parseInt(event.target.value));
    };


    return (
        <div className={classes.row}>
            <div className={classes.question}>{question}</div>
            <div className={classes.answers}>
                {answerScale.map((item, index) => (
                    <div className={classes.radioButton} key={index}>
                        <TurquoiseRadio
                            checked={selectedValue === item}
                            onChange={handleChange}
                            value={item}
                        />
                        {index + 1}
                    </div>
                ))}
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    row: props => ({
        display: "flex",
        flexDirection: "column",
        padding: 10,
        backgroundColor: props.backgroundColor,
    }),
    question: {
        margin: 8,
    },
    answers: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
    },
    radioButton: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
}));

export default EvaluationQuestionTile;