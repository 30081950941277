import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import 'react-toastify/dist/ReactToastify.css';
import {makeStyles} from "@material-ui/core/styles";
import Title from "../Title";
import {Colors} from "../../constants/colors";
import MotivatorsEvaluationRow from "./MotivatorsEvaluationRow";

const MotivatorsEvaluation = ({triedToFinish, motivators, setMotivators, checkedMotivators, setCheckedMotivators}) => {

    const height = window.innerHeight
    const classes = useStyles({height})
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false)
    const [motivatorsCheckedCount, setMotivatorsCheckedCount] = useState(0)
    let motivatorsExistCount = 0

    motivators.forEach((item) => {
        if (item.secondColumn === "tak") {
            motivatorsExistCount += 1
        }
    })

    useEffect(() => {
        setMotivatorsCheckedCount(motivatorsExistCount)
    },[])


    const handleChange = (index, item, value, at) => {
        setIsLoading(true)
        let newItem = Object.assign(item)
        newItem[at] = value

        let newMotivators = [...motivators]
        newMotivators.splice(index, 1, newItem)
        setMotivators(newMotivators)
        setIsLoading(false)
        // splice changes both newMotivators and motivators, so setMotivators do not refresh page and I'm doing it in above line
    };

    const handleCheck = (index) => {

        const checkedIndex = checkedMotivators.indexOf(index);
        let newChecked = [];

        if (checkedIndex === -1) {
            newChecked = newChecked.concat(checkedMotivators, index);
        } else {
            newChecked = newChecked.concat(
                checkedMotivators.slice(0, checkedIndex),
                checkedMotivators.slice(checkedIndex + 1),
            );
        }
        setCheckedMotivators(newChecked);
    }

    return (
        <div className={classes.container}>
            <Title title={"Które z motywatorów są stosowane w placówce?"}/>
            <div className={classes.subheader}>
                Wśród wymienionych motywatorów zaznacz 5, które z Twojej perspektywy najlepiej sprawdzają się w Twojej organizacji.
                Następnie uszereguj 5 wybranych motywatorów według ich skuteczności, wybierając w okienkach cyfry od 1 do 5,
                gdzie 1 oznacza najbardziej motywujący czynnik w Twoim przypadku, a 5 najmniej motywujący.
            </div>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Motywator</TableCell>
                            <TableCell align={"center"}>Czy jest stosowany?</TableCell>
                            <TableCell align={"center"}>Czy jest skuteczny?</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {motivators.map((item, index) => {
                            let itemChecked = checkedMotivators.indexOf(index) !== -1
                            return (
                                <MotivatorsEvaluationRow
                                    key={index}
                                    motivator={item}
                                    even={index % 2 === 0}
                                    checked={itemChecked}
                                    onCheck={() => {handleCheck(index); handleChange(index, item, !itemChecked, "exist")}}
                                    onOpinionChange={(value) => handleChange(index, item, value, "opinion")}
                                    triedToFinish={triedToFinish}
                                    motivatorsCheckedCount={motivatorsCheckedCount}
                                    setMotivatorsCheckedCount={setMotivatorsCheckedCount}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    container: props => ({
        maxHeight: props.height !== null ? props.height * 0.75 : 500,
        marginTop: 100,
        marginBottom: 150,
    }),
    tableContainer: props => ({
        maxHeight: props.height !== null ? props.height * 0.70 : 500,
        marginTop: 30,
        [theme.breakpoints.down(700)]: {
            display: "none",
        },
    }),
    subheader: {
        fontSize: 18,
        marginBottom: 5,
        paddingTop: 30,
        color: Colors.ALMOST_BLACK,
    },
}));

export default MotivatorsEvaluation;