import React from 'react';
import styled from "styled-components";
import {Colors} from "../constants/colors";

const LeftTurquoiseContainer = ({children}) => {

    return (
        <Container>{children}</Container>
    );
};

const Container = styled.div`
  display: flex;
  background-color: ${Colors.TURQUOISE};
  flex: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export default LeftTurquoiseContainer;