import React from 'react';
import {Colors} from "../../constants/colors";
import 'react-toastify/dist/ReactToastify.css';
import {makeStyles} from "@material-ui/core/styles";
import EvaluationQuestionTile from "../../components/evaluation/EvaluationQuestionTile";

const SmallScreenEvaluation = ({buttons, questions, answers, triedToFinish}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.answerScale}>
                <div className={classes.answerScaleHeader}>Skala oceniania</div>
                <div className={classes.answerScaleItem}>1 - Zupełnie się nie zgadzam</div>
                <div className={classes.answerScaleItem}>2 - Raczej się nie zgadzam</div>
                <div className={classes.answerScaleItem}>3 - Trudno powiedzieć</div>
                <div className={classes.answerScaleItem}>4 - Raczej się zgadzam</div>
                <div className={classes.answerScaleItem}>5 - W pełni się zgadzam</div>
            </div>
            {buttons}
            {questions.map((item, index) => (
                <EvaluationQuestionTile
                    key={index + "s"}
                    question={item.name}
                    answer={answers.get(index)}
                    invertedScale={item.invertedScale}
                    handleChangeAnswer={(value) => answers.set(index, value)}
                    even={index % 2 === 0}
                    triedToFinish={triedToFinish}
                />
            ))}
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up(700)]: {
            display: "none",
        },
    },
    answerScale: {
        padding: 18,
    },
    answerScaleHeader: {
        fontSize: 18,
        color: Colors.GREEN,
        marginBottom: 5,
    },
    answerScaleItem: {
        marginBottom: 3,
    },
}));

export default SmallScreenEvaluation;