import React from "react";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import {Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const IncompletePointWithLine = ({title, color=Colors.LIGHTER_GREY}) => {

    const classes = useStyles()

    return (
        <PointWithLine>
            <div className={classes.lineIncomplete}/>
            <Tooltip title={title} placement="top" arrow>
                <div className={classes.pointWithLabelBigScreen}>
                    <PointIncompleteContainer>
                        <PointIncomplete color={color}/>
                    </PointIncompleteContainer>
                    <div className={classes.itemLabel}>{title}</div>
                </div>
            </Tooltip>
            <div className={classes.pointWithLabelSmallScreen}>
                <PointIncompleteContainer>
                    <PointIncomplete color={color}/>
                </PointIncompleteContainer>
                <div className={classes.itemLabel}>{title}</div>
            </div>
        </PointWithLine>
    );
};

const useStyles = makeStyles(theme => ({
    lineIncomplete: {
        width: 60,
        height: 5,
        backgroundColor: Colors.LIGHTER_GREY,
        [theme.breakpoints.down(800)]: {
            display: "none",
        }
    },
    pointWithLabelBigScreen: {
        display: "flex",
        justifyContent: "row",
        alignItems: "center",
        [theme.breakpoints.down(800)]: {
            display: "none",
        }
    },
    pointWithLabelSmallScreen: {
        display: "flex",
        justifyContent: "row",
        alignItems: "center",
        [theme.breakpoints.up(800)]: {
            display: "none",
        }
    },
    itemLabel: {
        marginLeft: 17,
        [theme.breakpoints.up(800)]: {
            display: "none",
        },
    },
}));

const PointWithLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PointIncompleteContainer = styled.div`
  padding: 17px 0;
`;

const PointIncomplete = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background-color: ${params => params.color};
`;

export default IncompletePointWithLine;