import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";
import {formatNumberWithSpaces} from "../../services/NumberService";

const Result4TableRow = ({row}) => {

    let bolded = row.type === "K"
    const classes = useStyles({bolded})

    return (
        <React.Fragment>
            <TableRow className={classes.row}>
                <TableCell className={classes.cell}>{row.name}</TableCell>
                <TableCell className={classes.cell}>{formatNumberWithSpaces(row.value)}</TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    cell: props => ({
        fontWeight: props.bolded ? "bold" : "normal",
        backgroundColor: props.bolded ? Colors.VERY_LIGHT_GREY : "white",
    }),
    row: {
        [`@media print`]: {
            pageBreakInside: "avoid",
        }
    }
}));

export default Result4TableRow;