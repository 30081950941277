import {Dialog} from "@material-ui/core";
import React from "react";
import {Colors} from "../../../constants/colors";
import DialogTitle from "../../DialogTitle";
import DialogButtonsContainer from "../../DialogButtonsContainer";
import DataField from "../../DataField";
import OnlyIconButton from "../../buttons/OnlyIconButton";
import {IoClose} from "react-icons/all";
import {makeStyles} from "@material-ui/core/styles";
import Logo from "../../Logo";

const ViewCompanyDialog = ({open, setOpen, company}) => {

    const classes = useStyles()

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'md'} fullWidth={true}>
            <DialogTitle title={"Dane firmy"} />
            <div className={classes.container}>
                <Logo logoUrl={company.logoUrl} title={"Logo"}/>
                <DataField title={"Nazwa"} value={company.name}/>
                <DataField title={"Email"} value={company.email}/>
                <DataField title={"Nr telefonu"} value={company.phoneNumber}/>
                <DataField title={"Opis"} value={company.description}/>
                <DataField
                    title={"Widoczność w bazie"}
                    value={"Firma " + (company.agreementForDataSharing ? "" : "nie ")
                    + "pozwoliła na udostępnienie swoich danych w bazie podmiotów."}
                />
            </div>
            <DialogTitle title={"Dane właściciela firmy"} />
            <div className={classes.container}>
                <DataField title={"Imię i nazwisko"} value={company.employerName}/>
                <DataField title={"Email"} value={company.employerEmail}/>
                <DialogButtonsContainer onlySmallScreen={true}>
                    <OnlyIconButton
                        icon={<IoClose color={Colors.WHITE} size={30}/>}
                        onClick={() => setOpen(false)}
                        backgroundColor={Colors.TURQUOISE}
                    />
                </DialogButtonsContainer>
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "left",
        justifyContent: "center",
        width: "90%",
        padding: "20px 5%",
        marginBottom: "5%",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            padding: "20px 2%",
        }
    }
}));

export default ViewCompanyDialog;