import {getEvaluationStatus} from "./EvaluationService";

export const convertWorker = (worker) => {
    worker.status = getEvaluationStatus(worker)
    return worker;
};

export const updateName = (workers, workerEditDTO) => {
    workers.forEach( (w) => {
        if (w.id === workerEditDTO.id) {
            w.name = workerEditDTO.name + ' ' + workerEditDTO.surname
        }
    })
    return workers;
}

export const convertPost = (post) => {
    return {
        id: post.id,
        author: post.author.name,
        comments: post.comments,
        content: post.content,
        creationDate: post.creationDate,
        title: post.title,
        version: post.version
    }
}