import React, {useEffect, useState} from 'react';
import {checkIsUserAuthorized, getRoleDependentLink} from "../../services/LinksService";
import {makeStyles} from "@material-ui/core/styles";
import FinancesResultTile from "../../components/financesResult/FinancesResultTile";
import {isFinancialAnalysisFinishedUsingGET} from "../../swagger/vue-api-client";
import {CircularProgress} from "@material-ui/core";
import {Colors} from "../../constants/colors";

const FinancesResult = ({history}) => {

    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState("")

    useEffect(() => {
        setIsLoading(true)
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((response) => {
                return isFinancialAnalysisFinishedUsingGET()
            })
            .then((res) => {
                setMessage(res.data ? "" : "Wyniki analizy finansowej są dostępne dopiero po uzupełnieniu danych. " +
                    "Jeśli wszystkie dane zostały już uzupełnione i zapisane, spróbuj ponownie za ok. minutę.")
                setIsLoading(false)
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false)
            })
    }, [history])

    if (isLoading)
        return <div className={classes.loadingContainer}><CircularProgress/></div>

    if (message.length > 0)
        return <div className={classes.loadingContainer}>{message}</div>

    return (
        <div className={classes.items}>
            <FinancesResultTile
                label={"Wykaz kosztów stanowiskowych"}
                subtitle={"koszty"}
                onClick={() => history.push(getRoleDependentLink("/wyniki1"))}
            />
            <FinancesResultTile
                label={"Wykaz kosztów organizacyjnych"}
                subtitle={"koszty"}
                onClick={() => history.push(getRoleDependentLink("/wyniki2"))}
            />
            <FinancesResultTile
                label={"Korzyści stanowiskowe"}
                subtitle={"korzyści"}
                onClick={() => history.push(getRoleDependentLink("/wyniki3"))}
            />
            <FinancesResultTile
                label={"Korzyści organizacyjne"}
                subtitle={"korzyści"}
                onClick={() => history.push(getRoleDependentLink("/wyniki4"))}
            />
            <FinancesResultTile
                label={"Wynik analizy - zestawienie robocze"}
                subtitle={"podsumowanie"}
                onClick={() => history.push(getRoleDependentLink("/wyniki5"))}
            />
            <FinancesResultTile
                label={"Wynik analizy - ranking"}
                subtitle={"podsumowanie"}
                onClick={() => history.push(getRoleDependentLink("/wyniki6"))}
            />
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    items: {
        display: "grid",
        marginTop: "4%",
        gridTemplateColumns: "1fr 1fr 1fr",
        [theme.breakpoints.down(1100)]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down(700)]: {
            gridTemplateColumns: "1fr",
        }
    },
    loadingContainer: {
        display: "flex",
        flex: 1,
        minHeight: 200,
        justifyContent: "center",
        marginTop: "10%",
        fontSize: 18,
        color: Colors.GREY,
    },
}));

export default FinancesResult;