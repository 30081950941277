import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React, {useContext, useState} from "react";
import {Colors} from "../../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import OnlyIconButton from "../../buttons/OnlyIconButton";
import DeleteCompanyDialog from "./DeleteCompanyDialog";
import {AdminContext} from "../../../contexts/AdminContext";
import styled from "styled-components";
import EditCompanyDialog from "./EditCompanyDialog";
import ViewCompanyDialog from "./ViewCompanyDialog";
import CompanyExcelFilesDialog from "./CompanyExcelFilesDialog";
import {AiOutlineEye, AiOutlineEyeInvisible, FiInfo} from "react-icons/all";
import {FiEdit, FiFile} from "react-icons/fi";
import {AiOutlineDelete} from "react-icons/ai";
import TurquoiseCheckbox from "../../TurquoiseCheckbox";
import PublicationDialog from "./PublicationDialog";


const useStyles = makeStyles(theme => ({
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: Colors.PASTEL_TURQUOISE
        }
    },
    selected: {},
    checkbox: {
        "&checked, &checked:hover": {
            backgroundColor: Colors.BLUE
        }
    },
    checked: {},
}));


const CompanyTableRow = ({selectable, row, index, selected, setSelected}) => {

    const adminContext = useContext(AdminContext)

    const [deleteCompany, setDeleteCompany] = useState(false);
    const [viewCompany, setViewCompany] = useState(false);
    const [editCompany, setEditCompany] = useState(false);
    const [publication, setPublication] = useState(false);
    const [companyExcelFiles, setCompanyExcelFiles] = useState(false);

    const isSelected = (company) => {
        return selected.indexOf(company) !== -1;
    }

    const handleClick = (company) => {
        const selectedIndex = selected.indexOf(company);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, company);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const isItemSelected = isSelected(row.id);
    const labelId = `enhanced-table-checkbox-${index}`;
    const classes = useStyles();

    const getVisibilityInPublicBaseButton = () => {

        if (!row.agreementForDataSharing) {
            return (
                <OnlyIconButton
                    icon={<AiOutlineEyeInvisible color={Colors.LIGHT_GREY} size={25}/>}
                    onClick={() => setPublication(true)}
                    label={"Dane firmy"}
                />
            )
        } else if (row.publishedForPublicAccess) {
            return (
                <OnlyIconButton
                    icon={<AiOutlineEye color={Colors.TURQUOISE} size={25}/>}
                    onClick={() => setPublication(true)}
                    label={"Dane firmy"}
                />
            )
        } else {
            return (
                <OnlyIconButton
                    icon={<AiOutlineEyeInvisible color={Colors.TURQUOISE} size={25}/>}
                    onClick={() => setPublication(true)}
                    label={"Dane firmy"}
                />
            )
        }
    }

    return (
        <React.Fragment>
            <TableRow
                hover={selectable}
                onClick={() => handleClick(row.id)}
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={row.id}
                selected={selectable ? isItemSelected : false}
                classes={{selected: classes.selected}}
                className={classes.tableRow}
            >
                {selectable ? (<TableCell padding="checkbox"><TurquoiseCheckbox checked={isItemSelected}/></TableCell>) : null}
                <TableCell component="th" id={labelId} scope="row" padding="default">{row.name}</TableCell>
                <TableCell align="left">{row.phoneNumber}</TableCell>
                <TableCell align="left">{row.email}</TableCell>
                <TableCell><ButtonsContainer>{getVisibilityInPublicBaseButton()}</ButtonsContainer></TableCell>
                <TableCell>
                    <ButtonsContainer>
                        <OnlyIconButton
                            icon={<FiInfo color={Colors.GREEN} size={25}/>}
                            onClick={() => setViewCompany(true)}
                            label={"Dane firmy"}
                        />
                        <OnlyIconButton
                            icon={<FiEdit color={Colors.MEDIUM_GREEN} size={25}/>}
                            onClick={() => setEditCompany(true)}
                            label={"Edytuj dane firmy"}
                        />
                        {!selectable ? (
                        <OnlyIconButton
                            icon={<FiFile color={Colors.DARK_GREEN} size={25}/>}
                            onClick={() => setCompanyExcelFiles(true)}
                            label={"Pliki excel firmy"}
                        />) : null}
                    </ButtonsContainer>
                </TableCell>
                <TableCell>
                    <ButtonsContainer>
                        <OnlyIconButton
                            icon={<AiOutlineDelete color={Colors.RED} size={25}/>}
                            onClick={() => setDeleteCompany(true)}
                        />
                    </ButtonsContainer>
                </TableCell>
            </TableRow>
            <ViewCompanyDialog
                open={viewCompany}
                setOpen={setViewCompany}
                company={row}
                setCompanies={selectable ? adminContext.setCompaniesToAccept : adminContext.setCompanies}
            />
            <EditCompanyDialog
                open={editCompany}
                setOpen={setEditCompany}
                company={row}
                setCompanies={selectable ? adminContext.setCompaniesToAccept : adminContext.setCompanies}
            />
            <CompanyExcelFilesDialog
                open={companyExcelFiles}
                setOpen={setCompanyExcelFiles}
                company={row}
                setCompanies={selectable ? adminContext.setCompaniesToAccept : adminContext.setCompanies}
            />
            <DeleteCompanyDialog
                open={deleteCompany}
                setOpen={setDeleteCompany}
                company={row}
                setCompanies={selectable ? adminContext.setCompaniesToAccept : adminContext.setCompanies}
            />
            <PublicationDialog
                open={publication}
                setOpen={setPublication}
                company={row}
                setCompanies={selectable ? adminContext.setCompaniesToAccept : adminContext.setCompanies}
            />
        </React.Fragment>
    );
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default CompanyTableRow;