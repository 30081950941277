import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import NumberInput from "./NumberInput";
import FloatNumberInput from "./FloatNumberInput";
import ValueTypeSelect from "./ValueTypeSelect";
import TurquoiseSelect from "../TurquoiseSelect";
import {professions} from "../../constants/professions";

const WorkersInProgramTableRow = ({worker, setWorker, dataSource}) => {

    const classes = useStyles()

    const handleWorkerChange = (value, at) => {
        let newWorker = Object.assign(worker)
        newWorker[at] = value
        setWorker(newWorker)
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell className={classes.smallCell}>{worker.fullName}</TableCell>
                <TableCell className={classes.smallCell}>
                    <TurquoiseSelect
                        value={worker.position}
                        setValue={(value) => handleWorkerChange(value, "position")}
                        label={"Stanowisko"}
                        noValueLabel={"Wybierz stanowisko"}
                        possibilities={professions}
                    />
                </TableCell>
                <TableCell className={classes.smallCell}>
                    <FloatNumberInput
                        value={worker.engagementDimension}
                        setValue={(value) => handleWorkerChange(value, "engagementDimension")}
                        label={""}
                    />
                </TableCell>
                {(dataSource === "nie - wyłącznie dane własne" || dataSource === "nie - dane mieszane") && (
                    <React.Fragment>
                        <TableCell className={classes.bigCell}>
                            {dataSource === "nie - dane mieszane" &&
                                <ValueTypeSelect
                                    value={worker.salaryOption}
                                    setValue={(value) => handleWorkerChange(value, "salaryOption")}
                                />
                            }
                            <NumberInput
                                value={worker.salary}
                                setValue={(value) => handleWorkerChange(value, "salary")}
                                label={""}
                                disabled={worker.salaryOption !== "wartość podana samodzielnie"}
                            />
                        </TableCell>
                        <TableCell className={classes.bigCell}>
                            {dataSource === "nie - dane mieszane" &&
                                <ValueTypeSelect
                                    value={worker.workshopTrainingCostOption}
                                    setValue={(value) => handleWorkerChange(value, "workshopTrainingCostOption")}
                                />
                            }
                            <NumberInput
                                value={worker.workshopTrainingCost}
                                setValue={(value) => handleWorkerChange(value, "workshopTrainingCost")}
                                label={""}
                                disabled={worker.workshopTrainingCostOption !== "wartość podana samodzielnie"}
                            />
                        </TableCell>
                        <TableCell className={classes.bigCell}>
                            {dataSource === "nie - dane mieszane" &&
                                <ValueTypeSelect
                                    value={worker.advisoryTrainingCostOption}
                                    setValue={(value) => handleWorkerChange(value, "advisoryTrainingCostOption")}
                                />
                            }
                            <NumberInput
                                value={worker.advisoryTrainingCost}
                                setValue={(value) => handleWorkerChange(value, "advisoryTrainingCost")}
                                label={""}
                                disabled={worker.advisoryTrainingCostOption !== "wartość podana samodzielnie"}
                            />
                        </TableCell>
                        <TableCell className={classes.bigCell}>
                            {dataSource === "nie - dane mieszane" &&
                                <ValueTypeSelect
                                    value={worker.practicalTrainingCostOption}
                                    setValue={(value) => handleWorkerChange(value, "practicalTrainingCostOption")}
                                />
                            }
                            <NumberInput
                                value={worker.practicalTrainingCost}
                                setValue={(value) => handleWorkerChange(value, "practicalTrainingCost")}
                                label={""}
                                disabled={worker.practicalTrainingCostOption !== "wartość podana samodzielnie"}
                            />
                        </TableCell>
                    </React.Fragment>
                    )}
            </TableRow>
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    smallCell: {
        minWidth: 140,
        fontSize: 15,
    },
    bigCell: {
        minWidth: 175,
        fontSize: 15,
    }
}));

export default WorkersInProgramTableRow;