import React, {useContext, useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import WorkerTableRow from "./WorkerTableRow";
import {Container, TableFooter} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ButtonWithIcon from "../../buttons/ButtonWithIcon";
import WorkerTablePagination from "./WorkerTablePagination";
import WorkerTableHeading from "./WorkerTableHeading";
import AddWorkerDialog from "./AddWorkerDialog";
import {EmployerContext} from "../../../contexts/EmployerContext";
import {getComparator, stableSort} from "../../../services/SortService";
import TableRow from "@material-ui/core/TableRow";
import TitleWithButton from "../../TitleWithButton";
import {ToastContainer} from "react-toastify";

const WorkerTable = () => {

    const employerContext = useContext(EmployerContext);

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [addWorker, setAddWorker] = useState(false);

    return (
        <Container>
            <TitleWithButton title={"Pracownicy"} button={
                <ButtonWithIcon
                    icon={<AddIcon/>}
                    onClick={() => setAddWorker(true)}
                    label={"Dodaj nowego pracownika"}
                />
            }/>
            <TableContainer>
                <Table>
                    <WorkerTableHeading
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                    />
                    <TableBody>
                        {stableSort(employerContext.workers, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) =>
                                <WorkerTableRow key={row.id} worker={row} number={page * rowsPerPage + index+1}/>
                            )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <WorkerTablePagination
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <AddWorkerDialog open={addWorker} setOpen={setAddWorker}/>
            <ToastContainer/>
        </Container>
    );
};

export default WorkerTable;
