import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Title from "../Title";
import Equipment from "./Equipment";
import Structure from "./Structure";
import Communication from "./Communication";
import IndustryKnowledge from "./IndystryKnowledge";
import Motivators from "./Motivators";

const OrganisationRecommendations = ({result}) => {

    const classes = useStyles()

    if (result === null)
        return null

    return (
        <div className={classes.container}>
            <Title title={"Ocena organizacyjna"}/>
            <Equipment result={result}/>
            <Structure result={result}/>
            <Motivators result={result}/>
            <Communication result={result}/>
            <IndustryKnowledge result={result}/>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 150,
    },
    equipment: {
        display: "flex",
        justifyContent: "space-between",
    },
}));

export default OrganisationRecommendations;