import React, {useState} from "react";
import SmallScreenEvaluatorFormRow from "./SmallScreenEvaluatorFormRow";
import BigScreenEvaluatorFormRow from "./BigScreenEvaluatorFormRow";

const EvaluatorsFormRow = ({label, evaluatorLabel="", evaluation, setEvaluation, workerName, workerId, enabledCompetences}) => {

    const [checked, setChecked] = useState(evaluation.enabled)
    const [evaluatorId, setEvaluatorId] = useState(evaluation.evaluatorId)
    const [competences, setCompetences] = useState([]);

    const handleEnabledChange = () => {
        let newEvaluation = Object.assign(evaluation);
        newEvaluation.enabled = !evaluation.enabled;
        setEvaluation(newEvaluation);
        setChecked(newEvaluation.enabled);
    }

    const handleEvaluatorChange = (id) => {
        let newEvaluation = Object.assign(evaluation);
        newEvaluation.evaluatorId = id;
        setEvaluation(newEvaluation);
        setEvaluatorId(id);
    }

    const handleCompetencesChange = (competence) => {
        const currentIndex = competences.indexOf(competence);
        const newCompetences = [...competences];

        if (currentIndex === -1) {
            newCompetences.push(competence);
        } else {
            newCompetences.splice(currentIndex, 1);
        }
        setCompetences(newCompetences);
        let newEvaluation = Object.assign(evaluation);
        newEvaluation.competences = newCompetences;
        setEvaluation(newEvaluation);
    };

    return (
        <React.Fragment>
            <BigScreenEvaluatorFormRow
                label={label}
                evaluatorLabel={evaluatorLabel}
                competences={competences}
                workerName={workerName}
                workerId={workerId}
                checked={checked}
                evaluatorId={evaluatorId}
                enabledCompetences={enabledCompetences}
                handleCompetencesChange={handleCompetencesChange}
                handleEnabledChange={handleEnabledChange}
                handleEvaluatorChange={handleEvaluatorChange}
            />
            <SmallScreenEvaluatorFormRow
                label={label}
                evaluatorLabel={evaluatorLabel}
                competences={competences}
                workerName={workerName}
                workerId={workerId}
                checked={checked}
                evaluatorId={evaluatorId}
                handleCompetencesChange={handleCompetencesChange}
                handleEnabledChange={handleEnabledChange}
                handleEvaluatorChange={handleEvaluatorChange}
            />
        </React.Fragment>
    );
};

export default EvaluatorsFormRow;