import React from "react";
import trademarks from "../assets/trademarks.png";
import {Colors} from "../constants/colors";
import {makeStyles} from "@material-ui/core/styles";

const Footer = ({onlySmallScreen=false}) => {

    const classes = useStyles({onlySmallScreen})

    return (
        <div className={classes.container}>
            <img className={classes.trademarks} src={trademarks} alt={"trademarks"}/>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: props => ({
        display: "flex",
        justifyContent: "center",
        backgroundColor: Colors.VERY_LIGHT_GREY,
        padding: "0.5%",
        [theme.breakpoints.up("lg")]: {
            display: props.onlySmallScreen ? "none" : "flex",
        },
        [`@media print`]: {
            display: "none"
        }
    }),
    trademarks: {
        display: "block",
        width: "100%",
        maxWidth: 400,
    },
}));

export default Footer;