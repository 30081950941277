import React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {TableCell, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import {makeStyles} from "@material-ui/core/styles";
import Result2TableRow from "./Result2TableRow";

const Result2Table = ({result}) => {

    const classes = useStyles()

    return (
        <div>
            <div className={classes.container}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headCell}>Wymogi organizacji</TableCell>
                            <TableCell className={classes.headCell}>Opis</TableCell>
                            <TableCell className={classes.headCell}>Koszt [PLN]</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.map((row, index) => <Result2TableRow key={index} row={row}/>)}
                    </TableBody>
                </Table>
            </div>
            <div className={classes.printContainer}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.headCell}>Wymogi organizacji</TableCell>
                            <TableCell className={classes.headCell}>Opis</TableCell>
                            <TableCell className={classes.headCell}>Koszt [PLN]</TableCell>
                        </TableRow>
                        {result.map((row, index) => <Result2TableRow key={index} row={row}/>)}
                    </TableBody>
                </Table>
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container:{
        display: "inline",
        [`@media print`]: {
            display: "none",
        }
    },
    printContainer: {
        display: "none",
        [`@media print`]: {
            display: "inline",
            overflow: "visible",
        }
    },
    verticalText: {
        writingMode: "vertical-lr",
        textOrientation: "mixed",
        textAlign: "center",
        [`@media print`]: {
            fontWeight: "bold"
        }
    },
    headCell: {
        textAlign: "center",
        [`@media print`]: {
            fontWeight: "bold"
        }
    }
}));

export default Result2Table;