import React from 'react';
import Subheader from "../Subheader";
import {makeStyles} from "@material-ui/core/styles";
import ImplementersInProgramTable from "./ImplementersInProgramTable";

const ImplementersInProgram = ({workers, setWorkers, dataSource}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Subheader title={"Wykaz pracowników zaangażowanych we wdrożenie procesu reskillingu"}/>
            <ImplementersInProgramTable workers={workers} setWorkers={setWorkers} dataSource={dataSource}/>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 100,
    },
}));

export default ImplementersInProgram;