import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const Logo = ({logoUrl, title}) => {
    const classes = useStyles();
    return (
        <div className={classes.logoContainer}>
        {logoUrl !== "" ?
            <div>
                {title.length > 0 ? (<h3 className={classes.text}>{title}</h3>) : null}
                <img className={classes.img} src={logoUrl} height="200" alt={""}/>
            </div>:
            <h2 className={classes.fullText}>Brak logo</h2>}
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    fullText: {
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        display: "flex",
    },
    text: {
        overflow: "hidden",
        width: "20%",
        display: "block",
        float: "left"
    },
    logoContainer: {
        margin: "0.8em",
        paddingLeft: "1em",
        width: "90%"
    },
    img: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "80%"
    }
}));

export default Logo;
