import React from 'react';
import styled from "styled-components";
import {Colors} from "../constants/colors";
import TurquoiseCheckbox from "./TurquoiseCheckbox";

const CheckboxField = ({checked, onChange, label, disabled}) => {
    return (
        <Container>
            <TurquoiseCheckbox checked={checked} onChange={onChange} disabled={disabled} />
            {label}
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.WHITE};
  margin: 0.8em;
  width: 90%;
`;

export default CheckboxField;