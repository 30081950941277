const getTwoCipherLabel = (number) => {
    if (number < 10) {
        number = "0" + number;
    }
    return number;
}

// date.getMonth() index starts from 0, so it's required to add 1
export const getDateAndHourLabel = (date) => {
    return date.getDate() + "."
        + getTwoCipherLabel(date.getMonth() + 1) + "."
        + date.getFullYear() + ", "
        + date.getHours() + ":"
        + getTwoCipherLabel(date.getMinutes());
}

export const getDateLabel = (date) => {
    return date.getDate() + "."
        + getTwoCipherLabel(date.getMonth() + 1) + "."
        + date.getFullYear();
}