import {TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import OrganisationQuestion from "./OrganisationQuestion";
import Table from "@material-ui/core/Table";

const Section = ({name, questions, answers, triedToFinish, motivators, motivatorTitle}) => {
    const classes = useStyles()

    return (
        <Table stickyHeader>
            <TableHead>
                <TableRow classes={{root: classes.row}}>
                    <TableCell classes={{root: classes.cellRoot}}>{name}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {questions !== null ? questions.map((item, index) => (
                    <OrganisationQuestion
                        key={index}
                        answers={answers}
                        triedToFinish={triedToFinish}
                        data = {item}
                        motivators = {motivators}
                        motivatorTitle={motivatorTitle}
                    />)):
                    null
                }
            </TableBody>
        </Table>
    )
}

const useStyles = makeStyles(theme => ({
    row: {
        backgroundColor: Colors.VERY_LIGHT_GREY,
    },
    cellRoot: {
        fontSize: 15,
        border: "none"
    },

}));

export default Section;