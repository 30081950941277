/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    Api Documentation
 ==========================================================*/
/**
 * getAllPublishedCompanies
 * request: getAllPublishedCompaniesUsingGET
 * url: getAllPublishedCompaniesUsingGETURL
 * method: getAllPublishedCompaniesUsingGET_TYPE
 * raw_url: getAllPublishedCompaniesUsingGET_RAW_URL
 * @param onlyWithLogo - onlyWithLogo
 */
export const getAllPublishedCompaniesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/all-public-companies'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['onlyWithLogo'] !== undefined) {
    queryParameters['onlyWithLogo'] = parameters['onlyWithLogo']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllPublishedCompaniesUsingGET_RAW_URL = function() {
  return '/company/all-public-companies'
}
export const getAllPublishedCompaniesUsingGET_TYPE = function() {
  return 'get'
}
export const getAllPublishedCompaniesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/all-public-companies'
  if (parameters['onlyWithLogo'] !== undefined) {
    queryParameters['onlyWithLogo'] = parameters['onlyWithLogo']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editCompany
 * request: editCompanyUsingPUT
 * url: editCompanyUsingPUTURL
 * method: editCompanyUsingPUT_TYPE
 * raw_url: editCompanyUsingPUT_RAW_URL
 * @param editCompanyRequest - editCompanyRequest
 */
export const editCompanyUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['editCompanyRequest'] !== undefined) {
    body = parameters['editCompanyRequest']
  }
  if (parameters['editCompanyRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: editCompanyRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const editCompanyUsingPUT_RAW_URL = function() {
  return '/company/edit'
}
export const editCompanyUsingPUT_TYPE = function() {
  return 'put'
}
export const editCompanyUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/edit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * employerEditCompany
 * request: employerEditCompanyUsingPUT
 * url: employerEditCompanyUsingPUTURL
 * method: employerEditCompanyUsingPUT_TYPE
 * raw_url: employerEditCompanyUsingPUT_RAW_URL
 * @param editCompanyRequest - editCompanyRequest
 */
export const employerEditCompanyUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/edit/employer'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['editCompanyRequest'] !== undefined) {
    body = parameters['editCompanyRequest']
  }
  if (parameters['editCompanyRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: editCompanyRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const employerEditCompanyUsingPUT_RAW_URL = function() {
  return '/company/edit/employer'
}
export const employerEditCompanyUsingPUT_TYPE = function() {
  return 'put'
}
export const employerEditCompanyUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/edit/employer'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * companyExist
 * request: companyExistUsingGET
 * url: companyExistUsingGETURL
 * method: companyExistUsingGET_TYPE
 * raw_url: companyExistUsingGET_RAW_URL
 * @param companyName - companyName
 */
export const companyExistUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/exists'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['companyName'] !== undefined) {
    queryParameters['companyName'] = parameters['companyName']
  }
  if (parameters['companyName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyName'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const companyExistUsingGET_RAW_URL = function() {
  return '/company/exists'
}
export const companyExistUsingGET_TYPE = function() {
  return 'get'
}
export const companyExistUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/exists'
  if (parameters['companyName'] !== undefined) {
    queryParameters['companyName'] = parameters['companyName']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCompany
 * request: getCompanyUsingGET
 * url: getCompanyUsingGETURL
 * method: getCompanyUsingGET_TYPE
 * raw_url: getCompanyUsingGET_RAW_URL
 * @param companyId - companyId
 */
export const getCompanyUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/get/{companyId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters['companyId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCompanyUsingGET_RAW_URL = function() {
  return '/company/get/{companyId}'
}
export const getCompanyUsingGET_TYPE = function() {
  return 'get'
}
export const getCompanyUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/get/{companyId}'
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * registerCompany
 * request: registerCompanyUsingPOST
 * url: registerCompanyUsingPOSTURL
 * method: registerCompanyUsingPOST_TYPE
 * raw_url: registerCompanyUsingPOST_RAW_URL
 * @param companyRegistrationDto - companyRegistrationDTO
 */
export const registerCompanyUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/register'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['companyRegistrationDto'] !== undefined) {
    body = parameters['companyRegistrationDto']
  }
  if (parameters['companyRegistrationDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyRegistrationDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const registerCompanyUsingPOST_RAW_URL = function() {
  return '/company/register'
}
export const registerCompanyUsingPOST_TYPE = function() {
  return 'post'
}
export const registerCompanyUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/register'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCompany
 * request: searchCompanyUsingPOST
 * url: searchCompanyUsingPOSTURL
 * method: searchCompanyUsingPOST_TYPE
 * raw_url: searchCompanyUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchCompanyUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCompanyUsingPOST_RAW_URL = function() {
  return '/company/search'
}
export const searchCompanyUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCompanyUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteCompany
 * request: deleteCompanyUsingDELETE
 * url: deleteCompanyUsingDELETEURL
 * method: deleteCompanyUsingDELETE_TYPE
 * raw_url: deleteCompanyUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteCompanyUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/company/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteCompanyUsingDELETE_RAW_URL = function() {
  return '/company/{id}'
}
export const deleteCompanyUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteCompanyUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/company/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllCompetences
 * request: getAllCompetencesUsingGET
 * url: getAllCompetencesUsingGETURL
 * method: getAllCompetencesUsingGET_TYPE
 * raw_url: getAllCompetencesUsingGET_RAW_URL
 */
export const getAllCompetencesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/competence/'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllCompetencesUsingGET_RAW_URL = function() {
  return '/competence/'
}
export const getAllCompetencesUsingGET_TYPE = function() {
  return 'get'
}
export const getAllCompetencesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/competence/'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createCompetence
 * request: createCompetenceUsingPOST
 * url: createCompetenceUsingPOSTURL
 * method: createCompetenceUsingPOST_TYPE
 * raw_url: createCompetenceUsingPOST_RAW_URL
 * @param competenceDto - competenceDTO
 */
export const createCompetenceUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/competence/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['competenceDto'] !== undefined) {
    body = parameters['competenceDto']
  }
  if (parameters['competenceDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: competenceDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createCompetenceUsingPOST_RAW_URL = function() {
  return '/competence/create'
}
export const createCompetenceUsingPOST_TYPE = function() {
  return 'post'
}
export const createCompetenceUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/competence/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createCompetenceType
 * request: createCompetenceTypeUsingPOST
 * url: createCompetenceTypeUsingPOSTURL
 * method: createCompetenceTypeUsingPOST_TYPE
 * raw_url: createCompetenceTypeUsingPOST_RAW_URL
 * @param competenceTypeDto - competenceTypeDTO
 */
export const createCompetenceTypeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/competence/type/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['competenceTypeDto'] !== undefined) {
    body = parameters['competenceTypeDto']
  }
  if (parameters['competenceTypeDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: competenceTypeDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createCompetenceTypeUsingPOST_RAW_URL = function() {
  return '/competence/type/create'
}
export const createCompetenceTypeUsingPOST_TYPE = function() {
  return 'post'
}
export const createCompetenceTypeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/competence/type/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDiplomaForUser
 * request: getDiplomaForUserUsingGET
 * url: getDiplomaForUserUsingGETURL
 * method: getDiplomaForUserUsingGET_TYPE
 * raw_url: getDiplomaForUserUsingGET_RAW_URL
 * @param elearningId - elearningId
 */
export const getDiplomaForUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/diploma/{elearningId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{elearningId}', `${parameters['elearningId']}`)
  if (parameters['elearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: elearningId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDiplomaForUserUsingGET_RAW_URL = function() {
  return '/diploma/{elearningId}'
}
export const getDiplomaForUserUsingGET_TYPE = function() {
  return 'get'
}
export const getDiplomaForUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/diploma/{elearningId}'
  path = path.replace('{elearningId}', `${parameters['elearningId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllSteps
 * request: getAllStepsUsingGET
 * url: getAllStepsUsingGETURL
 * method: getAllStepsUsingGET_TYPE
 * raw_url: getAllStepsUsingGET_RAW_URL
 * @param eLearningId - eLearningId
 */
export const getAllStepsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/allSteps/{eLearningId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters['eLearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eLearningId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllStepsUsingGET_RAW_URL = function() {
  return '/elearning/allSteps/{eLearningId}'
}
export const getAllStepsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllStepsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/allSteps/{eLearningId}'
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllStepsForEmployer
 * request: getAllStepsForEmployerUsingGET
 * url: getAllStepsForEmployerUsingGETURL
 * method: getAllStepsForEmployerUsingGET_TYPE
 * raw_url: getAllStepsForEmployerUsingGET_RAW_URL
 * @param eLearningId - eLearningId
 */
export const getAllStepsForEmployerUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/allStepsForEmployer/{eLearningId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters['eLearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eLearningId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllStepsForEmployerUsingGET_RAW_URL = function() {
  return '/elearning/allStepsForEmployer/{eLearningId}'
}
export const getAllStepsForEmployerUsingGET_TYPE = function() {
  return 'get'
}
export const getAllStepsForEmployerUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/allStepsForEmployer/{eLearningId}'
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * publicELearnings
 * request: publicELearningsUsingGET
 * url: publicELearningsUsingGETURL
 * method: publicELearningsUsingGET_TYPE
 * raw_url: publicELearningsUsingGET_RAW_URL
 */
export const publicELearningsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/all_public'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const publicELearningsUsingGET_RAW_URL = function() {
  return '/elearning/all_public'
}
export const publicELearningsUsingGET_TYPE = function() {
  return 'get'
}
export const publicELearningsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/all_public'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * assignedELearnings
 * request: assignedELearningsUsingGET
 * url: assignedELearningsUsingGETURL
 * method: assignedELearningsUsingGET_TYPE
 * raw_url: assignedELearningsUsingGET_RAW_URL
 */
export const assignedELearningsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/assigned'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const assignedELearningsUsingGET_RAW_URL = function() {
  return '/elearning/assigned'
}
export const assignedELearningsUsingGET_TYPE = function() {
  return 'get'
}
export const assignedELearningsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/assigned'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * assignedById
 * request: assignedByIdUsingGET
 * url: assignedByIdUsingGETURL
 * method: assignedByIdUsingGET_TYPE
 * raw_url: assignedByIdUsingGET_RAW_URL
 * @param eLearningId - eLearningId
 */
export const assignedByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/assigned/{eLearningId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters['eLearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eLearningId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const assignedByIdUsingGET_RAW_URL = function() {
  return '/elearning/assigned/{eLearningId}'
}
export const assignedByIdUsingGET_TYPE = function() {
  return 'get'
}
export const assignedByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/assigned/{eLearningId}'
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * finishELearning
 * request: finishELearningUsingPOST
 * url: finishELearningUsingPOSTURL
 * method: finishELearningUsingPOST_TYPE
 * raw_url: finishELearningUsingPOST_RAW_URL
 * @param eLearningId - eLearningId
 */
export const finishELearningUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/finished/{eLearningId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters['eLearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eLearningId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const finishELearningUsingPOST_RAW_URL = function() {
  return '/elearning/finished/{eLearningId}'
}
export const finishELearningUsingPOST_TYPE = function() {
  return 'post'
}
export const finishELearningUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/finished/{eLearningId}'
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * saveQuizResults
 * request: saveQuizResultsUsingPOST
 * url: saveQuizResultsUsingPOSTURL
 * method: saveQuizResultsUsingPOST_TYPE
 * raw_url: saveQuizResultsUsingPOST_RAW_URL
 * @param eLearningId - eLearningId
 * @param quizDto - quizDTO
 */
export const saveQuizResultsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/quizResults/{eLearningId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters['eLearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eLearningId'))
  }
  if (parameters['quizDto'] !== undefined) {
    body = parameters['quizDto']
  }
  if (parameters['quizDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: quizDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const saveQuizResultsUsingPOST_RAW_URL = function() {
  return '/elearning/quizResults/{eLearningId}'
}
export const saveQuizResultsUsingPOST_TYPE = function() {
  return 'post'
}
export const saveQuizResultsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/quizResults/{eLearningId}'
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * saveELearningSources
 * request: saveELearningSourcesUsingPOST
 * url: saveELearningSourcesUsingPOSTURL
 * method: saveELearningSourcesUsingPOST_TYPE
 * raw_url: saveELearningSourcesUsingPOST_RAW_URL
 * @param sources - sources
 */
export const saveELearningSourcesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/sources'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['sources'] !== undefined) {
    body = parameters['sources']
  }
  if (parameters['sources'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sources'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const saveELearningSourcesUsingPOST_RAW_URL = function() {
  return '/elearning/sources'
}
export const saveELearningSourcesUsingPOST_TYPE = function() {
  return 'post'
}
export const saveELearningSourcesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/sources'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getELearningSources
 * request: getELearningSourcesUsingGET
 * url: getELearningSourcesUsingGETURL
 * method: getELearningSourcesUsingGET_TYPE
 * raw_url: getELearningSourcesUsingGET_RAW_URL
 * @param companyId - companyId
 */
export const getELearningSourcesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/sources/{companyId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters['companyId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getELearningSourcesUsingGET_RAW_URL = function() {
  return '/elearning/sources/{companyId}'
}
export const getELearningSourcesUsingGET_TYPE = function() {
  return 'get'
}
export const getELearningSourcesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/sources/{companyId}'
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteELearningSource
 * request: deleteELearningSourceUsingDELETE
 * url: deleteELearningSourceUsingDELETEURL
 * method: deleteELearningSourceUsingDELETE_TYPE
 * raw_url: deleteELearningSourceUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteELearningSourceUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/sources/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteELearningSourceUsingDELETE_RAW_URL = function() {
  return '/elearning/sources/{id}'
}
export const deleteELearningSourceUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteELearningSourceUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/sources/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getELearningStep
 * request: getELearningStepUsingPUT
 * url: getELearningStepUsingPUTURL
 * method: getELearningStepUsingPUT_TYPE
 * raw_url: getELearningStepUsingPUT_RAW_URL
 * @param eLearningId - eLearningId
 * @param stepId - stepId
 */
export const getELearningStepUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/step/{eLearningId}/{stepId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters['eLearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eLearningId'))
  }
  path = path.replace('{stepId}', `${parameters['stepId']}`)
  if (parameters['stepId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: stepId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const getELearningStepUsingPUT_RAW_URL = function() {
  return '/elearning/step/{eLearningId}/{stepId}'
}
export const getELearningStepUsingPUT_TYPE = function() {
  return 'put'
}
export const getELearningStepUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/step/{eLearningId}/{stepId}'
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  path = path.replace('{stepId}', `${parameters['stepId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getElearningStepsNumber
 * request: getElearningStepsNumberUsingGET
 * url: getElearningStepsNumberUsingGETURL
 * method: getElearningStepsNumberUsingGET_TYPE
 * raw_url: getElearningStepsNumberUsingGET_RAW_URL
 * @param eLearningId - eLearningId
 */
export const getElearningStepsNumberUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/steps/{eLearningId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters['eLearningId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eLearningId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getElearningStepsNumberUsingGET_RAW_URL = function() {
  return '/elearning/steps/{eLearningId}'
}
export const getElearningStepsNumberUsingGET_TYPE = function() {
  return 'get'
}
export const getElearningStepsNumberUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/steps/{eLearningId}'
  path = path.replace('{eLearningId}', `${parameters['eLearningId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getElearning
 * request: getElearningUsingGET
 * url: getElearningUsingGETURL
 * method: getElearningUsingGET_TYPE
 * raw_url: getElearningUsingGET_RAW_URL
 * @param id - id
 */
export const getElearningUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/elearning/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getElearningUsingGET_RAW_URL = function() {
  return '/elearning/{id}'
}
export const getElearningUsingGET_TYPE = function() {
  return 'get'
}
export const getElearningUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/elearning/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * emailExist
 * request: emailExistUsingGET
 * url: emailExistUsingGETURL
 * method: emailExistUsingGET_TYPE
 * raw_url: emailExistUsingGET_RAW_URL
 * @param email - email
 */
export const emailExistUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/email-exists'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['email'] !== undefined) {
    queryParameters['email'] = parameters['email']
  }
  if (parameters['email'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: email'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const emailExistUsingGET_RAW_URL = function() {
  return '/email-exists'
}
export const emailExistUsingGET_TYPE = function() {
  return 'get'
}
export const emailExistUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/email-exists'
  if (parameters['email'] !== undefined) {
    queryParameters['email'] = parameters['email']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * activateEmployerAccount
 * request: activateEmployerAccountUsingPOST
 * url: activateEmployerAccountUsingPOSTURL
 * method: activateEmployerAccountUsingPOST_TYPE
 * raw_url: activateEmployerAccountUsingPOST_RAW_URL
 * @param userIdRequest - userIdRequest
 */
export const activateEmployerAccountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/employer/activate-account'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userIdRequest'] !== undefined) {
    body = parameters['userIdRequest']
  }
  if (parameters['userIdRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userIdRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const activateEmployerAccountUsingPOST_RAW_URL = function() {
  return '/employer/activate-account'
}
export const activateEmployerAccountUsingPOST_TYPE = function() {
  return 'post'
}
export const activateEmployerAccountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/employer/activate-account'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingGET
 * url: errorUsingGETURL
 * method: errorUsingGET_TYPE
 * raw_url: errorUsingGET_RAW_URL
 */
export const errorUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const errorUsingGET_RAW_URL = function() {
  return '/error'
}
export const errorUsingGET_TYPE = function() {
  return 'get'
}
export const errorUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingHEAD
 * url: errorUsingHEADURL
 * method: errorUsingHEAD_TYPE
 * raw_url: errorUsingHEAD_RAW_URL
 */
export const errorUsingHEAD = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('head', domain + path, body, queryParameters, form, config)
}
export const errorUsingHEAD_RAW_URL = function() {
  return '/error'
}
export const errorUsingHEAD_TYPE = function() {
  return 'head'
}
export const errorUsingHEADURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingPOST
 * url: errorUsingPOSTURL
 * method: errorUsingPOST_TYPE
 * raw_url: errorUsingPOST_RAW_URL
 */
export const errorUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const errorUsingPOST_RAW_URL = function() {
  return '/error'
}
export const errorUsingPOST_TYPE = function() {
  return 'post'
}
export const errorUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingPUT
 * url: errorUsingPUTURL
 * method: errorUsingPUT_TYPE
 * raw_url: errorUsingPUT_RAW_URL
 */
export const errorUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const errorUsingPUT_RAW_URL = function() {
  return '/error'
}
export const errorUsingPUT_TYPE = function() {
  return 'put'
}
export const errorUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingDELETE
 * url: errorUsingDELETEURL
 * method: errorUsingDELETE_TYPE
 * raw_url: errorUsingDELETE_RAW_URL
 */
export const errorUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const errorUsingDELETE_RAW_URL = function() {
  return '/error'
}
export const errorUsingDELETE_TYPE = function() {
  return 'delete'
}
export const errorUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingOPTIONS
 * url: errorUsingOPTIONSURL
 * method: errorUsingOPTIONS_TYPE
 * raw_url: errorUsingOPTIONS_RAW_URL
 */
export const errorUsingOPTIONS = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('options', domain + path, body, queryParameters, form, config)
}
export const errorUsingOPTIONS_RAW_URL = function() {
  return '/error'
}
export const errorUsingOPTIONS_TYPE = function() {
  return 'options'
}
export const errorUsingOPTIONSURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * error
 * request: errorUsingPATCH
 * url: errorUsingPATCHURL
 * method: errorUsingPATCH_TYPE
 * raw_url: errorUsingPATCH_RAW_URL
 */
export const errorUsingPATCH = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const errorUsingPATCH_RAW_URL = function() {
  return '/error'
}
export const errorUsingPATCH_TYPE = function() {
  return 'patch'
}
export const errorUsingPATCHURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * manageEvaluation
 * request: manageEvaluationUsingPOST
 * url: manageEvaluationUsingPOSTURL
 * method: manageEvaluationUsingPOST_TYPE
 * raw_url: manageEvaluationUsingPOST_RAW_URL
 * @param manageEvaluationRequest - manageEvaluationRequest
 */
export const manageEvaluationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['manageEvaluationRequest'] !== undefined) {
    body = parameters['manageEvaluationRequest']
  }
  if (parameters['manageEvaluationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: manageEvaluationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const manageEvaluationUsingPOST_RAW_URL = function() {
  return '/evaluation/create'
}
export const manageEvaluationUsingPOST_TYPE = function() {
  return 'post'
}
export const manageEvaluationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEvaluation
 * request: searchEvaluationUsingPOST
 * url: searchEvaluationUsingPOSTURL
 * method: searchEvaluationUsingPOST_TYPE
 * raw_url: searchEvaluationUsingPOST_RAW_URL
 * @param evaluationSearchCriteria - evaluationSearchCriteria
 */
export const searchEvaluationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['evaluationSearchCriteria'] !== undefined) {
    body = parameters['evaluationSearchCriteria']
  }
  if (parameters['evaluationSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: evaluationSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEvaluationUsingPOST_RAW_URL = function() {
  return '/evaluation/search'
}
export const searchEvaluationUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEvaluationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEvaluationCount
 * request: searchEvaluationCountUsingPOST
 * url: searchEvaluationCountUsingPOSTURL
 * method: searchEvaluationCountUsingPOST_TYPE
 * raw_url: searchEvaluationCountUsingPOST_RAW_URL
 * @param evaluationSearchCriteria - evaluationSearchCriteria
 */
export const searchEvaluationCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['evaluationSearchCriteria'] !== undefined) {
    body = parameters['evaluationSearchCriteria']
  }
  if (parameters['evaluationSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: evaluationSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEvaluationCountUsingPOST_RAW_URL = function() {
  return '/evaluation/search/count'
}
export const searchEvaluationCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEvaluationCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateEvaluation
 * request: updateEvaluationUsingPOST
 * url: updateEvaluationUsingPOSTURL
 * method: updateEvaluationUsingPOST_TYPE
 * raw_url: updateEvaluationUsingPOST_RAW_URL
 * @param updateEvaluationRequest - updateEvaluationRequest
 */
export const updateEvaluationUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/evaluation/update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['updateEvaluationRequest'] !== undefined) {
    body = parameters['updateEvaluationRequest']
  }
  if (parameters['updateEvaluationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: updateEvaluationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const updateEvaluationUsingPOST_RAW_URL = function() {
  return '/evaluation/update'
}
export const updateEvaluationUsingPOST_TYPE = function() {
  return 'post'
}
export const updateEvaluationUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/evaluation/update'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCompanyExcelFilesCount
 * request: searchCompanyExcelFilesCountUsingPOST
 * url: searchCompanyExcelFilesCountUsingPOSTURL
 * method: searchCompanyExcelFilesCountUsingPOST_TYPE
 * raw_url: searchCompanyExcelFilesCountUsingPOST_RAW_URL
 * @param companyId - companyId
 * @param searchCriteria - searchCriteria
 */
export const searchCompanyExcelFilesCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/company/files/count/{companyId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters['companyId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyId'))
  }
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCompanyExcelFilesCountUsingPOST_RAW_URL = function() {
  return '/file/company/files/count/{companyId}'
}
export const searchCompanyExcelFilesCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCompanyExcelFilesCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/company/files/count/{companyId}'
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * downloadAllCompanyExcelFiles
 * request: downloadAllCompanyExcelFilesUsingGET
 * url: downloadAllCompanyExcelFilesUsingGETURL
 * method: downloadAllCompanyExcelFilesUsingGET_TYPE
 * raw_url: downloadAllCompanyExcelFilesUsingGET_RAW_URL
 * @param companyId - companyId
 */
export const downloadAllCompanyExcelFilesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/company/files/download/all/{companyId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters['companyId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const downloadAllCompanyExcelFilesUsingGET_RAW_URL = function() {
  return '/file/company/files/download/all/{companyId}'
}
export const downloadAllCompanyExcelFilesUsingGET_TYPE = function() {
  return 'get'
}
export const downloadAllCompanyExcelFilesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/company/files/download/all/{companyId}'
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * downloadExcelFile
 * request: downloadExcelFileUsingGET
 * url: downloadExcelFileUsingGETURL
 * method: downloadExcelFileUsingGET_TYPE
 * raw_url: downloadExcelFileUsingGET_RAW_URL
 * @param id - id
 */
export const downloadExcelFileUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/company/files/download/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const downloadExcelFileUsingGET_RAW_URL = function() {
  return '/file/company/files/download/{id}'
}
export const downloadExcelFileUsingGET_TYPE = function() {
  return 'get'
}
export const downloadExcelFileUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/company/files/download/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchCompanyExcelFiles
 * request: searchCompanyExcelFilesUsingPOST
 * url: searchCompanyExcelFilesUsingPOSTURL
 * method: searchCompanyExcelFilesUsingPOST_TYPE
 * raw_url: searchCompanyExcelFilesUsingPOST_RAW_URL
 * @param companyId - companyId
 * @param searchCriteria - searchCriteria
 */
export const searchCompanyExcelFilesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/company/files/search/{companyId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters['companyId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: companyId'))
  }
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchCompanyExcelFilesUsingPOST_RAW_URL = function() {
  return '/file/company/files/search/{companyId}'
}
export const searchCompanyExcelFilesUsingPOST_TYPE = function() {
  return 'post'
}
export const searchCompanyExcelFilesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/company/files/search/{companyId}'
  path = path.replace('{companyId}', `${parameters['companyId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEmployerFilesCount
 * request: searchEmployerFilesCountUsingPOST
 * url: searchEmployerFilesCountUsingPOSTURL
 * method: searchEmployerFilesCountUsingPOST_TYPE
 * raw_url: searchEmployerFilesCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchEmployerFilesCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEmployerFilesCountUsingPOST_RAW_URL = function() {
  return '/file/employer/count'
}
export const searchEmployerFilesCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEmployerFilesCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * downloadEmployerFile
 * request: downloadEmployerFileUsingGET
 * url: downloadEmployerFileUsingGETURL
 * method: downloadEmployerFileUsingGET_TYPE
 * raw_url: downloadEmployerFileUsingGET_RAW_URL
 * @param id - id
 */
export const downloadEmployerFileUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/download/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const downloadEmployerFileUsingGET_RAW_URL = function() {
  return '/file/employer/download/{id}'
}
export const downloadEmployerFileUsingGET_TYPE = function() {
  return 'get'
}
export const downloadEmployerFileUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/download/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEmployerFiles
 * request: searchEmployerFilesUsingPOST
 * url: searchEmployerFilesUsingPOSTURL
 * method: searchEmployerFilesUsingPOST_TYPE
 * raw_url: searchEmployerFilesUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchEmployerFilesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEmployerFilesUsingPOST_RAW_URL = function() {
  return '/file/employer/search'
}
export const searchEmployerFilesUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEmployerFilesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * updateEmployerFile
 * request: updateEmployerFileUsingPUT
 * url: updateEmployerFileUsingPUTURL
 * method: updateEmployerFileUsingPUT_TYPE
 * raw_url: updateEmployerFileUsingPUT_RAW_URL
 * @param fileDto - fileDTO
 */
export const updateEmployerFileUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/update'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['fileDto'] !== undefined) {
    body = parameters['fileDto']
  }
  if (parameters['fileDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fileDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const updateEmployerFileUsingPUT_RAW_URL = function() {
  return '/file/employer/update'
}
export const updateEmployerFileUsingPUT_TYPE = function() {
  return 'put'
}
export const updateEmployerFileUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/update'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * uploadEmployerFile
 * request: uploadEmployerFileUsingPOST
 * url: uploadEmployerFileUsingPOSTURL
 * method: uploadEmployerFileUsingPOST_TYPE
 * raw_url: uploadEmployerFileUsingPOST_RAW_URL
 * @param fileDto - fileDTO
 */
export const uploadEmployerFileUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/upload'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['fileDto'] !== undefined) {
    body = parameters['fileDto']
  }
  if (parameters['fileDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fileDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const uploadEmployerFileUsingPOST_RAW_URL = function() {
  return '/file/employer/upload'
}
export const uploadEmployerFileUsingPOST_TYPE = function() {
  return 'post'
}
export const uploadEmployerFileUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/upload'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEmployerFileInfo
 * request: getEmployerFileInfoUsingGET
 * url: getEmployerFileInfoUsingGETURL
 * method: getEmployerFileInfoUsingGET_TYPE
 * raw_url: getEmployerFileInfoUsingGET_RAW_URL
 * @param id - id
 */
export const getEmployerFileInfoUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEmployerFileInfoUsingGET_RAW_URL = function() {
  return '/file/employer/{id}'
}
export const getEmployerFileInfoUsingGET_TYPE = function() {
  return 'get'
}
export const getEmployerFileInfoUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteEmployerFile
 * request: deleteEmployerFileUsingDELETE
 * url: deleteEmployerFileUsingDELETEURL
 * method: deleteEmployerFileUsingDELETE_TYPE
 * raw_url: deleteEmployerFileUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteEmployerFileUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/file/employer/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteEmployerFileUsingDELETE_RAW_URL = function() {
  return '/file/employer/{id}'
}
export const deleteEmployerFileUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteEmployerFileUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/file/employer/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * isFinancialAnalysisFinished
 * request: isFinancialAnalysisFinishedUsingGET
 * url: isFinancialAnalysisFinishedUsingGETURL
 * method: isFinancialAnalysisFinishedUsingGET_TYPE
 * raw_url: isFinancialAnalysisFinishedUsingGET_RAW_URL
 */
export const isFinancialAnalysisFinishedUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/financial-analysis-data/is-analysis-finished'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const isFinancialAnalysisFinishedUsingGET_RAW_URL = function() {
  return '/financial-analysis-data/is-analysis-finished'
}
export const isFinancialAnalysisFinishedUsingGET_TYPE = function() {
  return 'get'
}
export const isFinancialAnalysisFinishedUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/financial-analysis-data/is-analysis-finished'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getFinancialAnalysisStepOneOutputOneData
 * request: getFinancialAnalysisStepOneOutputOneDataUsingGET
 * url: getFinancialAnalysisStepOneOutputOneDataUsingGETURL
 * method: getFinancialAnalysisStepOneOutputOneDataUsingGET_TYPE
 * raw_url: getFinancialAnalysisStepOneOutputOneDataUsingGET_RAW_URL
 */
export const getFinancialAnalysisStepOneOutputOneDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/financial-analysis-data/step-one-output-one-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFinancialAnalysisStepOneOutputOneDataUsingGET_RAW_URL = function() {
  return '/financial-analysis-data/step-one-output-one-data'
}
export const getFinancialAnalysisStepOneOutputOneDataUsingGET_TYPE = function() {
  return 'get'
}
export const getFinancialAnalysisStepOneOutputOneDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/financial-analysis-data/step-one-output-one-data'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getFinancialAnalysisStepOneOutputTwoData
 * request: getFinancialAnalysisStepOneOutputTwoDataUsingGET
 * url: getFinancialAnalysisStepOneOutputTwoDataUsingGETURL
 * method: getFinancialAnalysisStepOneOutputTwoDataUsingGET_TYPE
 * raw_url: getFinancialAnalysisStepOneOutputTwoDataUsingGET_RAW_URL
 */
export const getFinancialAnalysisStepOneOutputTwoDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/financial-analysis-data/step-one-output-two-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFinancialAnalysisStepOneOutputTwoDataUsingGET_RAW_URL = function() {
  return '/financial-analysis-data/step-one-output-two-data'
}
export const getFinancialAnalysisStepOneOutputTwoDataUsingGET_TYPE = function() {
  return 'get'
}
export const getFinancialAnalysisStepOneOutputTwoDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/financial-analysis-data/step-one-output-two-data'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getFinancialAnalysisStepTwoOutputFourData
 * request: getFinancialAnalysisStepTwoOutputFourDataUsingGET
 * url: getFinancialAnalysisStepTwoOutputFourDataUsingGETURL
 * method: getFinancialAnalysisStepTwoOutputFourDataUsingGET_TYPE
 * raw_url: getFinancialAnalysisStepTwoOutputFourDataUsingGET_RAW_URL
 */
export const getFinancialAnalysisStepTwoOutputFourDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/financial-analysis-data/step-two-output-four-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFinancialAnalysisStepTwoOutputFourDataUsingGET_RAW_URL = function() {
  return '/financial-analysis-data/step-two-output-four-data'
}
export const getFinancialAnalysisStepTwoOutputFourDataUsingGET_TYPE = function() {
  return 'get'
}
export const getFinancialAnalysisStepTwoOutputFourDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/financial-analysis-data/step-two-output-four-data'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getFinancialAnalysisStepTwoOutputOneData
 * request: getFinancialAnalysisStepTwoOutputOneDataUsingGET
 * url: getFinancialAnalysisStepTwoOutputOneDataUsingGETURL
 * method: getFinancialAnalysisStepTwoOutputOneDataUsingGET_TYPE
 * raw_url: getFinancialAnalysisStepTwoOutputOneDataUsingGET_RAW_URL
 */
export const getFinancialAnalysisStepTwoOutputOneDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/financial-analysis-data/step-two-output-one-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFinancialAnalysisStepTwoOutputOneDataUsingGET_RAW_URL = function() {
  return '/financial-analysis-data/step-two-output-one-data'
}
export const getFinancialAnalysisStepTwoOutputOneDataUsingGET_TYPE = function() {
  return 'get'
}
export const getFinancialAnalysisStepTwoOutputOneDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/financial-analysis-data/step-two-output-one-data'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getFinancialAnalysisStepTwoOutputThreeData
 * request: getFinancialAnalysisStepTwoOutputThreeDataUsingGET
 * url: getFinancialAnalysisStepTwoOutputThreeDataUsingGETURL
 * method: getFinancialAnalysisStepTwoOutputThreeDataUsingGET_TYPE
 * raw_url: getFinancialAnalysisStepTwoOutputThreeDataUsingGET_RAW_URL
 */
export const getFinancialAnalysisStepTwoOutputThreeDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/financial-analysis-data/step-two-output-three-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFinancialAnalysisStepTwoOutputThreeDataUsingGET_RAW_URL = function() {
  return '/financial-analysis-data/step-two-output-three-data'
}
export const getFinancialAnalysisStepTwoOutputThreeDataUsingGET_TYPE = function() {
  return 'get'
}
export const getFinancialAnalysisStepTwoOutputThreeDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/financial-analysis-data/step-two-output-three-data'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getFinancialAnalysisStepTwoOutputTwoData
 * request: getFinancialAnalysisStepTwoOutputTwoDataUsingGET
 * url: getFinancialAnalysisStepTwoOutputTwoDataUsingGETURL
 * method: getFinancialAnalysisStepTwoOutputTwoDataUsingGET_TYPE
 * raw_url: getFinancialAnalysisStepTwoOutputTwoDataUsingGET_RAW_URL
 */
export const getFinancialAnalysisStepTwoOutputTwoDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/financial-analysis-data/step-two-output-two-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFinancialAnalysisStepTwoOutputTwoDataUsingGET_RAW_URL = function() {
  return '/financial-analysis-data/step-two-output-two-data'
}
export const getFinancialAnalysisStepTwoOutputTwoDataUsingGET_TYPE = function() {
  return 'get'
}
export const getFinancialAnalysisStepTwoOutputTwoDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/financial-analysis-data/step-two-output-two-data'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getFinancialAnalysisProgressOutputData
 * request: getFinancialAnalysisProgressOutputDataUsingGET
 * url: getFinancialAnalysisProgressOutputDataUsingGETURL
 * method: getFinancialAnalysisProgressOutputDataUsingGET_TYPE
 * raw_url: getFinancialAnalysisProgressOutputDataUsingGET_RAW_URL
 */
export const getFinancialAnalysisProgressOutputDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/financial-analysis-progress/output-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getFinancialAnalysisProgressOutputDataUsingGET_RAW_URL = function() {
  return '/financial-analysis-progress/output-data'
}
export const getFinancialAnalysisProgressOutputDataUsingGET_TYPE = function() {
  return 'get'
}
export const getFinancialAnalysisProgressOutputDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/financial-analysis-progress/output-data'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getOrganisationEvaluationInput
 * request: getOrganisationEvaluationInputUsingGET
 * url: getOrganisationEvaluationInputUsingGETURL
 * method: getOrganisationEvaluationInputUsingGET_TYPE
 * raw_url: getOrganisationEvaluationInputUsingGET_RAW_URL
 */
export const getOrganisationEvaluationInputUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/get-organisation-evaluation-input'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getOrganisationEvaluationInputUsingGET_RAW_URL = function() {
  return '/get-organisation-evaluation-input'
}
export const getOrganisationEvaluationInputUsingGET_TYPE = function() {
  return 'get'
}
export const getOrganisationEvaluationInputUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/get-organisation-evaluation-input'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getOrganisationEvaluationResult
 * request: getOrganisationEvaluationResultUsingGET
 * url: getOrganisationEvaluationResultUsingGETURL
 * method: getOrganisationEvaluationResultUsingGET_TYPE
 * raw_url: getOrganisationEvaluationResultUsingGET_RAW_URL
 */
export const getOrganisationEvaluationResultUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/get-organisation-evaluation-result'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getOrganisationEvaluationResultUsingGET_RAW_URL = function() {
  return '/get-organisation-evaluation-result'
}
export const getOrganisationEvaluationResultUsingGET_TYPE = function() {
  return 'get'
}
export const getOrganisationEvaluationResultUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/get-organisation-evaluation-result'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEfficiencyCalculations
 * request: getEfficiencyCalculationsUsingGET
 * url: getEfficiencyCalculationsUsingGETURL
 * method: getEfficiencyCalculationsUsingGET_TYPE
 * raw_url: getEfficiencyCalculationsUsingGET_RAW_URL
 */
export const getEfficiencyCalculationsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/get_efficiency'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEfficiencyCalculationsUsingGET_RAW_URL = function() {
  return '/get_efficiency'
}
export const getEfficiencyCalculationsUsingGET_TYPE = function() {
  return 'get'
}
export const getEfficiencyCalculationsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/get_efficiency'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * isTokenValid
 * request: isTokenValidUsingGET
 * url: isTokenValidUsingGETURL
 * method: isTokenValidUsingGET_TYPE
 * raw_url: isTokenValidUsingGET_RAW_URL
 */
export const isTokenValidUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/is-token-valid'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const isTokenValidUsingGET_RAW_URL = function() {
  return '/is-token-valid'
}
export const isTokenValidUsingGET_TYPE = function() {
  return 'get'
}
export const isTokenValidUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/is-token-valid'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * login
 * request: loginUsingPOST
 * url: loginUsingPOSTURL
 * method: loginUsingPOST_TYPE
 * raw_url: loginUsingPOST_RAW_URL
 * @param authenticationRequest - authenticationRequest
 */
export const loginUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/login'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['authenticationRequest'] !== undefined) {
    body = parameters['authenticationRequest']
  }
  if (parameters['authenticationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: authenticationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const loginUsingPOST_RAW_URL = function() {
  return '/login'
}
export const loginUsingPOST_TYPE = function() {
  return 'post'
}
export const loginUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/login'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPages
 * request: searchPagesUsingGET
 * url: searchPagesUsingGETURL
 * method: searchPagesUsingGET_TYPE
 * raw_url: searchPagesUsingGET_RAW_URL
 * @param word - word
 */
export const searchPagesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/page/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['word'] !== undefined) {
    body = parameters['word']
  }
  if (parameters['word'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: word'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const searchPagesUsingGET_RAW_URL = function() {
  return '/page/search'
}
export const searchPagesUsingGET_TYPE = function() {
  return 'get'
}
export const searchPagesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/page/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * commentPost
 * request: commentPostUsingPOST
 * url: commentPostUsingPOSTURL
 * method: commentPostUsingPOST_TYPE
 * raw_url: commentPostUsingPOST_RAW_URL
 * @param commentDto - commentDTO
 */
export const commentPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/comment'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['commentDto'] !== undefined) {
    body = parameters['commentDto']
  }
  if (parameters['commentDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: commentDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const commentPostUsingPOST_RAW_URL = function() {
  return '/post/comment'
}
export const commentPostUsingPOST_TYPE = function() {
  return 'post'
}
export const commentPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/comment'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editComment
 * request: editCommentUsingPOST
 * url: editCommentUsingPOSTURL
 * method: editCommentUsingPOST_TYPE
 * raw_url: editCommentUsingPOST_RAW_URL
 * @param commentDto - commentDTO
 */
export const editCommentUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/comment/edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['commentDto'] !== undefined) {
    body = parameters['commentDto']
  }
  if (parameters['commentDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: commentDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editCommentUsingPOST_RAW_URL = function() {
  return '/post/comment/edit'
}
export const editCommentUsingPOST_TYPE = function() {
  return 'post'
}
export const editCommentUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/comment/edit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createPost
 * request: createPostUsingPOST
 * url: createPostUsingPOSTURL
 * method: createPostUsingPOST_TYPE
 * raw_url: createPostUsingPOST_RAW_URL
 * @param postDto - postDTO
 */
export const createPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['postDto'] !== undefined) {
    body = parameters['postDto']
  }
  if (parameters['postDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createPostUsingPOST_RAW_URL = function() {
  return '/post/create'
}
export const createPostUsingPOST_TYPE = function() {
  return 'post'
}
export const createPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editPost
 * request: editPostUsingPOST
 * url: editPostUsingPOSTURL
 * method: editPostUsingPOST_TYPE
 * raw_url: editPostUsingPOST_RAW_URL
 * @param postDto - postDTO
 */
export const editPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['postDto'] !== undefined) {
    body = parameters['postDto']
  }
  if (parameters['postDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editPostUsingPOST_RAW_URL = function() {
  return '/post/edit'
}
export const editPostUsingPOST_TYPE = function() {
  return 'post'
}
export const editPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/edit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPost
 * request: searchPostUsingPOST
 * url: searchPostUsingPOSTURL
 * method: searchPostUsingPOST_TYPE
 * raw_url: searchPostUsingPOST_RAW_URL
 * @param postSearchCriteria - postSearchCriteria
 */
export const searchPostUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['postSearchCriteria'] !== undefined) {
    body = parameters['postSearchCriteria']
  }
  if (parameters['postSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPostUsingPOST_RAW_URL = function() {
  return '/post/search'
}
export const searchPostUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPostUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchPostCount
 * request: searchPostCountUsingPOST
 * url: searchPostCountUsingPOSTURL
 * method: searchPostCountUsingPOST_TYPE
 * raw_url: searchPostCountUsingPOST_RAW_URL
 * @param postSearchCriteria - postSearchCriteria
 */
export const searchPostCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['postSearchCriteria'] !== undefined) {
    body = parameters['postSearchCriteria']
  }
  if (parameters['postSearchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: postSearchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchPostCountUsingPOST_RAW_URL = function() {
  return '/post/search/count'
}
export const searchPostCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchPostCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deletePost
 * request: deletePostUsingDELETE
 * url: deletePostUsingDELETEURL
 * method: deletePostUsingDELETE_TYPE
 * raw_url: deletePostUsingDELETE_RAW_URL
 * @param id - id
 */
export const deletePostUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/post/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deletePostUsingDELETE_RAW_URL = function() {
  return '/post/{id}'
}
export const deletePostUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deletePostUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/post/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * readEvaluationData
 * request: readEvaluationDataUsingGET
 * url: readEvaluationDataUsingGETURL
 * method: readEvaluationDataUsingGET_TYPE
 * raw_url: readEvaluationDataUsingGET_RAW_URL
 * @param evaluationId - evaluationId
 */
export const readEvaluationDataUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/read-evaluation-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['evaluationId'] !== undefined) {
    queryParameters['evaluationId'] = parameters['evaluationId']
  }
  if (parameters['evaluationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: evaluationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const readEvaluationDataUsingGET_RAW_URL = function() {
  return '/read-evaluation-data'
}
export const readEvaluationDataUsingGET_TYPE = function() {
  return 'get'
}
export const readEvaluationDataUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/read-evaluation-data'
  if (parameters['evaluationId'] !== undefined) {
    queryParameters['evaluationId'] = parameters['evaluationId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getCompanyMergeSummary
 * request: getCompanyMergeSummaryUsingGET
 * url: getCompanyMergeSummaryUsingGETURL
 * method: getCompanyMergeSummaryUsingGET_TYPE
 * raw_url: getCompanyMergeSummaryUsingGET_RAW_URL
 * @param employerId - employerId
 */
export const getCompanyMergeSummaryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/recommendation/company-merge-summary'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['employerId'] !== undefined) {
    queryParameters['employerId'] = parameters['employerId']
  }
  if (parameters['employerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: employerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCompanyMergeSummaryUsingGET_RAW_URL = function() {
  return '/recommendation/company-merge-summary'
}
export const getCompanyMergeSummaryUsingGET_TYPE = function() {
  return 'get'
}
export const getCompanyMergeSummaryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/recommendation/company-merge-summary'
  if (parameters['employerId'] !== undefined) {
    queryParameters['employerId'] = parameters['employerId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * saveRecommendationsForWorker
 * request: saveRecommendationsForWorkerUsingPOST
 * url: saveRecommendationsForWorkerUsingPOSTURL
 * method: saveRecommendationsForWorkerUsingPOST_TYPE
 * raw_url: saveRecommendationsForWorkerUsingPOST_RAW_URL
 * @param recommendations - recommendations
 */
export const saveRecommendationsForWorkerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/recommendation/save-worker-recommendations'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['recommendations'] !== undefined) {
    body = parameters['recommendations']
  }
  if (parameters['recommendations'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: recommendations'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const saveRecommendationsForWorkerUsingPOST_RAW_URL = function() {
  return '/recommendation/save-worker-recommendations'
}
export const saveRecommendationsForWorkerUsingPOST_TYPE = function() {
  return 'post'
}
export const saveRecommendationsForWorkerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/recommendation/save-worker-recommendations'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getWorkerRecommendationSummary
 * request: getWorkerRecommendationSummaryUsingGET
 * url: getWorkerRecommendationSummaryUsingGETURL
 * method: getWorkerRecommendationSummaryUsingGET_TYPE
 * raw_url: getWorkerRecommendationSummaryUsingGET_RAW_URL
 * @param workerId - workerId
 */
export const getWorkerRecommendationSummaryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/recommendation/worker-recommendation-summary'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['workerId'] !== undefined) {
    queryParameters['workerId'] = parameters['workerId']
  }
  if (parameters['workerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getWorkerRecommendationSummaryUsingGET_RAW_URL = function() {
  return '/recommendation/worker-recommendation-summary'
}
export const getWorkerRecommendationSummaryUsingGET_TYPE = function() {
  return 'get'
}
export const getWorkerRecommendationSummaryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/recommendation/worker-recommendation-summary'
  if (parameters['workerId'] !== undefined) {
    queryParameters['workerId'] = parameters['workerId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * saveSurvey
 * request: saveSurveyUsingPOST
 * url: saveSurveyUsingPOSTURL
 * method: saveSurveyUsingPOST_TYPE
 * raw_url: saveSurveyUsingPOST_RAW_URL
 * @param surveyRequest - surveyRequest
 */
export const saveSurveyUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/save-survey'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['surveyRequest'] !== undefined) {
    body = parameters['surveyRequest']
  }
  if (parameters['surveyRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: surveyRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const saveSurveyUsingPOST_RAW_URL = function() {
  return '/save-survey'
}
export const saveSurveyUsingPOST_TYPE = function() {
  return 'post'
}
export const saveSurveyUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/save-survey'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUser
 * request: searchUserUsingPOST
 * url: searchUserUsingPOSTURL
 * method: searchUserUsingPOST_TYPE
 * raw_url: searchUserUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserUsingPOST_RAW_URL = function() {
  return '/search/user'
}
export const searchUserUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUserCount
 * request: searchUserCountUsingPOST
 * url: searchUserCountUsingPOSTURL
 * method: searchUserCountUsingPOST_TYPE
 * raw_url: searchUserCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserCountUsingPOST_RAW_URL = function() {
  return '/search/user/count'
}
export const searchUserCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * setOrganisationEvaluationInput
 * request: setOrganisationEvaluationInputUsingPOST
 * url: setOrganisationEvaluationInputUsingPOSTURL
 * method: setOrganisationEvaluationInputUsingPOST_TYPE
 * raw_url: setOrganisationEvaluationInputUsingPOST_RAW_URL
 * @param organisationEvaluationInputDto - organisationEvaluationInputDTO
 */
export const setOrganisationEvaluationInputUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/set-organisation-evaluation-input'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['organisationEvaluationInputDto'] !== undefined) {
    body = parameters['organisationEvaluationInputDto']
  }
  if (parameters['organisationEvaluationInputDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organisationEvaluationInputDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const setOrganisationEvaluationInputUsingPOST_RAW_URL = function() {
  return '/set-organisation-evaluation-input'
}
export const setOrganisationEvaluationInputUsingPOST_TYPE = function() {
  return 'post'
}
export const setOrganisationEvaluationInputUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/set-organisation-evaluation-input'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * setEfficiencyCalculations
 * request: setEfficiencyCalculationsUsingPOST
 * url: setEfficiencyCalculationsUsingPOSTURL
 * method: setEfficiencyCalculationsUsingPOST_TYPE
 * raw_url: setEfficiencyCalculationsUsingPOST_RAW_URL
 * @param efficiencyMain - efficiencyMain
 */
export const setEfficiencyCalculationsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/set_efficiency'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['efficiencyMain'] !== undefined) {
    body = parameters['efficiencyMain']
  }
  if (parameters['efficiencyMain'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: efficiencyMain'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const setEfficiencyCalculationsUsingPOST_RAW_URL = function() {
  return '/set_efficiency'
}
export const setEfficiencyCalculationsUsingPOST_TYPE = function() {
  return 'post'
}
export const setEfficiencyCalculationsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/set_efficiency'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getActiveTrendsQuiz
 * request: getActiveTrendsQuizUsingGET
 * url: getActiveTrendsQuizUsingGETURL
 * method: getActiveTrendsQuizUsingGET_TYPE
 * raw_url: getActiveTrendsQuizUsingGET_RAW_URL
 */
export const getActiveTrendsQuizUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trends/active-trend-quizzes'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getActiveTrendsQuizUsingGET_RAW_URL = function() {
  return '/trends/active-trend-quizzes'
}
export const getActiveTrendsQuizUsingGET_TYPE = function() {
  return 'get'
}
export const getActiveTrendsQuizUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trends/active-trend-quizzes'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * countTrendQuizResults
 * request: countTrendQuizResultsUsingGET
 * url: countTrendQuizResultsUsingGETURL
 * method: countTrendQuizResultsUsingGET_TYPE
 * raw_url: countTrendQuizResultsUsingGET_RAW_URL
 * @param trendQuizId - trendQuizId
 */
export const countTrendQuizResultsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trends/count-trend-quiz-results/{trendQuizId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{trendQuizId}', `${parameters['trendQuizId']}`)
  if (parameters['trendQuizId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: trendQuizId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const countTrendQuizResultsUsingGET_RAW_URL = function() {
  return '/trends/count-trend-quiz-results/{trendQuizId}'
}
export const countTrendQuizResultsUsingGET_TYPE = function() {
  return 'get'
}
export const countTrendQuizResultsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trends/count-trend-quiz-results/{trendQuizId}'
  path = path.replace('{trendQuizId}', `${parameters['trendQuizId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createQuizFromTemplate
 * request: createQuizFromTemplateUsingPOST
 * url: createQuizFromTemplateUsingPOSTURL
 * method: createQuizFromTemplateUsingPOST_TYPE
 * raw_url: createQuizFromTemplateUsingPOST_RAW_URL
 * @param keyword - keyword
 */
export const createQuizFromTemplateUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trends/create-trends-quiz-from-template'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['keyword'] !== undefined) {
    queryParameters['keyword'] = parameters['keyword']
  }
  if (parameters['keyword'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: keyword'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createQuizFromTemplateUsingPOST_RAW_URL = function() {
  return '/trends/create-trends-quiz-from-template'
}
export const createQuizFromTemplateUsingPOST_TYPE = function() {
  return 'post'
}
export const createQuizFromTemplateUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trends/create-trends-quiz-from-template'
  if (parameters['keyword'] !== undefined) {
    queryParameters['keyword'] = parameters['keyword']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * submitTrendStudyResults
 * request: submitTrendStudyResultsUsingPOST
 * url: submitTrendStudyResultsUsingPOSTURL
 * method: submitTrendStudyResultsUsingPOST_TYPE
 * raw_url: submitTrendStudyResultsUsingPOST_RAW_URL
 * @param guestToken - guestToken
 * @param quizDto - quizDTO
 */
export const submitTrendStudyResultsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trends/trend-study-results'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['guestToken'] !== undefined) {
    queryParameters['guestToken'] = parameters['guestToken']
  }
  if (parameters['guestToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: guestToken'))
  }
  if (parameters['quizDto'] !== undefined) {
    body = parameters['quizDto']
  }
  if (parameters['quizDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: quizDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const submitTrendStudyResultsUsingPOST_RAW_URL = function() {
  return '/trends/trend-study-results'
}
export const submitTrendStudyResultsUsingPOST_TYPE = function() {
  return 'post'
}
export const submitTrendStudyResultsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trends/trend-study-results'
  if (parameters['guestToken'] !== undefined) {
    queryParameters['guestToken'] = parameters['guestToken']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTrendStudyResults
 * request: getTrendStudyResultsUsingGET
 * url: getTrendStudyResultsUsingGETURL
 * method: getTrendStudyResultsUsingGET_TYPE
 * raw_url: getTrendStudyResultsUsingGET_RAW_URL
 * @param trendQuizId - trendQuizId
 */
export const getTrendStudyResultsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trends/trend-study-results/{trendQuizId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{trendQuizId}', `${parameters['trendQuizId']}`)
  if (parameters['trendQuizId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: trendQuizId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTrendStudyResultsUsingGET_RAW_URL = function() {
  return '/trends/trend-study-results/{trendQuizId}'
}
export const getTrendStudyResultsUsingGET_TYPE = function() {
  return 'get'
}
export const getTrendStudyResultsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trends/trend-study-results/{trendQuizId}'
  path = path.replace('{trendQuizId}', `${parameters['trendQuizId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTrendQuizById
 * request: getTrendQuizByIdUsingGET
 * url: getTrendQuizByIdUsingGETURL
 * method: getTrendQuizByIdUsingGET_TYPE
 * raw_url: getTrendQuizByIdUsingGET_RAW_URL
 * @param guestToken - guestToken
 * @param trendQuizId - trendQuizId
 */
export const getTrendQuizByIdUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/trends/{trendQuizId}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['guestToken'] !== undefined) {
    queryParameters['guestToken'] = parameters['guestToken']
  }
  if (parameters['guestToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: guestToken'))
  }
  path = path.replace('{trendQuizId}', `${parameters['trendQuizId']}`)
  if (parameters['trendQuizId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: trendQuizId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTrendQuizByIdUsingGET_RAW_URL = function() {
  return '/trends/{trendQuizId}'
}
export const getTrendQuizByIdUsingGET_TYPE = function() {
  return 'get'
}
export const getTrendQuizByIdUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/trends/{trendQuizId}'
  if (parameters['guestToken'] !== undefined) {
    queryParameters['guestToken'] = parameters['guestToken']
  }
  path = path.replace('{trendQuizId}', `${parameters['trendQuizId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateUser
 * request: createOrUpdateUserUsingPOST
 * url: createOrUpdateUserUsingPOSTURL
 * method: createOrUpdateUserUsingPOST_TYPE
 * raw_url: createOrUpdateUserUsingPOST_RAW_URL
 * @param userDto - userDTO
 */
export const createOrUpdateUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userDto'] !== undefined) {
    body = parameters['userDto']
  }
  if (parameters['userDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateUserUsingPOST_RAW_URL = function() {
  return '/user'
}
export const createOrUpdateUserUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editUser
 * request: editUserUsingPOST
 * url: editUserUsingPOSTURL
 * method: editUserUsingPOST_TYPE
 * raw_url: editUserUsingPOST_RAW_URL
 * @param editUserRequest - editUserRequest
 */
export const editUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['editUserRequest'] !== undefined) {
    body = parameters['editUserRequest']
  }
  if (parameters['editUserRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: editUserRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const editUserUsingPOST_RAW_URL = function() {
  return '/user/edit'
}
export const editUserUsingPOST_TYPE = function() {
  return 'post'
}
export const editUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/edit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changePassword
 * request: changePasswordUsingPOST
 * url: changePasswordUsingPOSTURL
 * method: changePasswordUsingPOST_TYPE
 * raw_url: changePasswordUsingPOST_RAW_URL
 * @param passwordChangeRequest - passwordChangeRequest
 */
export const changePasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/change'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordChangeRequest'] !== undefined) {
    body = parameters['passwordChangeRequest']
  }
  if (parameters['passwordChangeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordChangeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changePasswordUsingPOST_RAW_URL = function() {
  return '/user/password/change'
}
export const changePasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const changePasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/change'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * remindPassword
 * request: remindPasswordUsingPOST
 * url: remindPasswordUsingPOSTURL
 * method: remindPasswordUsingPOST_TYPE
 * raw_url: remindPasswordUsingPOST_RAW_URL
 * @param passwordRemindRequest - passwordRemindRequest
 */
export const remindPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/remind'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordRemindRequest'] !== undefined) {
    body = parameters['passwordRemindRequest']
  }
  if (parameters['passwordRemindRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordRemindRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const remindPasswordUsingPOST_RAW_URL = function() {
  return '/user/password/remind'
}
export const remindPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const remindPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/remind'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changeRemindedPassword
 * request: changeRemindedPasswordUsingPOST
 * url: changeRemindedPasswordUsingPOSTURL
 * method: changeRemindedPasswordUsingPOST_TYPE
 * raw_url: changeRemindedPasswordUsingPOST_RAW_URL
 * @param remindedPasswordChangeRequest - remindedPasswordChangeRequest
 */
export const changeRemindedPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/remind/change'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['remindedPasswordChangeRequest'] !== undefined) {
    body = parameters['remindedPasswordChangeRequest']
  }
  if (parameters['remindedPasswordChangeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: remindedPasswordChangeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changeRemindedPasswordUsingPOST_RAW_URL = function() {
  return '/user/password/remind/change'
}
export const changeRemindedPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const changeRemindedPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/remind/change'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUserRoles
 * request: getUserRolesUsingGET
 * url: getUserRolesUsingGETURL
 * method: getUserRolesUsingGET_TYPE
 * raw_url: getUserRolesUsingGET_RAW_URL
 */
export const getUserRolesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/roles'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserRolesUsingGET_RAW_URL = function() {
  return '/user/roles'
}
export const getUserRolesUsingGET_TYPE = function() {
  return 'get'
}
export const getUserRolesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/roles'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUserSpecific
 * request: getUserSpecificUsingGET
 * url: getUserSpecificUsingGETURL
 * method: getUserSpecificUsingGET_TYPE
 * raw_url: getUserSpecificUsingGET_RAW_URL
 * @param id - id
 */
export const getUserSpecificUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/specific/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserSpecificUsingGET_RAW_URL = function() {
  return '/user/specific/{id}'
}
export const getUserSpecificUsingGET_TYPE = function() {
  return 'get'
}
export const getUserSpecificUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/specific/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * verifyEmail
 * request: verifyEmailUsingPOST
 * url: verifyEmailUsingPOSTURL
 * method: verifyEmailUsingPOST_TYPE
 * raw_url: verifyEmailUsingPOST_RAW_URL
 * @param emailVerificationRequest - emailVerificationRequest
 */
export const verifyEmailUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/verify-email'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['emailVerificationRequest'] !== undefined) {
    body = parameters['emailVerificationRequest']
  }
  if (parameters['emailVerificationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: emailVerificationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const verifyEmailUsingPOST_RAW_URL = function() {
  return '/user/verify-email'
}
export const verifyEmailUsingPOST_TYPE = function() {
  return 'post'
}
export const verifyEmailUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/verify-email'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUser
 * request: getUserUsingGET
 * url: getUserUsingGETURL
 * method: getUserUsingGET_TYPE
 * raw_url: getUserUsingGET_RAW_URL
 * @param id - id
 */
export const getUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserUsingGET_RAW_URL = function() {
  return '/user/{id}'
}
export const getUserUsingGET_TYPE = function() {
  return 'get'
}
export const getUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getWorkerSelfEvaluationSummary
 * request: getWorkerSelfEvaluationSummaryUsingGET
 * url: getWorkerSelfEvaluationSummaryUsingGETURL
 * method: getWorkerSelfEvaluationSummaryUsingGET_TYPE
 * raw_url: getWorkerSelfEvaluationSummaryUsingGET_RAW_URL
 * @param workerId - workerId
 */
export const getWorkerSelfEvaluationSummaryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker-self-evaluation-summary'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['workerId'] !== undefined) {
    queryParameters['workerId'] = parameters['workerId']
  }
  if (parameters['workerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getWorkerSelfEvaluationSummaryUsingGET_RAW_URL = function() {
  return '/worker-self-evaluation-summary'
}
export const getWorkerSelfEvaluationSummaryUsingGET_TYPE = function() {
  return 'get'
}
export const getWorkerSelfEvaluationSummaryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker-self-evaluation-summary'
  if (parameters['workerId'] !== undefined) {
    queryParameters['workerId'] = parameters['workerId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * registerWorker
 * request: registerWorkerUsingPOST
 * url: registerWorkerUsingPOSTURL
 * method: registerWorkerUsingPOST_TYPE
 * raw_url: registerWorkerUsingPOST_RAW_URL
 * @param workerRegistrationDto - workerRegistrationDTO
 */
export const registerWorkerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['workerRegistrationDto'] !== undefined) {
    body = parameters['workerRegistrationDto']
  }
  if (parameters['workerRegistrationDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerRegistrationDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const registerWorkerUsingPOST_RAW_URL = function() {
  return '/worker/create'
}
export const registerWorkerUsingPOST_TYPE = function() {
  return 'post'
}
export const registerWorkerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/create'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * editWorker
 * request: editWorkerUsingPUT
 * url: editWorkerUsingPUTURL
 * method: editWorkerUsingPUT_TYPE
 * raw_url: editWorkerUsingPUT_RAW_URL
 * @param workerEditDto - workerEditDTO
 */
export const editWorkerUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/edit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['workerEditDto'] !== undefined) {
    body = parameters['workerEditDto']
  }
  if (parameters['workerEditDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerEditDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const editWorkerUsingPUT_RAW_URL = function() {
  return '/worker/edit'
}
export const editWorkerUsingPUT_TYPE = function() {
  return 'put'
}
export const editWorkerUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/edit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * recalculateRecommendations
 * request: recalculateRecommendationsUsingPOST
 * url: recalculateRecommendationsUsingPOSTURL
 * method: recalculateRecommendationsUsingPOST_TYPE
 * raw_url: recalculateRecommendationsUsingPOST_RAW_URL
 * @param workerId - workerId
 */
export const recalculateRecommendationsUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/recommendation/recalculate'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['workerId'] !== undefined) {
    body = parameters['workerId']
  }
  if (parameters['workerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const recalculateRecommendationsUsingPOST_RAW_URL = function() {
  return '/worker/recommendation/recalculate'
}
export const recalculateRecommendationsUsingPOST_TYPE = function() {
  return 'post'
}
export const recalculateRecommendationsUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/recommendation/recalculate'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchWorker
 * request: searchWorkerUsingPOST
 * url: searchWorkerUsingPOSTURL
 * method: searchWorkerUsingPOST_TYPE
 * raw_url: searchWorkerUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchWorkerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/search'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchWorkerUsingPOST_RAW_URL = function() {
  return '/worker/search'
}
export const searchWorkerUsingPOST_TYPE = function() {
  return 'post'
}
export const searchWorkerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/search'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchWorkerCount
 * request: searchWorkerCountUsingPOST
 * url: searchWorkerCountUsingPOSTURL
 * method: searchWorkerCountUsingPOST_TYPE
 * raw_url: searchWorkerCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchWorkerCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/search/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchWorkerCountUsingPOST_RAW_URL = function() {
  return '/worker/search/count'
}
export const searchWorkerCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchWorkerCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/search/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchMyData
 * request: searchMyDataUsingPOST
 * url: searchMyDataUsingPOSTURL
 * method: searchMyDataUsingPOST_TYPE
 * raw_url: searchMyDataUsingPOST_RAW_URL
 */
export const searchMyDataUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/search/me'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchMyDataUsingPOST_RAW_URL = function() {
  return '/worker/search/me'
}
export const searchMyDataUsingPOST_TYPE = function() {
  return 'post'
}
export const searchMyDataUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/search/me'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * sendVerificationEmail
 * request: sendVerificationEmailUsingPOST
 * url: sendVerificationEmailUsingPOSTURL
 * method: sendVerificationEmailUsingPOST_TYPE
 * raw_url: sendVerificationEmailUsingPOST_RAW_URL
 * @param id - id
 */
export const sendVerificationEmailUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/send-verification-email/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const sendVerificationEmailUsingPOST_RAW_URL = function() {
  return '/worker/send-verification-email/{id}'
}
export const sendVerificationEmailUsingPOST_TYPE = function() {
  return 'post'
}
export const sendVerificationEmailUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/send-verification-email/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getWorkerSummary
 * request: getWorkerSummaryUsingGET
 * url: getWorkerSummaryUsingGETURL
 * method: getWorkerSummaryUsingGET_TYPE
 * raw_url: getWorkerSummaryUsingGET_RAW_URL
 * @param workerId - workerId
 */
export const getWorkerSummaryUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/worker-summary'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['workerId'] !== undefined) {
    queryParameters['workerId'] = parameters['workerId']
  }
  if (parameters['workerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getWorkerSummaryUsingGET_RAW_URL = function() {
  return '/worker/worker-summary'
}
export const getWorkerSummaryUsingGET_TYPE = function() {
  return 'get'
}
export const getWorkerSummaryUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/worker-summary'
  if (parameters['workerId'] !== undefined) {
    queryParameters['workerId'] = parameters['workerId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteWorker
 * request: deleteWorkerUsingDELETE
 * url: deleteWorkerUsingDELETEURL
 * method: deleteWorkerUsingDELETE_TYPE
 * raw_url: deleteWorkerUsingDELETE_RAW_URL
 * @param id - id
 */
export const deleteWorkerUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/worker/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const deleteWorkerUsingDELETE_RAW_URL = function() {
  return '/worker/{id}'
}
export const deleteWorkerUsingDELETE_TYPE = function() {
  return 'delete'
}
export const deleteWorkerUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/worker/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}