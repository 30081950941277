import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Cell, Legend, Pie, PieChart, Tooltip} from 'recharts';

const ResultPieChart = ({chartData, label = ""}) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        let tempData = []
        for (let i = 0; i < chartData.data.length; i++) {
            if (chartData.data[i] > 0) {
                tempData.push({
                    id: i,
                    value: chartData.data[i],
                    name: chartData.labels[i]
                })
            }
        }
        setData(tempData)

    },[chartData, label])

    const getChartWidth = () => {
        return Math.min(window.innerWidth - 120, 900)
    }

    const getChartHeight = () => {
        return Math.min(window.innerHeight / 2, 400)
    }

    const COLORS = [
        '#59CAEA',
        '#8ddf00',
        '#008fff',
        '#40B649',
        '#061d94',
    ];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <Container>
            <PieChart width={getChartWidth()} height={getChartHeight()}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={data}
                    cx="50%"
                    cy="50%"
                    outerRadius={120}
                    fill="#8884d8"
                    labelLine={false}
                    label={renderCustomizedLabel}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip/>
                <Legend wrapperStyle={{width:getChartWidth() - 50, right: 25}}/>
            </PieChart>
        </Container>
    )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;


export default ResultPieChart;