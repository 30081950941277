import {Collapse, IconButton, makeStyles, Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import React, {useState} from "react";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {Colors} from "../../constants/colors";

const SummaryRow = ({competenceType}) => {

    const [open, setOpen] = useState(false);
    const classes = useStyles()

    return (
        <React.Fragment>
            <TableRow className={classes.mainRow}>
                <TableCell width={"10%"}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell width={"50%"}>{competenceType.name}</TableCell>
                <TableCell width={"40%"} align={"center"}>{competenceType.result}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{padding: 0, borderBottom: 'unset'}} colSpan={3}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table>
                            <TableBody>
                                {competenceType.competences.map((competence, index) => (
                                        <TableRow key={index} className={classes.commonRow}>
                                            <TableCell width={"10%"}/>
                                            <TableCell width={"50%"}>{competence.name}</TableCell>
                                            <TableCell width={"40%"} align={"center"}>{competence.result}</TableCell>
                                        </TableRow>
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

const useStyles = makeStyles({
    mainRow: {
        '& > *': {
            color: Colors.GREEN,
            fontWeight: "bold",
        },
    },
    commonRow: {
        '& > *': {},
    }
});

export default SummaryRow;