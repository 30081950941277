import NumberInput from "./NumberInput";
import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import TurquoiseSelect from "../TurquoiseSelect";

const ExpectedChangesRow = ({label, option, setOption, percentage, setPercentage, dataSource}) => {
    const classes = useStyles()

    useEffect(() => {
        if (dataSource === "tak") {
            setOption("dane statystyczne")
        }
    }, [])

    return (
        <div className={classes.item}>
            <div className={classes.label}>{label}</div>
            {dataSource === "nie - dane mieszane" &&
                <div className={classes.input}>
                    <TurquoiseSelect
                        value={option}
                        setValue={setOption}
                        label={"Źródło danych"}
                        noValueLabel={""}
                        possibilities={[
                            {value: "dane własne", label: "dane własne"},
                            {value: "dane statystyczne", label: "dane statystyczne"}
                        ]}
                    />
                </div>
            }
            <div className={classes.input}>
                <NumberInput
                    value={percentage}
                    setValue={setPercentage}
                    label={"Spodziewane zmiany [%]"}
                    disabled={option !== "dane własne"}
                />
            </div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        marginTop: 100,
    },
    item: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 20,
    },
    label: {
        display: "flex",
        flex: 2,
    },
    input: {
        display: "flex",
        flex: 1,
        marginLeft: 30,
    },
}));

export default ExpectedChangesRow;