import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {Colors} from "../../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import OnlyIconButton from "../../buttons/OnlyIconButton";
import styled from "styled-components";
import {FiDownload} from "react-icons/fi";
import {downloadFileAsBlob} from "../../../services/FilesService";
import {downloadExcelFileUsingGETURL} from "../../../swagger/vue-api-client";


const useStyles = makeStyles(theme => ({
    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: Colors.PASTEL_TURQUOISE
        }
    },
    selected: {},
    checkbox: {
        "&checked, &checked:hover": {
            backgroundColor: Colors.BLUE
        }
    },
    checked: {},
}));


const CompanyExcelFilesTableRow = ({row}) => {

    const classes = useStyles();

    async function downloadExcelFile(file) {
        await downloadFileAsBlob(downloadExcelFileUsingGETURL({id: file.id}), file.filename);
    }

    return (
        <React.Fragment>
            <TableRow
                tabIndex={-1}
                key={row.id}
                className={classes.tableRow}
            >
                <TableCell align="left">{row.name}</TableCell>
                <TableCell align="left">{row.filename}</TableCell>
                <TableCell align="left">{row.description}</TableCell>
                <TableCell>
                    <ButtonsContainer>
                        <OnlyIconButton
                            icon={<FiDownload color={Colors.GREEN} size={25}/>}
                            onClick={() => downloadExcelFile(row)}
                            label={"Pobierz plik excel"}
                        />
                    </ButtonsContainer>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export default CompanyExcelFilesTableRow;