import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ButtonWithoutIcon from "../buttons/ButtonWithoutIcon";
import {Colors} from "../../constants/colors";
import {getRoleDependentLink} from "../../services/LinksService";
import Subheader from "../Subheader";
import {downloadDiplomaFile} from "../../services/FilesService";
import {useParams} from "react-router";

const RecommendationSummary = ({recommendation, history}) => {

    const classes = useStyles()
    let {recommendationId} = useParams()

    if (recommendation === null)
        return null

    let attemptLeft = 3 - recommendation.attemptCount


    return (
        <div className={classes.container}>
            <Subheader title={"Test sprawdzający wiedzę"}/>
            <div className={classes.description}>
                {recommendation.quizCompleted ?
                    "Gratulacje! Ukończyłeś e-learning!"
                    :
                    "Aby ukończyć e-learning rozwiąż test dotyczący jego treści."
                }
                <br/>
                <br/>
                Do ukończenia wymagane jest uzyskanie co najmniej 70% wszystkich punktów.
                <br/>
                Możesz rozwiązać ten test maksymalnie trzy razy.
            </div>
            <div className={classes.result}>{recommendation.quizScore === null ? null : "Twój wynik: " + recommendation.quizScore + "%"}</div>
            <div className={classes.result}>{"Pozostałe próby: " + attemptLeft}</div>
            {!recommendation.quizCompleted ? null :
                <div className={classes.button}>
                    <ButtonWithoutIcon
                        label={"Pobierz zaświadczenie"}
                        onClick={() => downloadDiplomaFile(recommendationId, recommendation.name)}
                    />
                </div>
            }
            {attemptLeft === 0 ? null :
                <div className={classes.button}>
                    <ButtonWithoutIcon
                        label={"Rozwiąż test"}
                        onClick={() => history.push(getRoleDependentLink("/sciezka/" + recommendation.id + "/quiz"))}
                    />
                </div>
            }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        width: 760,
        margin: "0 20px",
        height: 300,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    },
    description: {
        marginBottom: "7%",
        fontSize: 17,
        color: Colors.GREY,
        textAlign: "center",
        [theme.breakpoints.down(600)]: {
            textAlign: "center",
            marginTop: 10,
        }
    },
    result: {
        fontSize: 18,
        marginBottom: "2%",
        [theme.breakpoints.down(600)]: {
            textAlign: "center",
            marginTop: 10,
        }
    },
    button: {
        marginTop: "5%",
    }
}));

export default RecommendationSummary;