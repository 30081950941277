import React, {useEffect, useState} from "react";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import {getTrendQuizByIdUsingGET, submitTrendStudyResultsUsingPOST} from "../../swagger/vue-api-client";
import styled from "styled-components";
import {useParams} from "react-router";
import Title from "../../components/Title";
import QuizItems from "../../components/quiz/QuizItems";
import ErrorAndLoadingMessage from "../../components/ErrorAndLoadingMessage";
import ButtonWithoutIcon from "../../components/buttons/ButtonWithoutIcon";
import {toast} from "react-toastify";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";

const FillQuizPage = ({history}) => {

    const [quiz, setQuiz] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const classes = useStyles()
    let {quizId} = useParams()
    let {guestToken} = useParams()

    const handleSubmitQuiz = () => {
        console.log(quizId)
        setIsLoading(true)
        let sthIsNull = false

        quiz.questions.forEach((item, index) => {
            if (!(item.answers.some((ans) => ans.chosen === true))) {
                sthIsNull = true
            }
        })

        if (sthIsNull) {
            setIsLoading(false)
            toast.error("Nie wszystkie zadania zostały rozwiązane.")
        } else {
            submitTrendStudyResultsUsingPOST({quizDto: quiz, guestToken: guestToken})
                .then((response) => {
                    toast.success("Sukces! Zapisano quiz.")
                    setIsLoading(false)
                    history.push("/trend-quiz/ukonczone");
                })
                .catch((error) => {
                    console.log(error.response)
                    if (error.response.status === 409) {
                        toast.error("Ankieta została już przez Ciebie wypełniona.")
                    } else {
                        toast.error("Nie udało się zapisać wyników quizu!")
                    }
                    setIsLoading(false)
                })
        }
    }

    useEffect(() => {
        getTrendQuizByIdUsingGET({trendQuizId: quizId, guestToken: guestToken})
            .then((res) => {
                if (res.data === true) {
                    history.push("/trend-quiz/ukonczone");
                }
                setQuiz(res.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }, [history, quizId, guestToken])

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            {quiz !== null ?
                <div className={classes.content}>
                    <React.Fragment>
                        <Title title={quiz.name}/>
                        <QuizItems quiz={quiz} setQuiz={setQuiz} leftAlign={true}/>
                    </React.Fragment>
                    <ErrorAndLoadingMessage issoading={isLoading} errorMessage={""}/>
                    <ButtonsContainer>
                        <ButtonWithoutIcon
                            label={"Zakończ"}
                            onClick={() => handleSubmitQuiz()}
                        />
                    </ButtonsContainer>
                </div> : null}
            <Footer/>
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

const ButtonsContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default FillQuizPage;