import React from 'react';
import styled from "styled-components";
import {Colors} from "../constants/colors";
import {BsFileRichtext} from "react-icons/bs";

const BrowseFileField = ({selectedFile, setSelectedFile, placeholder}) => {

    const hiddenFileInput = React.useRef(null);

    const getSelectFileButtonLabel = () => {
        if (window.innerWidth > 700) {
            return "Wybierz plik"
        } else {
            return "Wybierz"
        }
    }

    return (
        <Container>
            <BsFileRichtext size={20} color={Colors.LIGHT_GREY}/>
            <FileName>{selectedFile === null ? placeholder : selectedFile.name}</FileName>
            <Button onClick={() => hiddenFileInput.current.click()}>{getSelectFileButtonLabel()}</Button>
            <input
                type="file"
                ref={hiddenFileInput}
                onChange={(event) => setSelectedFile(event.target.files[0])}
                style={{display: 'none'}}
            />
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${Colors.VERY_LIGHT_GREY};
  margin: 0.8em;
  padding-left: 1em;
  width: 90%;
`;

const FileName = styled.div`
  display: flex;
  flex: 2;
  justify-content: flex-start;
  width: 100%;
  height: 18px;
  padding: 1em;
  color: ${Colors.GREY};
  background-color: ${Colors.VERY_LIGHT_GREY};
  border: none;
  font-size: 1em;
`;


const Button = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  height: 18px;
  padding: 1.2em;
  color: ${Colors.ALMOST_BLACK};
  background-color: ${Colors.LIGHTER_GREY};
  text-transform: uppercase;
  border: none;
  font-size: 0.9em;
  transition: opacity 0.3s;
  cursor: pointer;

  &:focus, &:hover, &:active {
    outline: none;
  }

  &:hover, &:active {
    opacity: 0.7;
  }
`;

export default BrowseFileField;