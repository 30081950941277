import React, {useEffect, useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import styled from "styled-components";
import {countTrendQuizResultsUsingGET, getTrendStudyResultsUsingGET} from "../../../swagger/vue-api-client";
import {Dialog, TableFooter, TableHead} from "@material-ui/core";
import DialogTitle from "../../DialogTitle";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Pagination from "../Pagination";
import DialogButtonsContainer from "../../DialogButtonsContainer";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import GetQuizResultDialogRow from "./GetQuizResultDialogRow";
import {Colors} from "../../../constants/colors";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import TableCell from "@material-ui/core/TableCell";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import {makeStyles} from "@material-ui/core/styles";

const GetQuizResultDialog = ({open, setOpen, quiz}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [quizToShow, setQuizToShow] = useState(quiz);
    const [noResult, setNoResult] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [countTrendQuiz, setCountTrendQuiz] = useState("");
    const classes = useStyles();

    useEffect(() => {
        if (quiz !== null) {
            getTrendStudyResultsUsingGET({trendQuizId: quiz.id})
                .then((response) => {
                    setQuizToShow(response.data)
                    if (response.data.questions.length > 0 && response.data.questions[0].answers.length > 0) {
                        for (let i = 0; i < response.data.questions[0].answers.length; ++i) {
                            if (response.data.questions[0].answers[i].answerCount > 0) {
                                setNoResult(false)
                                break;
                            }
                        }
                    }
                    countTrendQuizResultsUsingGET({trendQuizId: quiz.id})
                        .then((response) => {
                        setCountTrendQuiz(response.data)
                    })
                })
                .catch((error) => {
                    setErrorMessage("Wystąpił problem z wyświetleniem wyników")
                });
        }
    }, [quiz])

    let data = []

    data.push({name: "polskie firmy z branży usług opiekuńczych", value: countTrendQuiz.polishDDPCompaniesAmount});
    data.push({name: "zagraniczne firmy z branży usług opiekuńczych", value: countTrendQuiz.foreignDDPCompaniesAmount});
    data.push({name: "uczelnie i jednostki naukowe", value: countTrendQuiz.universitiesAmount});
    data.push({name: "instytucje otoczenia biznesu", value: countTrendQuiz.institutionsAssociatingEntrepreneursAmount});
    data.push({name: "organizatorzy targów", value: countTrendQuiz.fairOrganizersAmount});

    const COLORS = [Colors.BLUE, Colors.TURQUOISE, Colors.GREEN, Colors.MEDIUM_GREEN, Colors.DARK_TURQUOISE];


    return (
        quizToShow !== null ?
            <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'md'}
                    fullWidth={true}>
                <Container>
                    <DialogTitle title={"RAPORT Z ANALIZY TRENDÓW W BRANŻY USŁUG OPIEKUŃCZYCH"}/>
                    {noResult ? (
                        <Message>Brak wyników. Nikt jeszcze nie wypełnił ankiety.</Message>
                    ) : (
                        <TableContainer>
                            <div style={{textAlign: "center"}}>
                                <h3>Dane dotyczące ankiety</h3>
                            </div>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Nazwa
                                        </TableCell>
                                        <TableCell>
                                            Wartość
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            Okres przeprowadzenia ankiety
                                        </TableCell>
                                        <TableCell>
                                            od {countTrendQuiz.quizCreationDate}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Liczba respondentów, w tym:
                                        </TableCell>
                                        <TableCell>
                                            {countTrendQuiz.globalAmount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            polskie firmy z branży usług opiekuńczych
                                        </TableCell>
                                        <TableCell>
                                            {countTrendQuiz.polishDDPCompaniesAmount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            zagraniczne firmy z branży usług opiekuńczych
                                        </TableCell>
                                        <TableCell>
                                            {countTrendQuiz.foreignDDPCompaniesAmount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            uczelnie i jednostki naukowe
                                        </TableCell>
                                        <TableCell>
                                            {countTrendQuiz.universitiesAmount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            instytucje otoczenia biznesu
                                        </TableCell>
                                        <TableCell>
                                            {countTrendQuiz.institutionsAssociatingEntrepreneursAmount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            organizatorzy targów
                                        </TableCell>
                                        <TableCell>
                                            {countTrendQuiz.fairOrganizersAmount}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Analizowana fraza
                                        </TableCell>
                                        <TableCell>
                                            {countTrendQuiz.quizKeyword}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                            <div style={{textAlign: "center", marginTop: 50}}>
                                <h3>Struktura respondentów badania</h3>
                            </div>
                            <div className={classes.chartContainer}>
                                <ResponsiveContainer width="90%" height="100%">
                                    <PieChart width="90%" height="100%">
                                        <Legend/>
                                        <Pie
                                            data={data}
                                            cx="50%"
                                            cy="50%"
                                            label
                                            outerRadius={80}
                                            dataKey="value"
                                        >
                                            {data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                                <br/>
                            </div>
                            <Table>
                                <TableBody>
                                    {quizToShow.questions.map((question, index) =>
                                        <GetQuizResultDialogRow
                                            key={question.id}
                                            question={question}
                                            keyword={countTrendQuiz.quizKeyword}
                                            globalAmount={countTrendQuiz.globalAmount}
                                        />
                                    )}
                                </TableBody>
                                {noResult === false ?
                                    <TableFooter>
                                        <TableRow>
                                            <Pagination
                                                rows={quiz.questions}
                                                page={page}
                                                setPage={setPage}
                                                rowsPerPage={rowsPerPage}
                                                setRowsPerPage={setRowsPerPage}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                    : null}
                            </Table>
                        </TableContainer>
                    )}
                    <ErrorAndLoadingMessage isLoading={false} errorMessage={errorMessage}/>
                    <DialogButtonsContainer>
                        <ButtonWithoutIcon
                            label={"Wróć"}
                            onClick={() => setOpen(false)}
                        />
                    </DialogButtonsContainer>
                </Container>
            </Dialog> :
            <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'md'}
                    fullWidth={true}>
                <DialogTitle title={"RAPORT Z ANALIZY TRENDÓW W BRANŻY USŁUG OPIEKUŃCZYCH"}/>
                <ErrorAndLoadingMessage isLoading={false} errorMessage={errorMessage}/>
            </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 5%;
  width: 90%;
`;

const Message = styled.div`
  font-size: 160%;
  color: ${Colors.GREEN};
  text-align: center;
  margin-bottom: 5%;
`;

const useStyles = makeStyles(theme => ({
    chartContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 300,
        marginBottom: 20,
    },
}));

export default GetQuizResultDialog;