import React from "react";
import Title from "../Title";
import {makeStyles} from "@material-ui/core/styles";
import ElearningTile from "./ElearningTile";
import {Container} from "@material-ui/core";

const ElearningTiles = ({history, elearnings=[]}) => {

    const classes = useStyles()

    return (
        <Container>
            <div><Title title={"E-learningi"}/></div>
            <div className={classes.items}>
                {elearnings.map((item, index) => (
                    <ElearningTile key={index} history={history} elearning={item}/>
                ))}
            </div>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        width: "70%",
        margin: "5% 15%",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
    items: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        [theme.breakpoints.down(1100)]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down(700)]: {
            gridTemplateColumns: "1fr",
        }
    },
    marginLeft: {
        marginLeft: 20,
    }
}));

export default ElearningTiles;