import React, {useEffect, useState} from 'react';
import {checkIsUserAuthorized} from "../../services/LinksService";
import {getFinancialAnalysisProgressOutputDataUsingGET,} from "../../swagger/vue-api-client";
import TurquoiseSelect from "../TurquoiseSelect";
import FinalResultColumnChart from "./FinalResultColumnChart";
import FinalResultTable from "./FinalResultTable";
import Header from "../Header";
import {Colors} from "../../constants/colors";
import {CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const FinalFinancesResult = ({history}) => {

    const classes = useStyles()
    const [dataName, setDataName] = useState('')
    const [possibleOptions, setPossibleOptions] = useState([])
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const changeOption = (newOption) => {
        if (newOption !== '') {
            setData(possibleOptions.filter(option => option.value === newOption)[0].chartData)
        }
        else {
            setData(null)
        }
        setDataName(newOption)
    }

    useEffect(() => {
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((response) => {
                setIsLoading(true);
                return getFinancialAnalysisProgressOutputDataUsingGET()
            })
            .then((res) => {
                let tempOptions = []

                for (let i = 0; i < res.data.length; ++i) {
                    if (res.data[i].workerName === 'Zakres kompetencji') {
                        tempOptions.push({
                            value: res.data[i].workerName,
                            label: 'Wynik całej firmy',
                            chartData: res.data[i].rows,
                        })
                    } else {
                        tempOptions.push({
                            value: res.data[i].workerName,
                            label: res.data[i].workerName,
                            chartData: res.data[i].rows,
                        })
                    }
                }
                setPossibleOptions(tempOptions)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error);
            })
    }, [history])

    console.log(data)

    if (isLoading)
        return <div className={classes.loadingContainer}><CircularProgress/></div>

    return (
        <div>
            <TurquoiseSelect
                value={dataName}
                setValue={changeOption}
                label={"Wyświetlany wynik"}
                noValueLabel={"Wybierz wynik"}
                possibilities={possibleOptions}
            />
            {data !== null ?
                <div>
                    <Header title={"Poziom luki kompetencyjnej"} color={Colors.BLACK}/>
                    <FinalResultColumnChart chartData={data}/>
                    <FinalResultTable data={data}/>
                </div> : null}
        </div>

    )
}

export default FinalFinancesResult;

const useStyles = makeStyles(theme => ({
    loadingContainer: {
        display: "flex",
        flex: 1,
        minHeight: 200,
        justifyContent: "center",
        marginTop: "10%",
        fontSize: 18,
        color: Colors.GREY,
    },
}));