import React from 'react';
import HorizontalMenu from "./HorizontalMenu";
import VerticalMenu from "./VerticalMenu";

const adminMenuItems = [
    {to: "/firmy", label: "Firmy"},
    {to: "/trendy", label: "Trendy"},
]

const employerMenuItems = [
    {to: "/pracownicy", label: "Pracownicy"},
    {to: "/ocenianie", label: "Ocenianie"},
    {to: "/trendy", label: "Trendy"},
    {to: "/materialy", label: "Materiały"},
    {to: "/zalecenia", label: "Zalecenia"},
    {to: "/finanse", label: "Finanse"},
]

const workerMenuItems = [
    {to: "/ocenianie", label: "Ocenianie"},
    {to: "/trendy", label: "Trendy"},
    {to: "/materialy", label: "Materiały"},
    {to: "/sciezka", label: "Ścieżka reskillingu"},
]

const RoleDependantMenu = ({history}) => {

    let role = localStorage.getItem("role");

    const getMenuItems = () => {
        if (role === "ADMIN")
            return adminMenuItems
        else if (role === "EMPLOYER")
            return employerMenuItems
        else if (role === "WORKER")
            return workerMenuItems
        else
            return []
    }

    return (
        <React.Fragment>
            <HorizontalMenu history={history} menuItems={getMenuItems()} />
            <VerticalMenu history={history} menuItems={getMenuItems()} />
        </React.Fragment>
    )
};

export default RoleDependantMenu;