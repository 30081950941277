import React from 'react';
import {Colors} from "../constants/colors";
import {makeStyles} from "@material-ui/core/styles";

const Subheader = ({title, color, marginTop=0, marginBottom=30, justify="center"}) => {

    const classes = useStyles({color, marginTop, marginBottom, justify})

    return (
        <div className={classes.text}>{title}</div>
    );
};

const useStyles = makeStyles(theme => ({
    text: props => ({
        display: "flex",
        alignItems: "center",
        justifyContent: props.justify,
        fontSize: "1.5em",
        color: props.color ? props.color : Colors.TURQUOISE,
        marginTop: props.marginTop,
        marginBottom: props.marginBottom,
        textAlign: props.textAlign,
        [theme.breakpoints.down("sm")]: {
            fontSize: "1em",
        },
    }),
}));

export default Subheader;