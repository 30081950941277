import React, {useEffect, useState} from "react";
import {Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis} from "recharts";
import {Colors} from "../../constants/colors";
import styled from "styled-components";

const SummaryChart = ({result}) => {

    const [data, setData] = useState([]);

    useEffect(() => {
        setData([])

        result.forEach((type, index) => {
                setData(d => [...d, {
                    id: type.id + "t",
                    name: type.name.toUpperCase(),
                    luka: type.result,
                    fill: Colors.GREEN
                }])

                type.competences.forEach((c, i) => {
                        setData(d => [...d, {
                            id: c.id + "t",
                            name: c.name,
                            luka: c.result,
                            fill: Colors.TURQUOISE
                        }])
                })
        })
    },[result])

    const getChartWidth = () => {
        return Math.min(window.innerWidth - 120, 900)
    }

    const getChartHeight = () => {
        return Math.min(window.innerHeight / 3, 300)
    }

    return (
        <Container>
            <BarChart width={getChartWidth()} height={getChartHeight()} data={data}>
                <XAxis dataKey="name" stroke="#ccc" tick={null}/>
                <YAxis domain={[0, 5]} />
                <Tooltip wrapperStyle={{backgroundColor: '#ccc'}}/>
                <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                <Bar dataKey="luka" barSize={30}/>
            </BarChart>
        </Container>
    );
};

const Container = styled.div`
  display: flex;
  margin: 40px 10px 5px 0;
`;

export default SummaryChart;