import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";
import {Tab, Tabs, withStyles} from "@material-ui/core";
import SwipeableViews from 'react-swipeable-views';

const CustomTabs = ({tabs}) => {

    const classes = useStyles()
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div>
            <div className={classes.root}>
                <MyTabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                >
                    {tabs.map((item, index) => (
                        <MyTab label={item.label} key={index}/>
                    ))}
                </MyTabs>
                <SwipeableViews
                    index={value}
                    onChangeIndex={handleChangeIndex}
                    slideClassName={classes.tab}
                >
                    {tabs.map((item, index) => (
                        <TabPanel value={value} index={index} key={index + "tab"}>
                            {item.children}
                        </TabPanel>
                    ))}
                </SwipeableViews>
            </div>
            <div className={classes.printRoot}>
                <MyTabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth"
                >
                    {tabs.map((item, index) => (
                        <MyTab label={item.label} key={index}/>
                    ))}
                </MyTabs>
                {tabs.map((item, index) => (
                    <TabPanel value={value} index={index} key={index + "tab"}>
                        {item.children}
                    </TabPanel>
                ))}
            </div>
        </div>
    )
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    const classes = useStyles()

    return (
        <div
            className={classes.tab}
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div  className={classes.tab}>{children}</div>
            )}
        </div>
    );
}

const MyTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: Colors.TURQUOISE,
    },
})(Tabs);

const MyTab = withStyles((theme) => ({
    root: {
        minWidth: 100,
        marginRight: theme.spacing(4),
        '&:hover': {
            color: Colors.TURQUOISE,
        },
        '&$selected': {
            color: Colors.TURQUOISE,
        },
        '&:focus': {
            color: Colors.TURQUOISE,
        },
    },
    selected: {},
}))((props) => <Tab {...props} />);


const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        },
    },
    root: {
        display: "inline",
        [`@media print`]: {
            display: "none",
        }
    },
    printRoot: {
        display: "none",
        [`@media print`]: {
            display: "inline",
        }
    }
}));

export default CustomTabs;