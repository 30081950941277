import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized, getRoleDependentLink} from "../../services/LinksService";
import ButtonWithoutIcon from "../../components/buttons/ButtonWithoutIcon";
import {useParams} from "react-router";
import {readEvaluationDataUsingGET, saveSurveyUsingPOST, searchEvaluationUsingPOST} from "../../swagger/vue-api-client";
import {getEvaluationWithIdSearchCriteria} from "../../services/SearchService";
import {getTypeLabel} from "../../services/EvaluationService";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Title from "../../components/Title";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import SmallScreenEvaluation from "../../components/evaluation/SmallScreenEvaluation";
import BigScreenEvaluation from "../../components/evaluation/BigScreenEvaluation";
import ErrorAndLoadingMessage from "../../components/ErrorAndLoadingMessage";
import MotivatorsEvaluation from "../../components/evaluation/MotivatorsEvaluation";
import {CircularProgress} from "@material-ui/core";
import {Colors} from "../../constants/colors";

const EvaluationPage = ({history}) => {

    const classes = useStyles()
    let {evaluationId} = useParams()
    const [questions, setQuestions] = useState([])
    // eslint-disable-next-line
    const [evaluation, setEvaluation] = useState(null)
    const [workerName, setWorkerName] = useState(null)
    const [evaluationTypeLabel, setEvaluationTypeLabel] = useState(null)
    const [sent, setSent] = useState(true)
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [triedToFinish, setTriedToFinish] = useState(false);
    const [answers, setAnswers] = useState(new Map())
    const [motivators, setMotivators] = useState([])
    const [checkedMotivators, setCheckedMotivators] = useState([])

    useEffect(() => {
        setIsLoadingData(true)
        let role = localStorage.getItem("role")
        let type = null
        checkIsUserAuthorized(role, history)
            .then((response) => {
                return searchEvaluationUsingPOST({
                    evaluationSearchCriteria:
                        getEvaluationWithIdSearchCriteria(evaluationId)
                })
            })
            .then((res) => {
                let e = res.data[0]
                setEvaluation(e)
                setSent(e.status === "SENT")
                setWorkerName(e.worker.name)
                setEvaluationTypeLabel(getTypeLabel(e.type))
                type = e.type
                return readEvaluationDataUsingGET({evaluationId: evaluationId})

            })
            .then((response) => {
                setQuestions(response.data.questionList.map((item, index) => {
                    return {
                        questionIndex: item.questionIndex,
                        name: item.name,
                        completelyDisagreeWeight: item.completelyDisagreeWeight,
                        ratherDisagreeWeight: item.ratherDisagreeWeight,
                        hardToSayWeight: item.hardToSayWeight,
                        ratherAgreeWeight: item.ratherAgreeWeight,
                        fullyAgreeWeight: item.fullyAgreeWeight,
                        invertedScale: item.invertedScale,
                        answer: item.answer,
                    }
                }))
                let localAnswers = new Map()
                response.data.questionList.forEach((item, index) => {
                    if (item.answer !== null)
                        localAnswers.set(index, item.answer)
                })
                setAnswers(localAnswers)
                if (type === "SELF") {
                    let localCheckedMotivators = []
                    response.data.organisationQuestionList.forEach((item, index) => {
                        if (item.exist) {
                            localCheckedMotivators.push(index)
                        }
                    })
                    setMotivators(response.data.organisationQuestionList)
                    setCheckedMotivators(localCheckedMotivators)
                }
                setIsLoadingData(false)
            })
            .catch((error) => {
                console.log(error.response)
                setIsLoadingData(false)
            })
    }, [evaluationId, history])

    const handleSaveSurvey = (sent) => {

        setIsLoading(true)

        answers.forEach((value, key) => {
            questions[key].answer = value
        })

        saveSurveyUsingPOST({
            surveyRequest: {
                questionList: questions,
                organisationQuestionList: motivators,
                evaluationId: evaluationId,
                sent: sent
            }
        })
            .then((response) => {
                setIsLoading(false)
                if (sent) {
                    history.push(getRoleDependentLink("/ocenianie"))
                } else {
                    toast.success("Sukces! Zapisano dane ankiety.")
                }
            })
            .catch((error) => {
                setIsLoading(false)
                console.log(error.response)
            })
    };

    const handleFinishSurvey = () => {
        setIsLoading(true)

        let finished = true
        motivators.forEach((item, index) => {
            if (item.exist && item.opinion === "") {
                finished = false
            }
        })

        if (answers.size === questions.length && finished) {
            handleSaveSurvey(true)
        } else {
            setIsLoading(false)
            setTriedToFinish(true)
            toast.error("Nie wszystkie pola są wypełnione.")
        }
    }

    const getTitle = () => {
        if (sent)
            return "Podgląd oceny pracownika " + workerName + " " + evaluationTypeLabel
        else if (evaluationTypeLabel === "samoocena")
            return "Wskaż w jakim stopniu zgadzasz się z poniższymi stwierdzeniami"
        else
            return "Wskaż w jakim stopniu zgadzasz się z poniższymi stwierdzeniami w odniesieniu do pracownika " + workerName
    }

    const buttons = (
        <ButtonsContainer>
            <ButtonWithoutIcon
                label={"Zapisz zmiany"}
                onClick={() => handleSaveSurvey(false)}
            />
            <div style={{width: 30}}/>
            <ButtonWithoutIcon
                label={"Zakończ i prześlij"}
                onClick={handleFinishSurvey}
            />
        </ButtonsContainer>
    )

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <Title title={getTitle()}/>
                {isLoadingData ? (<div className={classes.loadingContainer}><CircularProgress/></div>) : (
                    <React.Fragment>
                        <BigScreenEvaluation
                            questions={questions}
                            answers={answers}
                            triedToFinish={triedToFinish}
                            sent={sent}
                        />
                        <SmallScreenEvaluation
                            buttons={sent ? null : buttons}
                            questions={questions}
                            answers={answers}
                            triedToFinish={triedToFinish}
                        />
                        {evaluationTypeLabel === "samoocena" ? (
                            <MotivatorsEvaluation
                                triedToFinish={triedToFinish}
                                motivators={motivators}
                                setMotivators={setMotivators}
                                checkedMotivators={checkedMotivators}
                                setCheckedMotivators={setCheckedMotivators}
                            />
                        ) : null
                        }
                        <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={""}/>
                        {sent ? null : buttons}
                    </React.Fragment>
                )}
                <ToastContainer/>
            </div>
            <Footer/>
        </Container>
    )
};

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        width: "70%",
        margin: "5% 15%",
        [theme.breakpoints.down("md")]: {
            width: "90%",
            margin: "5%",
        }
    },
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "10%",
        fontSize: 18,
        color: Colors.GREY,
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

const ButtonsContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default EvaluationPage;