import React from 'react';
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";

const FloatNumberInput = ({value, setValue, label, disabled=false}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <TextField
                label={label}
                type="number"
                value={value}
                onChange={(event) => setValue(event.target.value)}
                fullWidth={true}
                className={classes.root}
                disabled={disabled}
                placeholder="0.75"
                inputProps={{
                    min: 0,
                    step: "0.25",
                }}
            />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        margin: 10,
        display: "flex",
        flex: 1,
    },
    root: {
        '& label.Mui-focused': {
            color: Colors.TURQUOISE,
        },
        '& .MuiInput-underline:not(.Mui-disabled):after': {
            borderBottomColor: Colors.TURQUOISE,
        },
        '& .MuiInput-underline:not(.Mui-disabled):before': {
            borderBottomColor: Colors.TURQUOISE,
        },
    },
}))

export default FloatNumberInput;