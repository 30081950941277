import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Subheader from "../Subheader";
import {Colors} from "../../constants/colors";
import OrganisationELearnings from "./OrganisationELearnings";

const Structure = ({result}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Subheader title={"Struktura organizacyjna"} justify={"left"}/>
            <div className={classes.content}>
                    <div className={classes.item}>
                        <div>Struktura organizacyjna wymaga zmian pod kątem bieżącego funkcjonowania</div>
                        <div className={classes.answer}>{result.currentStructure}</div>
                    </div>
                    <div className={classes.item}>
                        <div>Struktura organizacyjna wymaga zmian pod kątem wdrożenia nowych trendów</div>
                        <div className={classes.answer}>{result.innovationStructure}</div>
                    </div>
            </div>
            <OrganisationELearnings eLearnings={result.structureELearnings}/>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 50,
        marginBottom: 30,
    },
    content: {
        lineHeight: 1.5,
    },
    item: {
        display: "flex",
        marginBottom: 15,
        color: Colors.GREY,
    },
    answer: {
        marginLeft: "10%",
        color: Colors.BLACK,
    },
}));

export default Structure;