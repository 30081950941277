import React, {useEffect, useState} from "react";
import {Bar, BarChart, CartesianGrid, Tooltip, XAxis, YAxis, Cell, LabelList} from "recharts";
import {Colors} from "../../constants/colors";
import {formatNumber} from "../../services/NumberService";
import {makeStyles} from "@material-ui/core/styles";

const ResultColumnChart = ({chartData, nameOfKey = "koszt", rotate = true}) => {

    const [data, setData] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        const newElement = (index, chartData) => {
            let newElem = {}
            newElem['id'] = index + 1
            newElem['name'] = chartData.labels[index]
            newElem[nameOfKey] = formatNumber(chartData.data[index])

            return newElem
        }

        let tempData = []
        for (let i = 0; i < Math.min(10, chartData.data.length); ++i) {
            tempData.push(newElement(i, chartData))
        }

        setData(tempData)
    },[chartData, nameOfKey])

    const getChartWidth = () => {
        return Math.min(window.innerWidth - 70 , rotate ? 1300 : 700)
    }

    const getChartHeight = () => {
        return Math.min(window.innerHeight / 2, rotate ? 600 : 400)
    }

    return (
        <div className={classes.container}>
            <div className={classes.chartContainer}>
                {rotate ?
                    <BarChart
                        width={getChartWidth()}
                        height={getChartHeight()}
                        data={data}
                        margin={{ top: 0, right: 0, bottom: 0, left: 10}}
                    >
                        <Tooltip wrapperStyle={{backgroundColor: '#ccc'}}/>
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                        <XAxis
                            dataKey="name"
                            stroke="#ccc"
                            tick={null}/>
                        <YAxis/>
                        <Bar isAnimationActive={false} barSize={25} dataKey={nameOfKey}>
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={Colors.GREEN} />
                            ))}
                        </Bar>
                    </BarChart> :
                    <BarChart
                        width={getChartWidth()}
                        height={getChartHeight()}
                        data={data}
                    >
                        <Tooltip wrapperStyle={{backgroundColor: '#ccc'}}/>
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                        <XAxis
                            dataKey="name"
                            stroke="#ccc"
                            tick={null}/>
                        <YAxis/>
                        <Bar isAnimationActive={false} barSize={25} dataKey={nameOfKey} >
                            {data.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={Colors.GREEN} />
                            ))}
                        </Bar>
                    </BarChart>}
            </div>
            <div className={classes.printChartContainer}>
                {rotate ?
                <BarChart
                    width={710}
                    height={getChartHeight()}
                    data={data}
                    margin={{ top: 0, right: 0, bottom: 0, left: 130}}
                >
                    <Tooltip wrapperStyle={{backgroundColor: '#ccc'}}/>
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                    <XAxis
                        height={350}
                        dataKey="name"
                        stroke="#ccc"
                        interval={0}
                        style={{textAnchor: 'end'}}
                        angle ={-35}/>
                    <YAxis/>
                    <Bar isAnimationActive={false} barSize={25} dataKey={nameOfKey}>
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`}  fill={Colors.GREEN} />
                        ))}
                    </Bar>
                </BarChart> :
                <BarChart
                    width={700}
                    height={450}
                    data={data}
                >
                    <Tooltip wrapperStyle={{backgroundColor: '#ccc'}}/>
                    <CartesianGrid stroke="#ccc" strokeDasharray="5 5"/>
                    <XAxis
                        height={250}
                        dataKey="name"
                        stroke="#ccc"
                        tick={null}/>
                    <YAxis/>
                    <Bar isAnimationActive={false} barSize={25} dataKey={nameOfKey}>
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={Colors.GREEN} />
                        ))}
                        <LabelList dataKey="name" position="bottom"/>
                    </Bar>
                </BarChart>}
            </div>
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    chartContainer: {
        display: "flex",
        margin: "40px 10px 5px 0",
        justifyContent: "center",
        alignItems: "center",
        [`@media print`]: {
            display: "none",
        }
    },
    printChartContainer: {
        display: "none",
        margin: "40px 10px 5px 0",
        justifyContent: "center",
        alignItems: "center",
        [`@media print`]: {
            display: "flex",
        }
    },
    container: {
        display: "block",
    }
}));

export default ResultColumnChart;