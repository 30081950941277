import React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {TableCell, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core/styles";
import Result3TableRow from "./Result3TableRow";

const Result3Table = ({result, workers, showDetails}) => {

    const height = window.innerHeight
    const classes = useStyles({height})

    return (
        <div>
            <TableContainer className={classes.container}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headCell}>Zakres kompetencji</TableCell>
                            <TableCell className={classes.bigHeadCell}>Opis</TableCell>
                            <TableCell className={classes.headCell}>
                                Wzrost lojalności / spadek rotacji (spadek kosztów rekrutacji) [PLN]
                            </TableCell>
                            {showDetails ? workers.map((item, index) => (
                                <TableCell className={classes.verticalText} key={index}>{item}</TableCell>
                            )) : null}
                            <TableCell className={classes.headCell}>
                                Poprawa motywacji i zadowolenia pracowników (wzrost wydajności) [PLN]
                            </TableCell>
                            {showDetails ? workers.map((item, index) => (
                                <TableCell className={classes.verticalText} key={index}>{item}</TableCell>
                            )) : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.map((row, index) => <Result3TableRow key={index} row={row} showDetails={showDetails}/>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer className={classes.printContainer}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className={classes.headCell}>Zakres kompetencji</TableCell>
                            <TableCell className={classes.bigHeadCell}>Opis</TableCell>
                            <TableCell className={classes.headCell}>
                                Wzrost lojalności / spadek rotacji (spadek kosztów rekrutacji) [PLN]
                            </TableCell>
                            {showDetails ? workers.map((item, index) => (
                                <TableCell className={classes.verticalText} key={index}>{item}</TableCell>
                            )) : null}
                            <TableCell className={classes.headCell}>
                                Poprawa motywacji i zadowolenia pracowników (wzrost wydajności) [PLN]
                            </TableCell>
                            {showDetails ? workers.map((item, index) => (
                                <TableCell className={classes.verticalText} key={index}>{item}</TableCell>
                            )) : null}
                        </TableRow>
                        {result.map((row, index) => <Result3TableRow key={index} row={row} showDetails={showDetails}/>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: props => ({
        maxHeight: props.height !== null ? props.height * 0.75 : 500,
        marginTop: 80,
        display: "inline",
        [`@media print`]: {
            display: "none",
        }
    }),
    printContainer: props => ({
        maxHeight: props.height !== null ? props.height * 0.75 : 500,
        marginTop: 80,
        display: "none",
        [`@media print`]: {
            display: "inline",
            overflow: "visible",
        }
    }),
    verticalText: {
        writingMode: "vertical-lr",
        textOrientation: "mixed",
        textAlign: "center",
        fontWeight: "bold"
    },
    headCell: {
        textAlign: "center",
        minWidth: 180,
        [`@media print`]: {
            minWidth: 0,
            fontWeight: "bold"
        }
    },
    bigHeadCell: {
        textAlign: "center",
        minWidth: 500,
        [`@media print`]: {
            minWidth: 0,
            fontWeight: "bold"
        }
    },
}));

export default Result3Table;