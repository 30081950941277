import React, {useEffect, useState} from "react";
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import DevelopmentPathHeader from "./DevelopmentPathHeader";
import DevelopmentPoint from "./DevelopmentPoint";

const DevelopmentPath = ({recommendation, history}) => {

    const classes = useStyles()
    const [points1, setPoints1] = useState([]);
    const [points2, setPoints2] = useState([]);
    const [incompletePoints1, setIncompletePoints1] = useState([]);
    const [incompletePoints2, setIncompletePoints2] = useState([]);
    const [allCompleted, setAllCompleted] = useState(false);

    useEffect(() => {
        let localPoints1 = [];
        let localPoints2 = [];
        let incompleteLocalPoints1 = [];
        let incompleteLocalPoints2 = [];

        recommendation.recommendationList.forEach((item, index) => {
            if (item.done) {
                if (item.stage === 1)
                    localPoints1.push(item)
                else if (item.stage === 2)
                    localPoints2.push(item)
            } else {
                if (item.stage === 1)
                    incompleteLocalPoints1.push(item)
                else if (item.stage === 2)
                    incompleteLocalPoints2.push(item)
            }
        })

        setPoints1(localPoints1)
        setPoints2(localPoints2)
        setIncompletePoints1(incompleteLocalPoints1)
        setIncompletePoints2(incompleteLocalPoints2)
        setAllCompleted(incompleteLocalPoints1.length == 0 && incompleteLocalPoints2.length == 0)

    }, [recommendation.recommendationList]);

    return (
        <div className={classes.container}>
            <DevelopmentPathHeader recommendation={recommendation} fontColor={Colors.TURQUOISE}/>
            <div className={classes.path}>
                <DevelopmentPoint
                    key={"start"}
                    title={"start"}
                    isFirst={true}
                    color1={Colors.BLUE}
                    color2={Colors.BLUE}
                    complete={true}
                    id={null}
                    history={history}
                    recommendation={recommendation}
                />
                {points1.map((item, index) => (
                    <DevelopmentPoint
                        key={index.toString() + "p1"}
                        title={item.name}
                        description={item.description}
                        color1={Colors.BLUE}
                        color2={Colors.BLUE}
                        complete={true}
                        id={item.id}
                        history={history}
                        recommendation={recommendation}
                    />))}
                {points2.map((item, index) => (
                    <DevelopmentPoint
                        key={index.toString() + "p2"}
                        title={item.name}
                        description={item.description}
                        color1={index === 0 ? Colors.BLUE : Colors.GREEN}
                        color2={Colors.GREEN}
                        complete={true}
                        id={item.id}
                        history={history}
                        recommendation={recommendation}
                    />))}
                {incompletePoints1.map((item, index) => (
                    <DevelopmentPoint
                        key={index.toString() + "ip1"}
                        title={item.name}
                        description={item.description}
                        color1={Colors.BLUE}
                        color2={Colors.BLUE}
                        complete={false}
                        id={item.id}
                        history={history}
                        recommendation={recommendation}
                    />))}
                {incompletePoints2.map((item, index) => (
                    <DevelopmentPoint
                        key={index.toString() + "ip2"}
                        title={item.name}
                        description={item.description}
                        color1={index === 0 ? Colors.BLUE : Colors.GREEN}
                        color2={Colors.GREEN}
                        complete={false}
                        id={item.id}
                        history={history}
                        recommendation={recommendation}
                    />))}
                    <DevelopmentPoint
                        key={"meta"}
                        title={"meta"}
                        isLast={true}
                        color1={Colors.BLUE}
                        color2={Colors.BLUE}
                        complete={allCompleted}
                        id={null}
                        history={history}
                        recommendation={recommendation}
                    />
            </div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        marginBottom: 100,
        [theme.breakpoints.down(800)]: {
            paddingTop: 30,
            flexDirection: "column",
            alignItems: "flex-start",
            margin: "30px 10px",
        }
    },
    line: {
        width: 60,
        height: 5,
        backgroundColor: Colors.TURQUOISE,
        [theme.breakpoints.down(800)]: {
            display: "none",
        }
    },
    lineIncomplete: {
        width: 60,
        height: 5,
        backgroundColor: Colors.LIGHTER_GREY,
        [theme.breakpoints.down(800)]: {
            display: "none",
        }
    },
    pointWithLabelBigScreen: {
        display: "flex",
        justifyContent: "row",
        alignItems: "center",
        [theme.breakpoints.down(800)]: {
            display: "none",
        }
    },
    pointWithLabelSmallScreen: {
        display: "flex",
        justifyContent: "row",
        alignItems: "center",
        [theme.breakpoints.up(800)]: {
            display: "none",
        }
    },
    itemLabel: {
        marginLeft: 17,
        [theme.breakpoints.up(800)]: {
            display: "none",
        },
    },
    path: {
        display: "flex",
        marginLeft: "15%",
        marginBottom: 20,
        [theme.breakpoints.down(800)]: {
            flexDirection: "column",
        },
    },
}));

export default DevelopmentPath;