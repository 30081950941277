import React from 'react';
import {Colors} from "../constants/colors";
import {makeStyles} from "@material-ui/core/styles";

const TitleWithButton = ({title, button}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.text}>{title}</div>
            {button}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 30,
        [theme.breakpoints.down(600)]: {
            flexDirection: "column",
            marginBottom: 20,
        }
    },
    text: {
        display: "flex",
        textTransform: "uppercase",
        color: Colors.BLACK,
        padding: 5,
        marginRight: 30,
        borderRadius: 10,
        backgroundColor: Colors.LIGHTER_GREY,
        fontSize: "1.8em",
        [theme.breakpoints.down(600)]: {
            fontSize: "1.5em",
            marginRight: 0,
            marginBottom: "5%",
            textAlign: "center",
        },
        [theme.breakpoints.down(450)]: {
            fontSize: "1.2em",
        }
    }
}));

export default TitleWithButton;