import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import {RiLockPasswordLine} from "react-icons/ri";
import InputField from "./InputField";
import {Colors} from "../constants/colors";
import ErrorAndLoadingMessage from "./ErrorAndLoadingMessage";
import ButtonWithoutIcon from "./buttons/ButtonWithoutIcon";
import {editUserUsingPOST} from "../swagger/vue-api-client";
import DialogTitle from "./DialogTitle";
import DialogButtonsContainer from "./DialogButtonsContainer";
import {makeStyles} from "@material-ui/core/styles";
import {IoPerson} from "react-icons/all";

const EditCompanyByEmployerDialog = ({open, setOpen, user, setUser}) => {

    const classes = useStyles()

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [userFirstName, setUserFirstName] = useState(user.firstName);
    const [userLastName, setUserLastName] = useState(user.lastName);
    const [userEmail, setUserEmail] = useState(user.email);
    const [oldUser, setOldUser] = useState(user);

    if(user !== oldUser) {
        setOldUser(user)
        setUserFirstName(user.firstName)
        setUserLastName(user.lastName)
        setUserEmail(user.email)
    }

    const handleDataChange = () => {
        setIsLoading(true);
        editUserUsingPOST({
            editUserRequest: {
                firstName: userFirstName,
                lastName: userLastName,
                email: userEmail,
            }
        })
            .then((res) => {
                setUser(res.data)
                setIsLoading(false)
                setOpen(false)
            })
            .catch((error) => {
                setErrorMessage("Podano niepoprawne dane.")
                setIsLoading(false)
            })
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle title={"Zmień dane użytkownika"}/>
            <div className={classes.container}>
                <InputField
                    type={"text"}
                    value={userFirstName}
                    setValue={setUserFirstName}
                    placeholder={"Imie"}
                    icon={<IoPerson size={20} color={Colors.LIGHT_GREY}/>}
                />
                <InputField
                    type={"text"}
                    value={userLastName}
                    setValue={setUserLastName}
                    placeholder={"Nazwisko"}
                    icon={<IoPerson size={20} color={Colors.LIGHT_GREY}/>}
                />
                <InputField
                    type={"text"}
                    value={userEmail}
                    setValue={setUserEmail}
                    placeholder={"Email"}
                    icon={<RiLockPasswordLine size={20} color={Colors.LIGHT_GREY}/>}
                />
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        onClick={() => setOpen(false)}
                        label={"Anuluj"}
                    />
                    <ButtonWithoutIcon
                        label={"Zmień"}
                        backgroundColor={Colors.GREEN}
                        onClick={handleDataChange}
                    />
                </DialogButtonsContainer>
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "7%",
    },
}));

export default EditCompanyByEmployerDialog;