import React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {TableCell, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Result5TableRow from "./Result5TableRow";
import {makeStyles} from "@material-ui/core/styles";

const Result5Table = ({result}) => {

    const height = window.innerHeight
    const classes = useStyles({height})

    return (
        <div>
            <TableContainer className={classes.container}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Zakres kompetencji</TableCell>
                            <TableCell>Opis</TableCell>
                            <TableCell>Wynik [PLN]</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.map((row, index) => <Result5TableRow key={index} row={row}/>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer className={classes.printContainer}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>Zakres kompetencji</TableCell>
                            <TableCell>Opis</TableCell>
                            <TableCell>Wynik [PLN]</TableCell>
                        </TableRow>
                        {result.map((row, index) => <Result5TableRow key={index} row={row}/>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: props => ({
        maxHeight: props.height !== null ? props.height * 0.75 : 500,
        marginTop: 80,
        display: "inline",
        [`@media print`]: {
            display: "none",
        }
    }),
    printContainer: props => ({
        maxHeight: props.height !== null ? props.height * 0.75 : 500,
        marginTop: 80,
        display: "none",
        [`@media print`]: {
            display: "inline",
            overflow: "visible",
        }
    }),
}));

export default Result5Table;