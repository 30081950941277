import React, {useState} from 'react';
import {Colors} from "../../constants/colors";
import OutlineButton from "../buttons/OutlineButton";
import Header from "../Header";
import ErrorAndLoadingMessage from "../ErrorAndLoadingMessage";
import {registerCompanyUsingPOST as registerCompany} from "../../swagger/vue-api-client";
import {makeStyles} from "@material-ui/core/styles";
import {fileToBase64} from "../../services/FilesService";
import SignUpCompanyForm from "./SignUpCompanyForm";
import SignUpEmployerForm from "./SignUpEmployerForm";

const SignUpForm = ({history}) => {

    const classes = useStyles()

    const [companyName, setCompanyName] = useState("");
    const [companyEmail, setCompanyEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [description, setDescription] = useState("");
    const [agreementForDataSharing, setAgreementForDataSharing] = useState(false);
    const [userName, setUserName] = useState("");
    const [userSurname, setUserSurname] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordRepeat, setPasswordRepeat] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [verify, setVerify] = useState(false)

    const [selectedFile, setSelectedFile] = useState(null)

    const getIconColor = (value) => {
        if (verify && value === "")
            return Colors.RED
        else
            return Colors.LIGHT_GREY
    }

    const getCompanyRegistrationData = (encodedData) => {
        let ans = {
            companyName: companyName,
            companyEmail: companyEmail,
            phoneNumber: phoneNumber,
            description: description,
            agreementForDataSharing: agreementForDataSharing,
            userName: userName,
            userSurname: userSurname,
            userEmail: userEmail,
            password: password,
        }

        if (encodedData !== null) {
            ans.logoFile = {
                encodedData: encodedData,
                filename: selectedFile.name,
                name: selectedFile.name
            }
        }

        return ans
    }

    const handleSignUp = (companyName, companyEmail, phoneNumber, description, agreementForDataSharing, userName, userSurname, userEmail, password, passwordRepeat) => {
        const emailRegex = new RegExp("^[\\w+-.]+@([\\w-]+\\.)+[\\w-]{2,4}$")
        setIsLoading(true)
        if (companyEmail === "" || companyName === "" || password === "" || phoneNumber === "" || userEmail === "" || userName === "" || userSurname === "") {
            setErrorMessage("Niekompletne dane")
            setVerify(true)
            setIsLoading(false)
        } else if (companyName.length > 100) {
            setErrorMessage("Nazwa firmy nie może być dłuższa niż 100 znaków")
            setIsLoading(false)
        } else if (description.length > 400) {
            setErrorMessage("Opis firmy nie może być dłuższy niż 400 znaków")
            setIsLoading(false)
        } else if (userName.length > 100) {
            setErrorMessage("Imię nie może być dłuższe niż 100 znaków")
            setIsLoading(false)
        } else if (userSurname.length > 100) {
            setErrorMessage("Nazwisko nie może być dłuższe niż 100 znaków")
            setIsLoading(false)
        } else if (!emailRegex.test(companyEmail)) {
            setErrorMessage("Email firmy ma niepoprawny format")
            setIsLoading(false)
        } else if (!emailRegex.test(userEmail)) {
            window.console.log('XXX' + userEmail + 'XXX')
            setErrorMessage("Email użytkownika ma niepoprawny format")
            setIsLoading(false)
        } else if (password !== passwordRepeat) {
            setErrorMessage("Podane hasła są różne")
            setIsLoading(false)
        } else if (password.length < 8) {
            setErrorMessage("Hasło musi mieć co najmniej 8 znaków")
            setIsLoading(false)
        } else {
            if (selectedFile === null) {
                registerCompany({companyRegistrationDto: getCompanyRegistrationData(null)})
                    .then((response) => {
                        setIsLoading(false)
                        history.push("/rejestracja/ukonczona")
                    })
                    .catch((error) => {
                        setIsLoading(false)
                        if (error.response && error.response.data && typeof error.response.data === 'string') {
                            setErrorMessage(error.response.data)
                        } else {
                            setErrorMessage("Podano niepoprawne dane")
                        }
                    })
            } else if (selectedFile.name.match(/.+\.(png|jpeg|jpg)$/i) === null) {
                setErrorMessage("Niedozwolony format pliku")
                setIsLoading(false)
            } else {
                setIsLoading(true)
                fileToBase64(selectedFile)
                    .then((encodedData) => {
                        registerCompany({companyRegistrationDto: getCompanyRegistrationData(encodedData)})
                            .then((response) => {
                                setIsLoading(false)
                                history.push("/rejestracja/ukonczona")
                            })
                            .catch((error) => {
                                setIsLoading(false)
                                if (error.response && error.response.data && typeof error.response.data === 'string') {
                                    setErrorMessage(error.response.data)
                                } else {
                                    setErrorMessage("Podano niepoprawne dane")
                                }
                            });
                    })
            }
        }
    };

    return (
        <div className={classes.container}>
            <Header title={"Utwórz swoje konto"} color={Colors.TURQUOISE}/>
            <div className={classes.form}>
                <SignUpCompanyForm
                    companyName={companyName} setCompanyName={setCompanyName}
                    companyEmail={companyEmail} setCompanyEmail={setCompanyEmail}
                    phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
                    description={description} setDescription={setDescription}
                    selectedFile={selectedFile} setSelectedFile={setSelectedFile}
                    agreementForDataSharing={agreementForDataSharing} setAgreementForDataSharing={setAgreementForDataSharing}
                    getIconColor={getIconColor}
                />
                <SignUpEmployerForm
                    userName={userName} setUserName={setUserName}
                    userSurname={userSurname} setUserSurname={setUserSurname}
                    userEmail={userEmail} setUserEmail={setUserEmail}
                    password={password} setPassword={setPassword}
                    passwordRepeat={passwordRepeat} setPasswordRepeat={setPasswordRepeat}
                    getIconColor={getIconColor}
                />
            </div>
            <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
            <OutlineButton
                label={"Utwórz konto"}
                onClick={() => handleSignUp(companyName, companyEmail, phoneNumber, description, agreementForDataSharing === "true" ? 1 : 0, userName, userSurname, userEmail, password, passwordRepeat)}
            />
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        backgroundColor: Colors.WHITE,
        flex: 3,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: 30,
        [theme.breakpoints.up("lg")]: {
            marginTop: "8%",
        }
    },
    form: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        [theme.breakpoints.down("sm")]: {
            width: "80%",
        }
    },
    companyData: {},
    employerData: {
        marginTop: 50,
    },
}));

export default SignUpForm;