import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";
import FileDownload from "./FileDownload";

const FilePreview = ({file}) => {

    const classes = useStyles()

    const getWidth = () => {
        if (window.innerWidth >= 400) {
            return Math.min(window.innerWidth - 200, 800)
        } else {
            return window.innerWidth - 150
        }
    }


    if (file.contentText === null || file.contentText === "") {
        return (
            <div className={classes.container}>
                <div className={classes.noLinkDescription}>{file.description}</div>
            </div>
        )
    }

    if (file.type === "OTHER") {
        return (
            <FileDownload file={file} width={getWidth()}/>
        )
    }

    return (
        <div className={classes.container}>
            <iframe width={getWidth().toString()} height="420" frameBorder="0" title={file.description}
                    src={file.contentText}/>
            <div className={classes.description}>{file.description}</div>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    },
    description: {
        margin: "40px 25px 0 25px",
        paddingTop: 30,
        borderTop: "solid 1px black",
        fontSize: 17,
        color: Colors.GREY,
        textAlign: "justify",
        textJustify: "inter-word",
    },
    noLinkDescription: {
        fontSize: 19,
        color: Colors.TURQUOISE,
        textAlign: "justify",
        textJustify: "inter-word",
    },
}));

export default FilePreview;