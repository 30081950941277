import React from "react";
import TurquoiseSelect from "../TurquoiseSelect";

const ValueTypeSelect = ({value, setValue}) => {

    return (
        <TurquoiseSelect
            value={value}
            setValue={setValue}
            label={"Źródło danych"}
            noValueLabel={""}
            possibilities={[
                {value: "wartość podana samodzielnie", label: "dane własne"},
                {value: "wartość z bazy danych statystycznych", label: "dane statystyczne"}
            ]}
        />
    );
}

export default ValueTypeSelect;