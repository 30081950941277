import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized} from "../../services/LinksService";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import Title from "../../components/Title";
import CustomTabs from "../../components/financesResult/CustomTabs";
import Result1Table from "../../components/financesResult/Result1Table";
import {FormControlLabel} from "@material-ui/core";
import TurquoiseCheckbox from "../../components/TurquoiseCheckbox";
import TurquoiseSwitch from "../../components/TurquoiseSwitch";
import {formatNumber} from "../../services/NumberService";
import {getFinancialAnalysisStepOneOutputOneDataUsingGET} from "../../swagger/vue-api-client";
import {cloneDeep} from "lodash";
import ResultColumnChart from "../../components/financesResult/ResultColumnChart";
import ButtonWithoutIcon from "../../components/buttons/ButtonWithoutIcon";

const FinancialResult1 = ({history}) => {

    const classes = useStyles()
    const [workShopResult, setWorkshopResult] = useState([])
    const [advisoryResult, setAdvisoryResult] = useState([])
    const [practicalResult, setPracticalResult] = useState([])

    const [workers, setWorkers] = useState([])
    const [chartWorkshopCostData, setChartWorkshopData] = useState(null)
    const [chartAdvisoryCostData, setChartAdvisoryData] = useState(null)
    const [chartPracticalCostData, setPracticalCostData] = useState(null)
    const [showDetails, setShowDetails] = useState(false)
    const [isVariant2, setIsVariant2] = useState(false)

    useEffect(() => {
        let tempWorkers = []

        const setData = (setResult, data, type) => {
            setResult(data.rows.map((item, index) => {
                let variantOne = null
                let variantTwo = null
                if (type === "workshop") {
                    variantOne = item.workshopCostTableRowVariantOne;
                    variantTwo = item.workshopCostTableRowVariantTwo;
                }
                else if (type === "practical") {
                    variantOne = item.practicalCostTableRow
                }
                else if (type === "advisory") {
                    variantOne = item.advisoryCostTableRowVariantOne;
                    variantTwo = item.advisoryCostTableRowVariantTwo;
                }

                let help = Object.assign(item)

                if (variantOne !== null) {

                    help.sumSingle1 = formatNumber(variantOne.singleWorkerTotalCost)
                    help.sumAll1 = formatNumber(variantOne.allSelectedWorkerTotalCost)
                    help.workers1 = variantOne.workerList.map((w, iw) => formatNumber(w.cost))
                }

                if (variantTwo !== null) {
                    help.sumSingle2 = formatNumber(variantTwo.singleWorkerTotalCost)
                    help.sumAll2 = formatNumber(variantTwo.allSelectedWorkerTotalCost)
                    help.workers2 = variantTwo.workerList.map((w, iw) => formatNumber(w.cost))
                    tempWorkers = variantTwo.workerList.map((w, iw) => w.name)
                }

                return help
            }))
        }

        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((response) => {
                return getFinancialAnalysisStepOneOutputOneDataUsingGET()
            })
            .then((res) => {
                console.log(res.data)
                setChartWorkshopData(res.data.chartWorkshopCostData)
                setChartAdvisoryData(res.data.chartAdvisoryCostData)
                setPracticalCostData(res.data.chartPracticalCostData)

                let workshopData = cloneDeep(res.data)
                let advisoryData = cloneDeep(res.data)
                let practicalData = cloneDeep(res.data)

                setData(setWorkshopResult, workshopData, "workshop")
                setData(setAdvisoryResult, advisoryData, "advisory")
                setData(setPracticalResult, practicalData, "practical")

                setWorkers(tempWorkers)
            })
            .catch((error) => {
                console.log(error);
            })
    }, [history])

    const print = () => {
        window.print();
    }

    const getTabContent = (result, twoVariants, chartData) => {
        return (
            <div className={classes.tableContainer}>
                {twoVariants ? (
                    <div className={classes.settings}>
                        <div className={classes.twoSideSwitch}>
                            <div className={classes.leftSwitchLabel}>Wariant 1: zakup usługi</div>
                            <FormControlLabel
                                control={<TurquoiseSwitch value={isVariant2} setValue={setIsVariant2}/>}
                                label="Wariant 2: realizacja we własnym zakresie"
                            />
                        </div>
                        <div className={classes.settingsCheckbox}>
                            <FormControlLabel
                                control={<TurquoiseCheckbox checked={showDetails} onClick={() => setShowDetails(c => !c)}/>}
                                label={"Pokaż szczegóły"}
                            />
                        </div>
                    </div>
                ) : (
                    <div className={classes.settingsRight}>
                        <div className={classes.settingsCheckbox}>
                            <FormControlLabel
                                control={<TurquoiseCheckbox checked={showDetails} onClick={() => setShowDetails(c => !c)}/>}
                                label={"Pokaż szczegóły"}
                            />
                        </div>
                    </div>
                )}
                {chartData !== null ? <ResultColumnChart chartData={chartData} rotate={false}/> : null}
                <Result1Table result={result} workers={workers} isVariant2={isVariant2 && twoVariants} showDetails={showDetails}/>
                <div className={classes.buttonsContainer}>
                    <ButtonWithoutIcon
                        label={"Drukuj"}
                        onClick={() => print()}
                    />
                </div>
            </div>
        )
    }

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <Title title={"Wykaz kosztów stanowiskowych"}/>
                <CustomTabs
                    tabs={[
                        {label: "Część szkoleniowo - warsztatowa", children: getTabContent(workShopResult, true, chartWorkshopCostData)},
                        {label: "Część doradczo - instruktażowa i superwizja", children: getTabContent(advisoryResult, true, chartAdvisoryCostData)},
                        {label: "Część praktyczna", children: getTabContent(practicalResult, false, chartPracticalCostData)},
                    ]}
                />
            </div>
            <Footer/>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "90%",
        margin: "5%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
    tableContainer: {
        marginTop: 20,
    },
    settings: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 10,
    },
    settingsRight: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 10,
    },
    settingsCheckbox: {
        [`@media print`]: {
            display: "none"
        }
    },
    twoSideSwitch: {
        display: "flex",
        alignItems: "center",
    },
    leftSwitchLabel: {
        marginRight: 12,
    },
    buttonsContainer : {
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [`@media print`]: {
            display: "none"
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;


export default FinancialResult1;