import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import {editCompanyUsingPUT} from "../../../swagger/vue-api-client";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import DialogTitle from "../../DialogTitle";
import DialogButtonsContainer from "../../DialogButtonsContainer";
import {HiDocumentText, HiOutlineOfficeBuilding, HiOutlinePhone} from "react-icons/hi";
import InputField from "../../InputField";
import {IoMailOutline} from "react-icons/io5";
import LongInputField from "../../LongInputField";
import BrowseFileField from "../../BrowseFileField";
import Logo from "../../Logo";
import {fileToBase64} from "../../../services/FilesService";

const EditCompanyDialog = ({open, setOpen, company, setCompanies}) => {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [oldCompany, setOldCompany] = useState(company);
    const [companyName, setCompanyName] = useState(company.name);
    const [companyEmail, setCompanyEmail] = useState(company.email);
    const [phoneNumber, setPhoneNumber] = useState(company.phoneNumber);
    const [description, setDescription] = useState(company.description);
    const [publicAccess, setPublicAccess] = useState(company.publishedForPublicAccess);
    const [logo, setLogo] = useState(company.logoFile);
    const [uploadedUrl, setUploadedUrl] = useState("");

    if(oldCompany !== company) {
        setOldCompany(company);
        setCompanyName(company.name);
        setCompanyEmail(company.email);
        setPhoneNumber(company.phoneNumber);
        setDescription(company.description);
        setPublicAccess(company.publishedForPublicAccess);
        setLogo(company.logoFile);
    }

    const setNewLogo = (newLogo) => {
        setLogo(newLogo)
        setUploadedUrl(URL.createObjectURL(newLogo))
    }

    const onEditCompany = () => {

        if(company.agreementForDataSharing === true || publicAccess === false) {

            setIsLoading(true);
            if(uploadedUrl !== "") {
                fileToBase64(logo)
                    .then((encodedData) => {
                        editCompanyUsingPUT({
                            editCompanyRequest: {
                                companyId: company.id,
                                companyName: companyName,
                                companyEmail: companyEmail,
                                phoneNumber: phoneNumber,
                                description: description,
                                publishedForPublicAccess: publicAccess,
                                logoFile: {
                                    encodedData: encodedData,
                                    filename: logo.name,
                                    name: logo.name
                                }
                            }
                        })
                            .then((res) => {
                                setUploadedUrl("");
                                setCompanies(c => c.map((item, index) => {
                                    if (item.id === company.id) {
                                        res.data.employerName = res.data.employers[0].name;
                                        res.data.employerEmail = res.data.employers[0].email;
                                        return res.data
                                    } else {
                                        return item
                                    }
                                }))
                                setErrorMessage('')
                                setIsLoading(false)
                                setOpen(false)
                            })
                            .catch((error) => {
                                setErrorMessage(error.response.data)
                                setIsLoading(false)
                            })
                    })
            }
            else {
                editCompanyUsingPUT({
                    editCompanyRequest: {
                        companyId: company.id,
                        companyName: companyName,
                        companyEmail: companyEmail,
                        phoneNumber: phoneNumber,
                        description: description,
                        publishedForPublicAccess: publicAccess
                    }
                })
                    .then((res) => {
                        console.log(res.data)
                        setCompanies(c => c.map((item, index) => {
                            if (item.id === company.id) {
                                res.data.employerName = res.data.employers[0].name;
                                res.data.employerEmail = res.data.employers[0].email;
                                console.log(item);
                                return res.data
                            } else {
                                return item
                            }
                        }))
                        setErrorMessage('')
                        setIsLoading(false)
                        setOpen(false)
                    })
                    .catch((error) => {
                        setErrorMessage(error.response.data)
                        setIsLoading(false)
                    })
            }
        }
        else {
            setErrorMessage("Właściciel nie pozwolił na udostępnianie danych jego firmy")
        }
    };

    // TODO poprawić wygląd na urządzenia mobilne

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'lg'} fullWidth={true}>
            <BigContainer>
                <LeftContainer>
                    <DialogTitle title={"Zmień dane firmy"}/>
                    <InputField
                    type={"text"}
                    value={companyName}
                    setValue={setCompanyName}
                    placeholder={"Nazwa firmy"}
                    icon={<HiOutlineOfficeBuilding size={20} color={Colors.LIGHT_GREY}/>}
                    />
                    <InputField
                        type={"text"}
                        value={companyEmail}
                        setValue={setCompanyEmail}
                        placeholder={"Email firmy"}
                        icon={<IoMailOutline size={20} color={Colors.LIGHT_GREY}/>}
                    />
                    <InputField
                        type={"text"}
                        value={phoneNumber}
                        setValue={setPhoneNumber}
                        placeholder={"Numer telefonu"}
                        icon={<HiOutlinePhone size={20} color={Colors.LIGHT_GREY}/>}
                    />
                    <LongInputField
                        value={description}
                        setValue={setDescription}
                        placeholder={"Opis"}
                        icon={<HiDocumentText size={20} color={Colors.LIGHT_GREY}/>}
                        rows={4}
                    />
                    <BrowseFileField selectedFile={logo} setSelectedFile={setNewLogo} placeholder={"logo..."}/>
                    <ErrorAndLoadingMessage errorMessage={errorMessage} isLoading={isLoading}/>
                    <DialogButtonsContainer onlySmallScreen={false}>
                        <ButtonWithoutIcon
                            label={"Anuluj"}
                            onClick={() => setOpen(false)}
                        />
                        <ButtonWithoutIcon
                            label={"Zaktualizuj"}
                            onClick={() => onEditCompany(company.id)}
                            backgroundColor={Colors.GREEN}
                        />
                    </DialogButtonsContainer>
                </LeftContainer>
                <RightContainer>
                    <DialogTitle title={"Podgląd logo"}/>
                    <Logo logoUrl={company.logoUrl} title={""}/>
                    {uploadedUrl !== "" ? <Logo logoUrl={uploadedUrl} title={""}/> : null}
                </RightContainer>
            </BigContainer>
        </Dialog>
    )
}

const BigContainer = styled.div`
  margin-bottom: 40px;
  display: flex;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-start;
  align-items: center;
`;

const LeftContainer = styled(Container)`
  flex: 2;
`;

const RightContainer = styled(Container)`
  flex: 1;
`;
export default EditCompanyDialog;