import {Dialog} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import ProgressPointsLine from "./ProgressPointsLine";
import EvaluatorsInfo from "./EvaluatorsInfo";
import DialogTitle from "../DialogTitle";

const EvaluationProgressDialog = ({open, setOpen, worker}) => {

    const self = worker !== null && worker.selfEvaluation !== null ? worker.selfEvaluation : null;
    const coworker = worker !== null && worker.coworkerEvaluation !== null ? worker.coworkerEvaluation : null;
    const supervisor = worker !== null  && worker.supervisorEvaluation !== null ? worker.supervisorEvaluation : null;

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'md'} fullWidth={true}>
            <DialogTitle title={"Szczegóły dotyczące procesu oceniania pracownika " + worker.name}/>
            <Container>
                <EvaluatorsInfo self={self} coworker={coworker} supervisor={supervisor}/>
                <ProgressPointsLine self={self} coworker={coworker} supervisor={supervisor}/>
            </Container>
        </Dialog>
    );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0 75px 0;
`;

export default EvaluationProgressDialog;