import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {makeStyles} from "@material-ui/core/styles";
import QuizQuestionTile from "./QuizQuestionTile";
import {cloneDeep} from "lodash";

const QuizItems = ({quiz, setQuiz, leftAlign=false}) => {

    const classes = useStyles()

    const handleChangeAnswer = (index, value) => {
        const newQuiz = cloneDeep(quiz)
        const newQuestion = newQuiz.questions[index]
        newQuestion.answers.forEach((ans) => {ans.chosen = false})
        newQuestion.answers[value].chosen = true
        newQuiz.questions[index] = newQuestion
        setQuiz(newQuiz)
    };

    return (
        <div className={classes.container}>
            {quiz.questions.map((item, index) => (
                <QuizQuestionTile
                    key={item.id}
                    index={index}
                    question={item}
                    handleChangeAnswer={(value) => handleChangeAnswer(index, value)}
                    even={index % 2 === 0}
                    leftAlign={leftAlign}
                />
            ))}
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        marginTop: "3%",
    },
}));

export default QuizItems;