import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../constants/colors";
import styled from "styled-components";
import {FiEdit} from "react-icons/fi";

const ModerationInfo = ({comment, setEditionOpen}) => {

    const classes = useStyles();
    const role = localStorage.getItem("role");

    return (
        <div className={classes.container}>
            {comment.version > 0 ? (
                <div className={classes.infoModerated}>
                    zmoderowany
                </div>
            ) : null}
            {role === "ADMIN" ? (
                <EditButton onClick={() => setEditionOpen(true)}>
                    <FiEdit size={25} color={Colors.TURQUOISE}/>
                    <div className={classes.moderateLabel}>Moderuj</div>
                </EditButton>
            ) : null}
        </div>
    )
}


const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "flex-end",
    },
    infoModerated: {
        fontSize: 16,
        color: Colors.TURQUOISE,
        marginRight: 40,
        [theme.breakpoints.down(700)]: {
            fontSize: 15,
        }
    },
    moderateLabel: {
        fontSize: 16,
        fontWeight: "bold",
        color: Colors.TURQUOISE,
        marginLeft: 5,
        marginTop: 2,
        [theme.breakpoints.down(700)]: {
            display: "none"
        }
    },
}));

const EditButton = styled.button`
  display: flex;
  align-items: center;
  background-color: unset;
  border-width: 0;
  text-decoration: none;
  transition: opacity 0.3s;
  cursor: pointer;

  &:focus, &:hover, &:active {
    outline: none;
  }

  &:hover, &:active {
    opacity: 0.5;
  }
`;

export default ModerationInfo;