import {TableBody, TableCell, TableRow} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import OrganisationAnswer from "./OrganisationAnswer";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import MotivatorsOrganisationEvaluation from "./MotivatorsOrganisationEvaluation";

const OrganisationQuestion = ({data, answers, triedToFinish, motivators, motivatorTitle}) => {

    const classes = useStyles()
    const [questions, setQuestions] = useState([])

    useEffect(() => {
        setQuestions(data.subQuestions)
    }, [data])

    return (
        <React.Fragment>
            <Table stickyHeader>
                <TableHead>
                    <TableRow classes={{root: classes.row}}>
                        {data !== null ? (
                            <React.Fragment>
                                <TableCell classes={{root: classes.cellHeadRoot}}>{data.name}</TableCell>
                                <TableCell classes={{root: classes.cellRoot}}> Tak </TableCell>
                                <TableCell classes={{root: classes.cellRoot}}> Nie </TableCell>
                            </React.Fragment>
                        ) : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {questions.map((item, index) => (
                        <OrganisationAnswer
                            key={index}
                            triedToFinish={triedToFinish}
                            data={item}
                            name={item.firstColumn}
                            answers={answers}
                            hasAnswer={item.secondColumn !== null}
                            even={index % 2 === 0}/>
                    ))}
                </TableBody>
            </Table>
            {data.motivators === true ?
                <MotivatorsOrganisationEvaluation
                    triedToFinish={triedToFinish}
                    motivators={motivators}
                    motivatorTitle={motivatorTitle}
                /> : null}
        </React.Fragment>
    )
}

const useStyles = makeStyles(theme => ({
    row: props => ({
        backgroundColor: Colors.VERY_LIGHT_GREY,
    }),
    cellHeadRoot: {
        fontSize: 15,
        width: "50%",
        border: "none",
    },
    cellRoot: {
        fontSize: 15,
        width: "25%",
        border: "none",
        textAlign: "center"
    },

}));

export default OrganisationQuestion;