import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import {fileToBase64} from "../../../services/FilesService";
import {uploadEmployerFileUsingPOST} from "../../../swagger/vue-api-client";
import {BsFileText} from "react-icons/bs";
import InputField from "../../InputField";
import BrowseFileField from "../../BrowseFileField";
import DialogTitle from "../../DialogTitle";
import DialogButtonsContainer from "../../DialogButtonsContainer";

const AddFileDialog = ({open, setOpen, setFiles}) => {

    const [selectedFile, setSelectedFile] = useState(null)
    const [name, setName] = useState("")
    const [description, setDescription] = useState("")

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onFileUpload = () => {
        if(selectedFile !== null) {
            setIsLoading(true)
            fileToBase64(selectedFile)
                .then((encodedData) => {
                    uploadEmployerFileUsingPOST(
                        {fileDto: {
                            filename: selectedFile.name,
                            name: name,
                            encodedData: encodedData,
                            description: description
                        }})
                        .then((res) => {
                            setFiles(c => [...c, res.data])
                            setIsLoading(false)
                            setOpen(false)
                            setName('')
                            setDescription('')
                            setSelectedFile(null)
                        })
                        .catch((error) => {
                            setErrorMessage(error.response.data)
                            setIsLoading(false)
                        })
                })
        }
        else {
            setErrorMessage("Wybierz plik");
        }
    };

    const handleSelectFile = (file) => {
        setSelectedFile(file)
        setName(file.name)
    }

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle title={"Dodaj wybrany plik"}/>
            <Container>
                <BrowseFileField selectedFile={selectedFile} setSelectedFile={handleSelectFile} placeholder={"plik..."}/>
                <InputField
                    value={name}
                    setValue={setName}
                    placeholder={"Nazwa pliku"}
                    icon={<BsFileText size={20} color={Colors.LIGHT_GREY}/>}
                />
                <InputField
                    value={description}
                    setValue={setDescription}
                    placeholder={"Opis pliku"}
                    icon={<BsFileText size={20} color={Colors.LIGHT_GREY}/>}
                />
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"Anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"Dodaj plik"}
                        onClick={onFileUpload}
                        backgroundColor={Colors.GREEN}
                    />
                </DialogButtonsContainer>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px 10% 20px 10%;
  width: 80%;
`;

export default AddFileDialog;