import React, {useContext, useEffect, useState} from "react";
import EvaluationTile from "./EvaluationTile";
import {WorkerContext} from "../../contexts/WorkerContext";
import Title from "../Title";
import {makeStyles} from "@material-ui/core/styles";
import OrganisationEvaluationTile from "./OrganisationEvaluationTile";

const FinishedEvaluations = ({history, setIsFinished, sent, role}) => {

    const classes = useStyles()

    const workerContext = useContext(WorkerContext)
    const [isSth, setIsSth] = useState(false)

    useEffect(() => {
        setIsSth(false)
        setIsFinished(false)
        workerContext.evaluations.forEach((item, index) => {
            if (item.status === "SENT") {
                setIsSth(true)
                setIsFinished(true)
            }
        })
    }, [setIsFinished, workerContext.evaluations])

    if (isSth || sent === true) {
        return (
            <div className={classes.container}>
                <div className={classes.marginLeft}><Title title={"Ocenione"}/></div>
                <div className={classes.items}>
                    {workerContext.evaluations.map((item, index) => {
                        if (item.status === "SENT") {
                            return <EvaluationTile evaluation={item} key={index} history={history} finished={true}/>
                        } else { return null }
                    })}
                    {sent === true && role === "EMPLOYER" ? <OrganisationEvaluationTile history={history} finished={true}/> : null}
                </div>
            </div>
        )
    } else {
        return null
    }
}

const useStyles = makeStyles(theme => ({
    container: {
        width: "70%",
        margin: "5% 15%",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
    items: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        [theme.breakpoints.down(1100)]: {
            gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.down(700)]: {
            gridTemplateColumns: "1fr",
        }
    },
    marginLeft: {
        marginLeft: 20,
    }
}));

export default FinishedEvaluations;