import {
    getWorkerSelfEvaluationSummaryUsingGET,
    getWorkerSummaryUsingGET,
    manageEvaluationUsingPOST,
    updateEvaluationUsingPOST
} from "../swagger/vue-api-client";
import {toast} from "react-toastify";

export const getEvaluationStatus = (worker) => {
    // ZAKOŃCZONA, W TRAKCIE lub BRAK
    // ASSIGNED - przypisana, STARTED- rozpoczęta, SENT - wysłana
    const self = worker.selfEvaluation != null ? worker.selfEvaluation.status : null;
    const coworker = worker.coworkerEvaluation != null ? worker.coworkerEvaluation.status : null;
    const supervisor = worker.supervisorEvaluation != null ? worker.supervisorEvaluation.status : null;

    if (self === null && coworker === null && supervisor === null) {
        return "BRAK";
    } else if ((self === null || self === 'SENT') &&
        (coworker === null || coworker === 'SENT') &&
        (supervisor === null || supervisor === 'SENT')) {
        return "ZAKOŃCZONA";
    } else {
        return "W TRAKCIE";
    }
};

export const getTypeLabel = (value) => {
    if (value === "SELF")
        return "samoocena"
    else if (value === "COWORKER")
        return "ocena współpracownika"
    else if (value === "SUPERVISOR")
        return "ocena podwładnego"
}

export async function getWorkerEvaluationResult(workerId) {

    let regexCapitalLetters = /^[A-ZÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŚŹŻŽ∂ð /-]+$/u

    return getWorkerSummaryUsingGET({workerId: workerId})
        .then((response) => {
            let types = []
            let type = {
                competences: [],
                name: "",
                result: 0,
            }
            response.data.forEach((item, index) => {
                if (regexCapitalLetters.test(item.competenceName)) {
                    if (type.name !== "")
                        types.push(type)
                    type = {
                        competences: [],
                        name: item.competenceName,
                        result: item.competenceLevelGap.toFixed(2),
                    }
                } else {
                    type.competences.push({
                        name: item.competenceName,
                        result: item.competenceLevelGap.toFixed(2),
                    })
                }
            })
            if (type.name !== "")
                types.push(type)

            return types
        })
}

export async function getWorkerSelfEvaluationResult(workerId) {

    let regexCapitalLetters = /^[A-ZÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŚŹŻŽ∂ð /-]+$/u

    return getWorkerSelfEvaluationSummaryUsingGET({workerId: workerId})
        .then((response) => {
            let types = []
            let type = {
                competences: [],
                name: "",
                result: 0,
            }
            response.data.forEach((item, index) => {
                if (regexCapitalLetters.test(item.competenceName)) {
                    if (type.name !== "")
                        types.push(type)
                    type = {
                        competences: [],
                        name: item.competenceName,
                        result: item.competenceLevelGap.toFixed(2),
                    }
                } else {
                    type.competences.push({
                        name: item.competenceName,
                        result: item.competenceLevelGap.toFixed(2),
                    })
                }
            })
            if (type.name !== "")
                types.push(type)

            return types
        })
}

const handleApprovalChangeSingle = (evaluation, publishApproval) => {
    if(evaluation !== null) {
        updateEvaluationUsingPOST({
            updateEvaluationRequest: {
                evaluationId: evaluation.id,
                publishApproval: publishApproval
            }
        })
            .catch((error) => {
                console.log("Nie udało się zmienić widoczności ewaluacji: ", error.response.data)
            })
    }
}

export const handleApprovalChange = (worker, setOpen, publishApproval) => {

    const self = worker !== null && worker.selfEvaluation !== null ? worker.selfEvaluation : null;
    const coworker = worker !== null && worker.coworkerEvaluation !== null ? worker.coworkerEvaluation : null;
    const supervisor = worker !== null  && worker.supervisorEvaluation !== null ? worker.supervisorEvaluation : null;

    handleApprovalChangeSingle(self, publishApproval)
    handleApprovalChangeSingle(coworker, publishApproval)
    handleApprovalChangeSingle(supervisor, publishApproval)
    setOpen(false);
}

export const createEvaluationData = (enabled, workerId, evaluatorId, competences, publishApproval) => {
    return { enabled, workerId, evaluatorId, competences, publishApproval };
}

export const mapCompetenceToEvaluationCompetence = (competenceType) => {
    return {
        enabled: false,
        id: competenceType.id,
        name: competenceType.name,
        shortName: competenceType.shortName,
        competences: competenceType.competences.map((i) => {
            return {
                enabled: false,
                id: i.id,
                name: i.name
            }
        })
    }
}

const getSentEvaluationToastMessage = (success, evaluationType) => {
    if (success) {
        return "Udało się poprawnie dodać prośbę o ocenienie " +
            (evaluationType.toString() === "SELF" ? "siebie" :
                evaluationType.toString() === "COWORKER" ? "współpracownika":
                    "przełożonego")
    } else {
        return "Nie dało się poprawnie dodać prośby o ocenienie " +
            (evaluationType.toString() === "SELF" ? "siebie" :
                evaluationType.toString() === "COWORKER" ? "współpracownika":
                    "przełożonego")
    }
}

export const sendNewEvaluation = (evaluation, evaluationName, evaluationType, waitingRequests, employerContext,
                                  setOpen, setIsLoading, worker, setErrorMessage, eLearningSources) => {
    if (evaluation.enabled && evaluation.competences.length > 0) {
        waitingRequests++
        manageEvaluationUsingPOST({
            manageEvaluationRequest: {
                workerId: evaluation.workerId,
                evaluatorId: evaluation.evaluatorId,
                type: evaluationType,
                competences: evaluation.competences,
                publishApproval: evaluation.publishApproval,
                elearningSources: eLearningSources
            }
        })
            .then((response) => {
                let newWorkers = [...employerContext.workers];
                newWorkers = newWorkers.map((item, index) => {
                    if (item.id === worker.id) {
                        item[evaluationName] = response.data;
                        item.status = getEvaluationStatus(item);
                    }
                    return item;
                })
                setOpen(false);
                employerContext.setWorkers(newWorkers);
                if (waitingRequests === 1) {
                    setIsLoading(false)
                }
                waitingRequests--
                toast.success(getSentEvaluationToastMessage(true, evaluationType))
            })
            .catch((error) => {
                console.log("Wystąpił błąd. ", error.response.data)
                setErrorMessage("Wystąpił błąd. " + error.response.data)
                if (waitingRequests === 1) {
                    setIsLoading(false)
                }
                waitingRequests--
                toast.error(getSentEvaluationToastMessage(false, evaluationType))
            })
    }
}

export const isPublished = (self, coworker, supervisor) => {

    let role = localStorage.getItem("role")
    if (role !== "WORKER")
        return true

    return (self !== null && self !== undefined && self.publishApproval === true) ||
        (coworker !== null && coworker !== undefined && coworker.publishApproval === true) ||
        (supervisor !== null && supervisor !== undefined && supervisor.publishApproval === true);
}