import React from "react";
import {makeStyles} from "@material-ui/core/styles";

const GetQuizResultComment = ({question, keyword, answerData, globalAmount}) => {

	const classes = useStyles();

	// PYTANIE 1
	if (question.questionText.includes("W jakim stopniu wykorzystują Państwo obecnie rozwiązania z zakresu: ")) {

		let indexOfMaxValue = indexOfMax(answerData)
		let maxValue = answerData[indexOfMaxValue].value
		let maxValuePercentage = maxValue / globalAmount * 100
		let maxName = answerData[indexOfMaxValue].name
		let description

		switch (maxName) {
			case "w ogóle":
				description = "Należy obserwować rozwiązanie. Potencjalnie może ono jeszcze nie być w ogóle znane na rynku, więc jest szansa na uzyskanie wysokiego stopnia przewagi konkurencyjnej, jeśli wykazuje się znaczącym potencjałem rynkowym."
				break;
			case "w niewielkim zakresie":
				description = "Należy obserwować rozwiązanie. Potencjalnie może ono jeszcze nie być znane na rynku, więc jest szansa na uzyskanie wysokiego stopnia przewagi konkurencyjnej, jeśli wykazuje się znaczącym potencjałem rynkowym."
				break;
			case "w umiarkowanym zakresie":
				description = "Rozwiązanie może mieć znaczący potencjał przewagi konkurencyjnej, nie jest jeszcze szeroko stosowane, ale część podmiotów już zidentyfikowała jego potencjał rozwojowy."
				break;
			case "w znacznym zakresie":
				description = "Rozwiązanie jest już dość powszechnie stosowane, jego wdrożenie może nie wiązać się z przewagą konkurencyjną, a raczej z podążaniem za branżą, co nie znaczy, że rozwiązanie to nie może przynieść wymiernych korzyści ekonomicznych."
				break;
			case "w dominującym zakresie":
				description = "Rozwiązanie jest już szeroko stosowane, jego wdrożenie może nie wiązać się z przewagą konkurencyjną, a raczej z podążaniem za branżą, co nie znaczy, że rozwiązanie to nie może przynieść wymiernych korzyści ekonomicznych."
				break;
			default:
				description = ""
		}

		return (
			<div className={classes.comment}>
				Zdecydowana większość respondentów - <b>{maxValuePercentage}%</b> wskazała, że rozwiązanie z zakresu <b>{keyword}</b> jest stosowane <b>{maxName}</b>.
				<br/>
				{description}
				{ globalAmount < 20 ?
					<div style={{color: "red"}}>
						(należy uwzględnić, że zbadano na niskoliczebnej grupie respondentów)
					</div> : null
				}
			</div>
		);
	}

	// PYTANIE 2
	if (question.questionText.includes("W jakim stopniu planują Państwo wdrożyć rozwiązania z zakresu: ")) {

		let indexOfMaxValue = indexOfMax(answerData)
		let maxValue = answerData[indexOfMaxValue].value
		let maxValuePercentage = maxValue / globalAmount * 100
		let maxName = answerData[indexOfMaxValue].name
		let description

		switch (maxName) {
			case "w bardzo niewielkim stopniu":
				description = "Należy przeanalizować powody braku zainteresowania wdrożeniem rozwiązania, możliwe, że nie jest ono efektywne bądź ma niski potencjał rynkowy lub też wysokie bariery techniczne wdrożenia. Powodem może być również brak świadomości w tym zakresie."
				break;
			case "w niewielkim stopniu":
				description = "Należy przeanalizować powody braku zainteresowania wdrożeniem rozwiązania, możliwe, że nie jest ono efektywne bądź ma niski potencjał rynkowy. Powodem może być również niski poziom świadomości w tym zakresie, jednak zidentyfikowane plany wdrożeniowe wskazują, że najprawdopodobniej jest technicznie możliwe do implementacji."
				break;
			case "w umiarkowanym stopniu":
				description = "Należy śledzić wdrożenie rozwiązania – może mieć wpływ na sytuację konkurencyjną na rynku."
				break;
			case "w dużym stopniu":
				description = "Należy uważnie śledzić wdrożenie rozwiązania – może mieć istotny wpływ na sytuację konkurencyjną na rynku."
				break;
			case "w bardzo dużym stopniu":
				description = "Należy bardzo uważnie śledzić wdrożenie rozwiązania – prawdopodobnie będzie kluczowe dla sytuacji konkurencyjnej w branży."
				break;
			default:
				description = ""
		}

		return (
			<div className={classes.comment}>
				Zdecydowana większość respondentów - <b>{maxValuePercentage}%</b> wskazała, że rozwiązanie z zakresu <b>{keyword}</b> jest planowane do wdrożenia <b>{maxName}</b>.
				<br/>
				{description}
				{ globalAmount < 20 ?
					<div style={{color: "red"}}>
						(należy uwzględnić, że zbadano na niskoliczebnej grupie respondentów)
					</div> : null
				}
			</div>
		);
	}

	// PYTANIE 3
	if (question.questionText.includes("Jak oceniają Państwo potencjał rynkowy wdrożenia rozwiązań z zakresu: ")) {

		let indexOfMaxValue = indexOfMax(answerData)
		let maxValue = answerData[indexOfMaxValue].value
		let maxValuePercentage = maxValue / globalAmount * 100
		let maxName = answerData[indexOfMaxValue].name
		let description

		switch (maxName) {
			case "niski potencjał":
				description = "Prawdopodobnie rozwiązanie nie znajdzie znaczącej grupy odbiorców – może cechować je niszowość."
				break;
			case "umiarkowany potencjał":
				description = "Prawdopodobnie rozwiązanie nie będzie wiodącym na rynku, ale ma potencjał do uzyskania udziału w rynku."
				break;
			case "wysoki potencjał":
				description = "Prawdopodobnie rozwiązanie może być wiodącym na rynku."
				break;
			default:
				description = ""
		}

		return (
			<div className={classes.comment}>
				Zdecydowana większość respondentów - <b>{maxValuePercentage}%</b> wskazała, że rozwiązanie z zakresu <b>{keyword}</b> ma <b>{maxName}</b> rynkowy.
				<br/>
				{description}
				{ globalAmount < 20 ?
					<div style={{color: "red"}}>
						(należy uwzględnić, że zbadano na niskoliczebnej grupie respondentów)
					</div> : null
				}
			</div>
		);
	}

	// PYTANIE 4
	if (question.questionText.includes("Jak oceniają Państwo trwałość zapotrzebowania na rozwiązania z zakresu: ")) {

		let indexOfMaxValue = indexOfMax(answerData)
		let maxValue = answerData[indexOfMaxValue].value
		let maxValuePercentage = maxValue / globalAmount * 100
		let maxName = answerData[indexOfMaxValue].name
		let description

		switch (maxName) {
			case "krótkookresowa":
				description = "Prawdopodobnie rozwiązanie może być chwilową „modą”, inwestycja we wdrożenie może okazać się nieefektywna."
				break;
			case "średniookresowa":
				description = "Prawdopodobnie rozwiązanie może być „modne” przez dłuższy okres czasu, inwestycja we wdrożenie może okazać się efektywna."
				break;
			case "długookresowa":
				description = "Prawdopodobnie rozwiązanie może stać się trwałym elementem w branży meblowej, inwestycja we wdrożenie może okazać się efektywna."
				break;
			default:
				description = ""
		}

		return (
			<div className={classes.comment}>
				Zdecydowana większość respondentów - <b>{maxValuePercentage}%</b> wskazała, że rozwiązanie z zakresu <b>{keyword}</b> cechuje <b>{maxName}</b> trwałość zapotrzebowania.
				<br/>
				{description}
				{ globalAmount < 20 ?
					<div style={{color: "red"}}>
						(należy uwzględnić, że zbadano na niskoliczebnej grupie respondentów)
					</div> : null
				}
			</div>
		);
	}

	// PYTANIE 5
	if (question.questionText.includes("W jakich miejscach poszukują Państwo informacji na temat innowacji w branży usług opiekuńczych?")) {

		let indexOfMaxValue = indexOfMax(answerData)
		let maxValue = answerData[indexOfMaxValue].value
		let maxValuePercentage = maxValue / globalAmount * 100
		let maxName = answerData[indexOfMaxValue].name

		return (
			<div className={classes.comment}>
				Zdecydowana większość respondentów - <b>{maxValuePercentage}%</b> wskazała, że dla poszukiwania nowych rozwiązań / trendów w branży usług opiekuńczych najlepszym źródłem są <b>{maxName}</b>.
				{ globalAmount < 20 ?
					<div style={{color: "red"}}>
						(należy uwzględnić, że zbadano na niskoliczebnej grupie respondentów)
					</div> : null
				}
			</div>
		);
	}

	function indexOfMax(arr) {
		if (arr.length === 0) {
			return -1;
		}

		var max = arr[0].value;
		var maxIndex = 0;

		for (var i = 1; i < arr.length; i++) {
			if (arr[i].value > max) {
				maxIndex = i;
				max = arr[i].value;
			}
		}

		return maxIndex;
	}
}

const useStyles = makeStyles(theme => ({
	comment: {
		textAlign: "center",
		marginTop: 20,
	},
}));

export default GetQuizResultComment;