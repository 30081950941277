import {FormControlLabel} from "@material-ui/core";
import styled from "styled-components";
import TurquoiseCheckbox from "../TurquoiseCheckbox";

const CheckboxForm = ({value, setValue, label, margin="5%", disabled=false}) => {

    return (
        <Container margin={margin}>
            <FormControlLabel
                control={
                    <TurquoiseCheckbox
                        checked={value}
                        onChange={() => {value ? setValue(false) : setValue(true)}}
                    />}
                label={label}
                disabled={disabled}
            />
        </Container>
    );
};


const Container = styled.div`
  display: flex;
  flex: 1;
  margin: ${props => props.margin};
  justify-content: flex-start;
  align-items: center;
`;

export default CheckboxForm;