import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";
import {Tooltip} from "@material-ui/core";

const DevelopmentPathHeader = ({recommendation, fontSize = 17, fontColor = Colors.GREEN}) => {

    const classes = useStyles({fontSize, fontColor})

    const getGapInterpretation = () => {
        let role = localStorage.getItem("role")
        let description = ""

        if (role === "WORKER")
            description = recommendation.gapInterpretationWorker
        else if (role === "EMPLOYER")
            description = recommendation.gapInterpretationEmployer

        let firstLetter = description.charAt(0).toUpperCase()
        return firstLetter + description.slice(1)
    }

    return (
        <div className={classes.container}>
            <div className={classes.header}>
                <div className={classes.competenceName}>{recommendation.competenceName}</div>
                <Tooltip title={getGapInterpretation()} placement={"top"}>
                    <div className={classes.gap}>
                        poziom luki kompetencyjnej: {recommendation.competenceLevelGap === null ?
                        null : recommendation.competenceLevelGap.toFixed(1)}
                    </div>
                </Tooltip>
            </div>
            <div className={classes.description}>{recommendation.competenceDescription}</div>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        marginBottom: 20,
        width: "100%",
    },
    header: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginBottom: 5,
        [theme.breakpoints.down(800)]: {
            flexDirection: "column",
            alignItems: "center",
        }
    },
    competenceName: props => ({
        marginRight: 20,
        color: props.fontColor,
        fontWeight: "bold",
        textTransform: "uppercase",
        fontSize: props.fontSize,
        [theme.breakpoints.down(800)]: {
            marginBottom: 10,
            textAlign: "center",
        }
    }),
    gap: props => ({
        color: Colors.ALMOST_BLACK,
        transition: "all 0.3s",
        "&:hover": {
            color: props.fontColor,
        }
    }),
    description: {
        color: Colors.MEDIUM_GREY,
        textAlign: "justify",
        textJustify: "inter-word",
    },
}));

export default DevelopmentPathHeader;