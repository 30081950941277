import React from "react";
import styled from "styled-components";
import ELearningSourceGroup from "./ELearningSourceGroup";
import {makeStyles} from "@material-ui/core/styles";

const ELearningSourcesForm = ({eLearningSources}) => {

    const classes = useStyles()

    const getELearningSourceGroup = (group) => (
        <ELearningSourceGroup
            group={group}
        />
    );

    return (
        <Container>
            <div className={classes.form}>
                {getELearningSourceGroup(eLearningSources)}
            </div>
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    form: {
        display: "grid"
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0 5%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export default ELearningSourcesForm;