import React, {useState} from 'react';
import {Colors} from "../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import {IoPersonOutline, MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/all";
import OnlyIconButton from "../buttons/OnlyIconButton";
import {handleSignOut} from "../../services/LinksService";
import ButtonWithoutIcon from "../buttons/ButtonWithoutIcon";
import ChangePasswordDialog from "../ChangePasswordDialog";
import OnlyTextButton from "../buttons/OnlyTextButton";

const RoleDependantUserMenu = ({role, history}) => {

    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [changePassword, setChangePassword] = useState(false)

    return (
        <div className={classes.container}>
            <OnlyIconButton
                icon={
                    <React.Fragment>
                        <IoPersonOutline size={40} color={Colors.GREY}/>
                        {open ? (
                            <MdKeyboardArrowUp size={30} color={Colors.GREY}/>
                        ) : (
                            <MdKeyboardArrowDown size={30} color={Colors.GREY}/>
                        )}
                    </React.Fragment>
                }
                onClick={() => setOpen(c => !c)}
            />
            {open ? (
                <div className={classes.content}>
                    <OnlyTextButton
                        text={"Pokaż profil"}
                        onClick={() => history.push("/profil")}
                    />
                    {role === "EMPLOYER" ? (
                        <OnlyTextButton
                            text={"Pokaż firmę"}
                            onClick={() => history.push("/firma")}
                        />
                    ) : null}
                    <OnlyTextButton
                        text={"Zmień hasło"}
                        onClick={() => setChangePassword(true)}
                    />
                    <ButtonWithoutIcon
                        label={"wyloguj się"}
                        onClick={() => handleSignOut(history)}
                    />
                </div>
            ) : null}
            <ChangePasswordDialog open={changePassword} setOpen={setChangePassword}/>
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 1vw",
        backgroundColor: Colors.WHITE,
    },
    content: {
        position: "fixed",
        top: 110,
        right: "2vw",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: Colors.LIGHT_GREY,
        backgroundColor: Colors.WHITE,
        padding: "1%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 2,
    },
}));

export default RoleDependantUserMenu;