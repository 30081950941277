import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized} from "../../services/LinksService";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import Title from "../../components/Title";
import Result5Table from "../../components/financesResult/Result5Table";
import {formatNumber} from "../../services/NumberService";
import {getFinancialAnalysisStepTwoOutputThreeDataUsingGET} from "../../swagger/vue-api-client";
import ButtonWithoutIcon from "../../components/buttons/ButtonWithoutIcon";

const FinancialResult5 = ({history}) => {

    const classes = useStyles()
    const [result, setResult] = useState([])

    useEffect(() => {
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((response) => {
                return getFinancialAnalysisStepTwoOutputThreeDataUsingGET()
            })
            .then((res) => {
                console.log(res.data)
                setResult(res.data.rows.map((item, index) => {
                    let help = Object.assign(item)
                    help.value = formatNumber(item.cost)
                    return help
                }))
            })
            .catch((error) => {
                console.log(error);
            })
    }, [history])

    const print = () => {
        window.print();
    }


    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <Title title={"Wynik analizy kosztów i korzyści (zestawienie robocze)"}/>
                <div className={classes.tableContainer}>
                    <Result5Table result={result}/>
                </div>
                <div className={classes.buttonsContainer}>
                    <ButtonWithoutIcon
                        label={"Drukuj"}
                        onClick={() => print()}
                    />
                </div>
            </div>
            <Footer/>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
    buttonsContainer : {
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [`@media print`]: {
            display: "none"
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default FinancialResult5;