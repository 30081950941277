import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized} from "../../services/LinksService";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router";
import Title from "../../components/Title";
import {ToastContainer} from "react-toastify";
import {assignedByIdUsingGET} from "../../swagger/vue-api-client";
import Subheader from "../../components/Subheader";
import ButtonWithoutIcon from "../../components/buttons/ButtonWithoutIcon";
import {Colors} from "../../constants/colors";
import {downloadDiplomaFile} from "../../services/FilesService";

const RecommendationSummaryPage = ({history}) => {

    const classes = useStyles()
    let {recommendationId} = useParams()
    const [recommendation, setRecommendation] = useState(null)

    useEffect(() => {
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((res) => {
                return assignedByIdUsingGET({eLearningId: recommendationId})
            })
            .then((res) => {
                setRecommendation(res.data);
            })
            .catch((error) => {
                console.log(error);
            })
    }, [history, recommendationId])

    if (recommendation === null)
        return null

    let attemptLeft = 3 - recommendation.attemptCount

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <Title title={recommendation.name}/>
                <div className={classes.container}>
                    <Subheader title={"Podsumowanie testu"}/>
                    <div className={classes.description}>
                        {recommendation.quizCompleted ?
                            "Gratulacje! Ukończyłeś e-learning!"
                            :
                            "Niestety nie udało Ci się ukończyć e-learningu. Do ukończenia wymagane jest uzyskanie co najmniej 70% wszystkich punktów."
                        }
                    </div>
                    <div className={classes.result}>
                        {recommendation.quizScore === null || recommendation.quizScore === undefined ? null : "Twój wynik: " + recommendation.quizScore + "%"}
                    </div>
                    <div className={classes.result}>
                        {"Pozostałe próby: " + attemptLeft}
                    </div>
                    {recommendation.quizCompleted ?
                        <div className={classes.button}>
                            <ButtonWithoutIcon
                                label={"Pobierz zaświadczenie"}
                                onClick={() => downloadDiplomaFile(recommendationId, recommendation.name)}
                            />
                        </div>
                        : null
                    }
                </div>
                <ToastContainer/>
            </div>
            <Footer/>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
    container: {
        display: "flex",
        width: 760,
        margin: "0 20px",
        height: 300,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
    },
    description: {
        marginBottom: "7%",
        fontSize: 17,
        color: Colors.GREY,
        textAlign: "center",
        [theme.breakpoints.down(600)]: {
            textAlign: "center",
            marginTop: 10,
        }
    },
    result: {
        fontSize: 18,
        marginBottom: "2%",
        [theme.breakpoints.down(600)]: {
            textAlign: "center",
            marginTop: 10,
        }
    },
    button: {
        marginTop: "5%",
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default RecommendationSummaryPage;