import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import {MdShortText, MdSubject} from "react-icons/md";
import {createPostUsingPOST} from "../../../swagger/vue-api-client";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import LongInputField from "../../LongInputField";
import DialogTitle from "../../DialogTitle";
import {convertPost} from "../../../services/WorkerService";
import DialogButtonsContainer from "../../DialogButtonsContainer";

const AddPostDialog = ({open, setOpen, setPosts}) => {

    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCreatePost = (title, content) => {

        if (title === "") {
            setErrorMessage("Tytuł posta nie może być pusty")
        } else if (content === "") {
            setErrorMessage("Treść posta nie może być pusta")
        } else {
            setIsLoading(true)

            createPostUsingPOST({
                postDto: {
                    title: title,
                    content: content
                }
            })
                .then((response) => {
                    setOpen(false);
                    setErrorMessage('');
                    setPosts(current => [...current, convertPost(response.data)]);
                    setIsLoading(false);
                })
                .catch((error) => {
                    if (error.response) {
                        setErrorMessage(error.response.data);
                    } else {
                        setErrorMessage("Wystąpił błąd podczas publikacji")
                    }
                    setIsLoading(false);
                });
        }
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'lg'} fullWidth={true}>
            <DialogTitle title={"Dodaj nowy post"}/>
            <Container>
                <LongInputField
                    value={title}
                    setValue={setTitle}
                    placeholder={"Tytuł"}
                    icon={<MdShortText size={20} color={Colors.LIGHT_GREY}/>}
                    rows={"1"}
                />
                <LongInputField
                    value={content}
                    setValue={setContent}
                    placeholder={"Treść posta"}
                    icon={<MdSubject size={20} color={Colors.LIGHT_GREY}/>}
                    rows={"20"}
                />
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"Anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"Opublikuj"}
                        onClick={() => handleCreatePost(title, content)}
                        backgroundColor={Colors.GREEN}
                    />
                </DialogButtonsContainer>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 5%;
  width: 90%;
`;

export default AddPostDialog;