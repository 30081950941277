import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React from "react";
import HeadingCells from "../HeadingCells";
import {makeStyles} from "@material-ui/core/styles";

const headCells = [
    {id: 'title', label: 'Temat', align: 'center'},
    {id: 'author', label: 'Autor', align: 'center'},
    {id: 'creationDate', label: 'Data', align: 'center'},
    {id: 'comments', label: 'Komentarze', align: 'center'},
];

const ForumTableHeading = ({order, orderBy, setOrder, setOrderBy}) => {

    const classes = useStyles()

    return (
        <TableHead>
            <TableRow className={classes.row}>
                <HeadingCells setOrderBy={setOrderBy} orderBy={orderBy} setOrder={setOrder} order={order} headCells={headCells}/>
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles(theme => ({
    row: {
        '& > *': {
            border: 0,
            padding: 0,
            margin: 0,
        },
        [theme.breakpoints.down(600)]: {
            display: "none",
        }
    },
}));

export default ForumTableHeading;