import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Subheader from "../Subheader";
import {Colors} from "../../constants/colors";
import OrganisationELearnings from "./OrganisationELearnings";

const IndustryKnowledge = ({result}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Subheader title={"Znajomość branży"} justify={"left"}/>
            <div className={classes.content}>
                <div className={classes.item}>
                    <div>Poziom analizy sytuacji w branży i śledzenia nowych trendów</div>
                    <div className={classes.answer}>{result.industryKnowledge}</div>
                </div>
            </div>
            <OrganisationELearnings eLearnings={result.industryKnowledgeELearnings}/>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 50,
        marginBottom: 30,
    },
    content: {
        lineHeight: 1.5,
    },
    item: {
        display: "flex",
        marginBottom: 15,
        color: Colors.GREY,
    },
    answer: {
        marginLeft: "10%",
        color: Colors.BLACK,
    },
}));

export default IndustryKnowledge;