import React, {useState} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import {Container, TableFooter} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ButtonWithIcon from "../../buttons/ButtonWithIcon";
import TableRow from "@material-ui/core/TableRow";
import PostTableRow from "./PostTableRow";
import {getComparator, stableSort} from "../../../services/SortService";
import AddCommentDialog from "./AddCommentDialog";
import TitleWithButton from "../../TitleWithButton";
import Pagination from "../Pagination";
import {makeStyles} from "@material-ui/core/styles";
import GetQuizResultDialog from "./QetQuizResultDialog";

const PostTable = ({comments, setComments, post, setPost, history}) => {

    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [addComment, setAddComment] = useState(false);
    const [showQuiz, setShowQuiz] = useState(false);

    return (
        <Container>
            <TitleWithButton title={post === null ? "" : post.title} button={
                <div className={classes.buttonContainer}>
                        <ButtonWithIcon
                            icon={<AddIcon size={25}/>}
                            onClick={() => setAddComment(true)}
                            label={"Dodaj komentarz"}
                        />
                </div>
            }/>
            <TableContainer>
                <Table>
                    <TableRow>
                        <Pagination
                            rows={comments}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </TableRow>
                    <TableBody>
                        {stableSort(comments, getComparator('asc', 'creationDate'))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((comment, index) =>
                                <PostTableRow
                                    key={comment.id}
                                    post={post}
                                    setPost={setPost}
                                    comment={comment}
                                    setComments={setComments}
                                    setShowQuiz={setShowQuiz}
                                    history={history}
                                    isPost={index === 0}
                                />
                            )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <Pagination
                                rows={comments}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <AddCommentDialog open={addComment} setOpen={setAddComment} setComments={setComments} post={post}/>
            <GetQuizResultDialog open={showQuiz} setOpen={setShowQuiz} quiz={post !== null ? post.quiz : null}/>
        </Container>
    );
};

const useStyles = makeStyles(theme => ({
    buttonContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        [theme.breakpoints.down(600)]: {
            flexDirection: "column",
        }
    },
    buttonWrapper: {
        margin: "5px",
    }
}))

export default PostTable;
