import React from 'react';
import styled from "styled-components";
import {Colors} from "../constants/colors";

const RightWhiteContainer = ({children}) => {

    return (
            <Container>{children}</Container>
    );
};
const Container = styled.div`
  display: flex;
  background-color: ${Colors.WHITE};
  flex: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
`;

export default RightWhiteContainer;