import {editCommentUsingPOST, editPostUsingPOST} from "../swagger/vue-api-client";

export const editPost = async (post, content, title, setPost, setComments) => {

    return editPostUsingPOST({
        postDto: {
            id: post.id,
            content: content,
            title: title,
            authorId: post.author.id,
        }
    })
        .then((res) => {
            setPost(res.data)
            setComments(current => current.map((item, index) => {
                if (item.id === 0) {
                    return {
                        id: 0,
                        author: res.data.author,
                        creationDate: res.data.creationDate,
                        content: res.data.content,
                        version: res.data.version,
                    }
                } else {
                    return item;
                }
            }))
            return '';
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data
            } else {
                 return "Wystąpił błąd podczas zapisywania"
            }
        })
}


export const editComment = async (comment, content, setComments) => {

    return editCommentUsingPOST({
        commentDto: {
            id: comment.id,
            content: content,
            authorId: comment.author.id,
        }
    })
        .then((res) => {
            setComments(current => current.map((item, index) => {
                return item.id === comment.id ? res.data : item
            }))
            return '';
        })
        .catch((error) => {
            if (error.response) {
                return error.response.data
            } else {
                return "Wystąpił błąd podczas zapisywania"
            }
        })
}