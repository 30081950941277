import React, {useState} from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import StageSummary from "../../recommendation/StageSummary";
import {Colors} from "../../../constants/colors";
import {getRoleDependentLink} from "../../../services/LinksService";
import {makeStyles} from "@material-ui/core/styles";

const RecommendationTableRow = ({recommendation, setRecommendation}) => {

    const classes = useStyles()
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false)

    const getTasks = () => {
        if (recommendation.recommendationForCompanySummaryList === null)
            return []
        else
            return recommendation.recommendationForCompanySummaryList
    }

    const getWorkersRecommendations = (workersList) => {
        if (workersList === null)
            return []
        else
            return workersList
    }

    const handleChangeItem = (index, item, at, newWorkerList) => {
        setIsLoading(true)
        let newItems = [...getTasks()]
        let newItem = item
        item[at] = newWorkerList
        newItems.splice(index, 1, newItem)
        let newRecommendation = recommendation
        newRecommendation.recommendationForCompanySummaryList = newItems
        setRecommendation(newRecommendation)
        setIsLoading(false)
    }

    return (
        <React.Fragment>
            <TableRow>
                <TableCell>{recommendation.competenceName}</TableCell>
                <TableCell>{recommendation.competenceLevelGap.toFixed(2)}</TableCell>
                <TableCell/>
                <TableCell/>
                <TableCell/>
            </TableRow>
            {getTasks().map((item, index) => {
                if (item.workerCountStageOne === 0) {
                    return null
                } else {
                    return (
                        <TableRow key={index}>
                            <TableCell/>
                            <TableCell/>
                            <TableCell>1</TableCell>
                            <TableCell>
                                {item.recommendation.id ? (
                                    <a href={process.env.REACT_APP_BASE_PATH + getRoleDependentLink("/zalecenia/" + item.recommendation.id)} className={classes.link}>
                                        {item.recommendation.name}
                                    </a>
                                ) : (
                                    <a className={classes.link}>
                                        {item.recommendation.name}
                                    </a>
                                )}
                            </TableCell>
                            <TableCell>
                                <StageSummary
                                    workersRecommendations={getWorkersRecommendations(item.workerListStageOne)}
                                    setWorkerRecommendations={(newWorkerList) => handleChangeItem(index, item, "workerListStageOne", newWorkerList)}
                                    color={Colors.TURQUOISE}
                                    title={recommendation.competenceName + ": " + item.recommendation.name}
                                />
                            </TableCell>
                        </TableRow>
                    )
                }
            })}
            {getTasks().map((item, index) => {
                if (item.workerCountStageTwo === 0) {
                    return null
                } else {
                    return (
                        <TableRow key={index}>
                            <TableCell/>
                            <TableCell/>
                            <TableCell>2</TableCell>
                            <TableCell>
                                {item.recommendation.id ? (
                                    <a href={process.env.REACT_APP_BASE_PATH + getRoleDependentLink("/zalecenia/" + item.recommendation.id)} className={classes.link}>
                                        {item.recommendation.name}
                                    </a>
                                ) : (
                                    <a className={classes.link}>
                                        {item.recommendation.name}
                                    </a>
                                )}
                            </TableCell>
                            <TableCell>
                                <StageSummary
                                    workersRecommendations={getWorkersRecommendations(item.workerListStageTwo)}
                                    setWorkerRecommendations={(newWorkerList) => handleChangeItem(index, item, "workerListStageTwo", newWorkerList)}
                                    color={Colors.GREEN}
                                    title={recommendation.competenceName + ": " + item.recommendation.name}
                                />
                            </TableCell>
                        </TableRow>
                    )
                }
            })}
        </React.Fragment>
    );
}

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: "none",
        color: Colors.ALMOST_BLACK,
        transition: "all 0.3s",
        "&:hover": {
            color: Colors.TURQUOISE,
        }
    },
}));

export default RecommendationTableRow;