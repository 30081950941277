import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import {MdSubject} from "react-icons/md";
import {commentPostUsingPOST} from "../../../swagger/vue-api-client";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import LongInputField from "../../LongInputField";
import DialogTitle from "../../DialogTitle";
import DialogButtonsContainer from "../../DialogButtonsContainer";

const AddCommentDialog = ({open, setOpen, setComments, post}) => {

    const [content, setContent] = useState('')

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCreatePost = (content) => {
        if (content === "") {
            setErrorMessage("Treść komentarza nie może być pusta")
        } else {
            setIsLoading(true)

            commentPostUsingPOST({
                commentDto: {
                    content: content,
                    postId: post.id,
                }
            })
                .then((response) => {
                    setOpen(false);
                    setErrorMessage('');
                    setComments(current => [...current, response.data])
                    setIsLoading(false);
                })
                .catch((error) => {
                    if (error.response) {
                        setErrorMessage(error.response.data);
                    } else {
                        setErrorMessage("Wystąpił błąd podczas publikacji")
                    }
                    setIsLoading(false);
                });
        }
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'md'}
                fullWidth={true}>
            <DialogTitle title={"Dodaj komentarz"}/>
            <Container>
                <LongInputField
                    value={content}
                    setValue={setContent}
                    placeholder={"Treść komentarza"}
                    icon={<MdSubject size={20} color={Colors.LIGHT_GREY}/>}
                    rows={"20"}
                />
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"Anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"Opublikuj"}
                        onClick={() => handleCreatePost(content)}
                        backgroundColor={Colors.GREEN}
                    />
                </DialogButtonsContainer>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 5%;
  width: 90%;
`;

export default AddCommentDialog;