import {Radio, withStyles} from "@material-ui/core";
import React from "react";
import {Colors} from "../constants/colors";

const TurquoiseRadio = ({checked, onChange, value, disabledProp=false}) => {

    return (
        <CustomColorRadio
            checked={checked}
            onChange={onChange}
            value={value}
            disabled={disabledProp}
        />
    )
}

const CustomColorRadio = withStyles({
    root: {
        color: Colors.TURQUOISE,
        '&$checked': {
            color: Colors.TURQUOISE,
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

export default TurquoiseRadio;