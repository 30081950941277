import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized, getRoleDependentLink} from "../../services/LinksService";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import {useParams} from "react-router";
import Title from "../../components/Title";
import ErrorAndLoadingMessage from "../../components/ErrorAndLoadingMessage";
import {toast, ToastContainer} from "react-toastify";
import ButtonWithoutIcon from "../../components/buttons/ButtonWithoutIcon";
import QuizItems from "../../components/quiz/QuizItems";
import {finishELearningUsingPOST, saveQuizResultsUsingPOST} from "../../swagger/vue-api-client";

const QuizPage = ({history}) => {

    const classes = useStyles()
    let {recommendationId} = useParams()
    const [quiz, setQuiz] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [show, setShow] = useState(false)

    useEffect(() => {
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((res) => {
                return finishELearningUsingPOST({eLearningId: recommendationId})
            })
            .then((res) => {
                if (res.status === 200) {
                    setQuiz(res.data);
                    setShow(true)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [history, recommendationId])


    const handleFinishQuiz = () => {
        setIsLoading(true)

        let sthIsNull = false

        quiz.questions.forEach((item, index) => {
            if (!(item.answers.some((ans) => ans.chosen === true))) {
                sthIsNull = true
            }
        })

        if (sthIsNull) {
            setIsLoading(false)
            toast.error("Nie wszystkie zadania zostały rozwiązane.")
        } else {
            let config = {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
            saveQuizResultsUsingPOST({eLearningId: recommendationId, quizDto: JSON.stringify(quiz), $config: config})
                .then((response) => {
                    history.push(getRoleDependentLink("/sciezka/" + recommendationId + "/podsumowanie"))
                    toast.success("Sukces! Zapisano quiz. Twój wynik: " + response.data + '%')
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                    toast.error("Nie udało się zapisać wyników quizu! Pamiętaj, że masz tylko 3 podejścia.")
                    setIsLoading(false)
                })
        }
    }

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                {show ?
                    <React.Fragment>
                        <Title title={quiz.name}/>
                        <QuizItems quiz={quiz} setQuiz={setQuiz}/>
                    </React.Fragment> : null
                }
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={""}/>
                <ButtonsContainer>
                    <ButtonWithoutIcon
                        label={"Zakończ"}
                        onClick={handleFinishQuiz}
                    />
                </ButtonsContainer>
                <ToastContainer/>
            </div>
            <Footer/>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

const ButtonsContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default QuizPage;