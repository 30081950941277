import {Dialog} from "@material-ui/core";
import React, {useState} from "react";
import {RiLockPasswordLine} from "react-icons/ri";
import InputField from "./InputField";
import {Colors} from "../constants/colors";
import ErrorAndLoadingMessage from "./ErrorAndLoadingMessage";
import ButtonWithoutIcon from "./buttons/ButtonWithoutIcon";
import {employerEditCompanyUsingPUT} from "../swagger/vue-api-client";
import DialogTitle from "./DialogTitle";
import DialogButtonsContainer from "./DialogButtonsContainer";
import {makeStyles} from "@material-ui/core/styles";
import {HiDocumentText, HiOutlinePhone} from "react-icons/hi";
import LongInputField from "./LongInputField";
import CheckboxForm from "./askForEvaluation/CheckboxForm";

const EditCompanyByEmployerDialog = ({open, setOpen, company, setCompany}) => {

    const classes = useStyles()

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [companyName, setCompanyName] = useState(company.name);
    const [companyEmail, setCompanyEmail] = useState(company.email);
    const [phoneNumber, setPhoneNumber] = useState(company.phoneNumber);
    const [description, setDescription] = useState(company.description);
    const [agreementForDataSharing, setAgreementForDataSharing] = useState(company.agreementForDataSharing);
    const [oldCompany, setOldCompany] = useState(company);

    if(company !== oldCompany) {
        setOldCompany(company)
        setCompanyName(company.name)
        setCompanyEmail(company.email)
        setPhoneNumber(company.phoneNumber)
        setDescription(company.description)
        setAgreementForDataSharing(company.agreementForDataSharing)
    }

    const handleDataChange = () => {
        setIsLoading(true);
        employerEditCompanyUsingPUT({
            editCompanyRequest: {
                companyId: company.id,
                companyName: companyName,
                companyEmail: companyEmail,
                phoneNumber: phoneNumber,
                description: description,
                agreementForDataSharing: agreementForDataSharing,
            }
        })
            .then((res) => {
                setCompany(res.data)
                setIsLoading(false)
                setOpen(false)
            })
            .catch((error) => {
                setErrorMessage("Podano niepoprawne dane.")
                setIsLoading(false)
            })
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle title={"Zmień dane firmy"}/>
            <div className={classes.container}>
                <InputField
                    type={"text"}
                    value={companyName}
                    setValue={setCompanyName}
                    placeholder={"Nazwa firmy"}
                    icon={<RiLockPasswordLine size={20} color={Colors.LIGHT_GREY}/>}
                />
                <InputField
                    type={"text"}
                    value={companyEmail}
                    setValue={setCompanyEmail}
                    placeholder={"Email firmy"}
                    icon={<RiLockPasswordLine size={20} color={Colors.LIGHT_GREY}/>}
                />
                <InputField
                    type={"text"}
                    value={phoneNumber}
                    setValue={setPhoneNumber}
                    placeholder={"Numer telefonu"}
                    icon={<HiOutlinePhone size={20} color={Colors.LIGHT_GREY}/>}
                />
                <LongInputField
                    value={description}
                    setValue={setDescription}
                    placeholder={"Opis"}
                    icon={<HiDocumentText size={20} color={Colors.LIGHT_GREY}/>}
                    rows={4}
                />
                <CheckboxForm
                    type={"checkbox"}
                    value={agreementForDataSharing}
                    setValue={setAgreementForDataSharing}
                    label={"Chcę aby dane firmy wyświetlały się w bazie podmiotów"}
                />
                <ErrorAndLoadingMessage isLoading={isLoading} errorMessage={errorMessage}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        onClick={() => setOpen(false)}
                        label={"Anuluj"}
                    />
                    <ButtonWithoutIcon
                        label={"Zmień"}
                        backgroundColor={Colors.GREEN}
                        onClick={handleDataChange}
                    />
                </DialogButtonsContainer>
            </div>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "7%",
    },
}));

export default EditCompanyByEmployerDialog;