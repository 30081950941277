import {Dialog} from "@material-ui/core";
import React, {useContext, useState} from "react";
import styled from "styled-components";
import {Colors} from "../../../constants/colors";
import {deleteWorkerUsingDELETE} from "../../../swagger/vue-api-client";
import ButtonWithoutIcon from "../../buttons/ButtonWithoutIcon";
import {EmployerContext} from "../../../contexts/EmployerContext";
import ErrorAndLoadingMessage from "../../ErrorAndLoadingMessage";
import DialogTitle from "../../DialogTitle";
import DialogButtonsContainer from "../../DialogButtonsContainer";

const DeleteWorkerDialog = ({open, setOpen, worker}) => {

    const employerContext = useContext(EmployerContext)

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const onDeleteWorker = () => {

        setIsLoading(true);

        deleteWorkerUsingDELETE({id: worker.id})
            .then((response) => {
                employerContext.setWorkers(current => current.filter(w => w.id !== worker.id));
                setErrorMessage('');
                setIsLoading(false);
                setOpen(false);
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    setErrorMessage("Nie udało się usunąć pracownika")
                }
                else {
                    setErrorMessage(error.response.data);
                }
                setIsLoading(false);
            });
    };

    return (
        <Dialog onClose={() => setOpen(false)} open={open} maxWidth={'sm'} fullWidth={true}>
            <DialogTitle title={"Czy na pewno chcesz usunąć pracownika " + worker.name + "?"}/>
            <Container>
                <ErrorAndLoadingMessage errorMessage={errorMessage} isLoading={isLoading}/>
                <DialogButtonsContainer>
                    <ButtonWithoutIcon
                        label={"anuluj"}
                        onClick={() => setOpen(false)}
                    />
                    <ButtonWithoutIcon
                        label={"usuń"}
                        onClick={() => onDeleteWorker(worker.id)}
                        backgroundColor={Colors.RED}
                    />
                </DialogButtonsContainer>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 20px 40px 20px;
  text-align: center;
`;

export default DeleteWorkerDialog;