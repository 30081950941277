import React from 'react';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import {TableCell, TableHead} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core/styles";
import Result1TableRow from "./Result1TableRow";

const Result1Table = ({result, workers, isVariant2, showDetails}) => {

    const height = window.innerHeight
    const classes = useStyles({height})

    return (
        <div>
            <TableContainer className={classes.container}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.headCell}>Zakres kompetencji</TableCell>
                            <TableCell className={classes.headCell}>Opis</TableCell>
                            <TableCell className={classes.headCell}>Realizacja wsparcia dla pojedynczego pracownika - SUMA KOSZTÓW [PLN]</TableCell>
                            {showDetails ? workers.map((item, index) => (
                                <TableCell className={classes.verticalText} key={index}>{item}</TableCell>
                            )) : null}
                            <TableCell className={classes.headCell}>Realizacja wsparcia dla wszystkich wskazanych pracowników - SUMA KOSZTÓW [PLN]</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {result.map((row, index) => <Result1TableRow key={index} row={row} showDetails={showDetails} isVariant2={isVariant2}/>)}
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer className={classes.printContainer}>
                <Table>
                    <TableBody className={classes.body}>
                        <TableRow>
                            <TableCell className={classes.headCell}>Zakres kompetencji</TableCell>
                            <TableCell className={classes.headCell}>Opis</TableCell>
                            <TableCell className={classes.headCell}>Realizacja wsparcia dla pojedynczego pracownika - SUMA KOSZTÓW [PLN]</TableCell>
                            {showDetails ? workers.map((item, index) => (
                                <TableCell className={classes.verticalText} key={index}>{item}</TableCell>
                            )) : null}
                            <TableCell className={classes.headCell}>Realizacja wsparcia dla wszystkich wskazanych pracowników - SUMA KOSZTÓW [PLN]</TableCell>
                        </TableRow>
                        {result.map((row, index) => <Result1TableRow key={index} row={row} showDetails={showDetails} isVariant2={isVariant2}/>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: props => ({
        maxHeight: props.height !== null ? props.height * 0.75 : 500,
        marginTop: 80,
        [`@media print`]: {
            display: "none"
        }
    }),
    printContainer: props => ({
        maxHeight: props.height !== null ? props.height * 0.75 : 500,
        marginTop: 80,
        display: "none",
        [`@media print`]: {
            display: "inline",
            overflow: "visible"
        }
    }),
    verticalText: {
        writingMode: "vertical-lr",
        textOrientation: "mixed",
        textAlign: "center",
        [`@media print`]: {
            fontWeight: "bold"
        }
    },
    headCell: {
        textAlign: "center",
        [`@media print`]: {
            fontWeight: "bold"
        }
    },
}));

export default Result1Table;