import {FormControlLabel, TableCell, TableRow} from "@material-ui/core";
import React, {useContext} from "react";
import {Colors} from "../../constants/colors";
import SelectWorker from "../SelectWorker";
import {WorkerContext} from "../../contexts/WorkerContext";
import {makeStyles} from "@material-ui/core/styles";
import TurquoiseCheckbox from "../TurquoiseCheckbox";

const SmallScreenEvaluatorsFormRow = ({label, evaluatorLabel = "", workerName, handleEnabledChange,
                                          handleEvaluatorChange, handleCompetencesChange, checked, evaluatorId,
                                          competences, workerId}) => {

    const classes = useStyles()
    const workerContext = useContext(WorkerContext)

    return (
        <TableRow className={classes.container}>
            <TableCell colSpan={6}>
                <div className={classes.cell}>
                    <FormControlLabel
                        control={
                            <TurquoiseCheckbox
                                checked={checked}
                                onChange={handleEnabledChange}
                            />}
                        label={label}
                    />
                    <div className={classes.checkboxes}>
                        {workerContext.competences.map((item, index) => {
                            if (item.shortName === "WO")
                                return null
                            else
                                return (
                                <div className={classes.checkboxWithLabel} key={index}>
                                    <TurquoiseCheckbox
                                        checked={competences.indexOf(item.id) !== -1}
                                        onChange={() => handleCompetencesChange(item.id)}
                                        disabled={!checked}
                                    />
                                    {item.shortName}
                                </div>
                        )})}
                    </div>
                    <div className={classes.evaluator}>
                        {label === "Samoocena" ? (
                            <div className={classes.workerName}>{workerName}</div>
                        ) : (
                            <SelectWorker
                                label={evaluatorLabel}
                                worker={evaluatorId}
                                setWorker={handleEvaluatorChange}
                                disabled={!checked}
                                excluded={workerId}
                            />
                        )}
                    </div>
                </div>
            </TableCell>
        </TableRow>
    );
};

const useStyles = makeStyles(theme => ({
    container: {
        [theme.breakpoints.up(700)]: {
            display: "none",
        }
    },
    cell: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 10,
    },
    checkboxes: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    checkboxWithLabel: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    evaluator: {
        display: "flex",
        justifyContent: "center",
    },
    workerName: {
        display: "flex",
        justifyContent: "center",
        marginTop: "10%",
        fontSize: 16,
        paddingBottom: 5,
        borderBottomWidth: 1,
        borderBottomColor: Colors.TURQUOISE,
        borderBottomStyle: "solid",
    }
}));

export default SmallScreenEvaluatorsFormRow;