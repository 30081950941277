import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../constants/colors";
import styled from "styled-components";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from 'recharts';
import GetQuizResultComment from "./GetQuizResultComment";

const GetQuizResultDialogRow = ({question, keyword, globalAmount}) => {

    const classes = useStyles();

    let data = []

    for (let i = 0; i < question.answers.length; ++i) {
        data.push({name: question.answers[i].answerText, value: question.answers[i].answerCount});
    }

    const COLORS = [Colors.BLUE, Colors.TURQUOISE, Colors.GREEN, Colors.MEDIUM_GREEN, Colors.DARK_TURQUOISE, Colors.DARKEST_GREEN, Colors.DARK_BLUE];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (
        <TableRow className={classes.root}>
            <TableCell>
                <SingleCell>
                    <div className={classes.metadata}>
                        <div className={classes.name}>
                            {question.questionText}
                        </div>
                        <div className={classes.chartContainer}>
                            <ResponsiveContainer width="90%" height="100%">
                                <PieChart>
                                    <Legend/>
                                    <Pie
                                        data={data}
                                        cx="50%"
                                        cy="50%"
                                        label={renderCustomizedLabel}
                                        labelLine={false}
                                        outerRadius={80}
                                        dataKey="value"
                                    >
                                        {data.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                        ))}
                                    </Pie>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                        <GetQuizResultComment
                            key={question.id}
                            question={question}
                            keyword={keyword}
                            answerData={data}
                            globalAmount={globalAmount}
                        />
                    </div>
                </SingleCell>
            </TableCell>
        </TableRow>
    );
}


const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            border: 0,
            padding: 0,
            paddingBottom: 20,
        },
    },
    metadata: {
        display: "flex",
        width: "100%",
        marginBottom: 15,
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "left",
        [theme.breakpoints.down(700)]: {
            alignItems: "flex-start",
        }
    },
    name: {
        fontSize: 20,
        fontWeight: "bold",
        margin: "5%",
        [theme.breakpoints.down(700)]: {
            fontSize: 16,
        }
    },
    chartContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 300,
    }
}));

const SingleCell = styled.div`
  background-color: ${Colors.PASTEL_TURQUOISE};
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 10px;
  align-items: flex-start;
`;

export default GetQuizResultDialogRow;