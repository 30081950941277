import {FormControlLabel, TableCell, TableRow} from "@material-ui/core";
import React, {useContext} from "react";
import SelectWorker from "../SelectWorker";
import {WorkerContext} from "../../contexts/WorkerContext";
import {makeStyles} from "@material-ui/core/styles";
import TurquoiseCheckbox from "../TurquoiseCheckbox";

const BigScreenEvaluatorsFormRow = ({label, evaluatorLabel="", workerName, handleEnabledChange, enabledCompetences,
                                        handleEvaluatorChange, handleCompetencesChange, checked, evaluatorId,
                                        competences, workerId}) => {

    const classes = useStyles()
    const workerContext = useContext(WorkerContext)

    return (
            <TableRow className={classes.bigScreenRow}>
                <TableCell>
                    <FormControlLabel
                        control={
                            <TurquoiseCheckbox
                                checked={checked}
                                onChange={handleEnabledChange}
                            />}
                        label={label}
                    />
                </TableCell>
                {workerContext.competences.map((item, index) => {
                    if (item.shortName === "WO")
                        return null
                    else
                        return (
                        <TableCell key={index + "-" + item.id} align="center">
                            <TurquoiseCheckbox
                                checked={competences.indexOf(item.id) !== -1}
                                onChange={() => handleCompetencesChange(item.id)}
                                disabled={!checked || enabledCompetences.indexOf(item.id) === -1}
                            />
                        </TableCell>
                )})}
                <TableCell align="center">
                    {label === "Samoocena" ? (
                            <div className={classes.workerName}>{workerName}</div>
                        ) : (
                            <SelectWorker
                                label={evaluatorLabel}
                                worker={evaluatorId}
                                setWorker={handleEvaluatorChange}
                                disabled={!checked}
                                excluded={workerId}
                            />
                        )}
                </TableCell>
            </TableRow>
    );
};

const useStyles = makeStyles(theme => ({
    form: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
        }
    },
    bigScreenRow: {
        [theme.breakpoints.down(700)]: {
            display: "none",
        }
    },
    workerName: {
        fontSize: 16,
    }
}));

export default BigScreenEvaluatorsFormRow;