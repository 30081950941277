import {isTokenValidUsingGET} from "../swagger/vue-api-client";

export const getRoleDependentLink = (commonPart) => {
    let role = localStorage.getItem("role");
    if (role === "ADMIN") {
        return "/admin" + commonPart;
    } else if (role === "EMPLOYER") {
        return "/pracodawca" + commonPart;
    } else if (role === "WORKER") {
        return "/pracownik" + commonPart;
    } else {
        return commonPart;
    }
};

export const getRoleIndependentLink = (pathname) => {
    let role = localStorage.getItem("role");
    if (role === "ADMIN") {
        return pathname.substring("/admin".length, pathname.length);
    } else if (role === "EMPLOYER") {
        return pathname.substring("/pracodawca".length, pathname.length);
    } else if (role === "WORKER") {
        return pathname.substring("/pracownik".length, pathname.length);
    }
};

async function checkToken () {
    return isTokenValidUsingGET()
}

export async function checkIsUserAuthorized (pageType, history) {
    let message = await checkToken()
        .then((res) => {
            let role = localStorage.getItem("role");
            if (role === pageType) {
                return "";
            } else {
                history.push(getRoleDependantHomePageLink(role));
                return "Użytkownik nie ma dostępu do tej strony.";
            }
        })
        .catch((err) => {
            history.push("/");

            localStorage.removeItem("token");
            localStorage.removeItem("role");
            localStorage.removeItem("name");
            localStorage.removeItem("id");
            localStorage.removeItem("companyId");
            localStorage.removeItem("email");

            return "Podany token użytkownika jest niepoprawny.";
        })

    if (message.length === 0) {
        return Promise.resolve()
    } else {
        return Promise.reject(message)
    }
}

const sendDataToMobileApp =  function () {
    if (!window.ReactNativeWebView) {
        return;
    }
    let message = {
        messageType: 'signout'
    }

    window.ReactNativeWebView.postMessage(JSON.stringify(message));
}

export const handleSignOut = (history) => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    localStorage.removeItem("id");
    localStorage.removeItem("companyId");
    localStorage.removeItem("email");
    
    sendDataToMobileApp();
    
    history.push("/");
}

export const getRoleDependantHomePageLink = (role) => {
    if (role === "ADMIN") {
        return "/admin/firmy"
    } else if (role === "EMPLOYER") {
        return "/pracodawca/pracownicy"
    } else if (role === "WORKER") {
        return "/pracownik/ocenianie"
    }
}
