import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Subheader from "../Subheader";
import {Colors} from "../../constants/colors";
import {IoStatsChart} from "react-icons/all";
import {Tooltip} from "@material-ui/core";
import OrganisationELearnings from "./OrganisationELearnings";

const Motivators = ({result}) => {

    const classes = useStyles()

    return (
        <div className={classes.container}>
            <Subheader title={"System motywacyjny"} justify={"left"}/>
            <div className={classes.columns}>
                <div className={classes.marginRight}>
                    <div className={classes.header}>Ranking wg pracowników</div>
                    {result.workersMotivatorsRanking.map((item, index) => (
                        <div key={index + "i"} className={classes.item}>
                            {index+1}. {item}
                            <Tooltip title={result.workersMotivatorsRankingDescriptions[index]} placement="right" arrow key={index}>
                                <div className={classes.icon}><IoStatsChart color={Colors.TURQUOISE} size={20}/></div>
                            </Tooltip>
                        </div>
                    ))}
                </div>
                <div>
                    <div className={classes.header}>Ranking wg przełożonego</div>
                    {result.employerMotivatorsRanking.map((item, index) => (
                        <div key={index} className={classes.item}>{index+1}. {item}</div>
                    ))}
                </div>
            </div>
            <OrganisationELearnings eLearnings={result.motivatorsELearnings}/>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 50,
        marginBottom: 30,
    },
    columns: {
        display: "flex",
        justifyContent: "space-between",
        lineHeight: 1.5,
        color: Colors.ALMOST_BLACK,
    },
    marginRight: {
        marginRight: 20,
    },
    header: {
        color: Colors.BLACK,
        textTransform: "uppercase",
        marginBottom: 20,
        fontSize: 17,
    },
    item: {
        display: "flex",
        alignItems: "center",
        marginBottom: 10,
    },
    icon: {
        marginLeft: 10,
    },
    eLearningItem: {
        display: "flex",
        alignItems: "center",
        marginTop: 15,
    },
    eLearningLabel: {
        margin: "0 10px",
        color: Colors.GREY,
    },
    link: {
        textDecoration: "none",
        color: Colors.BLACK,
        transition: "all 0.3s",
        "&:hover": {
            color: Colors.GREEN,
        }
    },
}));

export default Motivators;