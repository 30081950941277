import {
    downloadEmployerFileUsingGETURL,
    getDiplomaForUserUsingGETURL
} from "../swagger/vue-api-client";
import axios from "axios";

export async function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export async function downloadEmployerFile(file) {

    axios.get(downloadEmployerFileUsingGETURL({id: file.id}), {responseType: 'arraybuffer'})
        .then((response) => {
            console.log(response)
            let blob = new Blob([response.data], {type: response.headers['content-type']});
            if (!!window.MSInputMethodContext && !!document.documentMode) {
                window.navigator.msSaveOrOpenBlob(blob, file.filename);
            } else {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = file.filename;
                link.click();
            }

        })
        .catch((error) => {
            console.log(error)
        })
}

export async function downloadDiplomaFile(recommendationId, name) {

    const filename = "Zaświadczenie " + name + ".pdf"

    axios.get(getDiplomaForUserUsingGETURL({elearningId: recommendationId}), {responseType: 'arraybuffer'})
        .then((response) => {
            let blob = new Blob([response.data], {type: response.headers['content-type']});
            if (!!window.MSInputMethodContext && !!document.documentMode) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }

        })
        .catch((error) => {
            console.log(error)
        })
}

export async function downloadFileAsBlob(url, filename) {

    axios.get(url, {responseType: 'arraybuffer'})
        .then((response) => {
            let blob = new Blob([response.data], {type: response.headers['content-type']});
            if (!!window.MSInputMethodContext && !!document.documentMode) {
                window.navigator.msSaveOrOpenBlob(blob, filename);
            } else {
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }
        })
        .catch((error) => {
            console.log(error)
        })
}