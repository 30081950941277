import React, {useContext} from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import {EmployerContext} from "../../../contexts/EmployerContext";

const WorkerTablePagination = ({page, setPage, rowsPerPage, setRowsPerPage}) => {

    const employerContext = useContext(EmployerContext);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            count={employerContext.workers.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={''}
            labelDisplayedRows={({from, to, count}) => {return from + "-" + to + " z " + count}}
            page={page}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        />
    );
}

export default WorkerTablePagination;
