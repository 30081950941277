import React from 'react';
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import {Link} from "react-router-dom";

const VerticalMenuItem = ({to, label, current=false}) => {

    return (
        <StyledLink to={to} color={current ? Colors.TURQUOISE : Colors.BLACK}>{label}</StyledLink>
    );
};

const StyledLink = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  text-transform: uppercase;
  margin: 2% 0;
  color: ${params => params.color};
  width: 100%;
  height: 100%;
  transition: color 0.1s;

  &:hover, &:active {
    text-decoration: none;
    color: ${Colors.TURQUOISE};
  }
`;

export default VerticalMenuItem;