import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {checkIsUserAuthorized} from "../../services/LinksService";
import RoleDependantMenu from "../../components/menu/RoleDependantMenu";
import Footer from "../../components/Footer";
import {makeStyles} from "@material-ui/core/styles";
import Title from "../../components/Title";
import Result2Table from "../../components/financesResult/Result2Table";
import {formatNumber} from "../../services/NumberService";
import {getFinancialAnalysisStepOneOutputTwoDataUsingGET} from "../../swagger/vue-api-client";
import ResultPieChart from "../../components/financesResult/ResultPieChart";
import ButtonWithoutIcon from "../../components/buttons/ButtonWithoutIcon";

const FinancialResult2 = ({history}) => {

    const classes = useStyles()
    const [result, setResult] = useState([])
    const [chartData, setChartData] = useState(null)

    useEffect(() => {
        let role = localStorage.getItem("role")
        checkIsUserAuthorized(role, history)
            .then((response) => {
                return getFinancialAnalysisStepOneOutputTwoDataUsingGET()
            })
            .then((res) => {
                console.log(res.data)
                setChartData(res.data.chartData)
                setResult(res.data.rows.map((item, index) => {
                    let help = Object.assign(item)
                    help.sum = formatNumber(item.cost)
                    return help
                }))
            })
            .catch((error) => {
                console.log(error);
            })
    }, [history])

    const print = () => {
        window.print();
    }

    return (
        <Container>
            <RoleDependantMenu history={history}/>
            <div className={classes.content}>
                <Title title={"Wykaz kosztów organizacyjnych"}/>
                {chartData !== null ? <ResultPieChart chartData={chartData}/> : null}
                <Result2Table result={result}/>
            </div>
            <div className={classes.buttonsContainer}>
                <ButtonWithoutIcon
                    label={"Drukuj"}
                    onClick={() => print()}
                />
            </div>
            <Footer/>
        </Container>
    )
}

const useStyles = makeStyles(theme => ({
    content: {
        display: "flex",
        flex: 1,
        width: "70%",
        margin: "5% 15%",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "96%",
            margin: "5% 2%",
        }
    },
    tableContainer: {
        marginTop: 20,
    },
    settings: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 10,
    },
    settingsRight: {
        display: "flex",
        justifyContent: "flex-end",
        marginBottom: 10,
    },
    twoSideSwitch: {
        display: "flex",
        alignItems: "center",
    },
    leftSwitchLabel: {
        marginRight: 12,
    },
    buttonsContainer : {
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [`@media print`]: {
            display: "none"
        }
    },
}));

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100vh;
`;

export default FinancialResult2;